<template>
  <Expandable header="Medical Details" class="mb-10">
    <slot></slot>
  </Expandable>
</template>

<script>
import Expandable from "../../Expandable.vue";
export default {
  components: { Expandable },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style></style>
