<template>
  <div class="d-flex align-center">
    <b-button icon="x-octagon-fill" @click="dialog = true" variant="primary">
      Update</b-button
    >
    <b-modal
      tabindex="0"
      v-model="dialog"
      title="Update Organ Assessment Details"
      hide-footer
      scrollable
      ignore-enforce-focus-selector=".select2-search__field"
      size="md"
      centered
    >
      <form-wrapper @submit="submit">
        <div class="d-flex align-items-center">
          <b-row>
            <b-col  cols="12" md="6">
              <input-field
                label="Evaluation By"
                rules="required"
                v-model="form.inspection_by"
              ></input-field>
            </b-col>
            <b-col  cols="12" md="6">
              <input-field
                label="Evaluation Notes"
                rules="required"
                v-model="form.inspection_note"
              ></input-field>
            </b-col>
            <b-col  cols="12" md="6">
              <radio-group
                label="Status"
                rules="required"
                :options="options"
                v-model="form.organ_Inspection_status"
              ></radio-group>
            </b-col>
            <b-col  cols="12" md="6">
              <file-input-group
                label="Photo Upload 1"
                accept=".jpg,.jpeg,.png,.pdf"
                rules="file-image-pdf|file-size"
                v-model="form.inspection_file_1"
              ></file-input-group>
            </b-col>
            <b-col  cols="12" md="6">
              <file-input-group
                label="Photo Upload 2"
                accept=".jpg,.jpeg,.png,.pdf"
                rules="file-image-pdf|file-size"
                v-model="form.inspection_file_2"
              ></file-input-group>
            </b-col>
            <b-col  cols="12" md="6">
              <file-input-group
                label="Photo Upload 3"
                accept=".jpg,.jpeg,.png,.pdf"
                rules="file-image-pdf|file-size"
                v-model="form.inspection_file_3"
              ></file-input-group>
            </b-col>
            <b-col  cols="12" md="6">
              <file-input-group
                label="Photo Upload 4"
                accept=".jpg,.jpeg,.png,.pdf"
                rules="file-image-pdf|file-size"
                v-model="form.inspection_file_4"
              ></file-input-group>
            </b-col>
            <b-col  cols="12" md="6">
              <file-input-group
                label="Photo Upload 5"
                accept=".jpg,.jpeg,.png,.pdf"
                rules="file-image-pdf|file-size"
                v-model="form.inspection_file_5"
              ></file-input-group>
            </b-col>
            <b-col  cols="12" md="6">
              <file-input-group
                label="Photo Upload 6"
                accept=".jpg,.jpeg,.png,.pdf"
                rules="file-image-pdf|file-size"
                v-model="form.inspection_file_6"
              ></file-input-group>
            </b-col>
          </b-row>
        </div>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" class="ms-3" type="submit"
            >Submit
          </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import { ACCEPT_DECLINE } from "../../../../../assets/constants/recipient.constants";
import { openFile } from "../../../../../assets/js/common";
import donorService from "../../../../../assets/services/donor.service";
// import donorService from "../../../../../assets/services/donor.service";
import FileInputGroup from "../../../../form/FileInputGroup.vue";
import InputField from "../../../../form/InputField.vue";
import RadioGroup from "../../../../form/RadioGroup.vue";
import userHelper from "../../../../manageuser/user.helper";
// import userHelper from "../../../../manageuser/user.helper";
export default {
  components: { InputField, FileInputGroup, RadioGroup },
  props: {
    recipeint: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      dialog: false,
      form: {},
      list: [],
      options: ACCEPT_DECLINE,
    };
  },
  computed: {
    fields() {
      let doc = {
        first_name: "Name",
        phone_number_1: "Phone Number",
        photo: "View Transport Details",
        action: "Allocation Status",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
        });
      });
      return list;
    },
  },
  methods: {
    reset() {
      this.form = {};
    },
    edit(item) {
      const vm = this;
      Object.keys(vm.form).forEach((key) => {
        if (item[key] != undefined) vm.form[key] = item[key];
      });
    },
    open(file) {
      openFile(file);
    },
    async submit() {
      const vm = this;
      let {
        organ_allocation_id,
        donor,
        organ_id,
        final_donor_id,
        is_multi,
        organ_id_org,
      } = vm.item;
      if (is_multi) organ_id = organ_id_org;
      let { case_officer, donor_temp_registration_id } = donor;
      let { recipient_registration_id, recepeint_allocation_id, hospital_id } =
        vm.recipeint;
      let doc = {
        organ_inspection_id: 0,
        recepeint_allocation_id,
        organ_allocation_id,
        final_donor_id,
        donor_temp_registration_id,
        case_officer,
        hospital_id,
        organ_id,
        recepient_registration_id: recipient_registration_id,
        created_by: vm.created_by,
        updated_by: vm.created_by,
        dml_indicator: "I",
      };
      try {
        vm.$loader.show();
        let list = [];
        Object.keys(vm.form).forEach((key) => {
          list.push({
            key,
            value: vm.form[key],
          });
        });
        for (let item of list) {
          if (item.value instanceof File) {
            doc[item.key] = await userHelper.uploadContact(item.value);
          } else {
            doc[item.key] = item.value;
          }
        }
        let { data } = await donorService.organInspection(doc);
        let { status, msg } = data;
        vm.dialog = false;
        if (!status) {
          vm.$alert.show(msg);
        }
        vm.$emit("onSubmit");
        vm.$loader.hide();
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
  },
};
</script>

<style></style>
