<template>
  <div class="d-flex">
    <div class="flex-grow-1"></div>
    <b-button
      variant="outline-danger mx-3"
      @click="$router.push('/transplant-list')"
      >Cancel
    </b-button>
    <b-button variant="success" v-if="isSubmit" type="submit">Submit </b-button>
  </div>
</template>

<script>
import menuConstants from "../../../assets/constants/menu.constants";
export default {
  props: {
    status: [Number, String],
  },
  computed: {
    isCreate() {
      return this.$route.name == "transplant-registration";
    },
    isSubmit() {
      const { status, isHospitalAdmin, isHospitalOrganCoordinate } = this;
      let hasCreateAccess = this.hasCreateAccess(
        menuConstants.MANAGE_HOSPITAL_LICENCE
      );
      if (this.isCreate) return hasCreateAccess;
      let hasAccess = this.hasEditAccess(menuConstants.MANAGE_HOSPITAL_LICENCE);
      if (status == 4) return false;
      if (hasAccess) return true;
      if (
        (isHospitalAdmin || isHospitalOrganCoordinate) &&
        (status == 1 || status == 3)
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
