<template>
  <div>
    <edit-button @click="dialog = true"></edit-button>
    <b-modal
      tabindex="0"
      v-model="dialog"
      title="Position Change Reason"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
      ignore-enforce-focus-selector=".select2-search__field"
      size="sm"
      centered
    >
      <form-wrapper @submit="submit">
        <b-row class="pb-0">
          <b-col cols="12">
            <input-field v-model="index" type="number" label="Change Order" rules="required">
            </input-field>
          </b-col>

          <b-col cols="12">
            <text-area v-model="reason" label="Reason" rules="required">
            </text-area>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="success" type="submit">Submit</b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import EditButton from "../../buttons/EditButton.vue";
import InputField from "../../form/InputField.vue";
import TextArea from "../../form/TextArea.vue";
export default {
  props: {
    item: Object,
  },
  components: { TextArea, EditButton, InputField },
  data() {
    return {
      dialog: false,
      reason: "",
      index: "",
    };
  },
  methods: {
    async submit() {
      const vm = this;
      vm.$emit("onReason", {
        reason: vm.reason,
        newIndex: vm.index -1,
        oldIndex: vm.item.sno -1
      });
      vm.reason = "";
      vm.dialog = false;
    },
  },
};
</script>

<style></style>
