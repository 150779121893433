import http from '../js/axios'
class HospitalSerive {
  create(data) {
    return http.post('/recipient_registration/alf', data)
  }
  list() {
    return http.post('/recipient_registration/alf_list', {
      "dml_indicator": "S"
    })
  }
  pendingList() {
    return http.post('/recipient_registration/alf_list', {
      "dml_indicator": "PL"
    })
  }
  historyList() {
    return http.post('/recipient_registration/alf_list', {
      "dml_indicator": "HL"
    })
  }
  get(id) {
    return http.post('/recipient_registration/alf_liver_display', {
      "recipient_registration_id": id
    })
  }
  getALF(id) {
    return http.post('/recipient_registration/alf_list', {
      "alf_id": id,
      "dml_indicator": "E"
    })
  }
  getALFApproverList(id) {
    return http.post('/recipient_registration/alf_list', {
      "alf_id": id,
      "dml_indicator": "CL"
    })
  }
  requestALFReview(doc) {
    return http.post("/recipient_registration/alf_approval_status", doc);
  }
  updateStatus(data) {
    return http.post("/recipient_registration/alf_approval_status", data);
  }
  upload(data) {
    return http.post("/ByteArrayAPI/alf_files_upload_mapping", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  uploadFile(data) {
    return http.post("/ByteArrayAPI/alf_files_upload", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  removeFile(id) {
    return http.post('/donor_registration/donor_files_upload_delete', {
      "status": "4",
      "donor_investigations_fileupload_mapping_id": id
    })
  }
  masterALF() {
    return http.post("/recipient_registration/alf_list", {
      "dml_indicator": "MS"
    });
  }  
  checkALF() {
    return http.post("/recipient_registration/alf_generate_list", {
      "dml_indicator": "S"
    });
  }

}
export default new HospitalSerive()
