import { decode } from "../js/common";

export default {

    computed: {
        isCreate() {
            return this.$route.name == "donor-registration";
        },
        isUpdate() {
            let { name } = this.$route;
            return name == "update-donor" || name == "organ-allocation"
        },
        isOrganAllocation() {
            let { name } = this.$route;
            return name == "organ-allocation"
        },
        id() {
            return decode(this.$route.query.id)
        },
        final_donor_id() {
            return decode(this.$route.query.final_donor_id)
        }
    }
}