<template>
  <div>
    <div class="avatar-sm chat-btn" @click="show = true">
      <span class="avatar-btn rounded-circle btn-primary font-size-20">
        <b-icon icon="chat-text-fill"></b-icon>
      </span>
    </div>
    <div class="chat-window" :class="show ? 'show-chat' : 'hide-chat'">
      <div class="chat-header">
        <h5 class="font-weight-bold mb-0">Group Chat</h5>
        <div class="flex-grow-1"></div>
        <icon-button
          icon="x-circle"
          @click="show = false"
          variant="danger"
        ></icon-button>
      </div>
      <simplebar class="chat-messages" id="chat-messages" ref="messages">
        <ul class="list-unstyled">
          <li v-for="(item, i) in messages" :key="i" :class="isRight(item)">
            <div class="ctext-wrap">
              <div class="conversation-name" v-if="isUser(item)">
                {{ users[item.u] || "-" }}
              </div>
              <p class="mb-1">{{ item.m }}</p>
              <div class="chat-time mb-0 d-flex align-items-center">
                <i class="bx bx-time-five me-1"></i>
                <span>
                  {{ item.time }}
                </span>
              </div>
            </div>
          </li>
        </ul>
      </simplebar>

      <div class="chat-footer">
        <input
          type="text"
          v-model="message"
          class="flex-grow-1 form-control chat-input rounded"
          placeholder="Enter Message"
        />
        <b-button
          size="sm"
          icon
          variant="primary"
          class="rounded-circle ms-2"
          @click="sendMessage()"
        >
          <i class="mdi mdi-send"></i>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import IconButton from "../buttons/IconButton.vue";
import { DB } from "../../assets/js/firebase";
import {
  limitToLast,
  off,
  onChildAdded,
  onValue,
  orderByKey,
  query,
  ref,
  set,
} from "@firebase/database";
import { formatDate } from "../../assets/js/common";
import donorMixins from "../../assets/mixins/donor.mixins";
export default {
  components: {
    simplebar,
    IconButton,
  },
  data() {
    return {
      show: false,
      messages: [],
      message: "",
      users: {},
    };
  },
  mixins: [donorMixins],
  computed: {
    uid() {
      return this.user.fcm_uid;
    },
    ref() {
      return `/chat_rooms/${this.id}/messages`;
    },
  },
  beforeDestroy() {
    off(ref(DB, this.ref));
    off(ref(DB, "user_names"));
  },
  methods: {
    isUser(item) {
      if (item.u == this.uid) return false;
      return true;
    },
    isRight(item) {
      return item.u == this.uid ? "right" : "";
    },
    async sendMessage() {
      const vm = this;
      if (!vm.message) return;
      let ts = new Date().getTime();
      let chatRef = ref(DB, `${vm.ref}/${ts}`);
      try {
        await set(chatRef, {
          m: vm.message,
          u: vm.uid,
        });
        vm.message = "";
      } catch (error) {
        vm.$alert.show(error.message);
      }
    },
    listenChat() {
      const vm = this;
      let chatRef = ref(DB, vm.ref);
      let q = query(chatRef, orderByKey(), limitToLast(100));
      onChildAdded(q, (data) => {
        vm.messages.push({
          time: formatDate(Date(Number(data.key))),
          ...data.val(),
        });
        vm.scrollToBottom();
      });
      let userRef = ref(DB, "user_names");
      onValue(userRef, (snap) => {
        vm.users = snap.val();
      });
    },
    scrollToBottom() {
      setTimeout(() => {
        let $ = window.$;
        let chat = $("#chat-messages .simplebar-content-wrapper");
        chat.scrollTop(chat.prop("scrollHeight"));
      }, 100);
    },
    setName() {
      const vm = this;
      if (!vm.uid) return false;
      let { first_name, last_name } = vm.user;
      let name = `${first_name} ${last_name}`;
      let nameRef = ref(DB, `user_names/${vm.uid}`);
      set(nameRef, name);
    },
  },
  mounted() {
    this.setName();
    this.listenChat();
  },
};
</script>

<style lang="scss">
.chat {
  &-input {
    background: #eff2f7 !important;
    border-color: #eff2f7 !important;
  }
  &-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 10000;
    .avatar-btn {
      align-items: center;
      color: #fff;
      display: flex;
      font-weight: 500;
      height: 100%;
      justify-content: center;
      width: 100%;
    }
  }
  &-window {
    position: fixed;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    bottom: 80px;
    width: 320px;
    height: 400px;
    border-radius: 10px;
    border: 1px solid #eff2f7;
    z-index: 10000;
    background: white;
    right: 20px;
  }
  &-header {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eff2f7;
  }
  &-messages {
    height: 298px;
    padding: 10px;
    li {
      clear: both;
    }
    .ctext-wrap {
      margin-bottom: 10px;
      padding: 12px;
      float: left;
      background-color: rgba(85, 110, 230, 0.1);
      border-radius: 8px 8px 8px 0;
      overflow: hidden;
      .conversation-name {
        font-weight: 600;
        color: #556ee6;
        margin-bottom: 4px;
      }
      .chat-time {
        font-size: 8px;
      }
    }
    .right {
      .ctext-wrap {
        float: right;
        background-color: #eff2f7;
        text-align: right;
        border-radius: 8px 8px 0 8px;
      }
      .chat-time {
        justify-content: end;
      }
    }
  }

  @keyframes showhide {
    from {
      transform: scale(0.5);
      opacity: 0;
    }
  }
  &-footer {
    border-top: 1px solid #eff2f7;
    padding: 10px;
    display: flex;
    height: 50px;
  }
}
.hide-chat {
  display: none;
  animation-name: showhide;
  animation-duration: 0.5s;
  transform: scale(1);
  opacity: 1;
}
.show-chat {
  display: block;
  animation-name: showhide;
  animation-duration: 0.5s;
  transform: scale(1);
  opacity: 1;
}
</style>
