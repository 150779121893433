<template>
  <div>
    <table-view :fields="fields" :items="table" :isBusy="isLoading">
      <div class="d-flex mt-3">
        <div class="flex-grow-1"></div>
        <b-button
          variant="success"
          v-if="table.length && !isFinal"
          @click="submit()"
          >Submit</b-button
        >
      </div>
      <template v-slot:action="{ item }">
        <position-change-reason
          @onReason="onReason"
          :item="item"
        ></position-change-reason>
      </template>
    </table-view>
  </div>
</template>

<script>
import { encode, formatDate } from "../../../assets/js/common";
import recipientService from "../../../assets/services/recipient.service";
import PositionChangeReason from "./PositionChangeReason.vue";
import TableView from "../../table/TableView.vue";

export default {
  components: { PositionChangeReason, TableView },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isFinal: {
      type: Boolean,
      default: false,
    },
    type: {},
    list: Array,
  },
  data() {
    return {
      isDraftLoading: false,
      table: [],
    };
  },
  computed: {
    fields() {
      let doc = {
        sno: "S.No",
        recipient_registration_id: "UID",
        final_rank: "Final Status",
        zone_rank: "Zonal Rank",
        upcoming_rank: "Upcoming Status",
        first_name: "Name",
        transtan_status: "Patient Status",
        group_desc: "Blood Group",
        age: "Age",
        gender: "Gender",
        created_on: "Date of Registration",
        hospital_name: "Hospital Name",
        organ_name: "Organs Requested",
        // payment_status: "Payment Status",
        // CM_insurance: "CM Insurance",
        phone_number_1: "Phone Number",
        inhouse_reason: "Reason",
        action: "Action",
      };
      if (this.type == 2) {
        delete doc.upcoming_rank;
        delete doc.action;
      }
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
        });
      });
      return list;
    },
  },
  watch: {
    list(val) {
      let list = [];
      val.forEach((item) => {
        list.push(item);
      });
      let upcommingZero = list.filter((item) => {
        return item.upcoming_rank == 0;
      });
      let finalZero = list.filter((item) => {
        return item.final_rank == 0;
      });
      let isFinalZero = finalZero.length == list.length;
      let isZero = upcommingZero.length == list.length;
      if (this.type != 2) {
        if (!isZero) {
          list = list.sort((a, b) => {
            return a.upcoming_rank - b.upcoming_rank;
          });
        }
        if (!isFinalZero && isZero) {
          list = list.sort((a, b) => {
            return a.final_rank - b.final_rank;
          });
        }
      } else {
        list = list.sort((a, b) => {
          return a.final_rank - b.final_rank;
        });
      }

      this.table = list.map((item, i) => {
        item.created_on = formatDate(item.created_on);
        item.transtan_status = this.getPatientStatus(item.transtan_status);
        item.gender = this.getGender(item.gender);
        item.sno = i + 1;
        if (isZero && this.type != 2) {
          item.upcoming_rank = i + 1;
        }
        return item;
      });
    },
  },
  methods: {
    onReason({ oldIndex, newIndex, reason }) {
      let table = [];
      this.table.forEach((item) => {
        table.push(item);
      });
      table[oldIndex].inhouse_reason = reason;
      changePosition(table, oldIndex, newIndex);
      function changePosition(arr, fromIndex, toIndex) {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
      }
      this.table = table;
    },
    onChange(val) {
      this.$refs.reason.dialog = true;
      this.$refs.reason.index = val.moved.newIndex;
    },
    async submit() {
      const vm = this;
      try {
        vm.$loader.show();
        let list = vm.table.map((item, i) => {
          let doc = {
            upcoming_rank: i + 1,
            recipient_registration_id: item.recipient_registration_id,
            upcoming_rank_updated_by: vm.created_by,
            inhouse_reason: item.inhouse_reason,
          };
          return doc;
        });
        let { data } = await recipientService.updateInhouseWaitList({
          upcoming_rank_list: list,
        });
        let { status, msg } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.$emit("fetch");
        // vm.fetch();
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    isDelete(item) {
      return item.transtan_status == 4 || item.transtan_status == 1;
    },
    isApproved(item) {
      return item.transtan_status == 4;
    },
    open({ recipient_registration_id }) {
      let id = encode(recipient_registration_id);
      this.$router.push(`/update-recipient?id=${id}`);
    },
    remove({ recipient_registration_id, transtan_status }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do want to remove this recipient?",
        onConfirm: () => {
          vm.changeStatus({
            recipient_registration_id,
            transtan_status: transtan_status == 1 ? 7 : 5,
            dml_indicator: "A",
          });
        },
      });
    },
    approve({ recipient_registration_id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want to approve this recipient?",
        onConfirm: () => {
          vm.changeStatus({
            recipient_registration_id,
            transtan_status: 3,
            dml_indicator: "A",
          });
        },
      });
    },
    async changeStatus(doc) {
      const vm = this;
      try {
        vm.$loader.show();
        let { data } = await recipientService.updateStatus(doc);
        let { status, msg } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.$emit("fetch");
        // vm.fetch();
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style>
.inhouse__table {
  width: 100%;
  overflow-x: auto;
}
</style>
