<template>
  <div>
    <Header :hospitals="hospitals"></Header>
    <recipient-filter
      v-model="filter"
      @hospitals="hospitals = $event"
      @fetch="fetchAll"
      :reasons="reasons"
    ></recipient-filter>
    <RecipientList
      :isLoading="isLoading"
      :total="totalRows"
      :drafts="drafts"
      @export="exportCSV()"
      @select="onRecipientSelect"
      :list="list"
      ref="list"
      @onPageChange="onPageChange"
      :isDraftLoading="isDraftLoading"
      @fetch="fetch(pagination)"
    ></RecipientList>
    <JsonCSV
      :name="`Waiting list.csv`"
      v-show="false"
      ref="csv"
      :data="json"
    ></JsonCSV>
  </div>
</template>

<script>
import { formatDate } from "../../assets/js/common";
import recipientService from "../../assets/services/recipient.service";
import Header from "../../components/managerecipient/Header.vue";
import RecipientList from "../../components/managerecipient/RecipientList.vue";
import RecipientFilter from "../../components/managerecipient/RecipientFilter.vue";
import JsonCSV from "vue-json-csv";
export default {
  components: { RecipientList, Header, RecipientFilter, JsonCSV },
  data() {
    return {
      list: [],
      filter: {},
      reasons: [],
      totalRows: 0,
      hospitals: [],
      pagination: {},
      drafts: [],
      json: [],
      isDraftLoading: false,
      isLoading: false,
    };
  },
  methods: {
    async getDeleteReasonMaster() {
      const vm = this;
      try {
        let { data } = await recipientService.getDeleteReasonMaster();
        let { status, msg, list } = data;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.reasons = list.map((item) => {
          let { delete_reason } = item;
          return { value: delete_reason, name: delete_reason };
        });
      } catch (error) {
        vm.$alert.show(error.message);
      }
    },

    onPageChange(event) {
      this.pagination = event;
      this.fetch(event);
    },
    onRecipientSelect(event) {
      const vm = this;
      if (event.id) {
        vm.list = [event];
        vm.totalRows = vm.list.length;
      }
    },
    async exportCSV() {
      const vm = this;
      try {
        vm.$loader.show();
        let doc = { ...vm.filter };
        doc.Limit_Start = 0;
        doc.Limit_End = vm.totalRows;
        let { data } = await recipientService.list(doc);
        let { status, msg, list } = data;
        vm.$loader.hide();
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        list = list.map((item) => {
          item.date = formatDate(item.created_on);
          item.payment_status = vm.getPaymentStatus(item.payment_status);
          item.transtan_status = vm.getPatientStatus(item.transtan_status);
          item.hospital_status = vm.getBeReadyStatus(item.hospital_status);
          item.CM_insurance = vm.getCMInsuranceStatus(item.CM_insurance);
          item.gender = vm.getGender(item.gender);
          return item;
        });
        let fields = vm.$refs.list.fields(false);
        vm.json = list.map((item, i) => {
          let doc = {};
          fields.forEach((field) => {
            if (field.key != "action") {
              doc[field.label] = item[field.key];
            }
          });
          doc["S.No"] = i + 1;
          return doc;
        });
        setTimeout(() => {
          vm.$refs.csv.$el.click();
        }, 500);
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
    fetchAll() {
      this.$refs.list.$refs.table.currentPage = 1;
      this.fetch({});
      this.fetchDraft();
    },
    async fetchDraft() {
      const vm = this;
      try {
        vm.isDraftLoading = true;
        vm.drafts = [];
        let doc = { ...vm.filter, dml_indicator: "SS" };
        let { data } = await recipientService.list(doc);
        let { status, msg, list } = data;
        vm.isDraftLoading = false;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.drafts = list.map((item) => {
          item.date = formatDate(item.created_on);
          return item;
        });
      } catch (error) {
        vm.$alert.show(error.message);
        vm.isDraftLoading = false;
      }
    },
    async fetch({ size, page }) {
      const vm = this;
      try {
        size = size || 50;
        page = page || 0;
        if (page) page = page - 1;
        vm.isLoading = true;
        vm.list = [];
        let doc = { ...vm.filter };
        doc.Limit_Start = size * page;
        doc.Limit_End = size;

        let { data } = await recipientService.list(doc);
        let { status, msg, list, Total_row_count } = data;
        vm.isLoading = false;
        vm.totalRows = Total_row_count;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.list = list.map((item) => {
          item.date = formatDate(item.created_on);
          return item;
        });
      } catch (error) {
        vm.$alert.show(error.message);
        vm.isLoading = false;
      }
    },
  },
  mounted() {
    this.getDeleteReasonMaster();
  },
};
</script>

<style></style>
