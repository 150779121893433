<template>
  <div>
    <div class="d-flex mb-4">
      <div class="flex-grow-1"></div>
      <create-hospital-rota-order
        :hospitals="hospitals"
        v-if="isCreate"
        :item="{}"
        :history="history"
        @fetch="fetch()"
      ></create-hospital-rota-order>
    </div>
    <table-view
      :isSearch="false"
      :items="list"
      :isBusy="loading"
      :fields="headers"
    >
      <template v-slot:filter>
        <filter-select
          v-model="zone"
          :items="masterZones"
          placeholder="Select Zone"
        ></filter-select>
      </template>
      <template v-slot:action="{ item }">
        <div class="d-flex">
          <create-hospital-rota-order
            :hospitals="history"
            v-if="isCreate"
            :history="list"
            @onUpdate="onUpdate"
            :item="item"
            @fetch="fetch()"
          ></create-hospital-rota-order>
          <delete-button
            v-if="isDeletable"
            @click="removeHospital(item)"
          ></delete-button>
        </div>
      </template>
      <div class="d-flex mt-3">
        <div class="flex-grow-1"></div>
        <b-button v-if="list.length && isCreate" variant="success" @click="submit()"
          >Submit
        </b-button>
      </div>
    </table-view>
  </div>
</template>

<script>
import menuConstants from "../../assets/constants/menu.constants";
import hospitalService from "../../assets/services/hospital.service";
import DeleteButton from "../../components/buttons/DeleteButton.vue";
import FilterSelect from "../../components/form/FilterSelect.vue";
import CreateHospitalRotaOrder from "../../components/hospitals/hospitalRotaOrder/CreateHospitalRotaOrder.vue";
import TableView from "../../components/table/TableView.vue";
export default {
  components: {
    TableView,
    CreateHospitalRotaOrder,
    FilterSelect,
    DeleteButton,
  },
  data() {
    return {
      hospitals: [],
      history: [],
      zone: null,
      list: [],
      loading: false,
    };
  },
  computed: {
    isCreate() {
      let hasAccess = this.hasCreateAccess(menuConstants.HOSPITAL_ROTA_ORDER);
      return hasAccess;
    },
    isDeletable() {
      let hasAccess = this.hasStatusAccess(
        menuConstants.HOSPITAL_ROTA_ORDER,
        4
      );
      return hasAccess;
    },
    headers() {
      let list = [];
      let headers = {
        sno: "S No",
        hospital_name: "Hospital Name",
        initial_rank: "Initial Rank",
        current_rank: "Current Rank",
        zone_name: "Zone",
        action: "Action",
      };
      Object.keys(headers).forEach((key) => {
        let label = headers[key];
        let doc = {
          label,
          sortable: true,
          key,
          tdClass: key == "action" ? "width-100" : "",
        };
        list.push(doc);
      });
      return list;
    },
  },
  watch: {
    zone() {
      this.getList();
    },
  },
  methods: {
    async removeHospital({ rota_hospital_order_id }) {
      let vm = this;
      let i = 0;
      let list = [...vm.list].map((item) => {
        item.dml_indicator = "U";
        if (item.rota_hospital_order_id == rota_hospital_order_id) {
          item.status = 4;
        } else {
          item.status = 1;
          i++;
          item.current_rank = i;
        }
        return item;
      });
      try {
        vm.$loader.show();
        let { status, msg } = (
          await hospitalService.addRotaOrder({
            rota_hospital_orderlist: list,
          })
        ).data;
        vm.$loader.hide();
        if (!status) {
          vm.$alert.show(msg);
        } else {
          vm.dialog = false;
          vm.fetch();
        }
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    getList() {
      this.list = [...this.history]
        .filter((item) => {
          return item.zone_id == this.zone;
        })
        .sort((a, b) => {
          return a.current_rank - b.current_rank;
        });
    },
    async onUpdate({ oldIndex, newIndex }) {
      let table = [...this.list];
      changePosition(table, oldIndex, newIndex);
      function changePosition(arr, fromIndex, toIndex) {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
      }
      this.list = table
        .map((item, i) => {
          item.current_rank = i + 1;
          return item;
        })
        .sort((a, b) => {
          return a.current_rank - b.current_rank;
        });
    },
    async submit() {
      const vm = this;
      try {
        vm.$loader.show();
        let list = [...this.list].map((item) => {
          item.dml_indicator = "U";
          return item;
        });
        let { status, msg } = (
          await hospitalService.addRotaOrder({
            rota_hospital_orderlist: list,
          })
        ).data;
        vm.$loader.hide();
        if (!status) {
          vm.$alert.show(msg);
        }
        vm.fetch();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    async fetch() {
      const vm = this;
      try {
        vm.$loader.show();
        vm.hospitals = [];
        vm.history = [];
        let { list, status } = (await hospitalService.getLiverHospital()).data;
        let { data } = await hospitalService.getRotaOrder();
        if (data.status) {
          vm.history = data.list.map((item) => {
            item.name = item.hospital_name;
            item.value = item.hospital_id;
            return item;
          });
        }
        if (status) {
          list = list.filter((item) => {
            return (
              vm.history.find((d) => {
                return d.hospital_id == item.hospital_id;
              }) == null
            );
          });
          vm.hospitals = list.map((item) => {
            item.name = item.hospital_name;
            item.value = item.hospital_id;
            return item;
          });
        }
        vm.getList();
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style></style>
