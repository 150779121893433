<template>
  <div>
    <table-view
      :isBusy="isLoading"
      :fields="fields"
      :items="uploads"
      title="File Upload"
    >
      <template v-slot:filter>
        <filter-select :items="types" v-model="type" placeholder="All"></filter-select>
      </template>
      <template v-slot:add>
        <create-file-upload @init="init" v-if="isCreate"></create-file-upload>
      </template>
      <template v-slot:is_active="{ value }">
        {{ value == 1 ? "Active" : "Deleted" }}
      </template>
      <template v-slot:file_upload_type_id="{ value }">
        {{ getType(value) }}
      </template>

      <template v-slot:action="{ item }">
        <div class="d-flex" v-if="isAction(item)">
          <create-file-upload :item="item" @init="init"></create-file-upload>
          <delete-button @click="remove(item)"></delete-button>
        </div>
      </template>
    </table-view>
  </div>
</template>

<script>
import menuConstants from "../../assets/constants/menu.constants";
import masterService from "../../assets/services/master.service";
import DeleteButton from "../buttons/DeleteButton.vue";
import FilterSelect from '../form/FilterSelect.vue';
import TableView from "../table/TableView.vue";
import CreateFileUpload from "./create/CreateFileUpload.vue";
export default {
  components: { TableView, DeleteButton, CreateFileUpload, FilterSelect },
  data() {
    return {
      list: [],
      type: null,
      isLoading: false,
    };
  },
  computed: {
    fields() {
      let doc = {
        file_upload_name: "File Upload Name",
        file_upload_type_id: "File Upload Type",
        is_active: "Status",
        action: "Action",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
          sortable: true,
          tdClass: key == "action" ? "width-100" : "",
        });
      });
      return list;
    },
    isCreate() {
      return this.hasCreateAccess(menuConstants.FILE_UPLOAD);
    },
    uploads() {
      if(this.type == null) return this.list;
      return this.list.filter(item => {
        return item.file_upload_type_id == this.type;
      })
    },
        types() {
      return this.masterFileUploadTypes;
    },

  },
  methods: {
    isAction({ is_active }) {
      if (!this.hasStatusAccess(menuConstants.FILE_UPLOAD, 4)) return false;
      return is_active == 1;
    },
    getType(val) {
      let item = this.masterFileUploadTypes.find((item) => {
        return item.value == val;
      });
      return item ? item.name : "";
    },

    async remove({ file_upload_id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want to remove this?",
        onConfirm: async () => {
          try {
            let { data } = await masterService.CUDFileUpload({
              file_upload_id,
              dml_indicator: "D",
              is_active: 4,
            });
            let { status, msg } = data;
            vm.init();
            if (!status) {
              vm.$alert.show(msg);
            }
          } catch (error) {
            vm.isLoading = false;
            vm.$alert.show(error.message);
          }
        },
      });
    },
    async init() {
      const vm = this;
      try {
        vm.list = [];
        vm.isLoading = true;
        let { data } = await masterService.getFileUpload();
        let { status, msg, list } = data;
        vm.isLoading = false;
        if (status) {
          vm.list = list;
        } else {
          vm.$alert.show(msg);
        }
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
