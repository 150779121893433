<template>
  <div>
    <allocation-mobile-view-tabs
      :tabs="tabs"
      v-model="tab"
      v-if="isMobile"
    ></allocation-mobile-view-tabs>
    <div class="allocation__view">
      <div class="allocation__view-left" v-if="!isMobile">
        <b-tabs class="oa-tabs" pills card vertical :value="tab">
          <b-tab
            v-for="(item, i) in tabs"
            :key="i"
            :disabled="item.disabled"
            @click="tab = i"
          >
            <template #title>
              <p class="mb-0">
                {{ item.name }}
              </p>
              <div class="font-size-10">
                {{ item.subtitle }}
              </div>
              <div class="font-size-10 mt-1">
                {{ item.created_on }}
              </div>
            </template>
          </b-tab>
        </b-tabs>
      </div>
      <div
        class="allocation__view-right p-0 oa-tabs-content"
        v-if="tab != null"
      >
        <generate-waiting-list
          :hospitals="hospitals"
          :item="item"
          v-if="tabItem.status == 1"
        ></generate-waiting-list>
        <allocating-waiting-list
          :hospitals="hospitals"
          :item="item"
          :isLast="isLast"
          :status="tabItem.status"
          v-if="isAlocate"
        ></allocating-waiting-list>
        <update-my-waitlist
          :hospitals="hospitals"
          :status="tabItem.status"
          :history="history"
          :item="item"
          v-if="isMyUpdate"
        ></update-my-waitlist>
        <generate-provisional-wait-list
          :hospitals="hospitals"
          :status="tabItem.status"
          :isLast="isLast"
          :item="item"
          v-if="isPriority"
        ></generate-provisional-wait-list>
        <update-logistics-list
          :item="item"
          :isLast="isLast"
          v-if="tabItem.status == 7"
        ></update-logistics-list>
        <organ-infection-list
          :item="item"
          v-if="tabItem.status == 25"
          :isLast="isLast"
        >
        </organ-infection-list>
        <retrieval-list
          :status="tabItem.status"
          :item="item"
          :isLast="isLast"
          :history="history"
          v-if="tabItem.status == 26"
        ></retrieval-list>
        <transplant-surgery
          :item="item"
          v-if="tabItem.status == 28"
          :isLast="isLast"
        ></transplant-surgery>
        <final-allocation-list
          :item="item"
          :isLast="isLast"
          v-if="tabItem.status == 18"
        ></final-allocation-list>
        <allocate-to-other-state-view
          :item="item"
          v-if="tabItem.status == 41"
        ></allocate-to-other-state-view>
      </div>
    </div>
  </div>
</template>

<script>
import AllocatingWaitingList from "./steps/AllocatingWaitingList.vue";
import FinalAllocationList from "./steps/FinalAllocationList.vue";
import GenerateProvisionalWaitList from "./steps/GenerateProvisionalWaitList.vue";
import GenerateWaitingList from "./steps/GenerateWaitingList.vue";
import OrganInfectionList from "./steps/OrganInfectionList.vue";
import UpdateLogisticsList from "./steps/UpdateLogisticsList.vue";
import UpdateMyWaitlist from "./steps/UpdateMyWaitlist.vue";
import RetrievalList from "./steps/RetrievalList.vue";
import TransplantSurgery from "./steps/TransplantSurgery.vue";
import AllocationMobileViewTabs from "../mobile/AllocationMobileViewTabs.vue";
import { formatDate } from "../../../assets/js/common";
import AllocateToOtherStateView from "./steps/provisionalwaitlist/AllocateToOtherStateView.vue";
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    history: {
      type: Array,
      default: () => [],
    },
    hospitals: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    GenerateWaitingList,
    UpdateMyWaitlist,
    AllocatingWaitingList,
    GenerateProvisionalWaitList,
    UpdateLogisticsList,
    FinalAllocationList,
    OrganInfectionList,
    RetrievalList,
    TransplantSurgery,
    AllocationMobileViewTabs,
    AllocateToOtherStateView,
  },
  data() {
    return {
      tab: null,
    };
  },
  computed: {
    isLast() {
      return this.tab == this.tabs.length - 1;
    },
    isAlocate() {
      let allowed = [2, 4, 33, 29];
      return allowed.indexOf(this.tabItem.status) != -1;
    },
    isPriority() {
      let allowed = [6, 20, 34, 30];
      return allowed.indexOf(this.tabItem.status) != -1;
    },
    isMyUpdate() {
      let allowed = [2.1, 4.1, 33.1, 29.1];
      return allowed.indexOf(this.tabItem.status) != -1;
    },
    tabItem() {
      return this.tabs[this.tab] || {};
    },
    tabs() {
      let { allocation_status, organ_allocation_id } = this.item;
      let list = [
        {
          subtitle: "Wait List",
          disabled: allocation_status != 1,
          status: 1,
        },
        {
          subtitle: "Allocate List",
          status: 2,
        },
        {
          subtitle: "Update List",
          status: 2.1,
        },
        {
          subtitle: "Priority List",
          status: 6,
        },
        {
          subtitle: "Allocate List",
          status: 33,
        },
        {
          subtitle: "Update List",
          status: 33.1,
        },
        {
          subtitle: "Priority List",
          status: 34,
        },
        {
          subtitle: "No Taker 1 List",
          status: 4,
        },
        {
          subtitle: "Update List",
          status: 4.1,
        },
        {
          subtitle: "Priority List",
          status: 20,
        },
        {
          subtitle: "No Taker 2 List",
          status: 29,
        },
        {
          subtitle: "Update List",
          status: 29.1,
        },
        {
          subtitle: "Priority List",
          status: 30,
        },
        {
          subtitle: "Logistics",
          status: 7,
        },
        {
          subtitle: "Inspection List",
          status: 25,
        },
        {
          subtitle: "Final Allocation",
          status: 18,
        },
        {
          subtitle: "Organ Retrieval",
          status: 26,
        },
        {
          subtitle: "Transplant Surgery",
          status: 28,
        },
        {
          subtitle: "Other State",
          status: 41,
        },
      ];
      if (this.isAdmins) {
        list.splice(2, 1);
        list.splice(4, 1);
        list.splice(6, 1);
        list.splice(8, 1);
      }
      list = list.filter((item, i) => {
        let history = this.history.find((doc) => {
          let status = item.status;
          if (status == 2.1) status = 2;
          if (status == 33.1) status = 33;
          if (status == 4.1) status = 4;
          if (status == 29.1) status = 29;
          return (
            doc.status == status &&
            doc.organ_allocation_id == organ_allocation_id
          );
        });
        if (item.status == 1) return true;
        if (history != null) {
          list[i].created_on = formatDate(history.created_on);
        }
        return allocation_status == item.status || history != null;
      });
      return list.map((item, i) => {
        item.name = `Step ${i + 1}`;
        return item;
      });
    },
    fields() {
      let doc = {
        name: "Name",
        email_id: "Email ID / User ID",
        contact_name: "Contact Type",
        phone_number_1: "Phone Number",
        status: "Status",
        action: "Action",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
          tdClass: key == "action" ? "width-100" : "",
        });
      });

      return list;
    },
  },
  mounted() {
    if (this.isMobile) {
      this.tab = this.tabs.length - 1;
      return;
    }
    setTimeout(() => {
      this.tab = this.tabs.length - 1;
    }, 1000);
  },
};
</script>

<style lang="scss">
.oa-tabs {
  padding: 0px 24px !important;
  height: auto;
  &-content {
    margin-right: 12px;
    margin-left: 12px;
    flex: 1;
  }
  .col-auto {
    padding: 0px;
    overflow: hidden;
    background-color: transparent;
  }
  .tab-content {
    padding: 0px;
  }
  ul {
    background-color: transparent;
    padding: 0px;
  }
  ul:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(50% - 1px);
    border-left: 2px dashed #556ee6;
  }
  li {
    text-align: center;
    z-index: 1 !important;
    margin-bottom: 50px;
    background-color: white;
    box-shadow: 0 0.5rem 1rem rgb(18 38 63 / 3%);
    border-radius: 0.25rem;
    white-space: nowrap;
    .nav-link {
      border-radius: 0.25rem;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>
