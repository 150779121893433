<template>
  <b-card class="pa-0 tabs">
    <tabs v-model="tab" :tabItems="tabs" @input="onChange()">
      <template v-for="tab in tabs" v-slot:[tab.id]="{ item }">
        <div
          :key="tab.id"
          class="d-flex flex-column align-items-start text-capitalize"
        >
          <p class="mb-0 d-flex">
            {{ item.name }}
            <b-badge
              pill
              class="float-end mx-2 font-weight-normal"
              :class="getBg(item)"
              style="height: 16px"
              >{{ item.badge }}</b-badge
            >
            <timer
              class="ms-2"
              v-if="isTimer(tab)"
              :config="getTimer(tab)"
            ></timer>
          </p>
          <div class="font-size-11" :style="getColor(item)">
            {{ item.status }} <br />
          </div>
          <div
            class="text-muted text-truncate font-size-11"
            :title="item.termination_reason"
            style="max-width: 200px"
          >
            {{ item.termination_reason }}
          </div>
          <div class="font-size-11">
            {{ item.updated_on }}
          </div>
        </div>
      </template>
    </tabs>
  </b-card>
</template>

<script>
import moment from "moment";
import {
  ALLOCATION_STATUS_LIST,
  DONOR_TIMER,
  FINAL_DONOR_STATUS,
  TYPE_OF_ALLOCATION,
} from "../../../assets/constants/donor.constants";
import { formatDate } from "../../../assets/js/common";
import Tabs from "../../ui/Tabs.vue";
import Timer from "../../ui/Timer.vue";
export default {
  components: { Tabs, Timer },
  props: {
    value: {
      type: String,
      default: "0",
    },
    donor: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Array,
      default: () => [],
    },
    history: {
      type: Array,
      default: () => [],
    },
    status: {},
  },
  data() {
    return {
      tabs: [],
      tab: "0",
    };
  },
  methods: {
    isTimer(item) {
      let { allocation_status, type_of_allocation } = item;
      let allowed = DONOR_TIMER;
      return allowed[allocation_status] != undefined && type_of_allocation == 2;
    },
    getTimer({ organ_allocation_id, allocation_status }) {
      let time = DONOR_TIMER[allocation_status];
      let allowed = DONOR_TIMER;
      let timer = {};
      let history = this.history.find((item) => {
        return (
          item.organ_allocation_id == organ_allocation_id &&
          item.status == allocation_status
        );
      });
      if (history != null && allowed[allocation_status] != undefined) {
        let date = moment(history.created_on);
        timer = {
          start: date.unix(),
          end: date.add(time, "minutes").unix(),
        };
      }
      return timer;
    },
    onChange() {
      this.$emit("input", this.tabs[this.tab].id);
    },
    getBg({ type_of_allocation }) {
      let item = TYPE_OF_ALLOCATION.find((item) => {
        return item.value == type_of_allocation;
      });
      return item != null ? item.color : "bg-primary";
    },
    getColor(doc) {
      let item = ALLOCATION_STATUS_LIST.find((item) => {
        return item.value == doc.allocation_status;
      });
      if (item != null) {
        return { color: item.color };
      }
      return {};
    },
    getStatus() {
      let item = FINAL_DONOR_STATUS.find((item) => {
        return item.value == this.status;
      });
      return item != null ? item.name : "";
    },

    getAllocationStatus({ allocation_status }) {
      let item = ALLOCATION_STATUS_LIST.find((item) => {
        return item.value == allocation_status;
      });
      return item != null ? item.name : "";
    },
    getType({ type_of_allocation }) {
      let item = TYPE_OF_ALLOCATION.find((item) => {
        return item.value == type_of_allocation;
      });
      return item != null ? item.name : "";
    },
    init() {
      const vm = this;
      let list = [
        {
          name: "Allocation Process",
          status: vm.getStatus(),
          badge: "",
          id: "0",
        },
      ];
      vm.list.forEach((item) => {
        item.name = item.organ_name;
        if (item.backup_flow == 1) {
          item.name = `${item.name} (Backup)`;
        }
        item.updated_on = formatDate(item.updated_on);
        item.donor = vm.donor;
        item.status = vm.getAllocationStatus(item);
        item.badge = vm.getType(item);
        item.id = item.organ_allocation_id.toString();
        list.push(item);
      });
      vm.tabs = [];
      vm.$set(vm, "tabs", list);
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
