<template>
  <ValidationProvider :rules="rules" :vid="vid" v-slot="validationContext">
    <b-form-group :invalid-feedback="message(validationContext)" class="mb-3">
      <template v-slot:label>
        <span v-html="formattedLabel"></span>
      </template>
      <b-form-textarea
        no-resize
        v-model="model"
        :type="type"
        rows="3"
        :disabled="disabled"
        :state="getValidationState(validationContext)"
        @input="$emit('input', model)"
      >
      </b-form-textarea>
    </b-form-group>
  </ValidationProvider>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    vid: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
    },
    type: {
      type: String,
      default: "text",
    },
    invalid: {
      type: String,
      default: "This field is required",
    },
    value: {},
  },
  data() {
    return {
      model: "",
    };
  },
  computed: {
    formattedLabel() {
      if(!this.rules) return this.label;
      if (this.rules.indexOf("required") != -1)
        return this.label + " <span style='color:red'>*</span>";
      return this.label;
    },
  },
  watch: {
    value(val) {
      this.model = val;
    },
  },
  methods: {
    message(validationContext) {
      return validationContext.errors[0];
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
  mounted() {
    this.model = this.value;
  },
};
</script>
