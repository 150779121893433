<template>
  <b-container class="login mb-5">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-8 col-xl-8 mt-5">
        <b-card overflow-hidden>
          <b-card-title>
            <h2 class="text-center">Registration</h2>
          </b-card-title>
          <b-card-body>
            <form-wrapper @submit="submit">
              <select-field
                label="User Type"
                v-model.trim="form.user_type"
                v-if="false"
                rules="required"
                :items="types"
              ></select-field>
              <input-field
                label="Hospital Name"
                v-model.trim="form.hospital_name"
                rules="required"
              ></input-field>

              <b-row cols="2">
                <b-col cols="6">
                  <input-field
                    label="Hospital Contact First Name"
                    v-model.trim="form.first_name"
                    rules="required"
                  ></input-field>
                </b-col>
                <b-col cols="6">
                  <input-field
                    label="Hospital Contact Last Name"
                    v-model.trim="form.last_name"
                    rules="required"
                  ></input-field>
                </b-col>
                <b-col cols="6">
                  <select-field
                    v-model.trim="form.gender"
                    label="Gender"
                    outlined
                    :items="gender"
                    rules="required|max-50"
                  ></select-field>
                </b-col>
                <b-col cols="6" v-if="false">
                  <select-field
                    label="Blood Group"
                    :items="bloodGroups"
                    v-model="form.blood_id"
                    rules="required"
                  ></select-field>
                </b-col>
                <b-col cols="6">
                  {{dobRules}}
                  <date-picker
                    v-model="form.DOB"
                    label="Date of Birth"
                    outlined
                    :max="new Date()"
                    :rules="dobRules"
                  ></date-picker>
                </b-col>
                <b-col cols="6">
                  <input-field
                    label="Phone Number 1"
                    v-model.trim="form.phone_number_1"
                    type="number"
                    rules="required|phone"
                    invalid="Please enter a valid Phone number"
                  ></input-field>
                </b-col>
                <b-col cols="6">
                  <input-field
                    label="Phone Number 2"
                    v-model.trim="form.phone_number_2"
                    type="number"
                    rules="phone"
                    invalid="Please enter a valid Phone number"
                  ></input-field>
                </b-col>
                <b-col cols="6">
                  <input-field
                    label="Email"
                    v-model.trim="form.email_id"
                    rules="required|email"
                  ></input-field>
                </b-col>
              </b-row>

              <vue-recaptcha
                class="mt-1"
                sitekey="6Ldd1ckaAAAAAGREbWaQDMwQfSYyEC2jHvteMmBu"
                :loadRecaptchaScript="true"
                @verify="onVerify"
              ></vue-recaptcha>
              <b-button
                block
                variant="primary"
                type="submit"
                class="mt-3"
                depressed
                >Register</b-button
              >
              <p class="subtitle-1 text-center mt-5">
                Already have an account?
                <a class="primary--text" @click="$router.push('/login')">
                  Login</a
                >
              </p>
            </form-wrapper>
          </b-card-body>
        </b-card>
      </div>
    </div>
  </b-container>
</template>

<script>
import { EMAIL, FIELD, PASSWORD, PHONE } from "../../assets/js/rules";
import VueRecaptcha from "vue-recaptcha";
import { GENDERS, USER_TYPES } from "../../assets/constants/hospital.contants";
import userService from "../../assets/services/user.service";
import SelectField from "../../components/form/SelectField.vue";
import DatePicker from "../../components/form/DatePicker.vue";
import { getAge, getDateOnly } from "../../assets/js/common";

export default {
  components: { VueRecaptcha, SelectField, DatePicker },
  data() {
    return {
      rules: FIELD,
      password: PASSWORD,
      phone: PHONE,
      form: {
        contact_id: 5,
        web_token: "",
        user_status: 1,
        ALF_approver: 0,
        user_created_by: "SELF",
        dml_indicator: "I",
        created_by: "",
        user_type: "1",
        age_registered_date: getDateOnly(),
      },
      email: EMAIL,
      isValid: false,
      token: "",
    };
  },
  computed: {
    gender() {
      return GENDERS;
    },
    types() {
      return USER_TYPES;
    },
    dobRules() {
      let dob = this.form.DOB;
      if (dob) {
        if(getAge(dob) < 18) return "age-18"
      }
      return "";
    },

    confirmPassRules() {
      return [
        (value) =>
          value === this.form.create_new_password ||
          "The password confirmation does not match.",
      ];
    },
  },
  methods: {
    onVerify(val) {
      this.token = val;
    },
    async submit() {
      const vm = this;
      // if (!vm.token) {
      //   vm.$alert.show("Please verify reCAPTCHA");
      //   return;
      // }
      let doc = {};
      Object.keys(vm.form).forEach((key) => {
        doc[key] = vm.form[key];
      });
      doc.age = getAge(this.form.DOB);
      doc.verfication_code = vm.token;
      doc.dml_indicator = "I";
      console.log(JSON.stringify(doc));
      vm.$loader.show();
      try {
        let { data } = await userService.create(doc);
        vm.$loader.hide();
        let { status, msg } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.$loader.hide();
        vm.$alert.show(
          "User Registered Successfully, Please check your email for login credentials."
        );
        vm.$router.replace("/login");
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {},
};
</script>

<style></style>
