<template>
  <div>
    <Header :hospitals="hospitals"></Header>
    <donor-filter
      v-model="filter"
      @hospitals="hospitals = $event"
      @fetch="fetch"
    ></donor-filter>
    <List :isLoading="isLoading" @fetch="fetch" :list="list"></List>
  </div>
</template>

<script>
import { formatDateOnly } from "../../assets/js/common";
import liveTransplantService from "../../assets/services/live.transplant.service";
import Header from "../../components/waitingList/livetrnsplant/Header.vue";
import List from "../../components/waitingList/livetrnsplant/List.vue";
import DonorFilter from "../../components/waitingList/livetrnsplant/RecipientFilter.vue";
export default {
  components: { List, Header, DonorFilter },
  data() {
    return {
      list: [],
      hospitals: [],
      filter: {},
      isLoading: false,
    };
  },
  methods: {
    getContactType(contact_id) {
      let item = this.masterContactTypes.find((item) => {
        return item.value == contact_id;
      });
      return item instanceof Object ? item.name : "";
    },
    async fetch() {
      const vm = this;
      try {
        vm.isLoading = true;
        vm.list = [];
        let { data } = await liveTransplantService.list(vm.filter);
        let { status, msg, list } = data;
        vm.isLoading = false;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.list = list.map((item) => {
          let { donor_sex, recipient_sex } = item;
          item.donor_sex = vm.getGender(donor_sex);
          item.recipient_sex = vm.getGender(recipient_sex);
          item.date_of_surgery = formatDateOnly(item.date_of_surgery);
          item.relationship = vm.getContactType(item.relationship);
          return item;
        });
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
