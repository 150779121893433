<template>
  <b-card class="mt-4">
    <form-wrapper @submit="submit(true)">
      <b-row>
        <b-col cols="4">
          <select-field
            v-model.trim="organ"
            label="Organ"
            rules="required"
            @input="openOrgan()"
            :multiple="true"
            :items="organList"
          ></select-field>
        </b-col>
        <b-col cols="4" v-if="organ == 'Kidney'">
          <autocomplete
            v-model.trim="zone"
            label="Zone"
            rules="required"
            :multiple="true"
            :items="zones"
          ></autocomplete>
        </b-col>
        <b-col cols="4">
          <autocomplete
            rules="required"
            label="Blood Group"
            :items="filterBloodGroups"
            :multiple="true"
            v-model="blood_group"
          ></autocomplete>
        </b-col>
      </b-row>
      <div class="d-flex">
        <b-form-input
          placeholder="Search by UID"
          v-model="uid"
          type="number"
          style="width: 200px"
        >
        </b-form-input>
        <b-button
          class="ms-3"
          @click="$emit('onSeachUid', uid)"
          variant="primary"
          >Search</b-button
        >
        <b-button class="ms-3" @click="clear()" variant="danger"
          >Clear</b-button
        >
        <div class="flex-grow-1"></div>
        <b-btn
          class="width-100 me-3"
          @click="reset()"
          variant="outline-secondary"
          >RESET</b-btn
        >
        <b-btn class="width-100" type="submit" variant="primary">SUBMIT</b-btn>
      </div>
    </form-wrapper>
  </b-card>
</template>

<script>
import {
  BE_READY_STATUS,
  PATIENT_STATUS,
  PAYMENT_STATUS,
} from "../../assets/constants/recipient.constants";
import {
  BLOOD_GROUP_VALUES,
  HEART_ID,
  KIDNEY_ID,
  LEFT_LUNG_ID,
  LUNG_ID,
  RIGHT_LUNG_ID,
} from "../../assets/js/common";
import userService from "../../assets/services/user.service";
import Autocomplete from "../form/Autocomplete.vue";
import SelectField from "../form/SelectField.vue";
export default {
  components: {
    Autocomplete,
    SelectField,
  },
  props: {
    value: {},
  },
  data() {
    return {
      form: {},
      organ: "",
      organList: [
        {
          value: "Kidney",
          name: "Kidney",
        },
        {
          value: "Heart & lungs",
          name: "Heart & lungs",
        },
      ],
      uid: "",
      zone: [],
      zones: [],
      hospitals: [],
      blood_group: [],
      paymentStatus: PAYMENT_STATUS,
      patientStatus: PATIENT_STATUS,
      beReadyStatus: BE_READY_STATUS,
    };
  },
  methods: {
    openOrgan() {
      const vm = this;
      console.log(vm.organ);
      vm.$emit("onOrganChange", vm.organ);
    },
    submit(isSubmit) {
      const vm = this;
      const doc = {
        dml_indicator: "S",
      };
      Object.keys(vm.form).forEach((key) => {
        let value = vm.form[key];
        if (value == "all") value = "";
        if (value) {
          doc[key] = value;
        } else {
          doc[key] = "";
        }
      });
      doc.blood_group = [];
      vm.blood_group.forEach((id) => {
        BLOOD_GROUP_VALUES[id].forEach((blood_group) => {
          doc.blood_group.push({ blood_group });
        });
      });
      if (vm.organ == "Heart & lungs") {
        doc.organ = [HEART_ID, LUNG_ID, LEFT_LUNG_ID, RIGHT_LUNG_ID].map(
          (organ_id) => {
            return { organ_id };
          }
        );
      } else {
        doc.organ = [KIDNEY_ID].map((organ_id) => {
          return { organ_id };
        });
      }
      if (vm.organ == "Kidney") {
        doc.zone_list = vm.zone.map((zone) => {
          return { zone };
        });
      } else {
        doc.zone_list = [];
      }
      console.log(doc);
      vm.$emit("input", doc);
      if (isSubmit) {
        vm.$emit("fetch");
      }
    },
    reset() {
      this.form = {
        hospital_id: "0",
        hospital_type: "",
        patient_nationlity: "",
        age: "",
        hospital_status: "",
      };
      this.zone = [];
      this.blood_group = [];
      this.organs = [];
      this.submit(false);
    },
    clear() {
      this.uid = "";
      this.$emit("reset");
    },
    async fetchMasterZone() {
      const vm = this;
      try {
        let { data } = await userService.fetchMasterZone();
        let { status, msg, list } = data;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        list = list.filter((item) => {
          return item.status != 4;
        });
        vm.zones = list.map((item) => {
          return {
            name: item.zone_name,
            value: item.zone_id,
          };
        });
      } catch (error) {
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.reset();
    this.fetchMasterZone();
  },
};
</script>

<style></style>
