<template>
  <expandable header="Medical History" class="mb-10">
    <b-row>
      <b-col cols="12" md="4" v-for="(item, i) in masterDonorMedicalDetails" :key="i">
        <yes-no
          v-model="form[item.value]"
          :label="item.name"
          :disabled="isDisabled"
          @input="onInput"
          rules="required"
        ></yes-no>
      </b-col>
      <b-col cols="12" md="4" v-if="isCovid">
        <select-field
          label="Covid Free Period"
          :items="periods"
          @input="onInput"
          rules="required"
          :disabled="isDisabled"
          v-model="covid_free"
        ></select-field>
      </b-col>
    </b-row>
  </expandable>
</template>

<script>
import { PREIODS } from "../../../assets/constants/recipient.constants";
import Expandable from "../../Expandable.vue";
import YesNo from "../../form/YesNo.vue";
export default {
  components: { YesNo, Expandable },
  props: {
    value: {},
    covid_free_period: {},
    isDisabled: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      form: {},
      covid_free: "",
      periods: PREIODS,
      isCovid: false,
    };
  },
  methods: {
    onInput() {
      this.$emit("input", this.form);
      this.$emit("onCovideChange", this.covid_free);
      this.isCovid = this.form[9] == 1;
    },
  },
  beforeMount() {
    this.form = this.value;
    this.covid_free = this.covid_free_period;
    this.isCovid = this.form[9] == 1;
  },
};
</script>

<style></style>
