<template>
  <div>
    <AlfApproverList
      :isLoading="isLoading"
      @fetch="fetch"
      :history="history"
      :list="list"
    ></AlfApproverList>
  </div>
</template>

<script>
import { formatDate } from "../../assets/js/common";
import alfService from "../../assets/services/alf.service";
import AlfApproverList from "../../components/managealf/AlfApproverList.vue";
export default {
  components: { AlfApproverList },
  data() {
    return {
      list: [{}],
      history: [],
      isLoading: false,
    };
  },
  methods: {
    async fetch() {
      const vm = this;
      try {
        vm.isLoading = true;
        vm.list = [];
        let { data } = await alfService.pendingList();
        let history = await alfService.historyList();
        let { status, msg, list } = data;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        if (!history.data.status) {
          vm.$alert.show(history.data.msg);
          return;
        }
        vm.isLoading = false;
        vm.history = history.data.list.map((item) => {
          item.created_on = formatDate(item.created_on);
          return item;
        });
        vm.list = list.map((item) => {
          item.created_on = formatDate(item.created_on);
          return item;
        });
      } catch (error) {
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style></style>
