<template>
  <div class="d-flex recipient-footer">
    <div class="flex-grow-1"></div>
    <b-button
      variant="outline-danger mx-3"
      @click="$router.push('/live-transplant')"
      >Close
    </b-button>
    <template v-if="isEdit">
      <b-button variant="success" type="submit">Submit </b-button>
    </template>
  </div>
</template>

<script>
import menuConstants from "../../../../assets/constants/menu.constants";
export default {
  props: {
    status: [Number, String],
  },
  computed: {
    isEdit() {
      let hasAccess = this.hasEditAccess(menuConstants.LIVE_TRANSPLANT);
      return hasAccess && this.status != 2;
    },
  },
};
</script>

<style lang="scss">
.recipient-footer {
  button {
    width: 100px;
  }
}
</style>
