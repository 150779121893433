export default {
  index: "",
  login: "Login",
  "hospital-list": "Manage Hospitals",
  "transplant-list": "Organ License List",
  "user-list": "User List",
  "temporary-license-list": "Temporary License List",
  "hospital-registration": "Hospital Registration",
  "update-hospital": "Update Hospital",
  "update-transplant-license": "Update Organ License",
  "transplant-registration": "Organ License Registration",
  "live-transplant-registration": "Live Transplant Registration",
  "update-transplant-registration": "Update Live Transplant",
  registration: "Registration",
  "manage-recipient": "Manage Recipient",
  "recipient-registration": "Recipient Registration",
  "update-recipient": "Update Recipient",
  "manage-donor": "Manage Donor",
  "donor-registration": "Donor Registration",
  "update-donor": "Update Donor",
  "recipient-transfer": "Recipient Transfer",
  "tnos-waiting-list": "Transtan Waiting List",
  "hospital-waiting-list": "Hospital Waiting List",
  "cm-insurance-list": "CM Insurance List",
  "live-transplant": "Live Transplant",
  "liver-urgent-listing": "Liver Urgent Listing",
  "manage-alf-approver": "ALF Approver",
  "create-alf": "Create ALF",
  "update-alf": "Update ALF",
  "manage-alf": "Liver Urgent Listing",
  reports: "Reports",
  masters: "Masters",
  "user-management": "User Management",
  "transtan-management": "Transtan Management",
  "organ-allocation": "Organ Allocation",
  "in-house-waiting-list": "Inhouse Waiting List",
  "be-ready-list": "Be Ready List",
  "recipient-pending-approval": "Recipient Pending Approval",
  "hospital-rota-order": "Hospital Rota Order",
  "donor-pledge": "Donor Pledge Form",
};
