<template>
  <div class="d-flex" v-if="isAdmin">
    <div class="flex-grow-1"></div>
    <Add @click="$router.push('/hospital-registration')"></Add>
  </div>
</template>

<script>
import Add from "../buttons/AddButton.vue";
export default {
  components: { Add },
  data() {
    return {};
  },
  computed: {},
};
</script>

<style></style>
