<template>
  <Expandable header="Hospital Details" class="mb-10">
    <b-row class="pb-0">
      <b-col cols="12">
        <autocomplete-group
          v-model.trim="form.departments"
          :multiple="true"
          :disabled="isDisabled"
          :items="masterDepartments"
          rules="required"
          label="Departments"
        >
          <template v-slot:help>
            <help
              text="Please list down the Medical Departments available in the hospital"
            >
            </help>
          </template>
        </autocomplete-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          v-model.trim="form.number_of_beds"
          label="Number of Beds"
          @input="input"
          type="number"
          :disabled="isDisabled"
          rules="required|integer"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <yes-no
          :disabled="isDisabled"
          @input="input"
          rules="required"
          label="Whether trauma unit available?"
          v-model="form.trauma_unit"
        ></yes-no>
      </b-col>
      <b-col cols="12" md="4">
        <yes-no
          @input="input"
          :disabled="isDisabled"
          rules="required"
          label="Whether medical college attached?"
          v-model="form.teaching"
        ></yes-no>
      </b-col>
      <b-col cols="12" md="4">
        <yes-no
          @input="input"
          label="Whether ISO/NABH accredited?"
          :disabled="isDisabled"
          rules="required"
          v-model="form.ISO_NABH_accreditation"
        ></yes-no>
      </b-col>
      <b-col cols="12" md="6">
        <yes-no
          @input="input"
          rules="required"
          :disabled="isDisabled"
          label="Whether registered under clinical establishment act?"
          v-model="form.clinical_establishment_act"
        ></yes-no>
      </b-col>
    </b-row>
  </Expandable>
</template>

<script>
import { FIELD } from "../../../assets/js/rules";
import { HOSPITAL_TYPES } from "../../../assets/constants/hospital.contants";
import Expandable from "../../Expandable.vue";
import YesNo from "../../form/YesNo.vue";
import Help from "../../Help.vue";
import hospitalCreateMixins from "../../../assets/mixins/hospital.create.mixins";
import AutocompleteGroup from "../../form/AutocompleteGroup.vue";
export default {
  props: {
    value: Object,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [hospitalCreateMixins],
  components: { Expandable, YesNo, Help, AutocompleteGroup },
  data() {
    return {
      rules: FIELD,
      form: {
        number_of_beds: "",
        trauma_unit: "",
        teaching: "",
        departments: "",
        clinical_establishment_act: "",
        registered_throught_ACT: "",
        ISO_NABH_accreditation: "",
      },
    };
  },
  computed: {
    types() {
      return HOSPITAL_TYPES;
    },
    years() {
      let year = 1899;
      let list = [];
      while (year < new Date().getFullYear()) {
        year++;
        list.push(year);
      }
      return list.reverse();
    },
    master() {
      return this.$store.getters.master;
    },
  },
  methods: {
    input() {
      this.$emit("input", this.form);
    },
    init() {
      const vm = this;
      Object.keys(vm.value).forEach((key) => {
        vm.$set(vm.form, key, vm.value[key]);
      });
    },
  },
  beforeMount() {
    this.init();
    this.input();
  },
};
</script>

<style></style>
