import moment from "moment";
import hospitalService from "../services/hospital.service";
class CommonHelper {
  fetchHospital = async (vm) => {
    try {
      vm.hospitals = [];
      let { user_type, hospital_id } = vm.$store.getters.user;
      let doc = { user_type };
      if (user_type == 1) {
        doc.hospital_id = hospital_id;
        vm.form.hospital_id = hospital_id.toString();
        vm.submit();
        return;
      }
      vm.$loader.show();
      let { data } = await hospitalService.list(doc);
      let { status, msg, list } = data;
      if (!status) {
        vm.$loader.hide();
        vm.$alert.show(msg);
        return;
      }
      vm.$loader.hide();
      vm.hospitals = list.map((item) => {
        item.name = item.hospital_name;
        item.value = item.hospital_id;
        return item;
      });
      vm.$emit("hospitals", vm.hospitals);
    } catch (error) {
      console.log(error.message);
      vm.$loader.hide();
      vm.$alert.show(error.message);
    }
  };
  setAuth(vm, data, isMain) {
    let { login_details, contact_organ_mapping, JwtToken, user_rights } = data;
    let time = moment().add(30, "days").toDate();
    localStorage.token = JwtToken;
    vm.$cookies.set("session", true, time);
    let user = login_details[0];
    if (user instanceof Object) {
      user.fcm_token = data.fcm_token;
    }
    if (contact_organ_mapping instanceof Array) {
      user.organs = contact_organ_mapping.map((item) => {
        return item.organ_id;
      });
    }
    vm.$cookies.set("session_details", user, time);
    vm.$store.commit("SET_AUTH");
    vm.$store.commit("SET_RIGHTS", user_rights);
    if (isMain == false) {
      // vm.$router.replace("/");
    }
  }
}
export default new CommonHelper();
