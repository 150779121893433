<template>
  <div class="d-flex justify-content-between flex-wrap tnos-stats">
    <b-card
      v-for="(item, i) in items"
      :key="i"
      no-body
      :bg-variant="item.color"
      text-variant="white"
    >
      <div class="card-body">
        <div class="media">
          <div class="media-body">
            <p class="text-white fw-medium mb-2">{{ item.subtitle }}</p>
            <h4 class="mb-0 text-white">{{ item.title }}</h4>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import commonMixins from "../../../assets/mixins/common.mixins";
export default {
  props: {
    status: Object,
  },
  mixins: [commonMixins],
  computed: {
    items() {
      let {
        Total,
        Active,
        Inactive,
        Blood_GroupO,
        Blood_GroupA,
        Blood_GroupB,
        Blood_GroupAB,
      } = this.status;
      return [
        {
          color: "warning",
          icon: "mdi-account-group",
          title: Total ? Total : 0,
          subtitle: "Total",
        },
        {
          color: "success",
          icon: "mdi-account-multiple-plus",
          title: Active ? Active : "0",
          subtitle: "Active",
        },
        {
          color: "danger",
          icon: "mdi-account-multiple-minus",
          title: Inactive ? Inactive : "0",
          subtitle: "Inactive",
        },
        {
          color: "primary",
          icon: "mdi-blood-bag",
          title: Blood_GroupO ? Blood_GroupO : "0",
          subtitle: "Blood Group O",
        },
        {
          color: "primary",
          icon: "mdi-blood-bag",
          title: Blood_GroupA ? Blood_GroupA : "0",
          subtitle: "Blood Group A",
        },
        {
          color: "primary",
          icon: "mdi-blood-bag",
          title: Blood_GroupB ? Blood_GroupB : "0",
          subtitle: "Blood Group B",
        },
        {
          color: "primary",
          icon: "mdi-blood-bag",
          title: Blood_GroupAB ? Blood_GroupAB : "0",
          subtitle: "Blood Group AB",
        },
      ];
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/design/mixins";
.tnos-stats {
  @include mobile {
    column-gap: 10px;
  }
  .card {
    width: 120px;
    .card-body {
      padding: 0.5rem !important;
    }
    @include mobile {
      width: calc(33% - 10px);
      .card-body {
        padding: 10px !important;
      }
      p {
        font-size: 10px;
      }
    }
  }
}
</style>
