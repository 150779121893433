import http from "../js/axios";
class MasterSerive {
  get() {
    return http.post("/hospital/master_details", {
      dml_indicator: "M",
    });
  }
  getStateCity(data) {
    return http.post("/hospital/country_state_city_master", data);
  }
  createState(name) {
    return http.post("/transtan_master/master_state", {
      name,
      country_id: 99,
      status: 1,
      dml_indicator: "I",
    });
  }
  createCity({ city, state_id }) {
    return http.post("/transtan_master/master_city", {
      name:city,
      state_id,
      status: 1,
      dml_indicator: "I",
    });
  }
  getVaccinationList() {
    return http.post("/transtan_master/master_vaccination", {
      dml_indicator: "S",
    });
  }
  getDonorMedicalDetails() {
    return http.post("/transtan_master/master_donor_medical_detail", {
      dml_indicator: "S",
    });
  }
  getDonorTermination() {
    return http.post("/transtan_master/master_donor_termination", {
      dml_indicator: "S",
    });
  }
  getDonorCauseOFDeath() {
    return http.post("/transtan_master/master_cause_of_death", {
      dml_indicator: "S",
    });
  }
  getZone() {
    return http.post("/transtan_master/master_zone", {
      dml_indicator: "S",
    });
  }
  getOrgan() {
    return http.post("/transtan_master/master_organ", {
      dml_indicator: "S",
    });
  }
  getContact() {
    return http.post("/transtan_master/master_contact", {
      dml_indicator: "S",
    });
  }
  getBloodGroup() {
    return http.post("/transtan_master/master_bloodgroup", {
      dml_indicator: "S",
    });
  }
  getFileUpload() {
    return http.post("/transtan_master/master_fileupload", {
      dml_indicator: "S",
    });
  }
  getQualification() {
    return http.post("/transtan_master/master_qualification", {
      dml_indicator: "S",
    });
  }
  getDepartment() {
    return http.post("/transtan_master/master_department", {
      dml_indicator: "S",
    });
  }
  getFileUploadType() {
    return http.post("/transtan_master/fileload_type", {
      dml_indicator: "S",
    });
  }
  CUDCauseOfDeath(doc) {
    return http.post("/transtan_master/master_cause_of_death", doc);
  }
  CUDTermination(doc) {
    return http.post("/transtan_master/master_donor_termination", doc);
  }
  CUDZone(doc) {
    return http.post("/transtan_master/master_zone", doc);
  }
  CUDOrgan(doc) {
    return http.post("/transtan_master/master_organ", doc);
  }
  CUDContact(doc) {
    return http.post("/transtan_master/master_contact", doc);
  }
  CUDBloodGroup(doc) {
    return http.post("/transtan_master/master_bloodgroup", doc);
  }
  CUDFileUpload(doc) {
    return http.post("/transtan_master/master_fileupload", doc);
  }
  CUDQualification(doc) {
    return http.post("/transtan_master/master_qualification", doc);
  }
  CUDDepartment(doc) {
    return http.post("/transtan_master/master_department", doc);
  }
}

export default new MasterSerive();
