<template>
  <div class="d-flex align-center">
    <icon-button
      icon="x-octagon-fill"
      @click="open"
      variant="danger"
    ></icon-button>
    <b-modal
      tabindex="0"
      v-model="dialog"
      title="Termination Reason"
      hide-footer
      ignore-enforce-focus-selector=".select2-search__field"
      size="sm"
      centered
    >
      <form-wrapper @submit="submit">
        <b-row class="pb-0">
          <b-col cols="12">
            <autocomplete
              v-model="master_termination_id"
              label="Reason"
              :items="masterDonorTermination"
              :multiple="false"
              :max="1"
              rules="required"
            >
            </autocomplete>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger mx-3" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" type="submit">Submit </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import donorService from "../../assets/services/donor.service";
import IconButton from "../buttons/IconButton.vue";
import Autocomplete from "../form/Autocomplete.vue";
export default {
  components: { Autocomplete, IconButton },
  props: {
    id: {},
  },
  data() {
    return {
      dialog: false,
      master_termination_id: "",
    };
  },
  methods: {
    async submit() {
      const vm = this;
      let { master_termination_id } = vm;
      try {
        vm.$loader.show();
        let { data } = await donorService.updateStatus({
          dml_indicator: "TA",
          master_termination_id,
          status: 7,
          donor_temp_registration_id: vm.id,
        });
        let { status, msg } = data;
        vm.dialog = false;
        if (!status) {
          vm.$alert.show(msg);
        }
        vm.$emit("fetch");
        vm.$loader.hide();
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
    open() {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want to terminate this donor?",
        onConfirm: () => {
          vm.dialog = true;
        },
      });
    },
  },
};
</script>

<style></style>
