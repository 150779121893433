import { decode } from "../js/common";

export default {

    computed: {
        isCreate() {
            return this.$route.name == "recipient-registration";
        },
        isUpdate() {
            return this.$route.name == "update-recipient"
        },
        isTransfer() {
            return this.$route.name == "transfer-recipient"
        },
        id() {
            return decode(this.$route.query.id)
        }
    }
}