import http from "../js/axios";
class HospitalSerive {
  create(data) {
    return http.post("/userlogin/user_registration", data);
  }
  login(data) {
    return http.post("/userlogin/user_registration", data);
  }
  updateToken(data) {
    return http.post("/hospital/user_token", data);
  }
  changePassword(data) {
    return http.post("/userlogin/changepassword", data);
  }
  commonWaitingList(data) {
    return http.post("/userlogin/common_waitlist", data);
  }
  fetchMasterZone() {
    return http.post("/userlogin/master_zone", {
      dml_indicator: "s",
    });
  }
  commonWaitingListUidSearch(data) {
    return http.post("/userlogin/common_uhid_search", data);
  }
  forgot(data) {
    return http.post("/userlogin/forgetpasswordupdation", data);
  }
  userList(data) {
    return http.post("/userlogin/user_list", data);
  }
  createUser(data) {
    return http.post("/hospital/newusercreate", data);
  }
  deleteUser(data) {
    return http.post("/hospital/user_delete", data);
  }
  resendPassword(data) {
    return http.post("/userlogin/resentpassword", data);
  }
  get(id) {
    return http.post("/userlogin/user_edit", { hospital_contact_id: id });
  }
  refresh() {
    return http.post("userlogin/refresh_token", {
      dml_indicator: "RT",
    });
  }
}
export default new HospitalSerive();
