<template>
  <div>
    <allocation-recipient-table
      title="Update Organ Assessment Details"
      :fields="fields"
      :isBusy="isLoading"
      :isLast="isLast"
      :item="item"
      :items="list"
    >
      <template v-slot:action="doc">
        <organ-infection-details-view
          :recipeint="doc.item"
          :item="item"
          v-if="doc.item.organ_Inspection_status != 0"
        ></organ-infection-details-view>
        <div v-if="isInfectionUpdate(doc.item)">
          <organ-infection-details
            :recipeint="doc.item"
            :item="item"
            @onSubmit="init()"
          ></organ-infection-details>
        </div>
      </template>
      <div class="d-flex mt-3" v-if="isGenerateFinalList">
        <div class="flex-grow-1"></div>
        <b-button
          variant="primary"
          v-if="isGenerateFinal"
          @click="generateFinalList()"
          >Generate Final List</b-button
        >
      </div>
    </allocation-recipient-table>
  </div>
</template>

<script>
import {
  BE_READY_STATUS,
  PATIENT_STATUS,
  PAYMENT_STATUS,
} from "../../../../assets/constants/recipient.constants";
import { formatDate } from "../../../../assets/js/common";
import event from "../../../../assets/js/event";
import donorMixins from "../../../../assets/mixins/donor.mixins";
import donorService from "../../../../assets/services/donor.service";
import AllocationRecipientTable from "./AllocationRecipientTable.vue";
import OrganInfectionDetails from "./organInfection/OrganInfectionDetails.vue";
import OrganInfectionDetailsView from "./organInfection/OrganInfectionDetailsView.vue";
export default {
  components: {
    OrganInfectionDetails,
    OrganInfectionDetailsView,
    AllocationRecipientTable,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    hospitals: {
      type: Array,
      default: () => [],
    },
    status: {},
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [donorMixins],
  data() {
    return {
      isLoading: false,
      dialog: true,
      list: [],
      paymentStatus: PAYMENT_STATUS,
      patientStatus: PATIENT_STATUS,
      beReadyStatus: BE_READY_STATUS,
    };
  },
  computed: {
    isGenerateFinal() {
      let { list } = this;
      let updated = list.filter((item) => {
        return item.organ_Inspection_status == 1;
      });
      if (list.length == 0) return false;
      if (updated.length == 0) return false;
      return true;
    },
    fields() {
      let doc = {
        organ_Inspection_status: "Status",
        action: "Action",
      };
      return doc;
    },
    isGenerateFinalList() {
      return this.item.allocation_status == 25 && this.isAdmins;
    },
  },
  methods: {
    isInfectionUpdate({ hospital_id, organ_Inspection_status }) {
      if (!this.isHospitalContact) return false;
      if (organ_Inspection_status != 0) return false;
      if (this.item.allocation_status != 25) return false;
      return hospital_id == this.user.hospital_id;
    },
    generateFinalList() {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want to generate final list?",
        onConfirm: async () => {
          try {
            vm.$loader.show();
            let { organ_allocation_id } = vm.item;
            let list = vm.list.filter((item) => {
              return item.organ_Inspection_status == 1;
            });
            list = list.map((item) => {
              let { recepeint_allocation_id } = item;
              let doc = {
                recepeint_allocation_id,
                organ_allocation_id,
                final_allocation_update_by: vm.created_by,
                dml_indicator: "FAU",
              };
              return doc;
            });
            let { data } =
              await donorService.organAllocationWaitingListStatusUpdate({
                recepient_organ_allocation_proc: list,
              });
            let { status, msg } = data;
            vm.isLoading = false;
            vm.$loader.hide();
            event.$emit("refresh");
            if (!status) {
              vm.$alert.show(msg);
            }
          } catch (error) {
            vm.$loader.hide();
            vm.$alert.show(error.message);
          }
        },
      });
    },
    async init() {
      const vm = this;
      vm.dialog = false;
      let {
        organ_id_org,
        is_multi,
        organ_id,
        organ_allocation_id,
        type_of_allocation,
      } = vm.item;
      if (is_multi) organ_id = organ_id_org;

      const doc = {
        dml_indicator: "LOS",
        organ_id,
        organ_allocation_id,
      };
      if (type_of_allocation == 1) doc.dml_indicator = "LOSL";
      try {
        vm.isLoading = true;
        vm.list = [];
        let { data } = await donorService.getAllocatedWL(doc);
        let { status, msg, list } = data;
        vm.isLoading = false;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.list = list.map((item) => {
          item.gender = vm.getGender(item.gender);
          item.created_on = formatDate(item.created_on);
          return item;
        });
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
