<template>
  <div class="d-flex align-center">
    <b-button icon="x-octagon-fill" @click="dialog = true" variant="primary">
      Update</b-button
    >
    <b-modal
      tabindex="0"
      v-model="dialog"
      title="Update Retrieval"
      hide-footer
      ignore-enforce-focus-selector=".select2-search__field"
      size="md"
      centered
      scrollable
    >
      <form-wrapper @submit="submit">
        <b-row>
          <b-col  cols="12" md="6">
            <radio-group
              label="Status"
              rules="required"
              :options="options"
              v-model="form.organ_retrieval_status"
            ></radio-group>
          </b-col>
          <b-col  cols="12" md="6">
            <input-field
              label="Notes"
              :rules="form.organ_retrieval_status == '2' ? 'required' : ''"
              v-model="form.notes"
            ></input-field>
          </b-col>
          <template v-if="form.organ_retrieval_status == '1'">
            <b-col  cols="12" md="6">
              <date-time-picker
                label="Organ Retrieval Date and Time"
                rules="required"
                v-model="form.retrieval_on"
              ></date-time-picker>
            </b-col>
            <b-col  cols="12" md="6">
              <input-field
                label="Organ Retrieval By"
                rules="required"
                v-model="form.retrieval_by"
              ></input-field>
            </b-col>
          </template>
          <b-col  cols="12" md="6">
            <file-input-group
              label="File Upload"
              accept=".jpg,.jpeg,.png,.pdf"
              rules="file-image-pdf|file-size"
              v-model="form.file_path"
            ></file-input-group>
          </b-col>
          <b-col  cols="12" md="6">
            <file-input-group
              label="Video Upload"
              accept=".mp4,.mkv,.3gp"
              rules="file-video|file-size-100"
              v-model="form.video_path"
            ></file-input-group>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" class="ms-3" type="submit"
            >Submit
          </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import { ACCEPT_DECLINE } from "../../../../../assets/constants/recipient.constants";
import { openFile } from "../../../../../assets/js/common";
import donorService from "../../../../../assets/services/donor.service";
import DateTimePicker from "../../../../form/DateTimePicker.vue";
import FileInputGroup from "../../../../form/FileInputGroup.vue";
import InputField from "../../../../form/InputField.vue";
import RadioGroup from "../../../../form/RadioGroup.vue";
import userHelper from "../../../../manageuser/user.helper";
export default {
  components: { InputField, DateTimePicker, RadioGroup, FileInputGroup },
  props: {
    recipeint: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      dialog: false,
      form: {
        file_path: null,
        video_path: null,
      },
      options: ACCEPT_DECLINE,
    };
  },
  methods: {
    reset() {
      this.form = {};
    },
    open(file) {
      openFile(file);
    },
    async submit() {
      const vm = this;
      let {
        organ_allocation_id,
        donor,
        organ_id,
        final_donor_id,
        is_multi,
        organ_id_org,
      } = vm.item;
      if (is_multi) organ_id = organ_id_org;

      let { case_officer, donor_temp_registration_id } = donor;
      let {
        retrieval_on,
        retrieval_by,
        organ_retrieval_status,
        file_path,
        notes,
        video_path,
      } = vm.form;
      let { recipient_registration_id, recepeint_allocation_id, hospital_id } =
        vm.recipeint;
      let doc = {
        retrieval_detail_id: 0,
        recipient_surgery_id: 0,
        retrieval_status: 1,
        organ_retrieval_status,
        recepeint_allocation_id,
        organ_allocation_id,
        final_donor_id,
        donor_temp_registration_id,
        notes,
        case_officer,
        hospital_id,
        organ_id,
        recepient_registration_id: recipient_registration_id,
        created_by: vm.created_by,
        updated_by: vm.created_by,
        retrieval_on,
        retrieval_by,
        dml_indicator: "I",
      };
      try {
        vm.$loader.show();
        if (file_path instanceof File) {
          doc.file_path = await userHelper.uploadContact(file_path);
        }
        if (video_path instanceof File) {
          doc.video_path = await userHelper.uploadVideo(video_path);
        }
        let { data } = await donorService.retrievalDetails(doc);
        let { status, msg } = data;
        vm.dialog = false;
        if (!status) {
          vm.$alert.show(msg);
        }
        vm.$emit("onSubmit");
        vm.$loader.hide();
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
  },
};
</script>

<style></style>
