<template>
  <b-button size="sm" icon variant="link" @click="$emit('click')">
    <b-icon :variant="variant" :icon="icon" class="font-size-16"></b-icon>
  </b-button>
</template>

<script>
export default {
  props: {
    icon: {
      default: "pencil-fill",
    },
    variant: {
      default: "secondary",
    },
  },
};
</script>

<style></style>
