<template>
  <div>
    <allocation-recipient-table
      title="Update My Waitlist"
      :fields="fields"
      class="mb-0"
      :item="item"
      :isBusy="isLoading"
      :items="list"
    >
      <template v-slot:action="{ item }">
        <template v-if="item.isAllocated && !isTimeOut">
          <update-waiting-list-status
            :id="item.recepeint_allocation_id"
            v-if="isStatusUpdate(item)"
            @fetch="init()"
          ></update-waiting-list-status>
        </template>
      </template>
    </allocation-recipient-table>
  </div>
</template>

<script>
import moment from "moment";
import {
  DONOR_TIMER,
  RECIPIENT_ALLOCATION_STATUS,
} from "../../../../assets/constants/donor.constants";
import {
  BE_READY_STATUS,
  PATIENT_STATUS,
  PAYMENT_STATUS,
} from "../../../../assets/constants/recipient.constants";
import { formatDate } from "../../../../assets/js/common";
import donorMixins from "../../../../assets/mixins/donor.mixins";
import donorService from "../../../../assets/services/donor.service";
import AllocationRecipientTable from "./AllocationRecipientTable.vue";
import UpdateWaitingListStatus from "./updatemywaitlist/UpdateWaitingListStatus.vue";
export default {
  components: {
    UpdateWaitingListStatus,
    AllocationRecipientTable,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    history: {
      type: Array,
      default: () => [],
    },
    hospitals: {
      type: Array,
      default: () => [],
    },
    status: {
      type: Number,
      default: 2,
    },
  },
  mixins: [donorMixins],
  data() {
    return {
      isLoading: false,
      dialog: false,
      list: [],
      isTimeOut: true,
      interval: null,
      paymentStatus: PAYMENT_STATUS,
      patientStatus: PATIENT_STATUS,
      beReadyStatus: BE_READY_STATUS,
    };
  },
  computed: {
    fields() {
      let doc = {
        recepient_organ_allocation_status: "Accept/Decline Status",
        reject_reason: "Reject Reason",
        action: "Action",
      };
      return doc;
    },
  },
  watch: {
    status() {
      this.init();
    },
  },
  beforeDestroy() {
    if (this.interval != null) {
      clearInterval(this.interval);
    }
  },
  methods: {
    checkCountDown() {
      const vm = this;
      let { organ_allocation_id, allocation_status, type_of_allocation } =
        vm.item;
      if ([3, 1].indexOf(type_of_allocation) != -1) {
        vm.isTimeOut = false;
        return;
      }
      let history = vm.history.find((item) => {
        return (
          item.organ_allocation_id == organ_allocation_id &&
          item.status == allocation_status
        );
      });
      if (history != null) {
        let date = moment(history.created_on).add(
          DONOR_TIMER[allocation_status],
          "minutes"
        );
        vm.interval = setInterval(() => {
          vm.isTimeOut = date.isBefore(moment());
        }, 1000);
      }
    },
    getBg(val) {
      switch (val) {
        case "Accepted":
          return "badge bg-success font-size-13";
        case "Decline":
          return "badge bg-danger font-size-13";
        default:
          return "badge bg-light font-size-13";
      }
    },
    isStatusUpdate({ recepient_organ_allocation_status }) {
      if (recepient_organ_allocation_status != 0) return;
      let { allocation_status } = this.item;
      let { status } = this;
      return (
        ((status == 2 || status == 2.1) && allocation_status == 2) ||
        ((status == 4 || status == 4.1) && allocation_status == 4) ||
        ((status == 19 || status == 19.1) && allocation_status == 19) ||
        ((status == 14 || status == 14.1) && allocation_status == 14) ||
        ((status == 15 || status == 15.1) && allocation_status == 15) ||
        ((status == 16 || status == 16.1) && allocation_status == 16) ||
        (status == 33.1 && allocation_status == 33) ||
        (status == 29.1 && allocation_status == 29)
      );
    },
    submit() {},
    getStatus(val) {
      let item = RECIPIENT_ALLOCATION_STATUS.find((item) => {
        return item.value == val;
      });
      return item ? item.name : "";
    },
    async init() {
      const vm = this;
      vm.dialog = false;
      let { user_type, hospital_id } = this.user;
      let { organ_id_org, is_multi, organ_id, organ_allocation_id } = vm.item;
      if (is_multi) organ_id = organ_id_org;
      const doc = {
        dml_indicator: "S",
        organ_id,
        recepient_type_of_allocation: 1,
        organ_allocation_id,
        hospital_id,
        user_type,
      };
      if (vm.status == 4 || vm.status == 4.1)
        doc.recepient_type_of_allocation = 2;
      if (vm.status == 19 || vm.status == 19.1) {
        doc.recepient_type_of_allocation = 3;
      }
      if (vm.status == 14 || vm.status == 14.1)
        doc.recepient_type_of_allocation = 4;
      if (vm.status == 15 || vm.status == 15.1)
        doc.recepient_type_of_allocation = 5;
      if (vm.status == 16 || vm.status == 16.1)
        doc.recepient_type_of_allocation = 6;
      if (vm.status == 33.1) doc.recepient_type_of_allocation = 7;
      if (vm.status == 29.1) doc.recepient_type_of_allocation = 8;

      try {
        vm.isLoading = true;
        vm.list = [];
        let { data } = await donorService.getAllocatedWL(doc);
        let { status, msg, list } = data;
        vm.isLoading = false;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        let isAllocated = false;

        vm.list = list.map((item) => {
          let statusList = ["2.1", "14.1", "19.1"];
          if (statusList.indexOf(vm.status.toString()) != -1) {
            if (
              item.recepient_organ_allocation_status == 0 &&
              isAllocated == false
            ) {
              item.isAllocated = true;
              isAllocated = true;
            }
          } else {
            item.isAllocated = true;
          }
          item.gender = vm.getGender(item.gender);
          item.created_on = formatDate(item.created_on);
          return item;
        });
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.init();
    this.checkCountDown();
  },
};
</script>

<style></style>
