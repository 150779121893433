<template>
  <div>
    <allocation-recipient-table
      title="Generate Wait list"
      :isBusy="isLoading"
      :item="item"
      :fields="{}"
      :items="list"
    >
      <template v-slot:add>
        <template v-if="isAdmins">
          <CheckALF :item="item" v-if="false"></CheckALF>
          <b-button variant="primary" @click="dialog = true" v-if="isAdmins"
            >Generate List</b-button
          >
        </template>
        <b-modal
          v-model="dialog"
          centered
          size="lg"
          ignore-enforce-focus-selector=".select2-search__field"
          no-close-on-backdrop
          scrollable
          hide-footer
          title="Recipient Filter"
        >
          <form-wrapper @submit="submit">
            <b-row>
              <template v-if="!isInhouse">
                <b-col  cols="12" md="3">
                  <select-field
                    v-model.trim="form.hospital_type"
                    label="Hospital Type"
                    :items="getSelect(masterHospitalTypes)"
                  ></select-field>
                </b-col>
                <b-col  cols="12" md="3">
                  <autocomplete
                    v-model.trim="zone"
                    label="Zone"
                    :multiple="true"
                    :items="masterZones"
                  ></autocomplete>
                </b-col>
              </template>
              <b-col  cols="12" md="3">
                <select-field
                  v-model="form.patient_nationlity"
                  label="Nationality"
                  :items="getSelect(masterNationality)"
                ></select-field>
              </b-col>
              <b-col  cols="12" md="3">
                <autocomplete
                  label="Blood Group"
                  :items="filterBloodGroups"
                  :multiple="true"
                  v-model="blood_group"
                ></autocomplete>
              </b-col>
              <b-col  cols="12" md="3" v-if="false">
                <select-field
                  label="Be Ready Status"
                  :items="beReadyStatus"
                  v-model="form.hospital_status"
                ></select-field>
              </b-col>
              <b-col  cols="12" md="3">
                <select-field
                  label="Age Type"
                  :items="getSelect(masterAgeTypes)"
                  v-model="form.age"
                ></select-field>
              </b-col>
              <b-col cols="6" v-if="type == 'popup'">
                <radio-group
                  label="Limit"
                  rules="required"
                  :options="radioGroup"
                  v-model="form.recordlimit"
                ></radio-group>
              </b-col>
            </b-row>
            <div class="d-flex">
              <div class="flex-grow-1"></div>
              <b-button
                variant="outline-danger mx-3"
                outlined
                @click="dialog = false"
              >
                Close
              </b-button>
              <b-button variant="success" type="submit"> Submit </b-button>
            </div>
          </form-wrapper>
        </b-modal>
      </template>
      <div class="d-flex mt-3" v-if="isAdmins">
        <div class="flex-grow-1"></div>
        <b-button
          variant="outline-danger mx-3"
          outlined
          v-if="type == 'popup'"
          @click="$emit('close')"
        >
          Close
        </b-button>

        <b-button variant="success" v-if="list.length" @click="generate"
          >Confirm</b-button
        >
      </div>
    </allocation-recipient-table>
  </div>
</template>

<script>
import {
  BE_READY_STATUS,
  PATIENT_STATUS,
  PAYMENT_STATUS,
} from "../../../../assets/constants/recipient.constants";
import { BLOOD_GROUP_VALUES, formatDate } from "../../../../assets/js/common";
import event from "../../../../assets/js/event";
import donorMixins from "../../../../assets/mixins/donor.mixins";
import donorService from "../../../../assets/services/donor.service";
import recipientService from "../../../../assets/services/recipient.service";
import Autocomplete from "../../../form/Autocomplete.vue";
import RadioGroup from "../../../form/RadioGroup.vue";
import AllocationRecipientTable from "./AllocationRecipientTable.vue";
import CheckALF from "./CheckALF.vue";
export default {
  components: {
    Autocomplete,
    CheckALF,
    RadioGroup,
    AllocationRecipientTable,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    hospitals: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "window",
    },
  },
  mixins: [donorMixins],
  data() {
    return {
      isLoading: false,
      dialog: false,
      list: [],
      form: {
        hospital_id: "",
        hospital_type: "",
        patient_nationlity: "",
        age: "",
        recordlimit: "1",
        hospital_status: "",
      },
      zone: [],
      radioGroup: [
        {
          name: "First 50",
          value: "1",
        },
        {
          name: "Next 50",
          value: "2",
        },
        {
          name: "All",
          value: "3",
        },
      ],

      blood_group: [],
      paymentStatus: PAYMENT_STATUS,
      patientStatus: PATIENT_STATUS,
      beReadyStatus: BE_READY_STATUS,
    };
  },
  computed: {
    isInhouse() {
      return this.item.type_of_allocation == 1;
    },
  },
  methods: {
    async generate() {
      const vm = this;
      if (vm.list.length == 0) return;
      try {
        vm.$loader.show();
        let list = [];
        let { final_donor_id, id } = vm;
        let {
          organ_allocation_id,
          donor,
          organ_id,
          is_multi,
          organ_id_org,
          type_of_allocation,
          allocation_status,
        } = vm.item;
        let { case_officer } = donor;
        if (is_multi) organ_id = organ_id_org;

        vm.list.forEach((item) => {
          let { recipient_registrationid, hospital_id } = item;
          let doc = {
            final_donor_id,
            organ_allocation_id,
            donor_temp_registration_id: id,
            case_officer,
            hospital_id,
            organ_id,
            status: 0,
            organ_allocated_step: 1,
            recepient_registration_id: recipient_registrationid,
            created_by: vm.created_by,
            updated_by: vm.created_by,
            type_of_allocation,
            allocation_status,
            reject_reason: "",
            dml_indicator: "I",
          };
          list.push(doc);
        });
        let { data } = await donorService.generateWL({
          recepient_organ_allocation_proc: list,
        });
        let { status, msg } = data;
        vm.isLoading = false;
        vm.$loader.hide();
        event.$emit("refresh");
        vm.$emit("close");

        if (!status) {
          vm.$alert.show(msg);
        }
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    isDelete(item) {
      return item.transtan_status == 4 || item.transtan_status == 1;
    },
    isApproved(item) {
      return item.transtan_status == 4;
    },
    async submit() {
      const vm = this;
      vm.dialog = false;
      const doc = {
        dml_indicator: "S",
      };
      let { allocated_organ } = vm.item;
      Object.keys(vm.form).forEach((key) => {
        let value = vm.form[key];
        if (value == "all") value = "";
        if (value) {
          doc[key] = value;
        } else {
          doc[key] = "";
        }
      });
      doc.blood_group = [];
      vm.blood_group.forEach((id) => {
        BLOOD_GROUP_VALUES[id].forEach((blood_group) => {
          doc.blood_group.push({ blood_group });
        });
      });
      doc.zone_list = vm.zone.map((zone) => {
        return { zone };
      });
      if (allocated_organ == "11,12") {
        doc.multi_organ = allocated_organ;
      } else {
        doc.organ = allocated_organ.split(",").map((organ_id) => {
          return { organ_id };
        });
      }
      doc.filter_type = 1;
      doc.multi_organ = allocated_organ;
      if (vm.isInhouse) {
        doc.hospital_id = vm.item.donor.hospital_id;
        doc.recordlimit = "4";
      }
      try {
        vm.isLoading = true;
        vm.list = [];
        let data = {};
        if (vm.isInhouse) {
          data = (await recipientService.getLocalWaitingList(doc)).data;
        } else {
          data = (await recipientService.getSharedWaitingList(doc)).data;
        }
        let { status, msg, list } = data;
        vm.isLoading = false;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.list = list.map((item) => {
          item.gender = vm.getGender(item.gender);
          item.created_on = formatDate(item.created_on);
          return item;
        });
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
