<template>
  <b-card class="allocation__tissue">
    <form-wrapper @submit="submit()">
      <b-row>
        <b-col cols="12" class="mb-2" v-if="!isLocal && !disabled">
          <b-form-checkbox
            v-model="is_other_state"
            @change="onOtherState"
            class="cursor-pointer"
          >
            <span class="ms-2"> Is tissue retrieved by other state? </span>
          </b-form-checkbox>
        </b-col>
        <b-col cols="12" md="6" v-if="!is_other_state">
          <autocomplete
            label="Hospital Name"
            :disabled="disabled || isLocal"
            :items="hospitals"
            rules="required"
            v-model="form.hospital_name"
          ></autocomplete>
          <input-field
            label="State"
            rules="required"
            :disabled="true"
            v-model="form.state_name"
          ></input-field>
        </b-col>
        <template v-else>
          <b-col cols="12" md="6">
            <input-field
              label="Hospital Name"
              :disabled="disabled"
              rules="required"
              v-model="form.hospital_name"
            ></input-field>
          </b-col>
          <b-col cols="12" md="6">
            <autocomplete
              label="State"
              rules="required"
              :disabled="disabled"
              :items="states"
              v-model="form.state_name"
            ></autocomplete>
          </b-col>
        </template>
        <b-col cols="12" md="6">
          <text-area
            label="Details of Tissue Retrieved"
            :disabled="disabled"
            v-model="form.details_of_tissue_retrieved"
            rules="required"
          ></text-area>
        </b-col>
        <b-col cols="12" md="6">
          <date-time-picker
            label="Retrieval Date and Time"
            rules="required"
            :disabled="disabled"
            v-model="form.retrieval_date_time"
          ></date-time-picker>
        </b-col>
        <b-col cols="12" md="6">
          <input-field
            rules="required"
            label="Retrieved By"
            :disabled="disabled"
            v-model="form.retrievedby"
          ></input-field>
        </b-col>
      </b-row>
      <div class="d-flex" v-if="isSubmit">
        <div class="flex-grow-1"></div>
        <b-button type="submit" v-if="isAdmins" variant="primary"
          >Submit</b-button
        >
      </div>
    </form-wrapper>
  </b-card>
</template>

<script>
import event from "../../../assets/js/event";
import donorService from "../../../assets/services/donor.service";
import hospitalService from "../../../assets/services/hospital.service";
import masterService from "../../../assets/services/master.service";
import Autocomplete from "../../form/Autocomplete.vue";
import DateTimePicker from "../../form/DateTimePicker.vue";
import InputField from "../../form/InputField.vue";
import TextArea from "../../form/TextArea.vue";
export default {
  components: { InputField, DateTimePicker, Autocomplete, TextArea },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      hospitals: [],
      form: {
        organ_allocation_id: null,
        hospital_name: null,
        state_name: "Tamil Nadu",
        details_of_tissue_retrieved: null,
        retrieval_date_time: null,
        retrievedby: null,
      },
      states: [],
      is_other_state: false,
    };
  },
  computed: {
    isSubmit() {
      if (this.item.allocation_status != 1) return false;
      if (this.isAdmins) return true;
      return this.userHospital == this.item.hospital_id;
    },
    disabled() {
      return this.item.allocation_status != 1;
    },
    isLocal() {
      return this.item.type_of_allocation == 1;
    },
  },
  methods: {
    onOtherState() {
      const vm = this;
      vm.form.hospital_name = "";
      vm.form.state_name = "";
      if (vm.is_other_state == false) {
        vm.form.state_name = "Tamil Nadu";
      }
    },
    async submit() {
      const vm = this;
      try {
        let doc = { ...vm.form };
        doc.dml_indicator = "I";
        doc.created_by = vm.created_by;
        doc.organ_allocation_id = vm.item.organ_allocation_id;
        vm.$loader.show();
        let { status, msg } = (await donorService.organAllocationTissue(doc))
          .data;
        vm.$loader.hide();
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        event.$emit("refresh");
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
    async fetchHospital() {
      const vm = this;
      try {
        let { user_type } = vm.$store.getters.user;
        let doc = { user_type };
        vm.$loader.show();
        let { status, msg, list } = (await hospitalService.list(doc)).data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.hospitals = list.map((item) => {
          item.name = item.hospital_name;
          item.value = item.hospital_name;
          return item;
        });
        if (vm.isLocal) {
          let hospital = list.find((item) => {
            return item.hospital_id == vm.item.hospital_id;
          });
          if (hospital != null) {
            vm.form.hospital_name = hospital.hospital_name;
          }
        }
        let data = (
          await masterService.getStateCity({
            country_id: "99",
          })
        ).data;
        vm.states = data.list.map((item) => {
          item.value = item.name;
          return item;
        });
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    async init() {
      const vm = this;
      console.log("allocation_status", vm.item.allocation_status);
      if (vm.item.allocation_status == 1) {
        vm.fetchHospital();
        return;
      }
      try {
        vm.$loader.show();
        let doc = {
          dml_indicator: "S",
          organ_allocation_id: vm.item.organ_allocation_id,
        };
        let { status, msg, list } = (
          await donorService.getAllocationTissue(doc)
        ).data;
        console.log("Allocation fetched successfully...");
        vm.$loader.hide();
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        if (list.length) {
          let doc = list[0];
          vm.is_other_state = true;
          vm.states.push({ name: doc.state_name, value: doc.state_name });
          vm.form = doc;
        }
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
.allocation__tissue {
  max-width: 600px;
  margin: 0 auto;
}
</style>
