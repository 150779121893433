<template>
  <vue-countdown-timer
    :start-time="config.start"
    :end-time="config.end"
    :interval="1000"
  >
    <template slot="countdown" slot-scope="scope">
      <div style="min-width: 70px">
        <span>{{ getHour(scope.props) }}</span> {{ scope.props.hourTxt }}
        <span>{{ scope.props.minutes }}</span>
        {{ scope.props.minutesTxt }}
        <span>{{ scope.props.seconds }}</span>
      </div>
    </template>
    <template slot="end-text"> 00:00:00 </template>
  </vue-countdown-timer>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getHour({ days, hours }) {
      days = parseInt(days) * 24;
      return days + parseInt(hours);
    },
  },
};
</script>

<style></style>
