<template>
  <div>
    <b-form-select
      style="width: 100px"
      class="form-control"
      v-model="input"
      v-bind="$attrs"
      text-field="name"
      value-field="value"
      @change="$emit('input', input)"
      :options="items"
    >
      <template #first>
        <b-form-select-option :value="null">{{
          placeholder
        }}</b-form-select-option>
      </template>
    </b-form-select>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    placeholder: String,
    value: {},
    items: Array,
  },
  data() {
    return {
      input: null,
    };
  },
  watch: {
    value(val) {
      this.input = val;
    },
  },
  computed: {
    formattedLabel() {
      if (!this.rules) return this.label;
      if (this.rules.indexOf("required") != -1)
        return this.label + " <span style='color:red'>*</span>";
      return this.label;
    },
  },
  mounted() {
    this.input = this.value;
  },
};
</script>

<style></style>
