<template>
  <div>
    <table-view
      title="User List"
      class="mt-4"
      :fields="fields"
      :isBusy="isLoading"
      :items="list"
    >
      <template v-slot:name="{ item }">
        {{ item.first_name }} {{ item.last_name }}
      </template>
      <template v-slot:status="{ item }">
        {{ getStatus(item) }}
      </template>
      <template v-slot:action="{ item }">
        <div class="d-flex">
          <create-user
            :hospital_id="item.hospital_id"
            @fetch="$emit('fetch')"
            :item="item"
          ></create-user>
          <delete-button
            v-if="isDeleteAble(item)"
            @click="remove(item)"
          ></delete-button>
          <b-button size="sm" icon variant="link" @click="reset(item)">
            <i class="mdi mdi-send font-size-16"></i>
          </b-button>
        </div>
      </template>
    </table-view>
  </div>
</template>

<script>
import { encode } from "../../assets/js/common";
import DeleteButton from "../buttons/DeleteButton.vue";
import TableView from "../table/TableView.vue";
import CreateUser from "./CreateUser.vue";
import userService from "../../assets/services/user.service";
import menuConstants from "../../assets/constants/menu.constants";
export default {
  components: { TableView, DeleteButton, CreateUser },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    list: Array,
  },
  data() {
    return {
      isDraftLoading: false,
    };
  },
  computed: {
    fields() {
      let doc = {
        sno: "S.No",
        name: "Name",
        email_id: "Email ID / User ID",
        contact_name: "Contact Type",
        phone_number_1: "Phone Number",
        status: "Status",
        action: "Action",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
          tdClass: key == "action" ? "width-100" : "",
        });
      });
      return list;
    },
    isUser() {
      return this.$route.name == "user-management";
    },
  },
  methods: {
    isView({ status }) {
      if (this.isUser) {
        let hasAccess = this.hasEditAccess(menuConstants.USER);
        return !hasAccess || status == 4;
      }
      let hasAccess = this.hasEditAccess(menuConstants.hasEditAccess);
      return !hasAccess || status == 4;
    },
    isDeleteAble({ status }) {
      if (this.isUser) {
        let hasAccess = this.hasStatusAccess(menuConstants.USER, 4);
        return hasAccess && status != 4;
      }
      let hasAccess = this.hasStatusAccess(menuConstants.TRANSTAN_USER_LIST, 4);
      return hasAccess && status != 4;
    },
    isTerminated(item) {
      return this.isDelete(item) && item.status != 6 && item.status != 8;
    },
    open({ donor_temp_registration_id }) {
      let id = encode(donor_temp_registration_id);
      this.$router.push(`/update-donor?id=${id}`);
    },
    getStatus({ status }) {
      return status == 1 ? "Active" : "Deleted";
    },
    remove({ hospital_contact_id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do want to remove this user?",
        onConfirm: () => {
          vm.changeStatus({
            hospital_contact_id,
            status: 4,
          });
        },
      });
    },
    async reset(item) {
      const vm = this;
      try {
        vm.$loader.show();
        item.created_by = vm.created_by;
        item.user_status = 1;
        item.user_created_by = vm.isUser ? 1 : 2;
        item.dml_indicator = "RSP";
        let { data } = await userService.resendPassword(item);
        let { status, msg } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    async changeStatus(doc) {
      const vm = this;
      try {
        vm.$loader.show();
        let { data } = await userService.deleteUser(doc);
        let { status, msg } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.$emit("fetch");
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
