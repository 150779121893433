<template>
  <table-view
    :fields="headers"
    :isBusy="loading"
    :search="search"
    title=""
    :items="formatedList"
    :items-per-page="5"
  >
    <template v-slot:filter>
      <filter-select
        :items="organs"
        class="ms-2"
        v-model="organ"
        placeholder="All Organs"
      ></filter-select>
      <filter-select
        :items="statusList"
        v-model="status"
        class="ms-2"
        placeholder="All Status"
      ></filter-select>
    </template>
    <template v-slot:status="{ item }">
      {{ getStatus(item) }}
    </template>
    <template v-slot:update="{ item }">
      <template v-if="!isView(item)">
        <template v-if="isOrgan(item)">
          <b-button
            v-if="isUpdate(item)"
            variant="danger"
            size="sm"
            block
            @click="open(item)"
          >
            UPDATE DETAILS
          </b-button>
          <b-button block v-else size="sm"> DETAILS UPDATED </b-button>
        </template>
      </template>
    </template>
    <template v-slot:action="{ item }">
      <div class="d-flex">
        <Menu :hospital="item"></Menu>
        <view-button v-if="isView(item)" @click="open(item)"></view-button>
        <edit-button @click="open(item)" v-else></edit-button>
        <payment-icon-button
          v-if="isPayment(item)"
          @click="initPayment(item)"
        ></payment-icon-button>
        <delete-button
          @click="remove(item)"
          v-if="isDeleteAble(item)"
        ></delete-button>
        <template>
          <template v-if="isOrgan(item)">
            <approve-button
              @click="approve(item)"
              v-if="isApprovable(item) && !isUpdate(item)"
            ></approve-button>
          </template>
          <template v-else>
            <approve-button
              @click="approve(item)"
              v-if="isApprovable(item)"
            ></approve-button>
          </template>
        </template>
      </div>
    </template>
  </table-view>
</template>

<script>
import moment from "moment";
import { STATUS } from "../../assets/constants/hospital.contants";
import menuConstants from "../../assets/constants/menu.constants";
import { encode, initPayment } from "../../assets/js/common";
import commonMixins from "../../assets/mixins/common.mixins";
import hospitalService from "../../assets/services/hospital.service";
import licenseService from "../../assets/services/license.service";
import ApproveButton from "../buttons/ApproveButton.vue";
import DeleteButton from "../buttons/DeleteButton.vue";
import EditButton from "../buttons/EditButton.vue";
import ViewButton from "../buttons/ViewButton.vue";
import FilterSelect from "../form/FilterSelect.vue";
import TableView from "../table/TableView.vue";
import Menu from "./list/Menu.vue";
import PaymentIconButton from "../buttons/PaymentIconButton.vue";
export default {
  props: {
    value: Object,
  },
  mixins: [commonMixins],
  components: {
    EditButton,
    Menu,
    DeleteButton,
    ApproveButton,
    ViewButton,
    TableView,
    FilterSelect,
    PaymentIconButton,
  },
  data() {
    return {
      list: [],
      organ: null,
      loading: true,
      status: null,
      search: "",
    };
  },
  computed: {
    organs() {
      if (this.master.master_organ instanceof Array == false) return [];
      let list = this.master.master_organ.filter(
        ({ status, tissue_bank, license_available }) => {
          return status != 4 && (tissue_bank == 1 || license_available == 1);
        }
      );
      return list.map((item) => {
        return { value: item.organ_id, name: item.organ_name };
      });
    },
    statusList() {
      let list = [];
      Object.keys(STATUS).forEach((value) => {
        list.push({
          name: STATUS[value],
          value,
        });
      });
      return list;
    },
    formatedList() {
      const vm = this;
      let list = vm.list
        .map((item) => {
          item.organ = vm.getOrgan(item);
          return item;
        })
        .reverse();
      list = list.filter((item) => {
        if (vm.organ == null) return true;
        return item.organ_id == vm.organ;
      });
      list = list.filter((item) => {
        if (vm.status == null) return true;
        return item.status == vm.status;
      });
      list = list.map((item, i) => {
        item.sno = i + 1;
        return item;
      });
      let pending = list.filter((item) => {
        return item.status == 1;
      }).length;
      let live = list.filter((item) => {
        return item.status == 2;
      }).length;
      let expired = list.filter((item) => {
        return item.status == 3;
      }).length;
      let deleted = list.filter((item) => {
        return item.status == 4;
      }).length;
      vm.$emit("input", { pending, live, expired, deleted });
      return list;
    },
    master() {
      return this.$store.getters.master;
    },
    headers() {
      let list = [];
      let headers = {
        sno: "S No",
        organ_name: "Organ Type",
        hospital_name: "Hospital Name",
        expiry_date_format: "License Expiry Date",
        certificate_number: "Certificate No",
        update: "Pending Update",
        status: "Status",
        action: "Action",
      };
      Object.keys(headers).forEach((key) => {
        let label = headers[key];
        let doc = {
          label,
          key,
          tdClass: key == "action" ? "width-100" : "",
        };
        list.push(doc);
      });
      return list;
    },
  },
  methods: {
    async initPayment(item) {
      const vm = this;
      let { organ_license_id, organ_id } = item;
      try {
        let doc = {
          dml_indicator: vm.isPaymentExpiry(item) ? "RO" : "NO",
          organ_license_id,
          multi_organ_id: organ_id,
        };
        vm.$loader.show();
        let data = (await hospitalService.initPayment(doc)).data;
        initPayment(data);
        vm.$loader.hide();
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
    isPayment({ payment_flag, expiry_date, hospital_type }) {
      if (hospital_type != 2) return false;
      var duration = moment.duration(moment(expiry_date).diff(moment()));
      return payment_flag == 0 || payment_flag == 2 || duration.asMonths() <= 1;
    },
    isPaymentExpiry({ payment_flag, expiry_date }) {
      var duration = moment.duration(moment(expiry_date).diff(moment()));
      return (
        payment_flag == 2 || (payment_flag == 1 && duration.asMonths() <= 1)
      );
    },

    getStatus({ status }) {
      return STATUS[status] || "";
    },
    isOrgan({ license_available }) {
      return license_available == 1;
    },
    isDeleteAble({ status }) {
      let hasAccess = this.hasStatusAccess(
        menuConstants.MANAGE_HOSPITAL_LICENCE,
        4
      );
      let isNotAdmin = true;
      if (!this.isAdmin) {
        isNotAdmin = status == 1;
      }
      return hasAccess && status != 4 && isNotAdmin;
    },
    isView({ status }) {
      let hasAccess = this.hasEditAccess(menuConstants.MANAGE_HOSPITAL_LICENCE);
      return !hasAccess || status == 4;
    },
    isApprovable({ status }) {
      let hasAccess = this.hasStatusAccess(
        menuConstants.MANAGE_HOSPITAL_LICENCE,
        2
      );
      return hasAccess && status != 2 && status != 4;
    },
    remove({ organ_license_id }) {
      const vm = this;
      vm.$confirm.show({
        message:
          "Are you sure, Do you want to remove this Hospital Organ List?",
        onConfirm: () => {
          vm.changeStatus({
            organ_license_id,
            status: 4,
          });
        },
      });
    },
    approve({ organ_license_id }) {
      const vm = this;
      vm.$confirm.show({
        message:
          "Are you sure, Do you want to approve this Hospital Organ License?",
        onConfirm: () => {
          vm.changeStatus({
            organ_license_id,
            status: 2,
          });
        },
      });
    },
    async changeStatus(doc) {
      const vm = this;
      try {
        vm.$loader.show();
        let { data } = await licenseService.updateStatus(doc);
        let { status, msg } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.fetch();
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },

    isUpdate({ expiry_date, certificate_number }) {
      return expiry_date == null || certificate_number == null;
    },
    open({ organ_license_id }) {
      let id = encode(organ_license_id);
      this.$router.push(`/update-transplant-license?id=${id}`);
    },
    getOrgan({ organ_id }) {
      let item = this.organs.find((item) => {
        return item.value == organ_id;
      });
      return item != null ? item.name : "";
    },
    async fetch() {
      const vm = this;
      try {
        vm.loading = true;
        vm.list = [];
        let { data } = await licenseService.list({ dml_indicator: "L" });
        let { status, msg, list } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.loading = false;
        vm.list = list.map((item) => {
          return licenseService.parseItem(item);
        });
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style></style>
