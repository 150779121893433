<template>
  <div>
    <div>
      <select class="js-data-example-ajax" style="width: 300px"></select>
    </div>
  </div>
</template>

<script>
import { formatDate } from '../../assets/js/common';
import recipientService from "../../assets/services/recipient.service";
export default {
  data() {
    return {
      uid: "",
      item: {},
    };
  },
  computed: {},
  methods: {
    formatRepo(repo) {
      if (repo.loading) {
        return repo.text;
      }
      var $container = window.$(
        `<div >
         <h6 class="mb-0">${repo.first_name} - <b>${repo.recipient_registration_id}</b></h6>
         <div>${repo.hospital_name}</div>
        </div>
        `
      );
      return $container;
    },
    formatRepoSelection(repo) {
      this.$emit("select", repo);
      return repo.first_name || repo.text;
    },
  },
  mounted() {
    window
      .$(".js-data-example-ajax")
      .select2({
        placeholder: "Search by UID/Aadhar/Name",
        theme: "bootstrap-5",
        dropdownCssClass: "ajax-autocomplete-list",
        templateResult: this.formatRepo,
        allowClear: true,
        templateSelection: this.formatRepoSelection,
        minimumInputLength: 3,
        ajax: {
          cache: true,
          transport: function (params, success, failure) {
            console.log(params);
            let q = params.data.q || "";
            recipientService
              .searchUid({ multi_organ: q })
              .then(({ data }) => {
                let results = data.list.map((item) => {
                  item.id = item.recipient_registration_id;
                  item.created_on = formatDate(item.created_on);
                  return item;
                });
                success({ results });
              })
              .catch((error) => {
                console.log(error);
                failure(error);
              });
          },
        },
      })
      .trigger("change");
  },
};
</script>

<style lang="scss">
.ajax-autocomplete-list {
  li {
    border-bottom: 1px solid lightgrey;
    &:hover {
      h6 {
        color: white;
      }
    }
  }
}
</style>
