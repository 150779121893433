<template>
  <div>
    <div class="recipient-transfer" v-if="!isVerified">
      <b-card>
        <b-card-title class="pb-3">Verify Donor</b-card-title>
        <form-wrapper @submit="verify()">
          <input-field
            label="Email"
            rules="required|email"
            type="text"
            v-model="form.email_id"
          ></input-field>
          <b-btn variant="primary" class="mt-1" type="submit" block>
            Submit</b-btn
          >
        </form-wrapper>
      </b-card>
    </div>
    <form-wrapper @submit="submit()" v-else>
      <expandable header="Donor Details">
        <b-row>
          <b-col cols="12" md="4">
            <input-field
              label="Name"
              rules="required"
              v-model="form.donor_name"
            ></input-field>
          </b-col>
          <b-col cols="12" md="4">
            <input-field
              label="Address"
              rules="required"
              v-model="form.address"
            ></input-field>
          </b-col>
          <b-col cols="12" md="4">
            <date-picker
              label="DOB"
              rules="required"
              v-model="form.dob"
            ></date-picker>
          </b-col>
          <b-col cols="12" md="4">
            <radio-group
              label="Gender"
              :options="genders"
              rules="required"
              v-model="form.gender"
            ></radio-group>
          </b-col>
          <b-col cols="12" md="4">
            <select-field
              label="Blood Group"
              rules="required"
              :items="bloodGroups"
              v-model="form.blood_group"
            ></select-field>
          </b-col>
        </b-row>
      </expandable>
      <expandable header="Organs">
        <b-form-checkbox-group
          v-model="form.organs"
          :options="organs"
          class="donor__pledge-organs"
        ></b-form-checkbox-group>
      </expandable>
      <expandable header="Donor Contact Details">
        <b-row>
          <b-col cols="12" md="4">
            <input-field
              label="Government Id.No"
              v-model="form.government_id_no"
            ></input-field>
          </b-col>
          <b-col cols="12" md="4">
            <input-field
              label="Email ID"
              rules="required|email"
              :disabled="true"
              v-model="form.email_id"
            ></input-field>
          </b-col>
          <b-col cols="12" md="4">
            <input-field
              label="Mobile No"
              rules="required|phone"
              v-model="form.mobile_number"
            ></input-field>
          </b-col>
          <b-col cols="12" md="4">
            <select-field
              label="District"
              :items="district"
              v-model="form.district"
            ></select-field>
          </b-col>
          <b-col cols="8"> </b-col>
          <b-col cols="12" md="4">
            <input-field
              label="Emergence Contact Name"
              rules="required"
              v-model="form.emergence_contact_name"
            ></input-field>
          </b-col>
          <b-col cols="12" md="4">
            <input-field
              label="Emergence Contact Mobile"
              rules="required|phone"
              v-model="form.emergence_contact_mobile"
            ></input-field>
          </b-col>
          <b-col cols="12">
            <input-field
              label="Emergence Contact Address"
              rules="required"
              v-model="form.emergence_contact_address"
            ></input-field>
          </b-col>
          <b-col cols="12" md="4">
            <input-field
              label="Emergence Contact Name 2"
              rules="required"
              v-model="form.emergence_contact_name_2"
            ></input-field>
          </b-col>
          <b-col cols="12" md="4">
            <input-field
              label="Emergence Contact Mobile 2"
              rules="required|phone"
              v-model="form.emergence_contact_mobile_2"
            ></input-field>
          </b-col>
          <b-col cols="12">
            <input-field
              label="Emergence Contact Address 2"
              rules="required"
              v-model="form.emergence_contact_address_2"
            ></input-field>
          </b-col>
          <b-col cols="12" md="4">
            <file-input
              :disabled="isExists"
              label="Upload Photo"
              v-model="form.photo"
            ></file-input>
          </b-col>
          <b-col cols="12">
            <b-alert variant="success" show>
              I hereby declare that i am willing to donate my organs for social
              benefit as a life saving measure for a patients suffering from
              permanaent organ(s) failure,in the event of an unanticipated Brain
              Stem Death Occurring to me.
            </b-alert>
          </b-col>
          <b-col cols="12" md="4">
            <input-field
              :disabled="true"
              label="Date"
              v-model="form.registered_date"
            ></input-field>
          </b-col>
        </b-row>
      </expandable>
      <div class="d-flex">
        <div class="flex-grow-1"></div>
        <b-btn variant="primary" v-if="isExists" @click="back()"> Back</b-btn>
        <b-btn variant="primary" v-else type="submit"> Submit</b-btn>
      </div>
    </form-wrapper>
  </div>
</template>

<script>
import {
  DONOR_PLEDGE_DISTRICT,
  DONOR_PLEDGE_ORGANS,
} from "../../assets/constants/donor.pledge.constants";
import { GENDERS } from "../../assets/constants/hospital.contants";
import { formatDateOnly } from "../../assets/js/common";
import donorService from "../../assets/services/donor.service";
import Expandable from "../../components/Expandable.vue";
import DatePicker from "../../components/form/DatePicker.vue";
import RadioGroup from "../../components/form/RadioGroup.vue";
export default {
  components: { DatePicker, RadioGroup, Expandable },
  data() {
    return {
      form: {
        registered_date: formatDateOnly(new Date()),
        donor_name: null,
        address: null,
        dob: null,
        gender: 0,
        blood_group: 0,
        organs: [],
        government_id_no: null,
        email_id: null,
        mobile_number: null,
        district: null,
        emergence_contact_name: null,
        emergence_contact_mobile: null,
        emergence_contact_address: null,
        emergence_contact_name_2: null,
        emergence_contact_mobile_2: null,
        emergence_contact_address_2: null,
        photo: null,
      },
      list: [],
      isVerified: false,
      genders: GENDERS,
      email: "",
      organs: DONOR_PLEDGE_ORGANS,
      district: DONOR_PLEDGE_DISTRICT,
    };
  },
  computed: {
    isExists() {
      return this.list.length != 0;
    },
  },
  methods: {
    back() {
      const vm = this;
      vm.form = {
        registered_date: formatDateOnly(new Date()),
        donor_name: null,
        address: null,
        dob: null,
        gender: 0,
        blood_group: 0,
        organs: [],
        government_id_no: null,
        email_id: null,
        mobile_number: null,
        district: null,
        emergence_contact_name: null,
        emergence_contact_mobile: null,
        emergence_contact_address: null,
        emergence_contact_name_2: null,
        emergence_contact_mobile_2: null,
        emergence_contact_address_2: null,
        photo: null,
      };
      vm.isVerified = false;
      vm.form = {};
      vm.email = "";
      vm.list = [];
    },
    uploadPhoto(file) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          var body = new FormData();
          body.append("file", file);
          let { Message } = (await donorService.uploadPledgeDonorImage(body))
            .data;
          resolve(Message);
        } catch (error) {
          reject(error);
        }
      });
    },
    async submit() {
      const vm = this;
      if (vm.form.organs.length == 0)
        return vm.$alert.show("Please select atleast one organ");
      try {
        vm.$loader.show();
        let doc = { ...vm.form };
        doc.dml_indicator = "I";
        doc.organs = doc.organs.toString();
        if (vm.form.photo instanceof File) {
          doc.photo = await vm.uploadPhoto(vm.form.photo);
        }
        let { status, message } = (await donorService.pledgeOrgan(doc)).data;
        vm.$loader.hide();
        if (!status) {
          vm.$alert.show(message);
          return;
        }
        vm.back();
      } catch (error) {
        vm.$alert.show(error);
        vm.$loader.hide();
      }
    },
    async verify() {
      const vm = this;
      try {
        vm.$loader.show();
        let doc = { email_id: vm.form.email_id };
        doc.dml_indicator = "S";
        let { result } = (await donorService.pledgeOrgan(doc)).data;
        vm.isVerified = true;
        vm.list = result;
        if (vm.list.length) {
          let data = vm.list[0];
          data.organs = data.organs || "";
          data.organs = data.organs.split(",").map((item) => {
            return item;
          });
          Object.keys(vm.form).forEach((key) => {
            vm.form[key] = data[key];
          });
          vm.form.registered_date = formatDateOnly(vm.form.registered_date);
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$alert.show(error);
        vm.$loader.hide();
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/design/mixins.scss";
.donor__pledge-organs {
  display: flex;
  @include mobile {
    flex-direction: column;
  }
  .custom-control-label {
    margin-left: 5px;
    margin-right: 15px;
  }
}
</style>
