<template>
  <div>
    <edit-button v-if="isUpdate" @click="dialog = true"></edit-button>
    <add-button v-else @click="dialog = true"></add-button>
    <b-modal
      tabindex="0"
      v-model="dialog"
      :title="title"
      hide-footer
      ignore-enforce-focus-selector=".select2-search__field"
      size="md"
      centered
    >
      <form-wrapper @submit="submit">
        <b-row class="pb-0">
          <b-col cols="6">
            <autocomplete
              label="Hospital"
              :items="hospitals"
              :disabled="isUpdate"
              rules="required"
              @input="getZone()"
              v-model="form.hospital_id"
            ></autocomplete>
          </b-col>
          <!-- <b-col cols="6">
            <input-field
              :disabled="isUpdate"
              label="Initial Rank"
              type="number"
              rules="required"
              v-model="form.initial_rank"
            >
            </input-field>
          </b-col> -->
          <b-col cols="6">
            <input-field
              label="Current Rank"
              type="number"
              rules="required|min-1"
              :disabled="!isUpdate"
              v-model="form.current_rank"
            >
            </input-field>
          </b-col>
          <b-col cols="6">
            <select-field
              v-model="form.zone_id"
              :items="masterZones"
              :disabled="true"
              label="Zone"
            ></select-field>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger mx-3" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" type="submit">Submit </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import { getDocObject } from "../../../assets/js/common";
import hospitalService from "../../../assets/services/hospital.service";
import AddButton from "../../buttons/AddButton.vue";
import EditButton from "../../buttons/EditButton.vue";
import Autocomplete from "../../form/Autocomplete.vue";
export default {
  components: { AddButton, Autocomplete, EditButton },
  props: {
    hospitals: {
      type: Array,
      default: () => [],
    },
    history: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        rota_hospital_order_id: null,
        zone_id: "",
        hospital_id: "",
        initial_rank: "",
        current_rank: "",
      },
      dialog: false,
    };
  },
  computed: {
    title() {
      return this.isUpdate
        ? "Update Hospital Rota Order"
        : "Create Hospital Rota Order";
    },
    list() {
      let zone = this.isUpdate ? this.item.zone_id : this.form.zone_id;
      return [...this.history].filter((item) => {
        return item.zone_id == zone;
      });
    },
    isUpdate() {
      return this.item.rota_hospital_order_id != undefined;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.init();
      }
    },
  },
  methods: {
    getZone() {
      let hospital = this.hospitals.find((item) => {
        return item.value == this.form.hospital_id;
      });
      this.form.zone_id = hospital != null ? hospital.zone_id : "";
      if (!this.isUpdate) {
        this.form.current_rank = this.list.length + 1;
        this.form.initial_rank = this.list.length + 1;
      }
    },
    async submit() {
      const vm = this;
      if (vm.isUpdate) {
        if (vm.list.length < vm.form.current_rank) {
          vm.$alert.show(
            "Current rank must be less than or equal to hospital count"
          );
          return;
        }
        vm.$emit("onUpdate", {
          newIndex: vm.form.current_rank - 1,
          oldIndex: vm.item.sno - 1,
        });
        vm.dialog = false;
        return;
      }
      try {
        let doc = getDocObject(vm.form, {});
        doc.dml_indicator = "I";
        doc.status = 1;
        if (!vm.isUpdate) doc.created_by = vm.created_by;
        vm.$loader.show();
        let { status, msg } = (
          await hospitalService.addRotaOrder({
            rota_hospital_orderlist: [doc],
          })
        ).data;
        vm.$loader.hide();
        if (!status) {
          vm.$alert.show(msg);
        } else {
          vm.dialog = false;
          vm.$emit("fetch");
        }
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    init() {
      const vm = this;
      if (vm.isUpdate) {
        Object.keys(vm.form).forEach((key) => {
          if (vm.item[key] != undefined) vm.form[key] = vm.item[key];
        });
      } else {
        vm.form = {
          rota_hospital_order_id: null,
          zone_id: "",
          hospital_id: "",
          initial_rank: "",
          current_rank: "",
        };
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
