var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{attrs:{"title":_vm.title,"fields":_vm.headers,"isBusy":_vm.isBusy,"items":_vm.items},scopedSlots:_vm._u([{key:"add",fn:function(){return [_vm._t("add",function(){return [_c('terminate-allocation-button',{attrs:{"isAction":_vm.isLast,"item":_vm.item}})]})]},proxy:true},{key:"recipient_registration_id",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('internal-recipient-icon',{attrs:{"item":item,"value":value}})]}},{key:"payment_status",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getPaymentStatus(value))+" ")]}},{key:"BMI",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getBMI(item))+" ")]}},{key:"transtan_status",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getPatientStatus(value))+" ")]}},{key:"CM_insurance",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getCMInsuranceStatus(value))+" ")]}},{key:"cause_of_lung_disease",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getCauseLung(value))+" ")]}},{key:"recepient_organ_allocation_status",fn:function(ref){
var value = ref.value;
return [_c('div',{class:_vm.getBg(_vm.getStatus(value))},[_vm._v(" "+_vm._s(_vm.getStatus(value))+" ")])]}},{key:"logistics_update_status",fn:function(ref){
var value = ref.value;
return [(value == 2)?_c('div',{class:_vm.getBg(_vm.getLogisticsStatus(value))},[_vm._v("Updated")]):_c('div')]}},{key:"organ_Inspection_status",fn:function(ref){
var value = ref.value;
return [_c('div',{class:_vm.getBg(_vm.getStatus(value))},[_vm._v(" "+_vm._s(_vm.getStatus(value))+" ")])]}},{key:"organ_retrieval_status",fn:function(ref){
var value = ref.value;
return [_c('div',{class:_vm.getBg(_vm.getStatus(value))},[_vm._v(" "+_vm._s(_vm.getStatus(value))+" ")])]}},{key:"organ_surgery_status",fn:function(ref){
var value = ref.value;
return [_c('div',{class:_vm.getBg(_vm.getLogisticsStatus(value))},[_vm._v(" "+_vm._s(_vm.getLogisticsStatus(value))+" ")])]}},{key:"covid_result_fileupload",fn:function(data){return [_vm._t("covid_result_fileupload",function(){return [_vm._v(" "+_vm._s(data.value)+" ")]},null,data)]}},{key:"action",fn:function(data){return [_vm._t("action",function(){return [_vm._v(" "+_vm._s(data.value)+" ")]},null,data)]}}],null,true)},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }