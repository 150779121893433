<template>
  <expandable header="Evaluation Specific to Kidney" class="mb-10">
    <b-row>
      <!-- <b-col cols="12" md="4">
        <yes-no
          label="Kidney Failure"
          v-model="form.kidney_failure"
          rules="required"
        ></yes-no>
      </b-col> -->
      <b-col cols="12" md="4">
        <input-group
          label="Urea"
          :disabled="isDisabled"
          @input="onInput"
          v-model="form.kidney_urea"
          rules="required|num-3|dec-2"
          type="text"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> mg/dL</b-button>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          label="Creatinine"
          @input="onInput"
          :disabled="isDisabled"
          v-model="form.se_creatinine"
          rules="required|num-3|dec-2"
          type="text"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> mg/dL</b-button>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          label="Serum Sodium"
          :disabled="isDisabled"
          type="text"
          v-model="form.sodium"
          @input="onInput"
          rules="required|num-3|dec-2"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> mg/dL</b-button>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          :disabled="isDisabled"
          label="Serum Potassium"
          type="text"
          v-model="form.potassium"
          @input="onInput"
          rules="required|num-3|dec-2"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> mg/dL</b-button>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          type="text"
          label="Serum Chloride"
          :disabled="isDisabled"
          v-model="form.chloride"
          @input="onInput"
          rules="required|num-3|dec-2"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> mg/dL</b-button>
          </template>
        </input-group>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          type="text"
          @input="onInput"
          label="Serum Bicarbonate"
          :disabled="isDisabled"
          v-model="form.Bi_carbonate"
          rules="required|num-3|dec-2"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> mg/dL</b-button>
          </template>
        </input-group>
      </b-col>
      <!-- <b-col cols="12" md="4">
        <input-field
          label="Lymphocyte Cross Match"
          v-model="form.lymphocyte_cross_match"
          @input="onInput"
          rules="max-255"
        >
        </input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          label="CMV"
          v-model="form.CMV"
          @input="onInput"
          rules="max-255"
        >
        </input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          label="IGG"
          v-model="form.IGG"
          @input="onInput"
          rules="max-255"
        >
        </input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          label="TB Quantiferon"
          v-model="form.TB_quantiferon"
          @input="onInput"
          rules="max-255"
        >
        </input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          label="C Reative Protein"
          v-model="form.C_reative_protein"
          @input="onInput"
          rules="max-255"
        >
        </input-field>
      </b-col> -->
      <b-col cols="12" md="4">
        <date-picker
          @input="onInput"
          label="First Dialysis Date"
          v-model="form.first_dialysis_date"
          :max="new Date()"
          :disabled="isDisabled"
          rules="required"
        ></date-picker>
      </b-col>
      <b-col cols="12" md="4">
        <input-group
          label="Period Undergoing Dialysis"
          v-model="form.period_undergoing_dialysis"
          type="number"
          :disabled="true"
          @input="onInput"
          rules="required|num-3"
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> Months</b-button>
          </template>
        </input-group>
      </b-col>
    </b-row>
  </expandable>
</template>

<script>
import moment from "moment";
import Expandable from "../../Expandable.vue";
import DatePicker from "../../form/DatePicker.vue";

export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    value: {},
  },

  components: { Expandable, DatePicker },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    onInput() {
      let { first_dialysis_date } = this.form;
      let mon = "";
      if (first_dialysis_date) {
        mon = moment().diff(moment(first_dialysis_date), "month");
      }
      this.form.period_undergoing_dialysis = mon;
      this.$emit("input", this.form);
    },
  },
  beforeMount() {
    if (this.value != undefined) this.form = this.value;
  },
};
</script>

<style></style>
