<template>
  <div class="d-flex" v-if="isCreate">
    <div class="flex-grow-1"></div>
    <search-recipient></search-recipient>
  </div>
</template>

<script>
import menuConstants from "../../assets/constants/menu.constants";
import { encode } from "../../assets/js/common";
import commonMixins from "../../assets/mixins/common.mixins";
import SearchRecipient from "./SearchRecipient.vue";
export default {
  components: { SearchRecipient },
  mixins: [commonMixins],
  computed: {
    isCreate() {
      let hasCreateAccess = this.hasCreateAccess(menuConstants.ACUTE_LIVER_FAILURE);
      return hasCreateAccess;
    },
  },
  methods: {
    open() {
      const vm = this;
      let hospital_id = vm.user.hospital_id;
      let id = encode(hospital_id);
      if (hospital_id) {
        vm.$router.push(`/donor-registration?hospital_id=${id}`);
      } else {
        vm.$alert.show("Please register the hospital fist");
      }
    },
  },
};
</script>

<style></style>
