/* eslint-disable no-async-promise-executor */
import {
  CANCER_SCREENINGS,
  CAUSE_OF_LUNG_DISEASE,
  CM_INSURANCE_STATUS,
  HEPATITIS,
  HISTORY_COMPLICATIONS,
  NATIONALITY,
  PREIODS,
  PVIR_UNIT,
} from "../constants/recipient.constants.js";
import {
  BASE_URL,
  formatDate,
  formatDateOnly,
  HEART_ID,
  KIDNEY_ID,
  LIVER_ID,
  LUNG_ID,
  PANCREAS_ID,
} from "../js/common.js";
import receipientLabel from "../label/recipient.label.js";
class ReceipienHelper {
  async print(vm, way) {
    let html = `<!DOCTYPE html>
        <html lang="en">
          <head>
            <meta charset="UTF-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <link rel="icon" href="/favicon.png" />
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap"
            />
            <title>Recipient Details</title>
            <style>
            .pagebreak { page-break-before: always; } 
            table { page-break-inside:auto }
            tr    { page-break-inside:avoid; page-break-after:auto }
              h3,
              h4 {
                margin-bottom: 10px;
                font-weight:600!important;
              }
              * {
                font-family: "Poppins", sans-serif;
              }
              table {
                margin-bottom: 5px;
                font-family: arial, sans-serif;
                width:100%;
                border-collapse: collapse;
              }
              .four  td, .contact  td {
                    width: 25%;
              }
              .two td {
                    width: 50%;
              }
              td,
              th {
                font-size:12px;
                border: 1px solid #dddddd;
                text-align: left;                
                padding: 4px;
              }
              .four td:nth-child(odd), .two td:nth-child(odd) {
                  font-weight: 500;
              }
              .four td:nth-child(even), .two td:nth-child(even){
                  font-weight: 300;
              }
              .logo {
                display: flex;
                align-items: center;
                justify-content: center;
              } 
              .logo h3{
                margin: 0px;
                margin-left: 10px;
                height:37px;
                
              }
              .logo img{
                width:150px;
                height: 37px;
                object-fit: contain;
              }
                * {
                -webkit-print-color-adjust: exact !important; 
                color-adjust: exact !important;                
              }
          
        
            </style>
          </head>
          <body>
          <div class="logo">
          <img src="/logo.png" alt="">
          <h3>TRANSPLANT AUTHORITY OF TAMIL NADU</h3>
          </div>
    
              html_content
          </body>
        </html>
        `;
    let parameter = {
      HEART_ID,
      KIDNEY_ID,
      LIVER_ID,
      LUNG_ID,
      PANCREAS_ID,
    };
    await this.fetchDetails(vm);
    let content = this.getDetails(vm);
    content += this.getAdditionalAddress(vm);
    content += this.getOrganRequested(vm);
    content += this.getConsultant(vm);
    // content += this.getDeclarationAttachments(vm);
    content += this.getMedicalDetails(vm, way);
    // content += this.getAttachments(vm);
    if (way != "openPatientPrint") {
      if (vm.isParameter(parameter.LUNG_ID)) {
        content += this.getLung(vm);
      }
      if (
        vm.isParameter(parameter.LIVER_ID) ||
        vm.isParameter(parameter.PANCREAS_ID)
      ) {
        content += this.getLiver(vm);
      }
      if (vm.isParameter(parameter.KIDNEY_ID)) {
        content += this.getKidney(vm);
      }
      if (vm.isParameter(parameter.HEART_ID)) {
        content += this.getHeart(vm);
      }
    }

    content += this.getContact(vm);
    content += this.getVaccination(vm);
    html = html.split("html_content").join(content);
    if (way == "download" || way == "openDownload") {
      let html2pdf = require("html2pdf.js");
      let element = document.createElement("div");
      element.style.padding = "40px";
      element.innerHTML = html;
      html2pdf()
        .set({
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          filename: `Recipient_${formatDate(new Date())}.pdf`,
        })
        .from(element)
        .save()
        .then(() => {
          setTimeout(() => {
            if (way == "openDownload") {
              window.open("", "_self");
              window.close();
            }
          }, 500);
        });
    } else {
      let open = "_blank";
      if (way == "print" || way == "preview") open = "_self";
      let win = window.open("about:blank", open);
      win.document.write(html);
      win.document.close();
      setTimeout(() => {
        if (way != "preview") {
          win.print();
        }
      }, 500);
    }
  }
  fetchDetails(vm) {
    return new Promise(async (resolve) => {
      try {
        vm.$loader.show();
        if (vm.bloodGroups.length == 0) {
          await vm.$store.dispatch("fetchMaster");
        }
        let consultant = vm.$refs.consultant;
        if (!consultant.consultants.length) {
          await consultant.init();
        }
        if (vm.masterVaccinations.length == 0) {
          await vm.$store.dispatch("fetchVaccinations");
        }
        resolve();
        vm.$loader.hide();
      } catch (error) {
        resolve();
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    });
  }
  getDetails(vm) {
    let { details } = vm;
    let label = receipientLabel.details;
    let doc = {};
    Object.keys(label).forEach((key) => {
      doc[label[key]] = details[key];
    });
    let { UHID, hospital_name } = vm.updateDoc.recipient_list[0];
    if (vm.isUpdate) {
      doc[label.uid] = UHID;
      doc[label.created_on] = formatDate(vm.created_on);
      let status = vm.details.transtan_status;
      if (status == 1 || status == 8) delete doc[label.uid];
    } else {
      delete doc[label.uid];
      delete doc[label.created_on];
    }
    doc[label.hospital_name] = hospital_name;
    doc[label.CM_insurance_notes] = this.getFile(doc[label.CM_insurance_notes]);
    doc[label.embassy_documents] = this.getFile(doc[label.embassy_documents]);
    doc[label.passport_document] = this.getFile(doc[label.passport_document]);
    doc[label.DOB] = formatDateOnly(doc[label.DOB]);
    doc[label.patient_nationlity] = this.getOptionValue(
      NATIONALITY,
      doc[label.patient_nationlity]
    );
    doc[label.CM_insurance] = this.getOptionValue(
      CM_INSURANCE_STATUS,
      doc[label.CM_insurance]
    );
    doc[label.blood_group] = this.getOptionValue(
      vm.bloodGroups,
      doc[label.blood_group]
    );
    doc[label.gender] = vm.getGender(doc[label.gender]);
    if (details.patient_nationlity != 1) {
      delete doc[label.embassy_documents];
      delete doc[label.passport_document];
      delete doc[label.passport];
    } else {
      delete doc[label.CM_insurance_notes];
      delete doc[label.aadhar_number];
      delete doc[label.CM_insurance];
    }
    if (details.CM_insurance != 1 && details.CM_insurance != 3) {
      delete doc[label.CM_insurance_notes];
    }
    let html = this.getHeader(receipientLabel.headers.details);
    html += this.getTable(doc);
    return html;
  }
  getKidney(vm) {
    let { kidney } = vm;
    let label = receipientLabel.kidney;
    let units = receipientLabel.units;
    let doc = {};
    Object.keys(label).forEach((key) => {
      doc[label[key]] = kidney[key];
      if (units[key]) doc[label[key]] += " " + units[key];
    });
    doc[label.first_dialysis_date] = formatDateOnly(kidney.first_dialysis_date);
    let html = this.getHeader(receipientLabel.headers.kidney);
    html += this.getTable(doc);
    return html;
  }
  getHeart(vm) {
    let { heart } = vm;
    let label = receipientLabel.heart;
    let units = receipientLabel.units;
    let doc = {};
    Object.keys(label).forEach((key) => {
      doc[label[key]] = heart[key];
      if (units[key]) doc[label[key]] += " " + units[key];
    });
    doc[label.PVRI] =
      doc[label.PVRI] + " " + this.getOptionValue(PVIR_UNIT, heart.PVRI_unit);
    if (heart.heart_non_transplant_heart != 1)
      delete doc[label.heart_lung_surgery];
    if (heart.walk_test_duration == 1) delete doc[label.walk_test_distance];
    let html = this.getHeader(receipientLabel.headers.heart);
    html += this.getTable(doc);
    return html;
  }
  getMedicalDetails(vm, way) {
    let { recipient_medical_details, virology_status } = vm;
    let label = receipientLabel.general_details;
    let doc = {};
    Object.keys(label).forEach((key) => {
      doc[label[key]] = recipient_medical_details[key];
    });
    doc[label.height] = doc[label.height] + " CM";
    doc[label.weight] = doc[label.weight] + " kg";
    doc[label.BMI] = doc[label.BMI] + " Kg/m2";
    let html = this.getHeader(receipientLabel.headers.medical_details);
    html += this.getSubHeader(receipientLabel.headers.general_details);
    html += this.getTable(doc);
    //history
    label = receipientLabel.history;
    doc = {};
    Object.keys(label).forEach((key) => {
      doc[label[key]] = this.getYesNo(recipient_medical_details[key]);
    });
    doc[label.covid_free_period] = this.getOptionValue(
      PREIODS,
      recipient_medical_details.covid_free_period
    );
    doc[label.hepatitis_B] = this.getOptionValue(
      HEPATITIS,
      recipient_medical_details.hepatitis_B
    );
    doc[label.hepatitis_C] = this.getOptionValue(
      HEPATITIS,
      recipient_medical_details.hepatitis_C
    );
    doc[label.organ_transplanted] =
      recipient_medical_details.organ_transplanted;
    if (doc[label.history_of_previous_transplants] != "Yes") {
      delete doc[label.organ_transplanted];
    }
    if (doc[label.alcohol] != "Yes") {
      delete doc[label.period_of_abstinence];
    }
    if (doc[label.history_of_covid] != "Yes") {
      delete doc[label.covid_free_period];
    }
    if (way != "openPatientPrint") {
      html += this.getSubHeader(receipientLabel.headers.history);
      html += this.getTable(doc);
    }

    // Malignancy Status
    label = receipientLabel.malignancy_status;
    doc = {};
    Object.keys(label).forEach((key) => {
      doc[label[key]] = this.getYesNo(recipient_medical_details[key]);
    });
    doc[label.duration_of_remission] = this.getOptionValue(
      PREIODS,
      recipient_medical_details.duration_of_remission
    );
    doc[label.type_of_malignancy] =
      recipient_medical_details.type_of_malignancy;
    if (doc[label.history_of_malignancy] != "Yes") {
      delete doc[label.type_of_malignancy];
      delete doc[label.duration_of_remission];
    }
    if (way != "openPatientPrint") {
      html += this.getSubHeader(receipientLabel.headers.malignancy_status);
      html += this.getTable(doc);
    }

    // Virology Status
    label = receipientLabel.virology_status;
    doc = {};
    Object.keys(label).forEach((key) => {
      doc[label[key]] = this.getPosNeg(virology_status[key]);
    });
    html += this.getSubHeader(receipientLabel.headers.virology_status);
    html += this.getTable(doc);
    return html;
  }
  getLung(vm) {
    let { lung } = vm;
    let label = receipientLabel.lung;
    let doc = {};
    Object.keys(label).forEach((key) => {
      doc[label[key]] = this.getYesNo(lung[key]);
    });
    doc[label.cause_of_lung_disease_notes] = lung.cause_of_lung_disease_notes;
    doc[label.cause_of_lung_disease] = this.getOptionValue(
      CAUSE_OF_LUNG_DISEASE,
      lung.cause_of_lung_disease
    );
    doc[label.lung_walk_test_distance] =
      lung.lung_walk_test_distance + " Meters";
    doc[label.lung_surgery] = lung.lung_surgery;
    if (lung.cause_of_lung_disease != 5)
      delete doc[label.cause_of_lung_disease_notes];
    if (lung.non_transplant_heart != 1) delete doc[label.lung_surgery];
    if (lung.lung_walk_test_duration != 1)
      delete doc[label.lung_walk_test_distance];
    let html = this.getHeader(receipientLabel.headers.lung);
    html += this.getTable(doc);
    // PFT
    label = receipientLabel.lung_PFT;
    doc = {};
    Object.keys(label).forEach((key) => {
      doc[label[key]] = this.getFile(lung[key]);
    });
    html += this.getSubHeader(receipientLabel.headers.lung_PFT);
    html += this.getTable(doc);
    // Ventilatory Status
    label = receipientLabel.lung_ventilary_status;
    doc = {};
    Object.keys(label).forEach((key) => {
      doc[label[key]] = this.getYesNo(lung[key]);
    });
    html += this.getSubHeader(receipientLabel.headers.lung_ventilary_status);
    html += this.getTable(doc);
    //Arterial Blood Gas
    label = receipientLabel.lung_arterial_blood_gas;
    doc = {};
    Object.keys(label).forEach((key) => {
      doc[label[key]] = lung[key];
    });
    doc[label.room_air_file_name] = this.getFile(lung.room_air_file_name);
    doc[label.on_oxygen_file_name] = this.getFile(lung.on_oxygen_file_name);
    html += this.getSubHeader(receipientLabel.headers.lung_arterial_blood_gas);
    html += this.getTable(doc);
    return html;
  }
  getLiver(vm) {
    let { liver } = vm;
    let label = receipientLabel.liver;
    let units = receipientLabel.units;
    let doc = {};

    Object.keys(label).forEach((key) => {
      doc[label[key]] = liver[key];
    });
    doc[label.cancer_screening] = this.getOptionValue(
      CANCER_SCREENINGS,
      liver.cancer_screening
    );
    if (doc[label.history_of_complications] instanceof Array) {
      doc[label.history_of_complications] = doc[label.history_of_complications]
        .map((id) => {
          return this.getOptionValue(HISTORY_COMPLICATIONS, id);
        })
        .toString();
    }
    let html = this.getHeader(receipientLabel.headers.liver);
    html += this.getTable(doc);
    // Liver Function
    label = receipientLabel.liver_function;
    doc = {};
    Object.keys(label).forEach((key) => {
      doc[label[key]] = liver[key] + " " + units[key];
    });
    html += this.getSubHeader(receipientLabel.headers.liver_function);
    html += this.getTable(doc);
    // Cardiac Evaluation
    label = receipientLabel.liver_cardiac_evaluation;
    doc = {};
    Object.keys(label).forEach((key) => {
      doc[label[key]] = this.getFile(liver[key]);
    });
    html += this.getSubHeader(receipientLabel.headers.liver_cardiac_evaluation);
    html += this.getTable(doc);
    // Pulmonary Evaluation
    label = receipientLabel.liver_pulmonary_evaluation;
    doc = {};
    Object.keys(label).forEach((key) => {
      doc[label[key]] = this.getFile(liver[key]);
    });
    html += this.getSubHeader(
      receipientLabel.headers.liver_pulmonary_evaluation
    );
    html += this.getTable(doc);
    // Renal Evaluation
    label = receipientLabel.liver_renal_evaluation;
    doc = {};
    Object.keys(label).forEach((key) => {
      doc[label[key]] = liver[key] + " mg/dl";
    });
    html += this.getSubHeader(receipientLabel.headers.liver_renal_evaluation);
    html += this.getTable(doc);
    // Coagulation Profile
    label = receipientLabel.liver_coagulation_profile;
    doc = {};
    Object.keys(label).forEach((key) => {
      doc[label[key]] = liver[key];
    });
    html += this.getSubHeader(
      receipientLabel.headers.liver_coagulation_profile
    );
    html += this.getTable(doc);
    return html;
  }
  getAdditionalAddress(vm) {
    let { address } = vm;
    let label = receipientLabel.address;
    let doc = {};
    Object.keys(label).forEach((key) => {
      doc[label[key]] = address[key];
    });
    let ref = vm.$refs.additionAddress;
    if (ref) {
      doc[label.city] = this.getOptionValue(ref.citys, doc[label.city]);
      doc[label.country] = this.getOptionValue(
        ref.countries,
        doc[label.country]
      );
      doc[label.state] = this.getOptionValue(ref.states, doc[label.state]);
    }

    let html = this.getHeader(receipientLabel.headers.address);
    html += this.getTable(doc);
    return html;
  }
  getOrganRequested(vm) {
    let { organ_requested } = vm;
    let label = receipientLabel.organ_requested;
    let doc = {};
    Object.keys(label).forEach((key) => {
      doc[label[key]] = organ_requested[key];
    });
    if (doc[label.organ_id] instanceof Array) {
      doc[label.organ_id] = doc[label.organ_id]
        .map((id) => {
          return this.getOptionValue(vm.masterOrgans, id);
        })
        .toString();
    }
    let html = this.getHeader(receipientLabel.headers.organ_requested);
    html += this.getTableSingleRow(doc);
    return html;
  }
  getConsultant(vm) {
    let { consultant } = vm;
    let label = receipientLabel.consultant;
    let doc = {};
    Object.keys(label).forEach((key) => {
      doc[label[key]] = consultant[key];
    });
    let ref = vm.$refs.consultant;
    if (ref) {
      doc[label.consultant_detail] = this.getOptionValue(
        ref.consultants,
        doc[label.consultant_detail]
      );
    }
    let html = this.getHeader(receipientLabel.headers.consultant);
    html += this.getTableSingleRow(doc);
    return html;
  }
  getDeclarationAttachments(vm) {
    let doc = {};
    let ref = vm.$refs.declarationAttachments;
    if (ref) {
      ref.files.forEach((item) => {
        doc[item.name] = this.getFile(item.url);
      });
    }
    let html = this.getHeader(receipientLabel.headers.declaration_attachments);
    html += this.getFileTable(doc);
    return html;
  }
  getAttachments(vm) {
    let doc = {};
    let ref = vm.$refs.attachments;
    if (ref) {
      ref.files.forEach((item) => {
        doc[item.name] = this.getFile(item.url);
      });
    }
    let html = this.getHeader(receipientLabel.headers.attachments);
    html += this.getTable(doc);
    return html;
  }
  getContact(vm) {
    let tbody =
      "<tr><th>Name</th><th>Phone</th><th>Contact Type</th><th>Email</th></tr>";
    if (vm.$refs.contacts) {
      vm.$refs.contacts.list.forEach((item) => {
        let { phone_number_1, email_id } = item;
        let contact = vm.$refs.contacts.getContactType(item);
        let name = `${item.first_name} ${item.last_name}`;
        tbody += `<td>${name}</td><td>${phone_number_1}</td><td>${contact}</td><td>${email_id}</td>`;
      });
    }
    let html = this.getHeader(receipientLabel.headers.contact);

    html += `<table  class="contact"><tbody>${tbody}</tbody></table>`;
    return html;
  }
  getVaccination(vm) {
    let tbody = "<tr><th>Vaccination Name</th><th>Vaccination Date</th></tr>";
    if (vm.$refs.vaccination) {
      vm.$refs.vaccination.list.forEach((item) => {
        let name = vm.$refs.vaccination.getVaccinationName(item);
        let date = formatDateOnly(item.vaccination_date);
        tbody += `<td>${name}</td><td>${date}</td></td>`;
      });
    }
    let html = this.getHeader(receipientLabel.headers.vaccination_status);
    html += `<table  class="two"><tbody>${tbody}</tbody></table>`;
    return html;
  }
  getPageBreak() {
    return "<div class='pagebreak'></div>";
  }
  getYesNo(val) {
    let list = [
      { text: "Yes", value: "1" },
      { text: "No", value: "0" },
    ];
    let item = list.find((item) => {
      return item.value == val;
    });
    return item != null ? item.text : "";
  }
  getPosNeg(val) {
    let list = [
      { text: "Positive", value: "1" },
      { text: "Negative", value: "0" },
    ];
    let item = list.find((item) => {
      return item.value == val;
    });
    return item != null ? item.text : "";
  }
  getOptionValue(options, val) {
    let item = options.find((item) => {
      return item.value == val;
    });
    return item != null ? item.name : "";
  }
  getTable(doc) {
    let tbody = "";
    let tr = "";
    Object.keys(doc).forEach((key) => {
      let val = doc[key];
      if (tr == "") {
        tr = `<tr><td>${key}</td><td>${val}</td>`;
      } else {
        tr += `<td>${key}</td><td>${val}</td></tr>`;
        tbody += tr;
        tr = "";
      }
    });
    if (tr != "") {
      tbody += tr + "<td></td><td></td></tr>";
    }
    return `<table  class="four"><tbody>${tbody}</tbody></table>`;
  }

  getTableSingleRow(doc) {
    let tbody = "";
    Object.keys(doc).forEach((key) => {
      let val = doc[key];
      tbody += `<tr><td>${key}</td><td>${val}</td></tr>`;
    });
    return `<table class="two"><tbody>${tbody}</tbody></table>`;
  }
  getFileTable(doc) {
    let tbody = "<tr><th>Name</th><th>File</th></tr>";
    Object.keys(doc).forEach((key) => {
      let val = doc[key];
      tbody += `<tr><td>${key}</td><td>${val}</td></tr>`;
    });
    return `<table class="two"><tbody>${tbody}</tbody></table>`;
  }
  getFile(val) {
    let url = BASE_URL + val;
    return val
      ? `<a href="${url}" target="_blank">View File</a>`
      : "Not Available";
  }
  getHeader(title) {
    return `<h3>${title}</h3>`;
  }
  getSubHeader(title) {
    return `<h4>${title}</h4>`;
  }
}
export default new ReceipienHelper();
