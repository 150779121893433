<template>
  <expandable header="Medications - Inotropes" class="mb-10">
    <table class="table align-middle table-nowrap">
      <thead class="table-light">
        <tr>
          <th class="align-middle">Inotrope</th>
          <th class="align-middle">Dosage</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="i in 4" :key="i">
          <td>Inotrope {{ i }}</td>
          <td>
            <input-field
              v-model="form[`inotrope_${i}`]"
              @input="onInput"
              :disabled="isDisabled"
            ></input-field>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="h5 py-2 px-2 section_subheader mt-3 mb-3">
      Hormone Replacement Therapy
    </div>
    <b-row>
      <b-col cols="4">
        <yes-no
          v-model="form.steroids"
          label="Steroids"
          class="ms-1"
          @input="onInput"
          :disabled="isDisabled"
        ></yes-no>
      </b-col>
      <b-col cols="4">
        <yes-no
          v-model="form.vasopressin"
          label="Vasopressin"
          @input="onInput"
          :disabled="isDisabled"
        ></yes-no>
      </b-col>
      <b-col cols="4">
        <yes-no
          v-model="form.thyroxine"
          label="Thyroxine"
          :disabled="isDisabled"
          @input="onInput"
        ></yes-no>
      </b-col>
    </b-row>
  </expandable>
</template>

<script>
import Expandable from "../../Expandable.vue";
import YesNo from "../../form/YesNo.vue";
export default {
  components: { YesNo, Expandable },
  props: {
    value: {},
    isDisabled: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    onInput() {
      this.$emit("input", this.form);
    },
  },
  beforeMount() {
    this.form = this.value;
  },
};
</script>

<style></style>
