import Confirm from "./Confirm";

const ConfirmModal = {
  install(Vue) {
    this.EventBus = new Vue();
    Vue.component("Confirm", Confirm);
    Vue.prototype.$confirm = {
      show(params) {
        ConfirmModal.EventBus.$emit("show", params);
      }
    };
  }
};

export default ConfirmModal;
