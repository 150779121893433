<template>
  <div>
    <add-button
      @click="dialog = true"
      v-if="isAdmin && isUserPage"
    ></add-button>
    <add-button
      v-else-if="isHospitalAdmin || isAdmin"
      @click="submit()"
    ></add-button>
    <b-modal
      tabindex="0"
      v-model="dialog"
      title="Select Hospital"
      hide-footer
      ignore-enforce-focus-selector=".select2-search__field"
      size="sm"
      centered
    >
      <form-wrapper @submit="submit">
        <b-row class="pb-0">
          <b-col cols="12">
            <autocomplete
              v-model="hospital_id"
              label="Hospital"
              :items="hospitals"
              :multiple="false"
              :max="1"
              rules="required"
            >
            </autocomplete>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger mx-3" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" type="submit">Submit </b-button>
        </div>
      </form-wrapper>
    </b-modal>
    <create-user
      ref="user"
      :hospital_id="hospital_id"
    ></create-user>
  </div>
</template>

<script>
import CreateUser from "./CreateUser.vue";
import AddButton from "../buttons/AddButton.vue";
import Autocomplete from "../form/Autocomplete.vue";
import userMixins from "../../assets/mixins/user.mixins";
export default {
  props: {
    hospitals: Array,
  },
  mixins: [userMixins],
  components: { AddButton, Autocomplete, CreateUser },
  data() {
    return {
      dialog: false,
      hospital_id: "",
    };
  },
  methods: {
    async submit() {
      const vm = this;
      vm.dialog = false;
      vm.$refs.user.dialog = true;
    },
    init() {
      const vm = this;
      let { isAdmin, userHospital } = vm;
      if (!isAdmin) vm.hospital_id = userHospital;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
