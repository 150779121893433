<template>
  <collapse-filter :class="isMobile ? 'mt-4' : ''">
    <b-card class="mt-4">
      <div class="h4">Filter</div>
      <form-wrapper @submit="submit">
        <b-row>
          <b-col cols="12" md="4" v-if="!isHospitalContact">
            <autocomplete
              label="Hospital"
              v-model="form.hospital_id"
              :items="hospitals"
              rules="required"
            ></autocomplete>
          </b-col>
          <b-col cols="12" md="4">
            <autocomplete
              label="Blood Group"
              :items="filterBloodGroups"
              rules="required"
              v-model="blood_group"
            ></autocomplete>
          </b-col>
          <b-col cols="12">
            <autocomplete
              label="Organs"
              :items="organList"
              rules="required"
              v-model="organs"
              :multiple="true"
            ></autocomplete>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mb-m-3">
            <in-house-waiting-list-approvel
              :hospitals="hospitals"
            ></in-house-waiting-list-approvel>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <b-btn
              class="width-100 me-3"
              @click="reset(false)"
              variant="outline-secondary"
              >RESET</b-btn
            >
            <b-btn class="width-100" type="submit" variant="primary"
              >SUBMIT</b-btn
            >
          </b-col>
        </b-row>
      </form-wrapper>
    </b-card>
  </collapse-filter>
</template>

<script>
import { INHOUSE_RANK } from "../../../assets/constants/recipient.constants";
import { BLOOD_GROUP_VALUES } from "../../../assets/js/common";
import hospitalService from "../../../assets/services/hospital.service";
import licenseService from "../../../assets/services/license.service";
import CollapseFilter from "../../ui/CollapseFilter.vue";
import InHouseWaitingListApprovel from "./InHouseWaitingListApprovel.vue";
export default {
  components: { InHouseWaitingListApprovel, CollapseFilter },
  props: {
    value: {},
  },
  data() {
    return {
      form: {},
      hospitals: [],
      blood_group: "",
      organList: [],
      ranks: INHOUSE_RANK,
      organs: [],
    };
  },
  methods: {
    submit() {
      const vm = this;
      const doc = {
        dml_indicator: "S",
      };
      Object.keys(vm.form).forEach((key) => {
        let value = vm.form[key];
        if (value == "all") value = "";
        if (value) {
          doc[key] = value;
        } else {
          doc[key] = "";
        }
      });
      doc.blood_group = [];
      BLOOD_GROUP_VALUES[vm.blood_group].forEach((blood_group) => {
        doc.blood_group.push({ blood_group });
      });

      doc.multi_organ = vm.organs
        .sort(function (a, b) {
          return a - b;
        })
        .toString();
      vm.$emit("input", doc);
      vm.$emit("fetch");
    },
    reset(isSubmit) {
      this.form = {
        hospital_id: "",
        inhouse_rank: "all",
      };
      if (this.isHospitalContact) {
        this.form.hospital_id = this.user.hospital_id;
      }
      this.blood_group = "";
      this.organs = [];
      if (isSubmit) {
        this.submit();
      }
    },
    async fetchOrgans() {
      const vm = this;
      try {
        let { hospital_id } = vm.user;
        let doc = { hospital_id, dml_indicator: "UL" };
        vm.hospital_id = hospital_id;
        let { data } = await licenseService.list(doc);
        let { status, list } = data;
        if (status) {
          list = list.filter((item) => {
            return item.status != 4;
          });
          vm.organList = list.map((item) => {
            return { value: item.organ_id, name: item.organ_name };
          });
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
        return;
      }
    },
    async init() {
      const vm = this;
      try {
        vm.$loader.show();
        vm.hospitals = [];
        let { user_type, hospital_id } = vm.$store.getters.user;
        let doc = { user_type };
        if (user_type == 1) {
          doc.hospital_id = hospital_id;
          vm.form.hospital_id = hospital_id;
        }
        let { data } = await hospitalService.list(doc);
        let { status, msg, list } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        if (vm.isAdmins) {
          vm.organList = vm.filterMasterOrgans;
          vm.$loader.hide();
        } else {
          vm.fetchOrgans();
        }
        let hospitals = list.map((item) => {
          item.name = item.hospital_name;
          item.value = item.hospital_id;
          return item;
        });
        vm.$emit("hospitals", hospitals);
        // hospitals.unshift({
        //   name: "All Hospital",
        //   value: "0",
        // });
        vm.hospitals = hospitals;
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.init();
    this.reset();
  },
};
</script>

<style></style>
