<template>
  <div>
    <edit-button v-if="isUpdate" @click="dialog = true"></edit-button>
    <add-button v-else @click="dialog = true"></add-button>
    <b-modal
      tabindex="0"
      v-model="dialog"
      :title="title"
      hide-footer
      ignore-enforce-focus-selector=".select2-search__field"
      size="md"
      centered
    >
      <form-wrapper @submit="submit">
        <b-row class="pb-0">
          <b-col cols="12">
            <autocomplete
              label="Organs"
              :multiple="true"
              :items="organs"
              rules="required"
              v-model="organ"
            ></autocomplete>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger mx-3" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" type="submit">Submit </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import masterService from "../../../assets/services/master.service";
import AddButton from "../../buttons/AddButton.vue";
import EditButton from "../../buttons/EditButton.vue";
import Autocomplete from "../../form/Autocomplete.vue";
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  components: { AddButton, EditButton, Autocomplete },
  data() {
    return {
      dialog: false,
      organ: [],
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.init();
      }
    },
  },
  computed: {
    organs() {
      return [...this.masterOrgans].filter((item) => {
        return item.multi_organ_flag == 0 && item.tissue_bank == 0;
      });
    },
    isUpdate() {
      return this.item != undefined;
    },
    title() {
      return this.isUpdate ? "Update Multi Organ" : "Add Multi Organ";
    },
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        let organs = vm.organ.sort((a, b) => a - b);
        let name = [];
        console.log("length", vm.organ.length);
        if (vm.organ.length == 1) {
          vm.$alert.show("Please select organs more then one");
          return;
        }
        vm.$loader.show();
        organs.forEach((id) => {
          let organ = this.organs.find((item) => {
            return item.organ_id == id;
          });
          if (organ != null) name.push(organ.name);
        });
        vm.form.allocated_organ = organs.toString();
        vm.form.multi_organ_flag = 1;
        vm.form.organ_name = name.toString();
        let { data } = await masterService.CUDOrgan(vm.form);
        let { status, msg } = data;
        vm.$emit("init");
        vm.init();
        if (status) {
          vm.dialog = false;
        } else {
          vm.$alert.show(msg);
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    init() {
      const vm = this;
      vm.form = {
        organ_name: "",
        allocation_available: "",
        license_available: "",
        license_cost: "",
        status: 1,
        created_by: vm.created_by,
        organ_id: 0,
        dml_indicator: "I",
      };
      if (vm.isUpdate) {
        delete vm.form.created_by;
        vm.form.dml_indicator = "U";
        Object.keys(vm.form).forEach((key) => {
          if (vm.item[key] != undefined) {
            vm.form[key] = vm.item[key];
          }
        });
        if (vm.item.allocated_organ) {
          vm.organ = vm.item.allocated_organ.split(",");
        }
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
