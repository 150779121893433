<template>
  <Expandable header="Surgery Details" class="mb-10">
    <b-row>
      <b-col cols="12" md="4">
        <radio-group
          :options="organs"
          label="Organ Name"
          v-model="form.organ_id"
          :disabled="isDisabled"
          rules="required"
          @input="onInput"
        ></radio-group>
      </b-col>
      <b-col cols="12" md="4">
        <date-picker
          label="Date of Surgery"
          rules="required"
          v-model="form.date_of_surgery"
          :disabled="isDisabled"
          @input="onInput"
        ></date-picker>
      </b-col>
      <template v-if="false">
        <b-col cols="12" md="4">
          <time-picker
            v-model="form.time_of_surgery"
            label="Time"
            :disabled="isDisabled"
            @input="onInput"
            rules="required"
          >
          </time-picker>
        </b-col>
        <b-col cols="12" md="4">
          <input-field
            v-model="form.organ_transplanted"
            label="Organ Transplant status"
            :disabled="isDisabled"
            @input="onInput"
            rules="required"
          >
          </input-field>
        </b-col>
        <b-col cols="12" md="4">
          <input-field
            v-model="form.ischaemia_warm"
            label="Ischaemia Warm"
            @input="onInput"
            :disabled="isDisabled"
            rules="required"
          >
          </input-field>
        </b-col>
        <b-col cols="12" md="4">
          <input-field
            v-model="form.ischaemia_cold"
            label="Ischaemia Cold"
            @input="onInput"
            :disabled="isDisabled"
            rules="required"
          >
          </input-field>
        </b-col>
        <b-col cols="12" md="4">
          <date-picker
            v-model="form.end_date"
            label="Surgery End Date"
            @input="onInput"
            :disabled="isDisabled"
            rules="required"
          >
          </date-picker>
        </b-col>
        <b-col cols="12" md="4">
          <time-picker
            v-model="form.end_time"
            :disabled="isDisabled"
            label="Surgery End time"
            @input="onInput"
            rules="required"
          >
          </time-picker>
        </b-col>
      </template>
      <b-col cols="12" md="4">
        <input-field
          v-model="form.status_after_surgery"
          label="Duration of Surgery"
          :disabled="isDisabled"
          @input="onInput"
          rules="required"
        >
        </input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          v-model="form.remarks"
          label="Remarks if any"
          @input="onInput"
          :disabled="isDisabled"
          rules="max-255"
        >
        </input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          v-model="form.ischaemia_warm"
          label="Ischaemia Warm"
          @input="onInput"
          :disabled="isDisabled"
          rules="required"
        >
        </input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          v-model="form.ischaemia_cold"
          label="Ischaemia Cold"
          @input="onInput"
          :disabled="isDisabled"
          rules="required"
        >
        </input-field>
      </b-col>
      <b-col cols="12" md="4"> </b-col>
      <b-col cols="12" md="4">
        <date-picker
          v-model="form.end_date"
          label="Surgery End Date"
          @input="onInput"
          :rules="dateRules"
          :disabled="isDisabled"
        >
        </date-picker>
      </b-col>
      <b-col cols="12" md="4">
        <time-picker
          v-model="form.end_time"
          :disabled="isDisabled"
          label="Surgery End time"
          @input="onInput"
          :rules="timeRules"
        >
        </time-picker>
      </b-col>
    </b-row>
  </Expandable>
</template>

<script>
import Expandable from "../../../Expandable.vue";
import InputField from "../../../form/InputField.vue";
import RadioGroup from "../../../form/RadioGroup.vue";
import DatePicker from "../../../form/DatePicker.vue";
import TimePicker from "../../../form/TimePicker.vue";
import { KIDNEY_ID, LIVER_ID } from "../../../../assets/js/common";
import moment from "moment";
export default {
  props: {
    value: {},
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { Expandable, InputField, RadioGroup, DatePicker, TimePicker },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    organs() {
      return [
        {
          name: "Liver",
          value: LIVER_ID,
        },
        {
          name: "Kidney",
          value: KIDNEY_ID,
        },
      ];
    },
    dateRules() {
      let { date_of_surgery, end_date } = this.form;
      if (date_of_surgery && end_date) {
        if (moment(date_of_surgery).isAfter(end_date))
          return "required|surgery-end-date";
      }
      return "required";
    },
    timeRules() {
      let { date_of_surgery, end_date, end_time, time_of_surgery } = this.form;
      if (date_of_surgery && end_date && end_time && time_of_surgery) {
        let start = date_of_surgery + time_of_surgery;
        let end = end_date + end_time;
        console.log(start);
        console.log("end", end);
        let df = "YYYY-MM-DDHH:mm";
        let isAfter = moment(start, df).isAfter(moment(end, df));
        if (isAfter) return "required|surgery-end-time";
      }
      return "required";
    },
  },
  methods: {
    onInput() {
      this.$emit("input", this.form);
    },
  },
  beforeMount() {
    this.form = this.value;
  },
};
</script>

<style></style>
