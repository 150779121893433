<template>
  <div>
    <b-dropdown-item @click="dialog = true">{{ title }}</b-dropdown-item>
    <b-modal
      tabindex="0"
      v-model="dialog"
      :title="title"
      hide-footer
      ignore-enforce-focus-selector=".select2-search__field"
      size="sm"
      centered
    >
      <form-wrapper @submit="submit">
        <b-row class="pb-0">
          <b-col cols="12">
            <div class="text-muted">UID</div>
            <div class="h6">{{ item.donor_temp_registration_id }}</div>
          </b-col>
          <b-col cols="12" class="mt-2">
            <div class="text-muted">Donor Name</div>
            <div class="h6">{{ item.donor_name }}</div>
          </b-col>
          <b-col cols="12" class="mt-2">
            <text-area
              label="Reason"
              rules="required"
              v-model="reason"
            ></text-area>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger mx-3" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" type="submit">Submit </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import donorService from "../../assets/services/donor.service";
import TextArea from "../form/TextArea.vue";
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    status: {},
    title: {},
  },
  components: { TextArea },
  data() {
    return {
      dialog: false,
      reason: "",
    };
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.$loader.show();
        let { donor_temp_registration_id } = vm.item;
        let doc = {
          donor_temp_registration_id,
          requesting_apnea_moredetails: vm.reason,
          status: vm.status,
          dml_indicator: "RAD",
        };
        let { status, msg } = (await donorService.updateStatus(doc)).data;
        vm.$emit("fetch");
        if (status) {
          vm.dialog = false;
        } else {
          vm.$alert.show(msg);
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
