<template>
  <Expandable header="Hospital Address" class="mb-10">
    <b-row class="pb-0">
      <b-col cols="12" md="4">
        <input-field
          v-model.trim="form.address_line_1"
          label="Address Line 1"
          @input="input"
          :disabled="isDisabled"
          rules="required|max-100"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          v-model.trim="form.address_line_2"
          label="Address Line 2"
          :disabled="isDisabled"
          rules="max-100"
          @input="input"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          v-model.trim="form.town_village"
          label="Town Village"
          @input="input"
          :disabled="isDisabled"
          rules="required|max-100"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          v-model.trim="form.landmark"
          label="Landmark"
          @input="input"
          rules="max-100"
          :disabled="isDisabled"
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <autocomplete
          v-model.trim="form.country"
          @input="onCountryChange"
          label="Country"
          :items="countries"
          :disabled="isDisabled"
          rules="required"
        ></autocomplete>
      </b-col>
      <b-col cols="12" md="4">
        <autocomplete
          v-model.trim="form.state"
          label="State"
          @input="onStateChange"
          :disabled="isDisabled"
          :items="states"
          rules="required"
        ></autocomplete>
      </b-col>
      <b-col cols="12" md="4">
        <autocomplete
          v-model.trim="form.city"
          :disabled="isDisabled"
          :items="citys"
          @input="input"
          label="City"
          rules="required"
        ></autocomplete>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          @input="input"
          v-model.trim="form.pincode"
          :disabled="isDisabled"
          label="Pincode"
          type="number"
          rules="required|zipcode|integer"
        ></input-field>
      </b-col>
    </b-row>
  </Expandable>
</template>

<script>
import {
  FIELD,
  getMaxNotRules,
  getMaxRules,
  PINCODE,
} from "../../../assets/js/rules";
import { HOSPITAL_TYPES } from "../../../assets/constants/hospital.contants";
import masterService from "../../../assets/services/master.service";
import Expandable from "../../Expandable.vue";
export default {
  components: { Expandable },
  props: {
    value: Object,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: FIELD,
      pincode: PINCODE,
      form: {
        address_line_1: "",
        address_line_2: "",
        town_village: "",
        is_permanent_address: 1,
        landmark: "",
        country: 99,
        state: 32,
        city: "",
        pincode: "",
      },
      citys: [],
      states: [],
    };
  },
  computed: {
    types() {
      return HOSPITAL_TYPES;
    },
    master() {
      return this.$store.getters.master;
    },
    countries() {
      if (this.master.country instanceof Array == false) return [];
      return this.master.country.map((item) => {
        return { value: item.id, name: item.name };
      });
    },

    years() {
      let year = 1899;
      let list = [];
      while (year < new Date().getFullYear()) {
        year++;
        list.push(year);
      }
      return list.reverse();
    },
  },
  methods: {
    getMaxRules(max) {
      return getMaxRules(max);
    },
    getMaxNotRules(max) {
      return getMaxNotRules(max);
    },
    async onCountryChange(copy) {
      const vm = this;
      if (copy == undefined) {
        vm.form.state = "";
        vm.form.city = "";
      }

      vm.states = [];
      vm.citys = [];
      vm.$emit("input", vm.form);
      if (!vm.form.country) return;
      try {
        vm.$loader.show();
        let { data } = await masterService.getStateCity({
          country_id: vm.form.country,
        });
        let { list, status, msg } = data;
        if (status) {
          vm.states = list.map((item) => {
            return { name: item.name, value: item.id };
          });
        } else {
          vm.$alert.show(msg);
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
      }
    },
    async onStateChange(copy) {
      const vm = this;
      if (copy == undefined) {
        vm.form.city = "";
      }
      vm.citys = [];
      if (!vm.form.state) return;
      vm.$emit("input", vm.form);
      try {
        vm.$loader.show();
        let { data } = await masterService.getStateCity({
          state_id: vm.form.state,
        });
        let { list, status, msg } = data;
        if (status) {
          vm.citys = list.map((item) => {
            return { name: item.name, value: item.id };
          });
        } else {
          vm.$alert.show(msg);
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
      }
    },
    input() {
      this.$emit("input", this.form);
    },
  },
  beforeMount() {
    this.form = this.value;
    this.onCountryChange(true);
    this.onStateChange(true);
  },
  mounted() {
    this.input();
  },
};
</script>

<style></style>
