<template>
  <b-modal title="Alert" hide-footer size="lg" centered v-model="dialog">
    <h5>Kindly follow the below steps:</h5>
    <ul>
      <li>
        First verify the Aadhar ID for authentication. Patient personal details
        will be automatically generated.
      </li>
      <li>Then continue to fill the medical details.</li>
      <li>
        Payment gateway page will open after you submit the registration form.
      </li>
      <li>Incomplete registration form will not open the payment gateway.</li>
    </ul>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
    };
  },
};
</script>

<style></style>
