<template>
  <div class="d-flex">
    <div class="flex-grow-1"></div>
    <b-button
      variant="outline-danger mx-3"
      @click="$router.push('/hospital-list')"
      >Cancel
    </b-button>
    <b-button v-if="isSubmit" variant="success" type="submit">Submit </b-button>
  </div>
</template>

<script>
import menuConstants from "../../../assets/constants/menu.constants";
export default {
  props: {
    status: [Number, String],
  },
  computed: {
    isCreate() {
      return this.$route.name == "hospital-registration";
    },
    isSubmit() {
      let hasCreateAccess = this.hasCreateAccess(menuConstants.MANAGE_HOSPITAL);
      if (this.isCreate) return hasCreateAccess;
      let hasAccess = this.hasEditAccess(menuConstants.MANAGE_HOSPITAL);
      return this.status != 4 && hasAccess;
    },
  },
};
</script>

<style></style>
