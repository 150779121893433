<template>
  <div>
    <div class="d-flex px-3" v-if="isTerminated">
      <div class="flex-grow-1"></div>
      <multi-organ-allocation
        class="me-3"
        :list="list"
        v-if="isAdmins"
        :organs="[...allocated, ...nonAllocated]"
      />
      <backup-allocation
        v-if="isAdmins"
        :organs="allocated"
      ></backup-allocation>
    </div>
    <expandable header="Process not Initiated" v-if="isNotInitiated">
      <b-row>
        <b-col cols="12" md="4" v-for="(item, i) in nonAllocated" :key="i">
          <button-group
            :label="item.organ_name"
            v-model="form[item.organ_id]"
            :disabled="!isTerminated"
            :options="allocationTypes(item)"
          >
          </button-group>
        </b-col>
      </b-row>
      <div class="d-flex">
        <div class="flex-grow-1"></div>
        <b-button variant="success" v-if="isTerminated" @click="submit"
          >Submit</b-button
        >
      </div>
    </expandable>
    <expandable header="Process Initiated" v-if="allocated.length">
      <b-row>
        <b-col cols="12" md="4" v-for="(item, i) in allocated" :key="i">
          <button-group
            :label="item.organ_name"
            :disabled="true"
            v-model="item.type_of_allocation"
            :options="allocationTypes(item)"
          ></button-group>
        </b-col>
      </b-row>
    </expandable>
  </div>
</template>

<script>
import { TYPE_OF_ALLOCATION } from "../../assets/constants/donor.constants";
import {
  HEART_ID,
  HEART_LUNG_ID,
  KIDNEY_ID,
  KIDNEY_LEFT_ID,
  KIDNEY_RIGHT_ID,
  LEFT_LUNG_ID,
  LIVER_ID,
  LUNG_ID,
  RIGHT_LUNG_ID,
  SPLIT_LIVER_1_ID,
  SPLIT_LIVER_2_ID,
} from "../../assets/js/common";
import event from "../../assets/js/event";
import donorMixins from "../../assets/mixins/donor.mixins";
import donorService from "../../assets/services/donor.service";
import Expandable from "../Expandable.vue";
import ButtonGroup from "../form/ButtonGroup.vue";
import BackupAllocation from "./create/BackupAllocation.vue";
import MultiOrganAllocation from "./create/MultiOrganAllocation.vue";
export default {
  props: {
    status: {},
    donor: {
      type: Object,
      default: () => {},
    },
    allocation_list: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [donorMixins],
  components: {
    Expandable,
    ButtonGroup,
    BackupAllocation,
    MultiOrganAllocation,
  },
  data() {
    return {
      form: {},
      isSplitLiver: false,
      isSplitLung: false,
    };
  },
  computed: {
    isTerminated() {
      console.log("status", this.status);
      return this.status != "5";
    },
    isNotInitiated() {
      const vm = this;
      let isDonorHospital =
        vm.isHospitalContact && vm.donor.hospital_id == vm.user.hospital_id;
      return this.nonAllocated.length && (vm.isAdmins || isDonorHospital);
    },
    organsList() {
      const vm = this;
      let list = [];
      this.list.forEach((item) => {
        if (item.organ_id == KIDNEY_ID) {
          list.push(vm.getOrgan(item, KIDNEY_LEFT_ID));
          list.push(vm.getOrgan(item, KIDNEY_RIGHT_ID));
        } else if (item.organ_id == LIVER_ID) {
          list.push(item);
          list.push(vm.getOrgan(item, SPLIT_LIVER_1_ID));
          list.push(vm.getOrgan(item, SPLIT_LIVER_2_ID));
        } else if (item.organ_id == LUNG_ID) {
          list.push(item);
          list.push(vm.getOrgan(item, LEFT_LUNG_ID));
          list.push(vm.getOrgan(item, RIGHT_LUNG_ID));
        } else {
          list.push(item);
        }
      });
      if (vm.isHeartLung) {
        let item = this.masterOrgans.find((item) => {
          return item.value == HEART_LUNG_ID;
        });
        list.push({
          organ_name: item != null ? item.name : "Heart & Lung",
          organ_id: HEART_LUNG_ID,
        });
      }
      return list;
    },
    isHeartLung() {
      let heart = this.list.find((item) => {
        return item.organ_id == HEART_ID;
      });
      let lung = this.list.find((item) => {
        return item.organ_id == LUNG_ID;
      });
      return heart != null && lung != null;
    },
    allocated() {
      return this.organsList.filter((item) => {
        let organ = this.allocation_list.find((organ) => {
          return organ.organ_id_org == item.organ_id;
        });
        if (organ != null) item.type_of_allocation = organ.type_of_allocation;
        return organ != null;
      });
    },
    nonAllocated() {
      return this.organsList.filter((item) => {
        let organ = this.allocation_list.find((organ) => {
          return organ.organ_id_org == item.organ_id;
        });
        return organ == null;
      });
    },
  },
  methods: {
    getOrgan(item, id) {
      let doc = {};
      Object.keys(item).forEach((key) => {
        doc[key] = item[key];
      });
      doc.organ_id = id;
      doc.organ_name = this.getOrganName(id);
      return doc;
    },
    getOrganName(organ_id) {
      let item = this.masterOrgans.find((item) => {
        return item.value == organ_id;
      });
      return item == null ? "" : item.name;
    },
    allocationTypes({ organ_status, organ_id }) {
      return TYPE_OF_ALLOCATION.filter((item) => {
        item.text = item.name;
        if (organ_id == 26 && item.value == 1) {
          let heart = this.list.find((item) => {
            return item.organ_id == HEART_ID;
          });
          let lung = this.list.find((item) => {
            return item.organ_id == LUNG_ID;
          });
          return heart.organ_status == 2 && lung.organ_status == 2;
        }
        let livers = [LIVER_ID, SPLIT_LIVER_2_ID, SPLIT_LIVER_1_ID];
        if (item.value == 3 && livers.indexOf(organ_id.toString()) == -1)
          return false;
        if (item.value == 2 && livers.indexOf(organ_id.toString()) != -1)
          return false;
        if (organ_status == 2) return true;
        return item.value != 1;
      });
    },
    async updateStatus() {
      const vm = this;
      let { final_donor_id } = vm;
      try {
        let { data } = await donorService.updateFinalDonorStatus({
          dml_indicator: "SU",
          status: 3,
          final_donor_id,
        });
        console.log(data);
      } catch (error) {
        console.log(
          "Error while updating the local organ chosen",
          error.message
        );
      }
    },
    async submit() {
      const vm = this;
      let { form, final_donor_id, id } = vm;
      let organ_allocation_list = [];
      let { hospital_id, case_officer } = this.donor;
      Object.keys(form).forEach((key) => {
        let type = form[key];
        let doc = {
          final_donor_id,
          organ_allocation_id: "",
          donor_temp_registration_id: id,
          case_officer,
          hospital_id,
          organ_id: key,
          backup_flow: 0,
          status: 1,
          type_of_allocation: type,
          created_by: vm.created_by,
          dml_indicator: "I",
        };
        organ_allocation_list.push(doc);
      });
      try {
        vm.$loader.show();
        let { data } = await donorService.organAllocation({
          organ_allocation_list,
        });
        let { status, msg } = data;
        vm.dialog = false;
        if (!status) {
          vm.$alert.show(msg);
        }
        vm.form = {};
        event.$emit("refresh");
        vm.$loader.hide();
        if (vm.nonAllocated.length == organ_allocation_list.length) {
          vm.updateStatus();
        }
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
        event.$emit("refresh");
      }
    },
  },
};
</script>

<style></style>
