import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ConfirmModel from "./components/confirm";
import LoaderModel from "./components/loader";
import AlertModel from "./components/alert";
import VueCookies from "vue-cookies";
import InputField from "./components/form/InputField.vue";
import InputGroup from "./components/form/InputGroup.vue";
import FileInput from "./components/form/FileInput.vue";
import SelectField from "./components/form/SelectField.vue";
import Autocomplete from "./components/form/Autocomplete.vue";
import FormWrapper from "./components/form/FormWrapper.vue";
import Progress from "./components/Progress.vue";
import commonMixins from "./assets/mixins/common.mixins";
import VueCountdownTimer from "vuejs-countdown-timer";
import Icon from "./components/Icon.vue";
import Tabs from "vue-material-tabs";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import PopupLayer from "vue-popup-layer";

import {
  ValidationObserver,
  ValidationProvider,
  configure,
} from "vee-validate";
import "bootstrap-vue/dist/bootstrap-vue.min.css";
import "./assets/design/app.scss";
import "../node_modules/select2/dist/css/select2.min.css";
import "./assets/design/custom/plugins/select2.css";
import "intl-tel-input/build/css/intlTelInput.css";
import "./assets/js/extend.rules";
import "../node_modules/select2/dist/js/select2.full.min.js";
import "vue2-datepicker/index.css";

global.jQuery = require("jquery");
const $ = global.jQuery;
window.$ = $;

configure({
  useConstraintAttrs: false,
});

Vue.use(PopupLayer);
Vue.use(VueCountdownTimer);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("InputField", InputField);
Vue.component("InputGroup", InputGroup);
Vue.component("SelectField", SelectField);
Vue.component("Autocomplete", Autocomplete);
Vue.component("FormWrapper", FormWrapper);
Vue.component("FileInput", FileInput);
Vue.component("progressbar", Progress);
Vue.component("v-icon", Icon);
Vue.mixin(commonMixins);
Vue.use(VueCookies);
Vue.use(ConfirmModel);
Vue.use(LoaderModel);
Vue.use(AlertModel);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Tabs);
Vue.config.productionTip = false;
Vue.$cookies.config("30");

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
