<template>
  <b-card class="mt-4">
    <div class="h4">Filter</div>
    <form-wrapper @submit="submit">
      <b-row>
        <b-col cols="12" md="4">
          <select-field
            label="Payment Status"
            v-model="form.payment_status"
            :items="paymentStatus"
          ></select-field>
        </b-col>
        <b-col cols="12" md="4">
          <select-field
            label="Patient Status"
            :items="patientStatus"
            v-model="form.transtan_status"
          ></select-field>
        </b-col>
      </b-row>
      <div class="d-flex">
        <div class="flex-grow-1"></div>
        <b-btn
          class="width-100 me-3"
          @click="reset(false)"
          variant="outline-secondary"
          >RESET</b-btn
        >
        <b-btn class="width-100" type="submit" variant="primary">SUBMIT</b-btn>
      </div>
    </form-wrapper>
  </b-card>
</template>

<script>
import {
  BE_READY_STATUS,
  PATIENT_STATUS,
  PAYMENT_STATUS,
} from "../../assets/constants/recipient.constants";
import commonHelper from "../../assets/helper/common.helper";
export default {
  props: {
    value: {},
  },
  data() {
    return {
      form: {
        hospital_id: "",
        payment_status: "all",
        transtan_status: "all",
        hospital_status: "all",
      },
      hospitals: [],
      paymentStatus: PAYMENT_STATUS,
      beReadyStatus: BE_READY_STATUS,
    };
  },
  computed: {
    patientStatus() {
      let statusAllowed = ["all", "11", "9", "6", "2","12"];
      return PATIENT_STATUS.filter((item) => {
        return statusAllowed.indexOf(item.value) != -1;
      });
    },
  },
  methods: {
    submit() {
      const vm = this;
      let { payment_status, transtan_status } = vm.form;
      let doc = {
        dml_indicator: "PS",
      };
      if (payment_status != "all") {
        doc.payment_status = payment_status;
      }
      if (transtan_status != "all") {
        doc.transtan_status = transtan_status;
      } else {
        doc.filter_type = 99;
      }
      // vm.$store.commit("SET_QUERY", { key: "recipient", query: vm.form });
      vm.$emit("input", doc);
      vm.$emit("fetch");
    },
    reset(isSubmit) {
      this.form = {
        hospital_id: "",
        payment_status: "all",
        transtan_status: "all",
        hospital_status: "all",
      };
      if (isSubmit) {
        this.submit();
      }
    },
  },
  mounted() {
    this.submit();
    commonHelper.fetchHospital(this);
  },
};
</script>

<style></style>
