export const CONTACT_TYPES = [{
    name: "Hospital Contact",
    value: "1"
}, {
    name: "Organ Contact",
    value: "2"
}, {
    name: "Recepiant Contact",
    value: "3"
}, {
    name: "Donor Contact",
    value: "4"
}, {
    name: "Admin Contact",
    value: "5"
},]
