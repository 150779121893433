<template>
  <collapse-filter>
    <b-card class="mt-4 mb-4">
      <div class="h4">Filter</div>
      <form-wrapper @submit="submit">
        <b-row>
          <b-col cols="12" md="4" v-if="isAdmins">
            <autocomplete
              label="Hospital"
              v-model="form.hospital_id"
              rules="required"
              :items="hospitals"
            ></autocomplete>
          </b-col>
          <b-col cols="12" md="4">
            <autocomplete
              label="Organ"
              v-model="form.organs"
              :multiple="true"
              rules="required"
              :items="organs"
            ></autocomplete>
          </b-col>
          <b-col cols="12" md="4" v-if="isAdmins">
            <autocomplete
              v-model.trim="form.zone"
              label="Zone"
              rules="required"
              :multiple="true"
              :items="masterZones"
            ></autocomplete>
          </b-col>
          <b-col cols="12" md="4">
            <date-range-picker
              v-model.trim="form.range"
              label="Date Range (Created ON)"
            ></date-range-picker>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-btn
            class="width-100 me-3"
            @click="reset(false)"
            variant="outline-secondary"
            >RESET</b-btn
          >
          <b-btn class="width-100" type="submit" variant="primary"
            >SUBMIT</b-btn
          >
        </div>
      </form-wrapper>
    </b-card>
  </collapse-filter>
</template>

<script>
import moment from "moment";
import { KIDNEY_ID, LIVER_ID } from "../../../assets/js/common";
import hospitalService from "../../../assets/services/hospital.service";
import Autocomplete from "../../form/Autocomplete.vue";
import DateRangePicker from "../../form/DateRangePicker.vue";
import CollapseFilter from "../../ui/CollapseFilter.vue";
export default {
  components: { Autocomplete, DateRangePicker, CollapseFilter },
  props: {
    value: {},
  },
  data() {
    return {
      form: {
        hospital_id: "",
        organs: [],
        zone: [],
        range: [],
      },
      hospitals: [],
    };
  },
  computed: {
    organs() {
      let allowed = [LIVER_ID, KIDNEY_ID];
      if (this.master.master_organ instanceof Array == false) return [];
      let list = this.master.master_organ.filter(({ organ_id }) => {
        return allowed.indexOf(organ_id.toString()) != -1;
      });
      return list.map((item) => {
        return { value: item.organ_id, name: item.organ_name };
      });
    },
  },
  methods: {
    submit() {
      const vm = this;
      let { hospital_id, organs, zone, range } = vm.form;
      const doc = {
        dml_indicator: "S",
        hospital_id,
      };
      doc.zone_list = zone.map((zone) => {
        return { zone };
      });
      doc.organ = organs.map((organ_id) => {
        return { organ_id };
      });
      if (range.length > 1) {
        doc.Fdate = moment(range[0], "DD-MM-YYYY").format("YYYY-MM-DD");
        doc.Edate = moment(range[1], "DD-MM-YYYY").format("YYYY-MM-DD");
      }
      vm.$emit("input", doc);
      vm.$emit("fetch");
    },
    reset(isSubmit) {
      const vm = this;
      vm.form.hospital_id = "0";
      vm.form.organs = [];
      vm.form.zone = [];
      vm.form.range = [];
      if (vm.isHospitalContact) {
        vm.form.hospital_id = vm.user.hospital_id;
      }
      if (isSubmit) {
        vm.submit();
      }
    },
    async init() {
      const vm = this;
      try {
        vm.$loader.show();
        vm.hospitals = [];
        let { user_type, hospital_id } = vm.$store.getters.user;
        let doc = { user_type };
        if (user_type == 1) {
          doc.hospital_id = hospital_id;
          vm.form.hospital_id = hospital_id;
        }
        let { data } = await hospitalService.list(doc);
        let { status, msg, list } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.$loader.hide();
        vm.hospitals = list.map((item) => {
          item.name = item.hospital_name;
          item.value = item.hospital_id;
          return item;
        });
        vm.$emit("hospitals", [...vm.hospitals]);
        if (vm.isAdmins) {
          vm.hospitals.unshift({
            name: "All Hospital",
            value: "0",
          });
        }
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.init();
    this.reset();
    if (this.isHospitalContact) {
      this.reset(true);
    }
  },
};
</script>

<style></style>
