<template>
  <div>
    <Tabs :theme="theme" v-model="tab" @input="reset()">
      <TabItem v-for="(item, i) in ranks" :key="i" :name="item.value">
        <template #name>
          {{ item.name }}
        </template>
      </TabItem>
    </Tabs>
    <in-house-waiting-filter
      v-model="filter"
      ref="filter"
      @hospitals="hospitals = $event"
      @fetch="fetch"
    ></in-house-waiting-filter>
    <in-house-waiting-table
      :isFinal="isFinal"
      :type="tab"
      :isLoading="isLoading"
      :list="list"
      @fetch="fetch"
    ></in-house-waiting-table>
  </div>
</template>

<script>
import { INHOUSE_RANK } from "../../assets/constants/recipient.constants";
import recipientService from "../../assets/services/recipient.service";
import InHouseWaitingFilter from "../../components/waitingList/inhouseWaitingList/InHouseWaitingFilter.vue";
import InHouseWaitingTable from "../../components/waitingList/inhouseWaitingList/InHouseWaitingTable.vue";
export default {
  components: { InHouseWaitingFilter, InHouseWaitingTable },
  data() {
    return {
      isLoading: false,
      filter: {},
      list: [],
      tab: "1",
      theme: {
        nav: "white",
        navItem: "#BDBDBD",
        navActiveItem: "#556ee6",
        slider: "#556ee6",
        arrow: "#556ee6",
      },
      isFinal: false,
      hospitals: [],
    };
  },
  computed: {
    ranks() {
      let list = INHOUSE_RANK.filter((item) => {
        if (item.value == "all") return false;
        if (this.isSubAdmin && item.value == "1") return false;
        return true;
      });
      return list.map((item) => {
        item.value = item.value.toString();
        return item;
      });
    },
  },
  methods: {
    reset() {
      this.$refs.filter.reset(false);
      this.list = [];
    },
    async fetch() {
      const vm = this;
      try {
        vm.isLoading = true;
        vm.list = [];
        vm.filter.inhouse_rank = vm.tab;
        if (vm.isSubAdmin) {
          vm.filter.inhouse_rank = 2;
        }
        vm.isFinal = vm.filter.inhouse_rank == 2;
        let { data } = await recipientService.getInhouseWaitList(vm.filter);
        let { status, msg, list } = data;
        vm.isLoading = false;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.list = list;
      } catch (error) {
        vm.isLoading = false;
        console.log(vm.error);
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    if (this.isSubAdmin) {
      this.tab = 2;
    }
  },
};
</script>

<style></style>
