<template>
  <expandable header="ALF Details">
    <b-row>
      <b-col cols="12">
        <select-field
          label="ALF Listing Type"
          :disabled="isDisabled"
          rules="required"
          v-model="form.master_alf_id"
          @input="onInput"
          :items="masterALF"
        ></select-field>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="4" class="mb-2">
        <file-input-group
          :disabled="isDisabled"
          v-model="form.file_name"
          @input="onFileChange"
          accept=".jpg,.jpeg,.png,.pdf"
          rules="required|file-image-pdf|file-size"
          label="ALF Evaluation Sheet"
        ></file-input-group>
      </b-col>
      <b-col cols="12" md="4" class="mb-2">
        <file-input-group
          v-model="form.additional_hepatology_notes"
          :disabled="isDisabled"
          @input="onFileChange"
          accept=".jpg,.jpeg,.png,.pdf"
          rules="required|file-image-pdf|file-size"
          label="Additional Hepatology Notes"
        ></file-input-group>
      </b-col>
      <b-col cols="12" md="4" class="mb-2">
        <file-input-group
          v-model="form.consultant_short_summary"
          :disabled="isDisabled"
          @input="onFileChange"
          accept=".jpg,.jpeg,.png,.pdf"
          rules="required|file-image-pdf|file-size"
          label="Consultant Short Summary"
        ></file-input-group>
      </b-col>
    </b-row>
  </expandable>
</template>

<script>
import { openFile } from "../../../assets/js/common";
import Expandable from "../../Expandable.vue";
import FileInputGroup from "../../form/FileInputGroup.vue";
import SelectField from "../../form/SelectField.vue";
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { Expandable, SelectField, FileInputGroup },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    isFile(file) {
      return file == null || file instanceof File;
    },
    onInput() {
      this.$emit("input", this.form);
    },
    open(file) {
      openFile(file);
    },
    onFileChange() {
      this.$emit("input", this.form);
    },
  },
  mounted() {
    this.form = this.value;
  },
};
</script>
<style></style>
