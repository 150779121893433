<template>
  <b-container class="payment__success">
    <img
      src="../assets/images/payment_failed.svg"
      width="200"
      height="200"
      alt=""
    />
    <h3>Payment Failed</h3>
    <p>Unfortunately payment was rejected</p>
  </b-container>
</template>

<script>
export default {};
</script>

