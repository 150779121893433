/* eslint-disable no-useless-escape */
import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { alpha, integer } from "vee-validate/dist/rules";

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

extend("alpha", {
  ...alpha,
  message: "The field may only contain alphabetic characters",
});
extend("integer", {
  ...integer,
  message: "The field may only contain number",
});

extend("url", {
  validate(value) {
    if (value) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        value
      );
    }
    return false;
  },
  message: "This value must be a valid URL",
});
extend("phone", {
  validate(value) {
    if (value) {
      return value.toString().length == 10;
    }
    return false;
  },
  message: "This value must be a valid phone number",
});
extend("expiry_date", {
  validate() {
    return false;
  },
  message:
    "License Expiry date cannot be lower than the First Registration date",
});
extend("date_of_admission", {
  validate() {
    return false;
  },
  message:
    "Date of Admission cannot be lower than the Date of Onset of illness / Date of Accident",
});
extend("surgery-end-time", {
  validate() {
    return false;
  },
  message: "Surgery end time can't be less then Surgery start time",
});
extend("surgery-end-date", {
  validate() {
    return false;
  },
  message: "Surgery end date can't be less then Surgery start date",
});
extend("second_apnea_date", {
  validate() {
    return false;
  },
  message:
    "Second Apnoea Date/Time cannot be lower than the First Apnoea Date/Time",
});
extend("organ_combination", {
  validate() {
    return false;
  },
  message: "Organ combination not allowed",
});
extend("aadhar", {
  validate(value) {
    if (value) {
      return value.toString().length == 12;
    }
    return false;
  },
  message: "This value must be 12 digit number",
});

extend("strong_password", {
  validate(value) {
    if (value) {
      let reg = new RegExp(
        "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
      );
      console.log(reg.test(value));
      return reg.test(value);
    }
    return false;
  },
  message:
    "Password must contain at least one number and one special charactor and one uppercase and lowercase letter, and at least 8 or more characters",
});

extend("required", {
  message: "This field is required",
});
extend("email", {
  message: "E-mail must be valid",
});

extend("max-150", {
  validate(value) {
    if (value) {
      return value.toString().length <= 150;
    }
    return false;
  },
  message: "Field must be less than 150 characters",
});
extend("max_num_120", {
  validate(value) {
    if (value) {
      return value <= 120;
    }
    return false;
  },
  message: "Field must be less than 120",
});
extend("file-image-pdf", {
  validate(value) {
    if (value instanceof File) {
      let types = ["application/pdf", "image/jpeg", "image/png", "image/jpg"];
      let { type } = value;
      return types.indexOf(type) != -1;
    }
    return false;
  },
  message: "File type not allowed",
});
extend("file-video", {
  validate(value) {
    if (value instanceof File) {
      let types = [
        "video/mp4",
        "video/x-m4v",
        "video/avi",
        "video/x-quicktime",
        "video/3gp",
      ];
      let { type } = value;
      return types.indexOf(type) != -1;
    }
    return false;
  },
  message: "File type not allowed",
});
extend("file-image", {
  validate(value) {
    if (value instanceof File) {
      let types = ["image/jpeg", "image/png", "image/jpg"];
      let { type } = value;
      return types.indexOf(type) != -1;
    }
    return false;
  },
  message: "File type not allowed",
});
extend("file-size", {
  validate(value) {
    if (value instanceof File) {
      let { size } = value;
      size = size / 1048576;
      return size < 5;
    }
    return false;
  },
  message: "File size must be less than 5mb",
});

extend("file-size-100", {
  validate(value) {
    if (value instanceof File) {
      let { size } = value;
      size = size / 1048576;
      return size < 100;
    }
    return false;
  },
  message: "File size must be less than 100mb",
});

extend("max-255", {
  validate(value) {
    if (value) {
      return value.toString().length <= 255;
    }
    return false;
  },
  message: "Field must be less than 255 characters",
});
extend("max-100", {
  validate(value) {
    if (value) {
      return value.toString().length <= 100;
    }
    return false;
  },
  message: "Field must be less than 100 characters",
});

extend("max-50", {
  validate(value) {
    if (value) {
      return value.toString().length <= 50;
    }
    return false;
  },
  message: "Field must be less than 50 characters",
});
extend("max-num-9", {
  validate(value) {
    if (value) {
      return value.toString().length <= 8;
    }
    return false;
  },
  message: "Field must be less than 9 digit",
});
extend("age-18", {
  validate() {
    return false;
  },
  message: "Age must be greated then 17 years",
});
extend("num-3", {
  validate(value) {
    if (value != undefined) {
      return value < 1000;
    }
    return false;
  },
  message: "Value should be between 0 and 999",
});
extend("num-5", {
  validate(value) {
    if (value != undefined) {
      return value < 100000;
    }
    return false;
  },
  message: "Value should be between 0 and 99999",
});
extend("num-2", {
  validate(value) {
    if (value != undefined) {
      return value < 100;
    }
    return false;
  },
  message: "Value should be between 0 and 99",
});
extend("percentage", {
  validate(value) {
    if (value != undefined) {
      return value >= 0 && value <= 100;
    }
    return false;
  },
  message: "Value should be between 0 and 100",
});
extend("min-1", {
  validate(value) {
    if (value != undefined) {
      return value > 0;
    }
    return false;
  },
  message: "Value should be getter than 0",
});
extend("weight", {
  validate(value) {
    if (value != undefined) {
      return value <= 200;
    }
    return false;
  },
  message: "Value should be between 0 and 200",
});
extend("height", {
  validate(value) {
    if (value != undefined) {
      return value <= 220;
    }
    return false;
  },
  message: "Value should be between 0 and 220",
});
extend("temperature", {
  validate(value) {
    if (value != undefined) {
      return value <= 120;
    }
    return false;
  },
  message: "Value should be between 0 and 120",
});
extend("dec-2", {
  validate(value) {
    let regex = /^\d*(\.\d{1,2})?$/;
    if (value != undefined) {
      return value.toString().match(regex);
    }
    return false;
  },
  message: "Only two decimal allowed",
});
extend("dec-1", {
  validate(value) {
    let regex = /^\d*(\.\d{1})?$/;
    if (value != undefined) {
      return value.toString().match(regex);
    }
    return false;
  },
  message: "Only one decimal allowed",
});
extend("dec-0", {
  validate(value) {
    let regex = /^\d*(\.\d{})?$/;
    if (value != undefined) {
      return value.toString().match(regex);
    }
    return false;
  },
  message: "Decimal not allowed",
});
extend("max-75", {
  validate(value) {
    if (value != undefined) {
      return value.toString().length <= 75;
    }
    return false;
  },
  message: "Field must be less than 75 characters",
});
extend("zipcode", {
  validate(value) {
    if (value) {
      return value.toString().length == 6;
    }
    return false;
  },
  message: "Please enter a valid pincode",
});
