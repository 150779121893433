import axios from "axios";
import Vue from "vue";

import { BASE_URL } from "./common";
let baseURL = BASE_URL + "api";
const http = axios.create({
  baseURL,
});
http.interceptors.request.use(
  (config) => {
    let token = localStorage.token;
    if (token) {
      config.headers.common["Authorization"] = "bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status == 401) {
      error.message = 'Authorization has been denied for this request.';
      Vue.$cookies.remove("session");
      localStorage.removeItem("token");
      setTimeout(() => {
        location.reload();
      }, 1000);
    }
    return Promise.reject(error);
  }
);
export default http;
