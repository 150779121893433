<template>
  <expandable header="Consultant Details">
    <b-row>
      <b-col cols="12">
        <select-field
          label="Consultant Name"
          v-model="form.consultant_detail"
          @input="onInput"
          :items="consultants"
          :disabled="isDisabled"
          rules="required"
        ></select-field>
      </b-col>
    </b-row>
  </expandable>
</template>

<script>
import recipientMixins from "../../../assets/mixins/recipient.mixins";
import recipientService from "../../../assets/services/recipient.service";
import Expandable from "../../Expandable.vue";
import SelectField from "../../form/SelectField.vue";
export default {
  props: {
    value: {},
    hospital_id: {},
    organs: {},
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { SelectField, Expandable },
  mixins: [recipientMixins],
  data() {
    return {
      form: {
        consultant_detail: "",
      },
      isLoading: true,
      consultants: [],
    };
  },
  computed: {},
  methods: {
    onInput() {
      this.$emit("input", this.form);
    },
    async init() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const vm = this;
        try {
          let { hospital_id } = vm;
          let { data } = await recipientService.consultatantList({
            hospital_id,
          });
          vm.consultants = data.list.map((item) => {
            let { hospital_contact_id, first_name, last_name } = item;
            let name = `${first_name} ${last_name}`;
            let value = hospital_contact_id;
            return { name, value };
          });
          resolve();
          console.log(data);
        } catch (error) {
          reject(error);

          console.log(error.message);
        }
      });
    },
  },
  mounted() {
    this.init();
  },
  beforeMount() {
    this.form = this.value;
  },
};
</script>

<style></style>
