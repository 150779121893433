<template>
  <div class="d-flex">
    <div class="flex-grow-1"></div>
    <select-hospital
      v-if="hasAccess && isAdmin"
      :hospitals="filteredHospitals"
    ></select-hospital>
    <Add @click="open" v-else-if="hasAccess"></Add>
  </div>
</template>

<script>
import menuConstants from "../../assets/constants/menu.constants";
import { encode } from "../../assets/js/common";
import commonMixins from "../../assets/mixins/common.mixins";
import Add from "../buttons/AddButton.vue";
import SelectHospital from "./SelectHospital.vue";
export default {
  props: {
    hospitals: Array,
  },
  components: { Add, SelectHospital },
  mixins: [commonMixins],
  computed: {
    hasAccess() {
      let hasAccess = this.hasCreateAccess(menuConstants.MANAGE_RECIPIENT);
      return hasAccess;
    },
    filteredHospitals() {
      return this.hospitals.filter((item) => {
        return item.value != 0;
      });
    },
  },
  methods: {
    open() {
      const vm = this;
      let { hospital_id, hospital_type } = vm.user;
      let id = encode(hospital_id);
      if (hospital_id) {
        vm.$router.push(
          `/recipient-registration?hospital_id=${id}&hospital_type=${hospital_type}`
        );
      } else {
        vm.$alert.show("Please register the hospital fist");
      }
    },
  },
};
</script>

<style></style>
