<template>
  <div>
    <Loader></Loader>
    <Alert></Alert>
    <Confirm></Confirm>
    <layout v-if="!isLoading"></layout>
  </div>
</template>

<script>
import Loader from "./components/loader/Loader.vue";
import Confirm from "./components/confirm/Confirm.vue";
import Alert from "./components/alert/Alert.vue";
import Layout from "./components/Layout.vue";
import userService from "./assets/services/user.service";
import commonHelper from "./assets/helper/common.helper";
import { AUTH, messaging, PUBLIC_KEY } from "./assets/js/firebase.js";
import { getToken, isSupported } from "@firebase/messaging";
import { signInWithCustomToken } from "@firebase/auth";
export default {
  name: "App",
  components: { Confirm, Loader, Alert, Layout },
  data: () => ({
    isLoading: true,
  }),
  computed: {
    isWaitingList() {
      return this.$route.name == "commong-waiting-list";
    },
  },
  methods: {
    async initMessage(data) {
      try {
        let isSupport = await isSupported(messaging);
        if (!isSupport) return;
        let token = await getToken(messaging, { vapidKey: PUBLIC_KEY });
        let { registration_id } = data.login_details[0];
        let doc = {
          registration_id,
          token,
          token_type: 1,
          dml_indicator: "I",
        };
        userService.updateToken(doc);
      } catch (error) {
        console.log(error);
      }
      // onMessage(messaging, (payload) => {
      //   console.log("Message received. ", payload);
      //   // ...
      // });
    },
    init() {
      const vm = this;
      vm.$store.commit("SET_AUTH");
      vm.$store.dispatch("fetchMaster");
      vm.$store.dispatch("fetchCaseOfficer");
      vm.$store.dispatch("fetchALFMaster");
      vm.$store.dispatch("fetchVaccinations");
      vm.$store.dispatch("fetchDonorMedicalDetails");
      vm.$store.dispatch("fetchCauseOfDeath");
      vm.$store.dispatch("fetchDonorTermination");
      vm.$store.dispatch("fetchFileUploadTypes");
      vm.loginToFirebase();
    },
    loginToFirebase() {
      let token = this.user.fcm_token;
      signInWithCustomToken(AUTH, token).catch((error) => {
        console.log(error.message);
      });
    },
    async checkAuth() {
      const vm = this;
      let token = localStorage.token;
      if (token) {
        try {
          vm.$loader.show();
          let { data } = await userService.refresh();
          vm.$loader.hide();
          if (data.status) {
            await commonHelper.setAuth(vm, data);
            vm.isLoading = false;
            vm.init();
            vm.initMessage(data);
          } else {
            vm.clearLogin();
          }
        } catch (error) {
          vm.clearLogin();
          vm.$loader.hide();
        }
      } else {
        vm.clearLogin();
      }
    },
    clearLogin() {
      const vm = this;
      vm.$cookies.remove("session");
      localStorage.removeItem("token");
      vm.$store.commit("SET_AUTH");
      if (!vm.isWaitingList) {
        vm.$router.replace("/login");
      }
      vm.isLoading = false;
    },
  },
  watch: {
    $route() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
  mounted() {
    this.checkAuth();
  },
};
</script>
<style lang="scss">
@import "./assets/design/mixins.scss";
body {
  overflow-y: hidden;
}
.container-fluid {
  @include mobile {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
.mb-m-3 {
  @include mobile {
    margin-bottom: 16px;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.v-application--wrap {
  background: #ecf0f5;
}
.top-footer {
  border-top: 0px !important;
}
.progress {
  border-radius: 0px !important;
}
.width-100 {
  width: 100px;
}
.section_subheader {
  background: #eff2f7;
}
.table-responsive-sm {
  overflow-x: auto;
  width: 100%;
}
</style>
