<template>
  <div>
    <b-card no-body class="px-3 py-3">
      <b-row>
        <b-col class="mb-m-3" cols="12" md="6">
          <slot name="left"> </slot>
        </b-col>
        <b-col cols="12" md="6" class="d-flex justify-content-end">
          <b-button v-b-toggle.collapse-1 variant="primary">
            <b-icon icon="funnel" aria-hidden="true"></b-icon>
            Advance Filter
          </b-button>
        </b-col>
      </b-row>
      <!-- <div class="d-flex align-items-center">
        <div class="flex-grow-1"></div>
      </div> -->
    </b-card>
    <b-collapse id="collapse-1">
      <slot></slot>
    </b-collapse>
  </div>
</template>


<script>
export default {};
</script>

<style lang="scss">
@import "../assets/design/mixins";

</style>
