export const NATIONALITY = [
  {
    name: "Indian",
    value: 0,
  },
  {
    name: "International",
    value: 1,
  },
];
export const PAYMENT_STATUS = [
  {
    name: "All",
    value: "all",
  },

  {
    name: "Paid",
    value: "1",
  },
  {
    name: "Unpaid",
    value: "2",
  },
];
export const PATIENT_STATUS = [
  {
    name: "All",
    value: "all",
  },
  {
    name: "Draft",
    value: "1",
  },
  {
    name: "Pending Approval",
    value: "2",
  },
  {
    name: "Active",
    value: "3",
  },
  {
    name: "Expired",
    value: "4",
  },
  {
    name: "Deleted",
    value: "5",
  },
  {
    name: "Extent Approval by 1 year",
    value: "6",
  },
  {
    name: "Draft Deleted",
    value: "7",
  },
  {
    name: "Transfer Draft",
    value: "8",
  },
  {
    name: "Transfer Pending",
    value: "9",
  },
  {
    name: "Inactive",
    value: "10",
  },
  {
    name: "Inactive to Active Pending",
    value: "11",
  },
  {
    name: "Document Verified",
    value: "12",
  },
  {
    name: "Organ Allocated",
    value: "13",
  },
];
export const BE_READY_STATUS = [
  {
    name: "All",
    value: "all",
  },
  {
    name: "Active",
    value: "1",
  },
  {
    name: "Not Ready For Operation",
    value: "2",
  },
  {
    name: "Inactive",
    value: "3",
  },
];
export const PREIODS = [
  {
    name: "<3months",
    value: "1",
  },
  {
    name: "<6months",
    value: "2",
  },
  {
    name: ">6months",
    value: "3",
  },
];
export const HEPATITIS = [
  {
    name: "Current",
    value: "1",
  },
  {
    name: "Healed",
    value: "2",
  },
  {
    name: "No History",
    value: "3",
  },
];
export const HISTORY_COMPLICATIONS = [
  { name: "GI Bleed", value: "1" },
  { name: "Ascites & Complications", value: "2" },
  { name: "Hepatorenal syndrome", value: "3" },
  { name: "Hepatopulmonary syndrome", value: "4" },
  { name: "Porto pulmonary Hypertension", value: "5" },
  { name: "Hepatic Encephalopathy", value: "6" },
  { name: "Hepatocellular Carcinoma", value: "7" },
  { name: "Others", value: "8" },
];

export const CANCER_SCREENINGS = [
  {
    name: "PSA",
    value: "1",
  },
  {
    name: "PAP Smear",
    value: "2",
  },
  {
    name: "Colonoscopy",
    value: "3",
  },
  {
    name: "Endometrial Biopsy",
    value: "4",
  },
  {
    name: "Mammogram",
    value: "5",
  },
];

export const CAUSE_OF_LUNG_DISEASE = [
  {
    name: "Restrictive lung disease",
    value: "1",
  },
  {
    name: "Obstructive lung disease",
    value: "2",
  },
  {
    name: "Suppurative lung disease",
    value: "3",
  },
  {
    name: "Vascular lung disease",
    value: "4",
  },
  {
    name: "Others",
    value: "5",
  },
];

export const CM_INSURANCE_STATUS = [
  {
    name: "Approved",
    value: "1",
  },
  {
    name: "Not Applied",
    value: "2",
  },
  {
    name: "Pending Approval",
    value: "3",
  },
  {
    name: "Not Applicable",
    value: "4",
  },
];
export const INHOUSE_RANK = [
  {
    name: "All",
    value: "all",
  },
  {
    name: "Upcoming Rank",
    value: 1,
  },
  {
    name: "Final Rank",
    value: 2,
  },
];
export const PVIR_UNIT = [
  {
    name: "WU(Woods Unit)",
    value: 1,
  },
  {
    name: "Dynes.Sec.cm5",
    value: 2,
  },
];

export const ACCEPT_DECLINE = [
  {
    html: "<span class='text-success'>Accept</span>",
    value: "1",
  },
  {
    html: "<span class='text-danger'>Decline</span>",
    value: "2",
  },
];

export const AADHAR_ERROR_MESSAGES = {
  "OTP Flooding error.": "You have tried too many OTP.",
  "Otp XSD Validation Failed.": "Invalid Aadhar Number.",
  "Aadhaar number does not have verified mobile.":
    "Aadhaar number does not have verified mobile number.",
};

export const TRANSFER_PAYMENT_MESSAGE =
  "The Transfer Recipient will be active after 5 days, once the payment will be done. Do you want to continue the payment?";
export const RECIPIENT_PAYMENT_MESSAGE =
  "Note: Unique ID will be generated only after the payment is completed.<br/> Do you want to continue to payment?";
