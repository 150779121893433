<template>
  <div>
    <template v-if="isUpdate">
      <edit-button v-if="isSubmit" @click="init()"></edit-button>
      <view-button v-else @click="init()"></view-button>
    </template>
    <b-modal
      v-model="dialog"
      size="lg"
      :title="title"
      hide-footer
      scrollable 
      centered
      :busy="isLoading"
    >
      <form-wrapper @submit="submit" v-if="!isLoading">
        <b-row class="pb-0">
          <b-col cols="12" md="4">
            <input-field
              :disabled="isDisabled"
              v-model.trim="form.first_name"
              label="First Name"
              rules="required|max-50"
            ></input-field>
          </b-col>
          <b-col cols="12" md="4">
            <input-field
              v-model.trim="form.last_name"
              :disabled="isDisabled"
              label="Last Name"
              rules="required|max-50"
            ></input-field>
          </b-col>
          <b-col cols="12" md="4">
            <select-field
              v-model.trim="form.gender"
              :disabled="isDisabled"
              label="Gender"
              outlined
              :items="gender"
              rules="required|max-50"
            ></select-field>
          </b-col>
          <b-col cols="12" md="4">
            <date-picker
              :disabled="isDisabled"
              v-model="form.DOB"
              :rules="dobRules"
              :max="new Date()"
              label="Date Of Birth"
            ></date-picker>
          </b-col>
          <b-col cols="12" md="4" v-if="false">
            <select-field
              label="Blood Group"
              :items="bloodGroups"
              :disabled="isDisabled"
              v-model="form.blood_id"
              rules="required"
            ></select-field>
          </b-col>
          <b-col cols="12" md="4">
            <select-field
              v-model.trim="form.contact_id"
              label="Contact Type"
              :disabled="isDisabled"
              :items="contactTypes"
              rules="required"
            ></select-field>
          </b-col>
          <b-col cols="12" md="4">
            <input-field
              v-model.trim="form.email_id"
              label="Email"
              :disabled="isEmailDisabled"
              rules="required|email|max-100"
            ></input-field>
          </b-col>
          <b-col cols="12" md="4">
            <phone-field
              :disabled="isDisabled"
              v-model.trim="form.phone_number_1"
              :defaultCountry="form.country_code_1"
              label="Phone Number 1"
              @onCountryCode="form.country_code_1 = $event"
              rules="required|integer"
            ></phone-field>
          </b-col>
          <b-col cols="12" md="4">
            <phone-field
              v-model.trim="form.phone_number_2"
              :disabled="isDisabled"
              :defaultCountry="form.country_code_2"
              label="Phone Number 2"
              @onCountryCode="form.country_code_2 = $event"
              rules="integer"
            ></phone-field>
          </b-col>
          <b-col cols="12" md="4" v-if="isUserPage && isOrgan">
            <autocomplete
              :disabled="isDisabled"
              v-model.trim="form.contact_organ_mapping"
              :items="organs"
              rules="required"
              label="Organs"
              multiple
            ></autocomplete>
          </b-col>
          <b-col cols="12" md="4">
            <autocomplete
              v-model.trim="form.qualification_details"
              :disabled="isDisabled"
              multiple
              :items="masterQualifications"
              label="Qualification Details"
            ></autocomplete>
          </b-col>
          <b-col cols="12" md="4" :class="isFile ? '' : 'd-flex align-items-center'">
            <file-input-group
              :disabled="isDisabled"
              v-model="form.photo"
              accept=".jpg,.jpeg,.png,.pdf"
              rules="file-image"
              label="Photo"
            ></file-input-group>

            <!-- <file-input
              v-if="isFile"
              v-model="form.photo"
              :disabled="isDisabled"
              accept="image/*"
              label="Photo"
              rules="file-image"
            >
              <template v-slot:help>
                <help text="Optional Field"></help>
              </template>
            </file-input>
            <b-button
              outlined
              style="height: 35px"
              class="mt-3"
              v-else
              @click="open(form.photo)"
              color="primary"
              small
            >
              View Photo
            </b-button> -->
          </b-col>
          <b-col cols="12" md="4" v-if="isUserPage && isAdmin">
            <yes-no v-model="form.ALF_approver" label="Is ALF Approver?"></yes-no>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button v-if="isEdit" class="ms-3" variant="success" type="submit"
            >Submit
          </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import { GENDERS } from "../../assets/constants/hospital.contants";
import { getAge, getDateOnly, isArray, openFile } from "../../assets/js/common";
import userHelper from "./user.helper";
import EditButton from "../buttons/EditButton.vue";
import DatePicker from "../form/DatePicker.vue";
import PhoneField from "../form/PhoneField.vue";
import SelectField from "../form/SelectField.vue";
import userMixins from "../../assets/mixins/user.mixins";
import licenseService from "../../assets/services/license.service";
import ViewButton from "../buttons/ViewButton.vue";
import menuConstants from "../../assets/constants/menu.constants";
import FileInputGroup from "../form/FileInputGroup.vue";
import YesNo from "../form/YesNo.vue";
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    hospital_id: {
      type: [Number, String],
      default: "",
    },
    index: {
      type: Number,
      default: null,
    },
  },
  mixins: [userMixins],
  components: {
    EditButton,
    SelectField,
    PhoneField,
    DatePicker,
    ViewButton,
    FileInputGroup,
    YesNo,
  },
  data() {
    return {
      dialog: false,
      isValid: false,
      isLoading: false,
      form: {
        DOB: null,
      },
      loading: true,
      updateDoc: {},
      organs: [],
    };
  },
  watch: {
    dialog(val) {
      if (val == true) {
        this.init();
      }
    },
  },
  computed: {
    dobRules() {
      let dob = this.form.DOB;
      if (dob) {
        if (getAge(dob) < 18) return "age-18";
      }
      return "";
    },
    isSubmit() {
      if (this.isUpdate) {
        return this.item.status == 1 && (this.isHospitalAdmin || this.isAdmin);
      }
      return this.isHospitalAdmin || this.isAdmin;
    },
    isFile() {
      let { photo } = this.form;
      return photo == null || photo instanceof File;
    },
    gender() {
      return GENDERS;
    },
    title() {
      return this.item.first_name ? "Update User" : "Add User";
    },
    contactTypes() {
      return this.masterContactTypes.filter((item) => {
        if (this.isTranstanPage) return item.contact_type == 5;
        return item.contact_type == 1 || item.contact_type == 2;
      });
    },
    isUser() {
      return this.$route.name == "user-management";
    },

    master() {
      return this.$store.getters.master;
    },
    isUpdate() {
      return this.item.hospital_contact_id;
    },
    isEdit() {
      let { USER, TRANSTAN_USER_LIST } = menuConstants;
      let id = this.isUser ? USER : TRANSTAN_USER_LIST;
      let hasCreateAccess = this.hasCreateAccess(id);
      if (!this.isUpdate) return hasCreateAccess;
      let hasAccess = this.hasEditAccess(id);
      return hasAccess && this.status != 4;
    },
    isDisabled() {
      return !this.isEdit;
    },
    isEmailDisabled() {
      return this.isUpdate != undefined;
    },
    isOrgan() {
      let user = this.contactTypes.find((item) => {
        return item.value == this.form.contact_id;
      });
      if (user == null) return false;
      return user.contact_type == 2;
    },
  },
  methods: {
    open(url) {
      openFile(url);
    },
    reset() {
      this.form = {
        first_name: "",
        last_name: "",
        gender: "",
        photo: null,
        email_id: "",
        contact_id: "",
        blood_id: "",
        country_code_1: "91",
        phone_number_1: "",
        country_code_2: "91",
        phone_number_2: "",
        individual_id: "",
        hospital_id: this.hospital_id,
        status: 1,
        DOB: "",
        hospital_contact_id: "",
        age: "",
        age_registered_date: getDateOnly(),
        created_by: this.created_by,
        qualification_details: [],
        registration_id: "",
        ALF_approver: "",
        contact_organ_mapping: [],
      };
    },
    async init() {
      const vm = this;
      let { hospital_id } = vm;
      vm.reset();
      if (vm.isUpdate) {
        userHelper.get(this);
      }
      if (vm.isUser) {
        try {
          vm.list = [];
          let doc = { hospital_id, dml_indicator: "UL" };
          let { data } = await licenseService.list(doc);
          let { status, msg, list } = data;
          if (!status) {
            vm.$alert.show(msg);
            return;
          }
          list = list.filter((item) => {
            return item.status == 2;
          });
          if (isArray(list)) {
            vm.organs = list.map((item) => {
              let { organ_license_id } = item;
              return {
                name: item.organ_name,
                value: item.organ_id.toString(),
                organ_license_id,
              };
            });
            console.log(list);
          }
        } catch (error) {
          vm.$alert.show(error.message);
        }
      }
    },
    async submit() {
      userHelper.createUser(this);
    },
  },
};
</script>

<style></style>
