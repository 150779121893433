<template>
  <b-container class="login">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5 mt-5">
        <b-card overflow-hidden>
          <b-card-title>
            <h2 class="text-center">Login</h2>
          </b-card-title>
          <b-card-body>
            <form-wrapper @submit="submit">
              <div class="d-none">
                <input type="password" tabindex="-1" />
              </div>
              <InputField
                type="email"
                rules="required|email"
                v-model="form.email_id"
                label="Email address"
                readonly
                @focus="onFocus"
                autocomplete="off"
                invalid="Enter the Email Address"
              >
              </InputField>
              <password-input
                label="Password"
                rules="required"
                type="password"
                v-model="form.password"
                invalid="Enter the Password"
                readonly
                @focus="onFocus"
                autocomplete="off"
              >
              </password-input>
              <div>
                <a
                  class="mb-1 text-decoration-underline"
                  @click="$router.push('/forgot-password')"
                >
                  Forgot Password?
                </a>
              </div>
              <b-button block class="mt-3" variant="primary" type="submit"
                >Login</b-button
              >
            </form-wrapper>
            <b-button
              block
              class="mt-3"
              variant="success"
              @click="$router.push('/commong-waiting-list')"
              >View Common Waiting List</b-button
            >

            <!-- <p class="subtitle-1 text-center mt-5">
              Don’t have an account yet?
              <a class="primary-text" @click="$router.push('/registration')">
                Click here to Sign Up
              </a>
            </p> -->
          </b-card-body>
        </b-card>
      </div>
    </div>
  </b-container>
</template>

<script>
import { USER_TYPES } from "../../assets/constants/hospital.contants";
import commonHelper from "../../assets/helper/common.helper";
import { encrypt } from "../../assets/js/common";
import userService from "../../assets/services/user.service";
import PasswordInput from "../../components/form/PasswordInput.vue";
export default {
  components: { PasswordInput },
  data() {
    return {
      form: {
        email_id: "",
        password: "",
      },
      token: "",
    };
  },
  computed: {
    types() {
      return USER_TYPES;
    },
  },
  methods: {
    onFocus(event) {
      console.log(event);
      event.target.removeAttribute("readonly");
    },
    async submit() {
      const vm = this;
      let doc = {};
      Object.keys(vm.form).forEach((key) => {
        doc[key] = vm.form[key];
      });
      doc.dml_indicator = "L";
      doc.password = encrypt(doc.password);
      doc.email_id = encrypt(doc.email_id);
      vm.$loader.show();
      try {
        let { data } = await userService.login(doc);
        vm.$loader.hide();
        let { status, msg } = data;
        if (!status) {
          vm.$loader.hide();
          if (msg == "No Record Founds") {
            msg = "The entered Email ID and Password is Invalid";
          } else {
            msg = msg[0].StrMsg;
          }
          vm.$alert.show(msg);
          return;
        }
        commonHelper.setAuth(vm, data);
        location.href = "/";
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
.login {
}
</style>
