export const ALF_STATUS = [{
    name: "Pending Transtan Review",
    value: "1"
}, {
    name: "Requested ALF Review",
    value: "2"
}, {
    name: "Approved",
    value: "3"
}, {
    name: "Deleted",
    value: "4"
}, {
    name: "Rejected",
    value: "5"
}]

export const ALF_REQUEST_STATUS = [{
    name: "Requested ALF Review",
    value: "0"
}, {
    name: "Approved",
    value: "1"
}, {
    name: "Rejected",
    value: "2"
}]
