export default {
  computed: {
    isUpdate() {
      let { name } = this.$route;
      return name == "update-hospital" || name == "update-transplant-license";
    },
    isAdmin() {
      return this.user.user_type == 2;
    },
  },
};
