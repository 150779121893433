<template>
  <ValidationProvider :rules="rules" :vid="vid" v-slot="validationContext">
    <b-form-group :invalid-feedback="message(validationContext)" class="mb-3">
      <template v-slot:label>
        <span v-html="formattedLabel"></span>
      </template>
      <b-form-input
        v-model="model"
        :type="type"
        @focus="$emit('focus', $event)"
        @keydown="onKeyDown"
        :disabled="disabled"
        v-bind="$attrs"
        :state="getValidationState(validationContext)"
        @input="$emit('input', model)"
      >
      </b-form-input>
    </b-form-group>
  </ValidationProvider>
</template>
<script>
import { BFormInput } from "bootstrap-vue";

export default {
  components: {
    BFormInput,
  },
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    vid: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
    },
    type: {
      type: String,
      default: "text",
    },
    invalid: {
      type: String,
      default: "",
    },
    value: {},
  },
  data() {
    return {
      model: "",
    };
  },
  watch: {
    value(val) {
      this.model = val;
    },
  },
  computed: {
    formattedLabel() {
      if(!this.rules) return this.label;
      if (this.rules.indexOf("required") != -1) return this.label + " <span style='color:red'>*</span>";
      return this.label;
    },
  },
  methods: {
    onKeyDown(e) {
      if (this.type == "number") {
        var invalidChars = ["-", "+", "e"];
        if (invalidChars.includes(e.key)) {
          e.preventDefault();
        }
      }
    },
    message(validationContext) {
      if (this.invalid) return this.invalid;
      return validationContext.errors[0];
    },
    getValidationState(context) {
      const { dirty, validated, valid = null } = context;
      return dirty || validated ? valid : null;
    },
  },
  mounted() {
    this.model = this.value;
  },
};
</script>
