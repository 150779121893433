<template>
  <expandable header="Evaluation Specific to Lung" class="mb-10">
    <b-row>
      <b-col cols="12" md="4">
        <select-field
          :items="causes"
          @input="onInput"
          label="Cause of Lung Disease"
          :disabled="isDisabled"
          v-model="form.cause_of_lung_disease"
          rules="required|max-255"
        ></select-field>
      </b-col>
      <b-col cols="12" md="4" v-if="form.cause_of_lung_disease == 5">
        <text-area
          label="Cause of Lung Disease - Description"
          :disabled="isDisabled"
          @input="onInput"
          v-model="form.cause_of_lung_disease_notes"
          rules="required"
        ></text-area>
      </b-col>

      <b-col cols="12" md="4">
        <yes-no
          label="6 Minute Walk Test - Able to Complete"
          type="number"
          :disabled="isDisabled"
          v-model="form.lung_walk_test_duration"
          @input="onInput"
          rules=""
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> Minutes</b-button>
          </template>
        </yes-no>
      </b-col>
      <b-col cols="12" md="4" v-if="form.lung_walk_test_duration == 1">
        <input-group
          label="6 minute Walk Test Distance"
          v-model="form.lung_walk_test_distance"
          :disabled="isDisabled"
          @input="onInput"
          type="number"
          rules=""
        >
          <template v-slot:help>
            <b-button variant="outline-info" class="help"> Meters</b-button>
          </template>
        </input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="4">
        <yes-no
          @input="onInput"
          :disabled="isDisabled"
          label="History of Previous Non-Transplant Heart & Lung Surgery"
          v-model="form.non_transplant_heart"
        ></yes-no>
      </b-col>
      <b-col cols="12" md="4" v-if="form.non_transplant_heart == 1">
        <text-area
          label="Surgery details"
          :disabled="isDisabled"
          @input="onInput"
          v-model="form.lung_surgery"
          rules="max-255"
        ></text-area>
      </b-col>
    </b-row>
    <div class="h5 py-2 px-2 section_subheader mt-3 mb-3">PFT</div>
    <b-row>
      <b-col cols="12" md="4">
        <file-input-group
          @input="onInput"
          :disabled="isDisabled"
          label="Forced Expiratory Volume in 1 second (FEV1)"
          v-model="form.FEV1"
          rules="file-image-pdf|file-size"
        />
      </b-col>
      <b-col cols="12" md="4">
        <file-input-group
          label="Forced Vital Capacity (FVC)"
          :disabled="isDisabled"
          @input="onInput"
          v-model="form.FVC"
          rules="file-image-pdf|file-size"
        />
      </b-col>
      <b-col cols="12" md="4">
        <file-input-group
          label="Maximal Voluntary Ventilation(MVV)"
          :disabled="isDisabled"
          @input="onInput"
          v-model="form.MVV"
          rules="file-image-pdf|file-size"
        />
      </b-col>
      <b-col cols="12" md="4">
        <file-input-group
          label="DLCO"
          @input="onInput"
          :disabled="isDisabled"
          v-model="form.DLCO"
          rules="file-image-pdf|file-size"
        />
      </b-col>
    </b-row>
    <b-row>
      <div class="h5 py-2 px-2 section_subheader mt-3 mb-3">
        Ventilatory Status
      </div>
      <b-col cols="12" md="4">
        <yes-no
          label="Self on Room air"
          v-model="form.self_on_RA"
          :disabled="isDisabled"
          rules="required"
          @input="onInput"
        ></yes-no>
      </b-col>
      <b-col cols="12" md="4">
        <yes-no
          label="Supplement O2"
          v-model="form.supplement_O2"
          :disabled="isDisabled"
          rules="required"
          @input="onInput"
        ></yes-no>
      </b-col>
      <b-col cols="12" md="4">
        <yes-no
          label="Non-invasive ventilation (NIV)"
          v-model="form.NIV"
          rules="required"
          :disabled="isDisabled"
          @input="onInput"
        ></yes-no>
      </b-col>
      <b-col cols="12" md="4">
        <yes-no
          label="Mechanical Ventilation"
          v-model="form.mechanical_ventilation"
          :disabled="isDisabled"
          rules="required"
          @input="onInput"
        ></yes-no>
      </b-col>
      <b-col cols="12" md="4">
        <yes-no
          label="ECMO"
          @input="onInput"
          :disabled="isDisabled"
          v-model="form.ECMO"
          rules="required"
        ></yes-no>
      </b-col>
    </b-row>
    <b-row>
      <div class="h5 py-2 px-2 section_subheader mt-3 mb-3">
        Arterial Blood Gas
      </div>
      <b-col cols="12" md="4">
        <input-field
          label="Room air"
          :disabled="isDisabled"
          v-model="form.room_AIR"
          @input="onInput"
          rules=""
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <file-input-group
          @input="onInput"
          :disabled="isDisabled"
          label="Room air file"
          accept=".jpg,.jpeg,.png,.pdf"
          v-model="form.room_air_file_name"
          rules="file-image-pdf|file-size"
        ></file-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="4">
        <input-field
          label="On Oxygen"
          :disabled="isDisabled"
          @input="onInput"
          v-model="form.on_oxygen"
          rules=""
        ></input-field>
      </b-col>
      <b-col cols="12" md="4">
        <file-input-group
          @input="onInput"
          label="On Oxygen File"
          accept=".jpg,.jpeg,.png,.pdf"
          :disabled="isDisabled"
          v-model="form.on_oxygen_file_name"
          rules="file-image-pdf|file-size"
        ></file-input-group>
      </b-col>
    </b-row>
  </expandable>
</template>

<script>
import { CAUSE_OF_LUNG_DISEASE } from "../../../assets/constants/recipient.constants";
import { openFile } from "../../../assets/js/common";
import Expandable from "../../Expandable.vue";
import FileInputGroup from "../../form/FileInputGroup.vue";
import TextArea from "../../form/TextArea.vue";
import YesNo from "../../form/YesNo.vue";
export default {
  props: {
    value: {},
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { Expandable, YesNo, FileInputGroup, TextArea },
  data() {
    return {
      form: {},
      causes: CAUSE_OF_LUNG_DISEASE,
    };
  },
  methods: {
    onInput() {
      this.$emit("input", this.form);
    },
    isFile(file) {
      return file == null || file == "" || file instanceof File;
    },
    open(file) {
      openFile(file);
    },
  },
  beforeMount() {
    if (this.value != undefined) this.form = this.value;
  },
};
</script>

<style></style>
