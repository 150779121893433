<template>
  <ValidationProvider :rules="rules" v-slot="validationContext">
    <b-form-group>
      <template v-slot:label>
        <span v-html="formattedLabel"></span>
      </template>
      <div class="d-flex">
        <b-input-group
          :invalid-feedback="message(validationContext)"
          class="mb-3"
        >
          <b-form-select
            class="form-select flex-grow-1"
            v-model="model"
            :type="type"
            :multiple="multiple ? 'multiple' : null"
            :id="id"
            v-show="false"
            :disabled="disabled"
            :options="items"
            :text-field="textField"
            :value-field="valueField"
            :state="getValidationState(validationContext)"
            @input="$emit('input', model)"
          ></b-form-select>

          <b-form-invalid-feedback>
            {{ message(validationContext) }}
          </b-form-invalid-feedback>
        </b-input-group>
        <div>
          <slot name="help"></slot>
        </div>
      </div>
    </b-form-group>
  </ValidationProvider>
</template>
<script>
export default {
  components: {},
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: -1,
    },
    textField: {
      type: String,
      default: "name",
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    valueField: {
      type: String,
      default: "value",
    },
    vid: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
    },
    type: {
      type: String,
      default: "text",
    },
    invalid: {
      type: String,
      default: "This field is required",
    },
    value: {},
  },
  data() {
    return {
      model: this.multiple ? [] : "",
      id: new Date().getTime().toString(),
    };
  },
  computed: {
    formattedLabel() {
      if(!this.rules) return this.label;
      if (this.rules.indexOf("required") != -1)
        return this.label + " <span style='color:red'>*</span>";
      return this.label;
    },
  },
  watch: {
    value() {
      this.init();
    },
  },
  methods: {
    message(validationContext) {
      return validationContext.errors[0];
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    init() {
      const vm = this;
      let select2 = window.$("#" + vm.id);
      if (vm.value instanceof Array && vm.value.length != 0) {
        vm.model = vm.value;
      }
      if (typeof vm.value == "string") vm.model = vm.value;
      if (vm.multiple && typeof vm.value == "string") vm.model = [];
      let doc = {
        theme: "bootstrap-5",
        allowClear: vm.multiple ? false : true,
        width: "100%",
        placeholder: "Select Option",
      };
      if (vm.max != -1) {
        doc.maximumSelectionLength = vm.max;
      }
      select2.select2(doc).val(vm.model).trigger("change");
      select2.on("change.select2", function () {
        vm.model = select2.val();
        vm.$emit("input", vm.model);
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss">
.select2-selection--multiple {
  padding-left: 14px;
  padding-right: 14px;
  .select2-search {
    textarea {
      width: 100% !important;
      outline: none !important;
      height: 100% !important;
    }
  }
}
</style>
