<template>
  <div>
    <table-view
      title="ALF List"
      class="mt-4"
      :fields="fields"
      :isBusy="isLoading"
      :items="filterList"
    >
      <template v-slot:filter>
        <filter-select
          :items="statusList"
          class="ms-2"
          v-model="status"
          placeholder="All Status"
        ></filter-select>
      </template>
      <template v-slot:name="{ item }">
        {{ item.first_name }} {{ item.last_name }}
      </template>
      <template v-slot:status="{ item }">
        {{ getStatus(item) }}
      </template>
      <template v-slot:action="{ item }">
        <div class="d-flex">
          <alf-request-list
            v-if="item.status != 1"
            :status="item.status"
            @fetch="$emit('fetch')"
            :id="item.alf_id"
          ></alf-request-list>
          <view-button v-if="isView(item)" @click="open(item)"></view-button>
          <edit-button
            v-else-if="isEdit(item)"
            @click="open(item)"
          ></edit-button>
          <delete-button
            v-if="isDeleteAble(item)"
            @click="remove(item)"
          ></delete-button>
          <icon-button
            variant="primary"
            v-if="isALFReviewable(item)"
            @click="requestALFReview(item)"
            icon="arrow-up-right-circle"
          ></icon-button>
        </div>
      </template>
    </table-view>
  </div>
</template>

<script>
import { encode } from "../../assets/js/common";
import { ALF_STATUS } from "../../assets/constants/alf.constants";
import DeleteButton from "../buttons/DeleteButton.vue";
import EditButton from "../buttons/EditButton.vue";
import TableView from "../table/TableView.vue";
import IconButton from "../buttons/IconButton.vue";
import alfService from "../../assets/services/alf.service";
import AlfRequestList from "./AlfRequestList.vue";
import alfHelper from "./alf.helper";
import menuConstants from "../../assets/constants/menu.constants";
import ViewButton from "../buttons/ViewButton.vue";
import FilterSelect from "../form/FilterSelect.vue";
export default {
  components: {
    TableView,
    EditButton,
    DeleteButton,
    IconButton,
    AlfRequestList,
    ViewButton,
    FilterSelect,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    list: Array,
  },
  data() {
    return {
      isDraftLoading: false,
      status: null,
      statusList: ALF_STATUS,
    };
  },
  computed: {
    fields() {
      let doc = {
        sno: "S.No",
        alf_id: "ALF ID",
        recipient_registration_id: "UID",
        first_name: "Patient Name",
        hospital_name: "Hospital Name",
        created_on: "Listing Date",
        alf_category: "Category",
        status: "Status",
        pending_approval_count: "Pending ALF Approve",
        action: "Action",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
          tdClass: key == "action" ? "width-100" : "",
        });
      });
      return list;
    },
    filterList() {
      return this.list.filter((item) => {
        if (this.status) return item.status == this.status;
        return true;
      });
    },
  },
  methods: {
    isDeleteAble({ status }) {
      let hasAccess = this.hasStatusAccess(menuConstants.LIVER_URGENT_LIST, 4);
      return hasAccess && status == 1;
    },
    isALFReviewable({ status }) {
      let hasAccess = this.hasStatusAccess(menuConstants.LIVER_URGENT_LIST, 2);
      return hasAccess && status == 1 && this.isAdmin;
    },
    isEdit({ pending_approval_count }) {
      if (this.isAdmins) return true;
      return pending_approval_count != 0;
    },
    isView({ status }) {
      let hasAccess = this.hasEditAccess(menuConstants.LIVER_URGENT_LIST);
      return !hasAccess || status == 4;
    },
    open({ alf_id }) {
      if (!alf_id) alf_id = 1008;
      let id = encode(alf_id);
      this.$router.push(`/update-alf?id=${id}`);
    },
    getStatus({ status }) {
      let item = ALF_STATUS.find((item) => {
        return item.value == status;
      });
      return item != null ? item.name : "";
    },
    remove({ alf_id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, You want to remove this ALF",
        onConfirm: () => {
          alfHelper.updateStatus(4, alf_id, vm);
        },
      });
    },

    requestALFReview({ alf_id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, You want request alf review?",
        onConfirm: async () => {
          try {
            vm.$loader.show();
            let doc = {
              alf_id,
              created_by: vm.created_by,
              dml_indicator: "I",
            };
            let { data } = await alfService.requestALFReview(doc);
            let { status, msg } = data;
            vm.$loader.hide();
            if (!status) {
              vm.$alert.show(msg);
              return;
            }
            vm.$emit("fetch");
          } catch (error) {
            vm.$alert.show(error.message);
            vm.$loader.hide();
          }
        },
      });
    },
  },
};
</script>

<style></style>
