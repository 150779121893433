<template>
  <form-wrapper @submit="submit" v-if="!isLoading">
    <donor-details
      :isDisabled="isDisabled"
      v-model="donor_details"
    ></donor-details>
    <recipient-details
      :isDisabled="isDisabled"
      v-model="recipient_details"
    ></recipient-details>
    <surgery-details
      :isDisabled="isDisabled"
      v-model="surgery_details"
    ></surgery-details>
    <Footer :status="donor_details.status"></Footer>
  </form-wrapper>
</template>

<script>
import { decode } from "../../assets/js/common";
import LiveTransplantMixins from "../../assets/mixins/live.transplant.mixins";
import DonorDetails from "../../components/waitingList/livetrnsplant/create/DonorDetails.vue";
import RecipientDetails from "../../components/waitingList/livetrnsplant/create/RecipientDetails.vue";
import SurgeryDetails from "../../components/waitingList/livetrnsplant/create/SurgeryDetails.vue";
import Footer from "../../components/waitingList/livetrnsplant/create/Footer.vue";
import LiveTransplantHelper from "../../components/waitingList/livetrnsplant/live.transplant.helper";
import liveTransplantService from "../../assets/services/live.transplant.service";
import menuConstants from "../../assets/constants/menu.constants";
export default {
  components: { DonorDetails, RecipientDetails, SurgeryDetails, Footer },
  data() {
    return {
      isLoading: true,
      donor_details: {
        live_transplant_detail_id: "",
        donor_name: "",
        donor_phone_number: "",
        donor_email_id: "",
        donor_age: "",
        donor_sex: "",
        donor_address: "",
        donor_blood_group: "",
        relationship: "",
        others: "",
        hospital_id: "",
        status: 1,
        created_by: "",
        dml_indicator: "I",
      },
      recipient_details: {
        recipient_name: "",
        recipient_phone_number: "",
        recipient_email_id: "",
        recipient_age: "",
        recipient_sex: "",
        recipient_address: "",
        recipient_blood_group: "",
      },
      surgery_details: {
        organ_id: "",
        date_of_surgery: "",
        organ_transplanted: "",
        time_of_surgery: "",
        status_after_surgery: "",
        remarks: "",
        ischaemia_warm: "",
        ischaemia_cold: "",
        end_date: "",
        end_time: "",
      },
      updateDoc: {},
    };
  },
  mixins: [LiveTransplantMixins],
  computed: {
    isAadharNotPermanent() {
      return this.aadhar_address.is_permanent_address == "0";
    },
    isDisabled() {
      let hasAccess = this.hasEditAccess(menuConstants.LIVE_TRANSPLANT);
      return !hasAccess || this.donor_details.status == 2;
    },
  },
  methods: {
    async submit() {
      const vm = this;
      let doc = LiveTransplantHelper.getDoc(vm);
      try {
        vm.$loader.show();
        let { data } = await liveTransplantService.create(doc);
        let { msg, status } = data;
        if (status == false) {
          vm.$alert.show(msg);
          vm.$loader.hide();
          return;
        }
        if (vm.isUpdate) {
          vm.$alert.show("Live Transplant updated successfully");
        } else {
          vm.$alert.show("Live Transplant created successfully");
        }
        vm.$router.push("/live-transplant");
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    init() {
      const vm = this;
      let { created_by } = vm;
      vm.donor_details.created_by = created_by;
      if (vm.isCreate) {
        vm.donor_details.hospital_id = decode(vm.$route.query.hospital_id);
      }
      console.log("isUpdate", vm.isUpdate);
      if (vm.isUpdate) {
        LiveTransplantHelper.get(vm);
      } else {
        vm.isLoading = false;
      }
    },
  },
  beforeMount() {
    this.init();
  },
  mounted() {},
};
</script>

<style></style>
