<template>
  <div>
    <table-view
      title="Generate Wait list"
      :fields="fields"
      :isBusy="isLoading"
      :items="list"
    >
      <template v-slot:recipient_registration_id="{ item, value }">
        <internal-recipient-icon
          :item="item"
          :value="value"
        ></internal-recipient-icon>
      </template>
      <template v-slot:payment_status="{ value }">
        {{ getPaymentStatus(value) }}
      </template>
      <template v-slot:transtan_status="{ value }">
        {{ getPatientStatus(value) }}
      </template>
      <template v-slot:CM_insurance="{ value }">
        {{ getCMInsuranceStatus(value) }}
      </template>
      <template v-slot:add>
        <template v-if="isAdmins">
          <CheckALF :item="item"></CheckALF>
          <b-button variant="primary" @click="dialog = true"
            >Generate List</b-button
          >
        </template>
        <b-modal
          v-model="dialog"
          centered
          size="lg"
          ignore-enforce-focus-selector=".select2-search__field"
          no-close-on-backdrop
          hide-footer
          title="Recipient Filter"
        >
          <form-wrapper @submit="submit">
            <b-row>
              <b-col cols="12" md="4">
                <select-field
                  v-model.trim="form.zone_id"
                  label="Zone"
                  :items="masterZones"
                  rules="required"
                ></select-field>
              </b-col>
              <b-col cols="12" md="4" v-if="false">
                <select-field
                  label="CM Insurance"
                  :items="masterCMInsurance"
                  v-model="form.CM_insurance"
                  rules="required"
                ></select-field>
              </b-col>
              <b-col cols="12" md="4">
                <autocomplete
                  label="Blood Group"
                  :items="filterBloodGroups"
                  rules="required"
                  :multiple="true"
                  v-model="blood_group"
                ></autocomplete>
              </b-col>
            </b-row>
            <div class="d-flex">
              <div class="flex-grow-1"></div>
              <b-button
                variant="outline-danger mx-3"
                outlined
                @click="dialog = false"
              >
                Close
              </b-button>
              <b-button type="submit" variant="success"> Submit </b-button>
            </div>
          </form-wrapper>
        </b-modal>
      </template>
      <div class="d-flex mt-3" v-if="isAdmins">
        <div class="flex-grow-1"></div>
        <b-button
          variant="outline-danger mx-3"
          outlined
          v-if="type == 'popup'"
          @click="$emit('close')"
        >
          Close
        </b-button>

        <b-button variant="success" v-if="list.length" @click="generate"
          >Confirm</b-button
        >
      </div>
    </table-view>
  </div>
</template>

<script>
import {
  BE_READY_STATUS,
  PATIENT_STATUS,
  PAYMENT_STATUS,
} from "../../../../assets/constants/recipient.constants";
import { BLOOD_GROUP_VALUES, formatDate } from "../../../../assets/js/common";
import event from "../../../../assets/js/event";
import donorMixins from "../../../../assets/mixins/donor.mixins";
import donorService from "../../../../assets/services/donor.service";
import InternalRecipientIcon from "../../../managerecipient/InternalRecipientIcon.vue";
import TableView from "../../../table/TableView.vue";
import CheckALF from "./CheckALF.vue";
export default {
  components: { TableView, CheckALF, InternalRecipientIcon },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    hospitals: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "window",
    },
  },
  mixins: [donorMixins],
  data() {
    return {
      isLoading: false,
      dialog: false,
      list: [],
      form: {
        CM_insurance: "",
        zone_id: "",
      },
      blood_group: [],

      paymentStatus: PAYMENT_STATUS,
      patientStatus: PATIENT_STATUS,
      beReadyStatus: BE_READY_STATUS,
    };
  },
  computed: {
    isInhouse() {
      return this.item.type_of_allocation == 1;
    },
    fields() {
      let doc = {
        recipient_registration_id: "UID",
        zone_rank_id: "Zonal Rank",
        created_on: "Date of Registration",
        // first_name: "Name",
        age: "Age",
        gender: "Gender",
        height: "Height",
        weight: "Weight",
        group_desc: "Blood Group",
        hospital_name: "Hospital Name",
        organ_name: "Organs Requested",
        // payment_status: "Payment Status",
        // transtan_status: "Patient Status",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
        });
      });
      return list;
    },
  },
  methods: {
    async generate() {
      const vm = this;
      if (vm.list.length == 0) return;
      try {
        vm.$loader.show();
        let list = [];
        let { final_donor_id, id } = vm;
        let {
          organ_allocation_id,
          donor,
          organ_id,
          type_of_allocation,
          allocation_status,
        } = vm.item;
        let { case_officer } = donor;

        vm.list.forEach((item) => {
          let { recipient_registrationid, hospital_id } = item;
          let doc = {
            final_donor_id,
            organ_allocation_id,
            donor_temp_registration_id: id,
            case_officer,
            hospital_id,
            organ_id,
            status: 0,
            recepient_registration_id: recipient_registrationid,
            created_by: vm.created_by,
            updated_by: vm.created_by,
            type_of_allocation,
            allocation_status,
            reject_reason: "",
            dml_indicator: "I",
          };
          list.push(doc);
        });
        let { data } = await donorService.generateWL({
          recepient_organ_allocation_proc: list,
        });
        let { status, msg } = data;
        vm.isLoading = false;
        vm.$loader.hide();
        event.$emit("refresh");
        vm.$emit("close");
        if (!status) {
          vm.$alert.show(msg);
        }
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    isDelete(item) {
      return item.transtan_status == 4 || item.transtan_status == 1;
    },
    isApproved(item) {
      return item.transtan_status == 4;
    },
    async submit() {
      const vm = this;
      vm.dialog = false;
      const doc = {
        dml_indicator: "S",
      };
      let { allocated_organ } = vm.item;
      Object.keys(vm.form).forEach((key) => {
        let value = vm.form[key];
        if (value == "all") value = "";
        if (value) {
          doc[key] = value;
        } else {
          doc[key] = "";
        }
      });
      doc.blood_group = [];
      vm.blood_group.forEach((id) => {
        BLOOD_GROUP_VALUES[id].forEach((blood_group) => {
          doc.blood_group.push({ blood_group });
        });
      });

      doc.organ = allocated_organ.split(",").map((organ_id) => {
        return { organ_id };
      });
      doc.filter_type = 1;
      doc.multi_organ = allocated_organ;
      try {
        vm.isLoading = true;
        vm.list = [];
        let { data } = await donorService.getRota(doc);
        let { status, msg, list } = data;
        vm.isLoading = false;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.list = list.map((item) => {
          item.gender = vm.getGender(item.gender);
          item.created_on = formatDate(item.created_on);
          return item;
        });
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
