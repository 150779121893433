<template>
  <div>
    <ValidationProvider
      v-if="isFile"
      :rules="rules"
      :vid="vid"
      v-slot="validationContext"
    >
      <b-form-group :invalid-feedback="message(validationContext)" class="mb-3">
        <template v-slot:label>
          <span v-html="formattedLabel"></span>
        </template>
        <b-form-file
          v-model="model"
          :disabled="disabled"
          :accept="accept"
          :state="getValidationState(validationContext)"
          @input="$emit('input', model)"
        >
        </b-form-file>
      </b-form-group>
    </ValidationProvider>
    <template v-else>
      <label> {{ label }}</label>
      <br />
      <b-button @click="open" variant="outline-primary" size="sm">
        View File
      </b-button>
      <b-button
        :disabled="disabled"
        @click="changeFile"
        class="ms-2"
        variant="danger"
        size="sm"
      >
        Change File
      </b-button>
    </template>
  </div>
</template>
<script>
import { openFile } from "../../assets/js/common";
export default {
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    vid: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    accept: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
    },
    type: {
      type: String,
      default: "text",
    },
    invalid: {
      type: String,
      default: "This field is required",
    },
    value: {},
  },
  data() {
    return {
      model: null,
    };
  },
  computed: {
    formattedLabel() {
      if(!this.label) return ""
      if(!this.rules) return this.label;
      if (this.rules.indexOf("required") != -1)
        return this.label + " <span style='color:red'>*</span>";
      return this.label;
    },
    isFile() {
      return !this.model || this.model instanceof File;
    },
  },
  watch: {
    value() {
      if (this.value == "") {
        this.model = null;
      } else {
        this.model = this.value;
      }
    },
  },
  methods: {
    open() {
      openFile(this.model);
    },
    changeFile() {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want to change this file?",
        onConfirm: () => {
          this.model = null;
          this.$emit("input", null);
        },
      });
    },
    message(validationContext) {
      return validationContext.errors[0];
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
  mounted() {
    if (this.value == "") {
      this.model = null;
    } else {
      this.model = this.value;
    }
  },
};
</script>
