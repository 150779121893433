<template>
  <div>
    <approve-button v-if="status == 1" @click="dialog = true"></approve-button>
    <reject-button v-else @click="dialog = true"></reject-button>
    <b-modal
      tabindex="0"
      v-model="dialog"
      :title="title"
      hide-footer
      ignore-enforce-focus-selector=".select2-search__field"
      size="sm"
      centered
    >
      <form-wrapper @submit="submit">
        <b-row class="pb-0">
          <b-col cols="12">
            <text-area
              label="Comment"
              rules="required"
              v-model="comment"
            ></text-area>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger mx-3" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" type="submit">Submit </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import alfService from "../../assets/services/alf.service";
import ApproveButton from "../buttons/ApproveButton.vue";
import RejectButton from "../buttons/RejectButton.vue";
import TextArea from "../form/TextArea.vue";
export default {
  components: { ApproveButton, TextArea, RejectButton },
  props: {
    status: {
      type: Number,
      default: 1,
    },
    id: {
      type: Number,
    },
  },
  data() {
    return {
      dialog: false,
      comment: "",
    };
  },
  computed: {
    title() {
      return this.status == 1 ? "ALF Approval" : "ALF Reject";
    },
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.$loader.show();
        let doc = {
          alf_approval_status_id: vm.id,
          approval_status: vm.status,
          comments: vm.comment,
          dml_indicator: "U",
        };
        let { data } = await alfService.requestALFReview(doc);
        let { status, msg } = data;
        if (!status) {
          vm.$alert.show(msg);
        }
        vm.dialog = false;
        vm.$loader.hide();
        vm.$emit("fetch");
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
