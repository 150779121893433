<template>
  <div>
    <table-view
      v-for="(item, i) in tables"
      class="mt-4"
      :title="item.title"
      :key="i"
      :fields="fields(i)"
      :isBusy="isLoading"
      :items="item.list"
    >
      <template v-slot:approval_status="{ item }">
        {{ getStatus(item) }}
      </template>
      <template v-slot:action="{ item }">
        <div class="d-flex">
          <template v-if="item.approval_status == 0">
            <ApproveALF
              :id="item.alf_approval_status_id"
              :status="1"
              @fetch="$emit('fetch')"
            ></ApproveALF>
            <ApproveALF
              @fetch="$emit('fetch')"
              :id="item.alf_approval_status_id"
              :status="2"
            ></ApproveALF>
          </template>
          <view-button @click="open(item)"></view-button>
        </div>
      </template>
    </table-view>
  </div>
</template>

<script>
import { ALF_REQUEST_STATUS } from "../../assets/constants/alf.constants";
import { encode } from "../../assets/js/common";
import ViewButton from "../buttons/ViewButton.vue";
import TableView from "../table/TableView.vue";
import ApproveALF from "./ApproveALF.vue";
export default {
  components: {
    TableView,
    ApproveALF,
    ViewButton,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    list: Array,
    history: Array,
  },
  data() {
    return {
      isDraftLoading: false,
    };
  },
  computed: {
    tables() {
      return [
        {
          title: "Pending List",
          list: this.list,
        },
        {
          title: "History",
          list: this.history,
        },
      ];
    },
  },
  methods: {
    fields(i) {
      let doc = {
        sno: "S.No",
        alf_id: "ALF ID",
        recipient_registration_id: "UID",
        first_name: "Patient Name",
        hospital_name: "Hospital Name",
        created_on: "Listing Date",
        alf_name: "Category",
        comments: "Comment",
        alf_approval_name: "Approver Name",
        approval_status: "Status",
        action: "Action",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
          tdClass: key == "action" ? "width-100" : "",
        });
      });
      if (i == 0) {
        list = list.filter((item) => {
          return item.key != "comments";
        });
      }
      return list;
    },
    open({ alf_id }) {
      if (!alf_id) alf_id = 1008;
      let id = encode(alf_id);
      this.$router.push(`/update-alf?id=${id}&view=true`);
    },

    getStatus({ approval_status }) {
      let item = ALF_REQUEST_STATUS.find((item) => {
        return item.value == approval_status;
      });
      console.log;
      return item != null ? item.name : "";
    },
  },
};
</script>

<style></style>
