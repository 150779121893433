<template>
  <div>
    <table-view
      title="Confirmed Donor List"
      class="mt-4"
      :fields="fields"
      :isBusy="isLoading"
      :items="list"
    >
      <template v-slot:name="{ item }">
        {{ item.first_name }} {{ item.last_name }}
      </template>
      <template v-slot:status="{ item }">
        {{ getStatus(item) }}
      </template>
      <template v-slot:action="{ item }">
        <div class="d-flex" v-if="isAccessAble(item)">
          <final-donor-list-menu
            :item="item"
            @fetch="$emit('fetch')"
            @changeStatus="changeStatus"
          ></final-donor-list-menu>
          <a :href="getAllocationLink(item)">
            <b-button class="mt-2" size="sm" icon variant="link" @click="$emit('click')">
              <b-icon
                variant="secondary"
                icon="card-list"
                class="font-size-16"
              ></b-icon>
            </b-button>
          </a>
        </div>
        <a :href="getAllocationLink(item)" v-else>
          <view-button></view-button>
        </a>
      </template>
    </table-view>
  </div>
</template>

<script>
import { encode } from "../../assets/js/common";
import { FINAL_DONOR_STATUS } from "../../assets/constants/donor.constants";
import TableView from "../table/TableView.vue";
import donorService from "../../assets/services/donor.service";
import FinalDonorListMenu from "./FinalDonorListMenu.vue";
import ViewButton from "../buttons/ViewButton.vue";
import menuConstants from "../../assets/constants/menu.constants";
export default {
  components: {
    TableView,
    FinalDonorListMenu,
    ViewButton,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    list: Array,
  },
  data() {
    return {
      isDraftLoading: false,
    };
  },
  computed: {
    fields() {
      let doc = {
        sno: "S.No",
        donor_temp_registration_id: "Donor ID",
        donor_name: "Donor Name",
        group_desc: "Blood Group",
        hospital_name: "Hospital Name",
        organ_consented: "Organ Consented",
        phone_number_1: "Phone Number",
        status: "Status",
        caseofficer: "Case Officer",
        action: "Action",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
          tdClass: key == "action" ? "width-100" : "",
        });
      });
      return list;
    },
  },
  methods: {
    isAccessAble(item) {
      let { isHospitalContact } = this;
      let { hospital_id } = this.user;
      if (!isHospitalContact) return true;
      return isHospitalContact && hospital_id == item.hospital_id;
    },
    isTerminateAble({ status }) {
      let hasAccess = this.hasStatusAccess(menuConstants.FINAl_DONOR, 7);
      return hasAccess && status != 6 && status != 5;
    },
    openAllocation({ donor_temp_registration_id, final_donor_id }) {
      let id = encode(donor_temp_registration_id);
      final_donor_id = encode(final_donor_id);
      this.$router.push(
        `/organ-allocation?id=${id}&final_donor_id=${final_donor_id}`
      );
    },
    getAllocationLink({ donor_temp_registration_id, final_donor_id }) {
      let id = encode(donor_temp_registration_id);
      final_donor_id = encode(final_donor_id);
      return this.$router.resolve({
        path: `/organ-allocation?id=${id}&final_donor_id=${final_donor_id}`,
      }).href;
    },
    open({ donor_temp_registration_id }) {
      let id = encode(donor_temp_registration_id);
      this.$router.push(`/update-donor?id=${id}`);
    },
    getStatus({ final_donor_status }) {
      let item = FINAL_DONOR_STATUS.find((item) => {
        return item.value == final_donor_status;
      });
      return item != null ? item.name : "";
    },
    remove({ donor_temp_registration_id, status }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do want to remove this donor?",
        onConfirm: () => {
          vm.changeStatus({
            donor_temp_registration_id,
            status: status == 1 ? 9 : 2,
            dml_indicator: "A",
          });
        },
      });
    },
    async changeStatus(doc) {
      const vm = this;
      try {
        vm.$loader.show();
        let { data } = await donorService.completeDraft(doc);
        let { status, msg } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.$emit("fetch");
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
