<template>
  <div id="preloader" v-if="loader">
    <div id="status">
      <div class="spinner-chase">
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
      </div>
    </div>
  </div>
</template>

<script>
import LoaderModal from "./index";
export default {
  data() {
    return {
      loader: false,
    };
  },
  beforeMount() {
    LoaderModal.EventBus.$on("show", () => {
      this.loader = true;
    });
    LoaderModal.EventBus.$on("hide", () => {
      this.loader = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  height: 70px;
}
.v-card__text {
  padding-top: 17px !important;
}
</style>
