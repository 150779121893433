<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box px-0">
          <div @click="$router.push('/')" class="logo logo-light">
            <div class="logo-sm">
              <img src="@/assets/logo_mini.svg" alt />
            </div>
            <div class="logo-lg">
              <img src="@/assets/logo.png" alt />
            </div>
          </div>
        </div>

        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>
        <div class="d-flex align-items-center">
          <h3 class="mb-0">{{ title }}</h3>
        </div>
      </div>
      <div class="d-flex">
        <b-dropdown
          right
          variant="black"
          toggle-class="header-item"
          menu-class="dropdown-menu-end"
        >
          <template v-slot:button-content>
            <div class="avatar-sm">
              <b-avatar
                variant="primary"
                class="avatar-title bg-info bg-soft text-primary font-size-16"
                :text="avater"
              ></b-avatar>
            </div>
          </template>
          <b-dropdown-item>
            <b-icon icon="person-fill" scale="1.2"></b-icon>
            {{ name }}
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link tag="span" to="/change-password">
              <b-icon icon="shield-lock-fill" scale="1.2"></b-icon>
              Change Password
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item @click="logout">
            <b-icon icon="power" scale="1"></b-icon>
            Logout
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>
<script>
import headers from "../../assets/constants/header.constants";
export default {
  data() {
    return {};
  },
  computed: {
    title() {
      let { name } = this.$route;
      let title = "";
      if (headers[name]) title = headers[name];
      if (title == "Manage Hospitals" && this.isHospitalContact)
        return "Manage Hospital";
      return title;
    },
    isAuth() {
      return this.$store.getters.isAuth;
    },
    name() {
      let { first_name, last_name } = this.user;
      if (!last_name) last_name = "";
      return `${first_name} ${last_name}`;
    },
    avater() {
      let { first_name } = this.user;
      if (first_name) return first_name.substring(0, 2);
      return "";
    },
  },
  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    logout() {
      const vm = this;
      vm.$cookies.remove("session");
      localStorage.removeItem("token");
      vm.$store.commit("SET_AUTH");
      vm.$router.replace("/login");
    },
  },
};
</script>
