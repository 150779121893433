<template>
  <div>
    <Header :hospitals="hospitals"></Header>
    <pending-recipient-filter
      v-model="filter"
      @hospitals="hospitals = $event"
      @fetch="fetch"
    ></pending-recipient-filter>
    <RecipientList :isLoading="isLoading" :list="list" @fetch="fetch"></RecipientList>
  </div>
</template>

<script>
import { formatDate } from "../../assets/js/common";
import recipientService from "../../assets/services/recipient.service";
import Header from "../../components/managerecipient/Header.vue";
import RecipientList from "../../components/managerecipient/RecipientList.vue";
import PendingRecipientFilter from "../../components/managerecipient/PendingRecipientFilter.vue";
export default {
  components: { RecipientList, Header, PendingRecipientFilter },
  data() {
    return {
      list: [],
      filter: {},
      hospitals: [],
      isLoading: false,
    };
  },
  methods: {
    async fetch() {
      const vm = this;
      try {
        vm.isLoading = true;
        vm.list = [];
        let { data } = await recipientService.getPendingList(vm.filter);
        let { status, msg, list } = data;
        vm.isLoading = false;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.list = list.map((item) => {
          item.date = formatDate(item.created_on);
          return item;
        });
      } catch (error) {
        vm.$alert.show(error.message);
        vm.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
