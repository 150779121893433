<template>
  <div class="d-flex">
    <div class="flex-grow-1"></div>
    <b-button variant="outline-danger mx-3" @click="$router.push('/manage-alf')"
      >Cancel
    </b-button>
    <b-button variant="success" v-if="isSubmit" type="submit">Submit </b-button>
  </div>
</template>

<script>
import menuConstants from "../../../assets/constants/menu.constants";
export default {
  props: {
    status: [Number, String],
  },
  computed: {
    isCreate() {
      return this.$route.name == "create-alf";
    },
    isSubmit() {
      let hasCreateAccess = this.hasCreateAccess(menuConstants.ACUTE_LIVER_FAILURE);
      if (this.isCreate) return hasCreateAccess;
      let hasAccess = this.hasEditAccess(menuConstants.ACUTE_LIVER_FAILURE);
      return hasAccess && this.status != 4;
    },
  },
};
</script>

<style></style>
