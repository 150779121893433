<template>
  <b-container class="pa-10" v-if="!isLoading">
    <form-wrapper @submit="submit">
      <Hospital
        v-model="hospital"
        :status="status"
        :isDisabled="isDisabled"
      ></Hospital>
      <business-address
        v-model="businessAddress"
        :isDisabled="isDisabled"
      ></business-address>
      <Details v-model="details" :isDisabled="isDisabled"></Details>
      <registration-address
        v-model="registrationAddress"
        :business="businessAddress"
        :isDisabled="isDisabled"
      ></registration-address>
      <license-details
        v-model="licenseDetails"
        :isDisabled="isDisabled"
      ></license-details>
      <attachments
        v-model="files"
        :hospital="hospital"
        :filesList="filesList"
        :isDisabled="isDisabled"
        ref="attachments"
        @onFileRemoved="onFileRemoved"
      ></attachments>
      <b-row class="mb-4">
        <b-col>
          <organ-license
            v-model="organs"
            :isDisabled="isDisabled"
          ></organ-license>
        </b-col>
        <b-col>
          <tissue-license
            v-model="tissues"
            :isDisabled="isDisabled"
          ></tissue-license>
        </b-col>
      </b-row>
      <contacts
        :count="4"
        v-if="isUpdate"
        :hospital_id="hospitalId"
        v-model="contacts"
      ></contacts>
      <hospital-payment
        :id="hospitalId"
        v-if="isUpdate"
        :isDisabled="isDisabled"
      ></hospital-payment>
      <Footer :status="status"></Footer>
    </form-wrapper>
  </b-container>
</template>

<script>
import Attachments from "../../components/hospitals/create/Attachments.vue";
import BusinessAddress from "../../components/hospitals/create/BusinessAddress.vue";
import Contacts from "../../components/hospitals/create/Contacts.vue";
import Details from "../../components/hospitals/create/Details.vue";
import Footer from "../../components/hospitals/create/Footer.vue";
import Hospital from "../../components/hospitals/create/Hospital.vue";
import LicenseDetails from "../../components/hospitals/create/LicenseDetails.vue";
import RegistrationAddress from "../../components/hospitals/create/RegistrationAddress.vue";
import hospitalService from "../../assets/services/hospital.service";
import {
  decode,
  initPayment,
  isArray,
  isDocObject,
} from "../../assets/js/common";
import OrganLicense from "../../components/hospitals/create/OrganLicense.vue";
import TissueLicense from "../../components/hospitals/create/TissueLicense.vue";
import moment from "moment-timezone";
import HospitalPayment from "../../components/hospitals/create/HospitalPayment.vue";
import menuConstants from "../../assets/constants/menu.constants";
export default {
  components: {
    Details,
    Hospital,
    BusinessAddress,
    RegistrationAddress,
    Attachments,
    LicenseDetails,
    Contacts,
    Footer,
    TissueLicense,
    OrganLicense,
    HospitalPayment,
  },
  data() {
    return {
      isValid: false,
      isLoading: true,
      tissues: [],
      organs: [],
      hospital: {
        hospital_name: "",
        hospital_type: "",
        hospital_registration_no: "",
        hospital_country_code_1: "91",
        hospital_phone_number_1: "",
        hospital_country_code_2: "91",
        hospital_phone_number_2: "",
        year_of_establishment: "",
        hospital_email_id: "",
        zone_id: "",
        website_url: "",
        payment_reference_no: "",
      },
      details: {
        number_of_beds: "",
        departments: [],
        trauma_unit: "",
        teaching: "",
        clinical_establishment_act: "",
        registered_throught_ACT: "",
        ISO_NABH_accreditation: "",
      },
      businessAddress: {
        address_line_1: "",
        address_line_2: "",
        town_village: "",
        is_permanent_address: 1,
        landmark: "",
        country: "99",
        state: "32",
        address_id: "",
        city: "",
        pincode: "",
      },
      registrationAddress: {},
      licenseDetails: {
        registered_for_transplant: "",
        license_type: "",
        transplant_first_registration: "",
        license_expiry_date: "",
        corneal: "",
        certificate_no: "",
        NOTTO_document: "",
      },
      contacts: [],
      contact: {
        first_name: "",
        last_name: "",
        gender: "",
        status: 1,
        photo: null,
        email_id: "",
        contact_name: "",
        individual_id: "",
        organ_name: "",
        hospital_contact_id: "",
        contact_id: "",
        country_code_1: "91",
        phone_number_1: "",
        country_code_2: "91",
        phone_number_2: "",
        qualification_details: [],
      },
      qualification_list: [],
      hospital_department_list: [],
      status: 1,
      files: [],
      filesList: [],
      updateItem: {},
    };
  },
  computed: {
    isAuth() {
      return this.$store.getters.isAuth;
    },
    isUpdate() {
      return this.$route.name == "update-hospital";
    },
    isDisabled() {
      let hasAccess = this.hasEditAccess(menuConstants.MANAGE_HOSPITAL);
      return this.status == 4 || !hasAccess;
    },
    isCreate() {
      return this.$route.name == "hospital-registration";
    },
    hospitalId() {
      return decode(this.$route.query.id);
    },
  },
  methods: {
    onFileRemoved(id) {
      const vm = this;
      let list = vm.filesList.filter((item) => {
        return item.hospital_license_fileupload_mapping_id != id;
      });
      vm.filesList = list;
      setTimeout(() => {
        vm.$refs.attachments.init();
      }, 500);
    },
    async init() {
      const vm = this;
      if (!vm.isUpdate) {
        vm.isLoading = false;
        return;
      }
      let { hospitalId } = vm;

      try {
        vm.$loader.show();
        let { user_type } = vm.$store.getters.user;
        let doc = { user_type, hospital_id: hospitalId };
        let { data } = await hospitalService.get(doc);
        let { status, msg } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          vm.$router.replace("/hospital-list");
          return;
        }
        vm.initForm(data);
      } catch (error) {
        console.log(error);
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
    async initForm(data) {
      const vm = this;
      let {
        hospital_list,
        hospital_license,
        organ_list,
        qualification,
        hospital_contact,
        hospital_department,
        file_uploaded_list,
        hospital_address,
      } = data;
      vm.updateItem = data;
      if (isDocObject(hospital_list)) {
        let doc = hospital_list[0];
        Object.keys(vm.hospital).forEach((key) => {
          vm.$set(vm.hospital, key, doc[key]);
        });
        Object.keys(vm.details).forEach((key) => {
          vm.$set(vm.details, key, doc[key]);
        });
        Object.keys(vm.licenseDetails).forEach((key) => {
          vm.$set(vm.licenseDetails, key, doc[key]);
        });
        vm.status = doc.status;
      }
      if (isArray(file_uploaded_list)) {
        vm.filesList = file_uploaded_list;
      }
      if (isArray(organ_list)) {
        let list = organ_list.filter((item) => {
          return item.license_available == 0 && item.status != 4;
        });
        vm.tissues = list.map((item) => {
          return item.organ_id;
        });
        list = organ_list.filter((item) => {
          return item.license_available == 1 && item.status != 4;
        });
        vm.organs = list.map((item) => {
          return item.organ_id;
        });
      }
      if (isArray(hospital_department)) {
        let departments = hospital_department.filter((item) => {
          return item.status != 4;
        });
        vm.details.departments = departments.map((item) => {
          vm.hospital_department_list.push(item.hospital_department_id);
          return item.department_id;
        });
      }
      if (isDocObject(hospital_license)) {
        // let doc = hospital_license[0];
        // Object.keys(vm.licenseDetails).forEach((key) => {
        //   vm.$set(vm.licenseDetails, key, doc[key]);
        // });
      }
      if (isArray(hospital_contact)) {
        hospital_contact.forEach((doc) => {
          let item = {};
          Object.keys(vm.contact).forEach((key) => {
            item[key] = doc[key];
          });
          let qualification_details = qualification.filter((qual) => {
            return item.hospital_contact_id == qual.hospital_contact_id;
          });
          item.qualification_details = qualification_details.map(
            ({ qualification_id }) => {
              return qualification_id;
            }
          );
          vm.contacts.push(item);
        });
      }
      if (isDocObject(hospital_address)) {
        Object.keys(vm.businessAddress).forEach((key) => {
          vm.businessAddress[key] = hospital_address[0][key];
        });
      }
      vm.isLoading = false;
    },
    validateContact() {
      return new Promise((resolve, reject) => {
        const vm = this;
        let dms = vm.contacts.filter((item) => {
          return item.contact_id == 4 && item.status == 1;
        });
        let directors = vm.contacts.filter((item) => {
          return item.contact_id == 1 && item.status == 1;
        });
        if (directors.length == 0 && dms.length == 0) {
          reject({
            message: "Please provide at least one director or DMS contact",
          });
          return;
        }
        resolve();
      });
    },
    async submit() {
      const vm = this;
      if (!vm.isAuth) {
        vm.$alert.show("Authentication required");
        vm.$router.replace("/login");
        return;
      }
      let doc = vm.getForm();
      vm.$loader.show();

      try {
        // await vm.validateContact();
        for (let i = 0; i < doc.contact_detail.length; i++) {
          let { photo } = doc.contact_detail[i];
          if (photo instanceof File) {
            doc.contact_detail[i].photo = await this.uploadContact(photo);
          }
        }
        Object.keys(doc).forEach((key) => {
          if (doc[key] === "") {
            doc[key] = null;
          }
        });
        let data = {};
        if (vm.isUpdate) {
          doc.dml_indicator = "U";
          let res = await hospitalService.update(doc);
          data = res.data;
        } else {
          doc.dml_indicator = "I";
          let res = await hospitalService.create(doc);
          console.log(res);
          data = res.data;
        }
        let { status, msg, hospital_license_id, hospital_id } = data;
        let user = vm.$store.getters.user;
        user.hospital_id = hospital_id;
        let time = moment().add(30, "days").toDate();
        vm.$cookies.set("session_details", user, time);
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        for (let item of vm.files) {
          if (item.file instanceof File) {
            await this.upload(item, hospital_license_id);
          }
        }
        vm.$loader.hide();
        if (vm.isUpdate) {
          vm.$alert.show("Hospital updated successfully");
        } else {
          vm.$alert.show("Hospital created successfully");
          if (doc.hospital_type != 1) {
            initPayment(data);
          }
          // if (vm.user.user_type == 1) {
          //   vm.$router.replace("/transplant-list");
          //   location.reload();
          // }
        }
        vm.openHospital();
      } catch ({ message }) {
        vm.$alert.show(message);
        vm.$loader.hide();
      }
    },
    openHospital() {
      const vm = this;
      let user = vm.$store.getters.user;
      if (user.user_type == 2) {
        vm.$router.replace("/hospital-list");
      } else {
        vm.$router.replace("/transplant-list");
      }
    },
    upload(doc, id) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          var body = new FormData();
          let { file, file_upload_id } = doc;
          body.append("file_upload_id", file_upload_id);
          body.append("hospital_license_id", id);
          body.append("created_by", 1);
          body.append("file", file);
          let { data } = await hospitalService.upload(body);
          let { status, msg } = data;
          if (status) {
            resolve();
          } else {
            reject({
              message: `Hospital created successfully. <br/>Error while uploading document : ${msg}`,
            });
            this.openHospital();
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    uploadContact(file) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          var body = new FormData();
          body.append("file", file);
          let { data } = await hospitalService.uploadContact(body);
          let { status, msg, filePath_url } = data;
          if (status) {
            resolve(filePath_url);
          } else {
            reject({
              message: `Error while uploading image : ${msg}`,
            });
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getForm() {
      const vm = this;
      let {
        hospital,
        details,
        businessAddress,
        licenseDetails,
        contacts,
        organs,
        tissues,
        user,
      } = vm;
      let { registration_id, user_type } = user;
      let created_by = registration_id;
      let organ_license = tissues.map((organ_id) => {
        return { organ_id, created_by, status: 1 };
      });
      organ_license = organ_license.concat(
        organs.map((organ_id) => {
          return { organ_id, created_by, status: 1 };
        })
      );
      businessAddress.created_by = created_by;
      let doc = {
        user_type,
        address_details: [businessAddress],
        organ_license,
        created_by,
        contact_detail: [],
      };
      Object.keys(hospital).forEach((key) => {
        doc[key] = hospital[key];
      });
      Object.keys(details).forEach((key) => {
        doc[key] = details[key];
      });
      doc.hospital_department = details.departments.map((department_id) => {
        return { department_id, created_by };
      });
      Object.keys(licenseDetails).forEach((key) => {
        doc[key] = licenseDetails[key];
      });
      doc.payment_status = doc.payment_reference_no ? 1 : 0;
      let contactlist = [];
      for (let contact of contacts) {
        let doc = {};
        Object.keys(contact).forEach((key) => {
          doc[key] = contact[key];
        });
        contactlist.push(doc);
      }
      // let contact_detail = contactlist.map((item) => {
      //   let qualification_details = item.qualification_details.map(
      //     (qualification_id) => {
      //       return { qualification_id, created_by };
      //     }
      //   );
      //   item.qualification_details = qualification_details;
      //   item.created_by = created_by;
      //   return item;
      // });
      // doc.contact_detail = contact_detail;
      doc.number_of_beds = Number(doc.number_of_beds);
      if (vm.isUpdate) {
        doc.hospital_id = vm.hospitalId;
        let { hospital_department, organ_list, qualification, hospital_list } =
          vm.updateItem;
        if (isArray(hospital_list)) {
          doc.hospital_license_id = hospital_list[0].hospital_license_id;
        }
        if (isArray(hospital_department)) {
          hospital_department.forEach((item) => {
            let check = false;
            doc.hospital_department.forEach((d, i) => {
              if (d.department_id == item.department_id) {
                doc.hospital_department[i].hospital_department_id =
                  item.hospital_department_id;
                check = true;
              }
            });
            if (!check) {
              doc.hospital_department.push({
                created_by,
                department_id: item.department_id,
                hospital_department_id: item.hospital_department_id,
                status: 4,
              });
            }
          });
        }
        if (isArray(organ_list)) {
          organ_list.forEach((item) => {
            let check = false;
            doc.organ_license.forEach((d, i) => {
              if (d.organ_id == item.organ_id) {
                doc.organ_license[i].organ_license_id = item.organ_license_id;
                check = true;
              }
            });
            if (!check) {
              doc.organ_license.push({
                created_by,
                organ_id: item.organ_id,
                organ_license_id: item.organ_license_id,
                status: 4,
              });
            }
          });
        }
        if (isArray(qualification)) {
          // qualification.forEach((item) => {
          //   doc.contact_detail.forEach((contact, c) => {
          //     if (contact.hospital_contact_id == item.hospital_contact_id) {
          //       let check = false;
          //       contact.qualification_details.forEach((d, i) => {
          //         if (d.qualification_id == item.qualification_id) {
          //           doc.contact_detail[c].qualification_details[
          //             i
          //           ].qualification_mapping_id = item.qualification_mapping_id;
          //           check = true;
          //         }
          //       });
          //       if (!check) {
          //         doc.contact_detail[c].qualification_details.push({
          //           created_by,
          //           qualification_id: item.qualification_id,
          //           qualification_mapping_id: item.qualification_mapping_id,
          //           status: 4,
          //         });
          //       }
          //     }
          //   });
          // });
        }
      }

      return doc;
    },
    getDepartmentId(id) {
      let dep_id = "";
      const vm = this;
      if (vm.isUpdate) {
        let { hospital_department } = vm.updateItem;
        if (isArray(hospital_department)) {
          let doc = hospital_department.find((item) => {
            return (item.department_id = id);
          });
          dep_id = doc instanceof Object ? doc.hospital_department_id : "";
        }
      }
      return dep_id;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
