export const AGE_TYPES = [
  {
    name: "Below 12 yrs",
    value: "1",
  },
  {
    name: "Below 18 yrs",
    value: "2",
  },
  {
    name: "Below 60 yrs",
    value: "3",
  },
  {
    name: "Above 60 yrs",
    value: "4",
  },
  {
    name: "All yrs",
    value: "all",
  },
];
export const HEART_LUNGS = [
  {
    name: "All",
    value: "",
  },
  {
    name: "Heart",
    value: "11",
  },
  {
    name: "Dual Lungs",
    value: "12",
  },
  {
    name: "Left Lung",
    value: "19",
  },
  {
    name: "Right Lung",
    value: "20",
  },
  {
    name: "All Lungs",
    value: "12,19,20",
  },
  {
    name: "All Heart and Lungs",
    value: "11,12",
  },
];
