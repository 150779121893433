<template>
  <collapse-filter>
    <b-card class="mt-4">
      <div class="h4">Filter</div>
      <form-wrapper @submit="submit" ref="form">
        <b-row>
          <b-col v-if="!isHospitalContact" cols="12" md="4">
            <autocomplete
              label="Hospital"
              v-model="form.hospital_id"
              rules="required"
              :items="hospitalList"
            ></autocomplete>
          </b-col>
          <b-col cols="12" md="4">
            <select-field
              label="Payment Status"
              v-model="form.payment_status"
              :items="paymentStatus"
            ></select-field>
          </b-col>
          <b-col cols="12" md="4">
            <select-field
              label="Patient Status"
              :items="patientStatus"
              v-model="form.transtan_status"
            ></select-field>
          </b-col>
          <b-col v-if="false" cols="12" md="4">
            <select-field
              label="Be Ready Status"
              :items="beReadyStatus"
              v-model="form.hospital_status"
            ></select-field>
          </b-col>
          <b-col cols="12" md="4">
            <select-field
              label="Organ"
              :items="prependAll(organs)"
              v-model="form.multi_organ"
            ></select-field>
          </b-col>
          <b-col cols="12" md="4">
            <select-field
              label="Blood Group"
              :items="prependAll(bloodGroups)"
              v-model="form.blood_group"
            ></select-field>
          </b-col>
          <b-col cols="12" md="4" v-if="isAdmins">
            <select-field
              label="Delete Reason"
              :items="prependAll(reasons)"
              v-model="form.delete_reason"
            ></select-field>
          </b-col>
          <b-col cols="12" md="4">
            <select-field
              label="Order"
              :items="sorts"
              v-model="form.row_type"
            ></select-field>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-btn
            class="width-100 me-3"
            @click="reset(false)"
            variant="outline-secondary"
            >RESET</b-btn
          >
          <b-btn class="width-100" type="submit" variant="primary"
            >SUBMIT</b-btn
          >
        </div>
      </form-wrapper>
    </b-card>
  </collapse-filter>
</template>

<script>
import {
  BE_READY_STATUS,
  PATIENT_STATUS,
  PAYMENT_STATUS,
} from "../../assets/constants/recipient.constants";
import commonHelper from "../../assets/helper/common.helper";
import { LEFT_LUNG_ID, RIGHT_LUNG_ID } from "../../assets/js/common";
import CollapseFilter from "../ui/CollapseFilter.vue";
export default {
  components: { CollapseFilter },
  props: {
    value: {},
    reasons: {},
  },
  data() {
    return {
      form: {
        hospital_id: "0",
        payment_status: "all",
        transtan_status: "all",
        hospital_status: "all",
        multi_organ: "all",
        blood_group: "all",
        row_type: "1",
        delete_reason: "all",
      },
      hospitals: [],
      sorts: [
        { name: "Asc Order", value: "1" },
        { name: "Desc Order", value: "2" },
      ],

      paymentStatus: PAYMENT_STATUS,
      beReadyStatus: BE_READY_STATUS,
    };
  },
  computed: {
    hospitalList() {
      let hospitals = this.hospitals;
      hospitals.unshift({
        name: "All Hospital",
        value: "0",
      });
      return hospitals;
    },
    organs() {
      if (this.master.master_organ instanceof Array == false) return [];
      let list = this.master.master_organ.filter(
        ({ status, tissue_bank, license_available, organ_id }) => {
          let isLung =
            [LEFT_LUNG_ID, RIGHT_LUNG_ID].indexOf(organ_id.toString()) != -1;
          return (
            status != 4 &&
            (tissue_bank == 1 || license_available == 1 || isLung)
          );
        }
      );
      return list.map((item) => {
        return { value: item.organ_id, name: item.organ_name };
      });
    },
    patientStatus() {
      let statusAllowed = ["all", "3", "5", "10", "12"];
      let otherStatusAllowed = ["all", "3", "10", "11", "9", "2", "12"];
      return PATIENT_STATUS.filter((item) => {
        if (this.isAdmins) {
          return statusAllowed.indexOf(item.value) != -1;
        } else {
          return otherStatusAllowed.indexOf(item.value) != -1;
        }
      });
    },
  },
  methods: {
    submit() {
      const vm = this;
      let { hospital_id } = vm.form;
      let filters = [];
      let skip = ["row_type", "hospital_id"];
      Object.keys(vm.form).forEach((key) => {
        if (skip.indexOf(key) == -1 && vm.form[key] != "all") {
          let strings = ["multi_organ"];
          if (strings.indexOf(key) != -1) {
            filters.push(`${key}='${vm.form[key].toString()}'`);
          } else if (key == "delete_reason") {
            let val = vm.form[key];
            console.log(val);
            if (val.trim() == "Others") {
              vm.reasons.forEach((item) => {
                filters.push(`${key}<>'${item.value}'`);
              });
            } else {
              filters.push(`${key}='${vm.form[key].toString()}'`);
            }
            filters.push(`delete_type=1`);
          } else {
            filters.push(`${key}=${vm.form[key]}`);
          }
        }
      });
      let filter = filters.toString().split(",").join(" and ");
      const doc = {
        dml_indicator: "S",
        filter,
        row_type: vm.form.row_type,
        hospital_id,
      };
      vm.$store.commit("SET_QUERY", { key: "recipient", query: vm.form });
      vm.$emit("input", doc);
      vm.$emit("fetch");
    },
    reset(isSubmit) {
      this.form = {
        hospital_id: "0",
        payment_status: "all",
        transtan_status: "all",
        hospital_status: "all",
        multi_organ: "all",
        blood_group: "all",
        row_type: "1",
        delete_reason: "all",
      };
      if (isSubmit) {
        this.submit();
      }
    },
    async exportCSV() {
      const vm = this;
      try {
        vm.$loader.show();
        await vm.$refs.form.validate();
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    const vm = this;
    if (vm.query.recipient) {
      vm.form = vm.query.recipient;
      this.submit();
    } else {
      this.reset();
    }
    commonHelper.fetchHospital(this);
  },
};
</script>

<style></style>
