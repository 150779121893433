import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../pages/Index.vue";
// import store from '../store/index'
import Hospitals from "../pages/hospital/Hospitals.vue";
import HospitalRotaOrder from "../pages/hospital/HospitalRotaOrder.vue";
import HospitalRegistration from "../pages/hospital/HospitalRegistration.vue";
import TransplantLicense from "../pages/hospital/TransplantLicense.vue";
import TransplantRegistration from "../pages/hospital/TransplantRegistration.vue";
import Registration from "../pages/account/Registration.vue";
import ManageRecipient from "../pages/managerecipient/ManageRecipient";
import RecipientTransfer from "../pages/managerecipient/RecipientTransfer.vue";
import ManageDonor from "../pages/donor/ManageDonor.vue";
import RecipientRegistration from "../pages/managerecipient/RecipientRegistration.vue";
import DonorRegistration from "../pages/donor/DonorRegistration.vue";
import Login from "../pages/account/Login.vue";
import ForgotPassword from "../pages/account/ForgotPassword.vue";
import ChangePassword from "../pages/account/ChangePassword.vue";
import TNOSWaitingList from "../pages/waitingList/TNOSWaitingList.vue";
import HospitalWaitingList from "../pages/waitingList/HospitalWaitingList.vue";
import InhouseWaitList from "../pages/waitingList/InhouseWaitList.vue";
import CMInsuranceList from "../pages/waitingList/CMInsuranceList.vue";
import LiveTransplant from "../pages/waitingList/LiveTransplant.vue";
import LiveTransplantRegistration from "../pages/waitingList/LiveTransplantRegistration.vue";
import ManageALF from "../pages/alf/ManageALF.vue";
import CreateALF from "../pages/alf/CreateALF.vue";
import ManageALFApprover from "../pages/alf/ManageALFApprover.vue";
import UserManagement from "../pages/manageUser/UserManagement.vue";
import TranstantUserManagement from "../pages/manageUser/TranstantUserManagement.vue";
import Masters from "../pages/Masters.vue";
import OrganAllocation from "../pages/organAllocation/OrganAllocation.vue";
import BeReadyList from "../pages/waitingList/BeReadyList.vue";
import menuConstants from "../assets/constants/menu.constants";
import RecipientPendingApproval from "../pages/managerecipient/RecipientPendingApproval.vue";
import CommonWaitingList from "../pages/CommonWaitingList.vue";
import PaymentSuccess from "../pages/PaymentSuccess.vue";
import PaymentFailure from "../pages/PaymentFailure.vue";
import DonorPledge from "../pages/donor/DonorPledge.vue";

Vue.use(VueRouter);
const isAuth = (to, from, next) => {
  let session = Vue.$cookies.get("session");
  if (session == "true") {
    // let { meta } = to;
    // let { userRights, user } = store.getters;
    // let rights = userRights.find(item => {
    //   return meta.id == item.module_id
    // })
    // let { ALF_approver } = user;
    // if (ALF_approver == 1 && meta.id == menuConstants.ALF_APPROVER) {
    //   next();
    //   return;
    // }
    // if (rights != null && rights.permit == 1 && rights[meta.type] == 1) {
    //   next();
    // } else {
    //   next("/");
    // }
    next();
  } else {
    next("/login");
  }
};

const isHospital = (to, from, next) => {
  let session = Vue.$cookies.get("session");
  let user = Vue.$cookies.get("session_details");
  user = user instanceof Object == false ? {} : user;
  if (session == "true") {
    if (!user.hospital_id && user.user_type == 1) {
      next("/hospital-registration");
    } else {
      if (user.contact_type == 2) {
        next("/transplant-list");
      } else {
        next();
      }
    }
  } else {
    next("/login");
  }
};
const isHospitalOrgan = (to, from, next) => {
  let session = Vue.$cookies.get("session");
  let user = Vue.$cookies.get("session_details");
  user = user instanceof Object == false ? {} : user;
  if (session == "true") {
    if (!user.hospital_id && user.user_type == 1) {
      next("/hospital-registration");
    } else {
      next();
    }
  } else {
    next("/login");
  }
};
const isLogin = (to, from, next) => {
  let session = Vue.$cookies.get("session");
  if (session != "true") {
    next();
  } else {
    next("/");
  }
};

const routes = [
  {
    path: "/",
    name: "index",
    redirect: "/hospital-list",
    component: Index,
    beforeEnter: isAuth,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: isLogin,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
    beforeEnter: isLogin,
  },
  {
    path: "/change-password",
    name: "change-password",
    component: ChangePassword,
    beforeEnter: isAuth,
  },
  {
    path: "/registration",
    name: "registration",
    component: Registration,
    beforeEnter: isLogin,
  },
  {
    path: "/hospital-list",
    name: "hospital-list",
    component: Hospitals,
    meta: {
      id: menuConstants.MANAGE_HOSPITAL,
      type: menuConstants.USER_VIEW,
    },
    beforeEnter: isHospital,
  },
  {
    path: "/hospital-registration",
    name: "hospital-registration",
    meta: {
      id: menuConstants.MANAGE_HOSPITAL,
      type: menuConstants.USER_CREATE,
    },
    component: HospitalRegistration,
    beforeEnter: isAuth,
  },
  {
    path: "/update-hospital",
    name: "update-hospital",
    component: HospitalRegistration,
    meta: {
      id: menuConstants.MANAGE_HOSPITAL,
      type: menuConstants.USER_EDIT,
    },
    beforeEnter: isAuth,
  },
  {
    path: "/transplant-registration",
    name: "transplant-registration",
    component: TransplantRegistration,
    meta: {
      id: menuConstants.MANAGE_HOSPITAL_LICENCE,
      type: menuConstants.USER_CREATE,
    },
    beforeEnter: isAuth,
  },
  {
    path: "/hospital-rota-order",
    name: "hospital-rota-order",
    component: HospitalRotaOrder,
    meta: {
      id: menuConstants.HOSPITAL_ROTA_ORDER,
      type: menuConstants.USER_CREATE,
    },
    beforeEnter: isAuth,
  },
  {
    path: "/update-transplant-license",
    name: "update-transplant-license",
    component: TransplantRegistration,
    meta: {
      id: menuConstants.MANAGE_HOSPITAL_LICENCE,
      type: menuConstants.USER_EDIT,
    },

    beforeEnter: isAuth,
  },
  {
    path: "/transplant-list",
    name: "transplant-list",
    component: TransplantLicense,
    meta: {
      id: menuConstants.MANAGE_HOSPITAL_LICENCE,
      type: menuConstants.USER_VIEW,
    },

    beforeEnter: isHospitalOrgan,
  },
  {
    path: "/manage-recipient",
    name: "manage-recipient",
    component: ManageRecipient,
    meta: {
      id: menuConstants.MANAGE_RECIPIENT,
      type: menuConstants.USER_VIEW,
    },
    beforeEnter: isAuth,
  },
  {
    path: "/recipient-pending-approval",
    name: "recipient-pending-approval",
    component: RecipientPendingApproval,
    meta: {
      id: menuConstants.PENDING_APPROVAL,
      type: menuConstants.USER_VIEW,
    },
    beforeEnter: isAuth,
  },
  {
    path: "/recipient-registration",
    name: "recipient-registration",
    component: RecipientRegistration,
    meta: {
      id: menuConstants.MANAGE_RECIPIENT,
      type: menuConstants.USER_CREATE,
    },

    beforeEnter: isAuth,
  },
  {
    path: "/update-recipient",
    name: "update-recipient",
    component: RecipientRegistration,
    meta: {
      id: menuConstants.MANAGE_RECIPIENT,
      type: menuConstants.USER_EDIT,
    },

    beforeEnter: isAuth,
  },
  {
    path: "/recipient-transfer",
    name: "recipient-transfer",
    component: RecipientTransfer,
    meta: {
      id: menuConstants.RECIPIENT_TRANSFER,
      type: menuConstants.USER_VIEW,
    },

    beforeEnter: isAuth,
  },
  {
    path: "/manage-donor",
    name: "manage-donor",
    component: ManageDonor,
    meta: {
      id: menuConstants.MANAGE_DONOR,
      type: menuConstants.USER_VIEW,
    },

    beforeEnter: isAuth,
  },
  {
    path: "/donor-pledge",
    name: "donor-pledge",
    component: DonorPledge,
    beforeEnter: isAuth,
  },
  {
    path: "/donor-registration",
    name: "donor-registration",
    component: DonorRegistration,
    meta: {
      id: menuConstants.MANAGE_DONOR,
      type: menuConstants.USER_CREATE,
    },

    beforeEnter: isAuth,
  },
  {
    path: "/update-donor",
    name: "update-donor",
    component: DonorRegistration,
    meta: {
      id: menuConstants.MANAGE_DONOR,
      type: menuConstants.USER_EDIT,
    },

    beforeEnter: isAuth,
  },
  {
    path: "/tnos-waiting-list",
    name: "tnos-waiting-list",
    component: TNOSWaitingList,
    meta: {
      id: menuConstants.TNOS_WAITING_LIST,
      type: menuConstants.USER_VIEW,
    },

    beforeEnter: isAuth,
  },
  {
    path: "/hospital-waiting-list",
    name: "hospital-waiting-list",
    component: HospitalWaitingList,
    meta: {
      id: menuConstants.HOSPITAL_WAITING_LIST,
      type: menuConstants.USER_VIEW,
    },

    beforeEnter: isAuth,
  },
  {
    path: "/in-house-waiting-list",
    name: "in-house-waiting-list",
    component: InhouseWaitList,
    meta: {
      id: menuConstants.IN_HOUSE_WAITING_LIST,
      type: menuConstants.USER_VIEW,
    },

    beforeEnter: isAuth,
  },
  {
    path: "/be-ready-list",
    name: "be-ready-list",
    component: BeReadyList,
    meta: {
      id: menuConstants.BEREADY_LIST,
      type: menuConstants.USER_VIEW,
    },

    beforeEnter: isAuth,
  },
  {
    path: "/cm-insurance-list",
    name: "cm-insurance-list",
    component: CMInsuranceList,
    meta: {
      id: menuConstants.CM_INSURANCE_LIST,
      type: menuConstants.USER_VIEW,
    },

    beforeEnter: isAuth,
  },
  {
    path: "/live-transplant",
    name: "live-transplant",
    component: LiveTransplant,
    meta: {
      id: menuConstants.LIVE_TRANSPLANT,
      type: menuConstants.USER_VIEW,
    },

    beforeEnter: isAuth,
  },
  {
    path: "/live-transplant-registration",
    name: "live-transplant-registration",
    component: LiveTransplantRegistration,
    meta: {
      id: menuConstants.LIVE_TRANSPLANT,
      type: menuConstants.USER_CREATE,
    },

    beforeEnter: isAuth,
  },
  {
    path: "/update-live-transplant",
    name: "update-live-transplant",
    component: LiveTransplantRegistration,
    meta: {
      id: menuConstants.LIVE_TRANSPLANT,
      type: menuConstants.USER_EDIT,
    },

    beforeEnter: isAuth,
  },
  {
    path: "/manage-alf",
    name: "manage-alf",
    component: ManageALF,
    meta: {
      id: menuConstants.ACUTE_LIVER_FAILURE,
      type: menuConstants.USER_VIEW,
    },

    beforeEnter: isAuth,
  },
  {
    path: "/create-alf",
    name: "create-alf",
    component: CreateALF,
    meta: {
      id: menuConstants.ACUTE_LIVER_FAILURE,
      type: menuConstants.USER_CREATE,
    },

    beforeEnter: isAuth,
  },
  {
    path: "/update-alf",
    name: "update-alf",
    component: CreateALF,
    meta: {
      id: menuConstants.ACUTE_LIVER_FAILURE,
      type: menuConstants.USER_EDIT,
    },

    beforeEnter: isAuth,
  },
  {
    path: "/manage-alf-approver",
    name: "manage-alf-approver",
    component: ManageALFApprover,
    meta: {
      id: menuConstants.ALF_APPROVER,
      type: menuConstants.USER_VIEW,
    },

    beforeEnter: isAuth,
  },
  {
    path: "/organ-allocation",
    name: "organ-allocation",
    component: OrganAllocation,
    meta: {
      id: menuConstants.MANAGE_DONOR,
      type: menuConstants.USER_VIEW,
    },

    beforeEnter: isAuth,
  },
  {
    path: "/user-management",
    name: "user-management",
    component: UserManagement,
    meta: {
      id: menuConstants.USER_MANAGEMENT,
      type: menuConstants.USER_VIEW,
    },

    beforeEnter: isAuth,
  },
  {
    path: "/transtan-management",
    name: "transtan-management",
    component: TranstantUserManagement,
    meta: {
      id: menuConstants.TRANSTAN_USER_LIST,
      type: menuConstants.USER_VIEW,
    },
    beforeEnter: isAuth,
  },
  {
    path: "/masters",
    name: "masters",
    component: Masters,
    meta: {
      id: menuConstants.MASTERS,
      type: menuConstants.USER_VIEW,
    },
    beforeEnter: isAuth,
  },
  {
    path: "/commong-waiting-list",
    name: "commong-waiting-list",
    component: CommonWaitingList,
  },
  {
    path: "/payment-success",
    name: "payment-success",
    component: PaymentSuccess,
  },
  {
    path: "/payment-failure",
    name: "payment-failure",
    component: PaymentFailure,
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
