<template>
  <ValidationProvider :rules="rules" :vid="vid" v-slot="validationContext">
    <date-picker
      v-model="model"
      value-type="format"
      format="HH:mm"
      type="time"
      :disabled="disabled"
      :open.sync="open"
      :editable="false"
      @change="onInput"
      :clearable="false"
      :disabled-time="disabledTime"
    >
      <template v-slot:input>
        <b-form-group>
          <template v-slot:label>
            <span v-html="formattedLabel"></span>
          </template>

          <b-input-group
            class="mb-3"
            :invalid-feedback="message(validationContext)"
          >
            <b-form-input
              v-model="model"
              :type="type"
              readonly
              :disabled="disabled"
              :state="getValidationState(validationContext)"
              @input="$emit('input', model)"
            >
            </b-form-input>
            <b-input-group-append>
              <b-button variant="outline-info" class="help">
                <b-icon icon="calendar-event" class="font-size-16"></b-icon>
              </b-button>
            </b-input-group-append>
            <b-form-invalid-feedback>
              {{ message(validationContext) }}
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </template>
      <template v-slot:footer>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button size="sm" @click="open = false">Close</b-button>
        </div>
      </template>
    </date-picker>
  </ValidationProvider>
</template>
<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";

export default {
  components: {
    DatePicker,
  },
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    vid: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
    },
    type: {
      type: String,
      default: "text",
    },
    invalid: {
      type: String,
      default: "This field is required",
    },
    value: {
      type: String,
      default: "",
    },
    max: {
      default: "",
    },
    min: {
      default: "",
    },
    minTime: {
      default: "",
    },
  },
  data() {
    return {
      model: "",
      open: false,
    };
  },
  computed: {
    formattedLabel() {
      if (!this.rules) return this.label;
      if (this.rules.indexOf("required") != -1)
        return this.label + " <span style='color:red'>*</span>";
      return this.label;
    },
  },
  watch: {
    value() {
      this.init();
    },
  },
  methods: {
    disabledTime(date) {
      if (this.minTime) {
        return moment(date).isBefore(moment(this.minTime, "HH:mm"));
      }
      return false;
    },

    onInput(val) {
      let time = moment(val, "hh:mm").format("HH:mm");
      this.$emit("input", time);
    },
    message(validationContext) {
      return validationContext.errors[0];
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    init() {
      if (this.value) {
        let date = moment(this.value, "HH:mm").format("HH:mm");
        this.model = date;
      } else {
        this.model = "";
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss">
.mx-icon-calendar {
  display: none;
}
.mx-datepicker {
  width: 100% !important;
}
</style>
