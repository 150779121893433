<template>
  <div>
    <b-button class="help" @click="dialog = true" variant="outline-info">
      <b-icon class="font-size-16" icon="question" scale="1.5"> </b-icon>
    </b-button>
    <b-modal v-model="dialog" hide-footer hide-header size="lg" centered>
      <img :src="src" class="help-image" />
      {{ tip }}
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    src: String,
    tip: String,
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style scoped>
.help-image {
  width: 100%;
  height: 500px;
  object-fit: contain;
}
</style>
