import { formatDateOnly, isDocObject } from "../../assets/js/common";
import alfService from "../../assets/services/alf.service";
import recipientHelper from "../managerecipient/recipient.helper";
class ReceipienHelper {
    async get(vm) {
        try {
            vm.$loader.show();
            let { data } = await alfService.get(vm.id);
            let { status, msg, list } = data;
            if (!status) {
                vm.$alert.show(msg);
                vm.$loader.hide();
                vm.$router.replace('/manage-alf')
                return;
            }
            if (isDocObject(list)) {
                vm.details = list[0]
                vm.details.DOB = formatDateOnly(vm.details.DOB);
            }
            vm.isLoading = false;
            vm.$loader.hide();
        } catch (error) {
            vm.$alert.show(error.message);
            vm.$loader.hide();
        }
    }
    async getALF(vm) {
        try {
            vm.$loader.show();
            let { data } = await alfService.getALF(vm.id);
            let { status, msg, list } = data;
            if (!status) {
                vm.$alert.show(msg);
                vm.$loader.hide();
                vm.$router.replace('/manage-alf')
                return;
            }
            if (isDocObject(list)) {
                let doc = list[0]
                console.log(list[0]);
                // vm.details = list[0]
                // vm.details.DOB = formatDateOnly(vm.details.DOB);
                recipientHelper.getRecipient(vm, doc.recipient_registration_id)
                vm.doc = list[0];
                vm.status = doc.status;
                Object.keys(vm.alf_details).forEach(key => {
                    vm.alf_details[key] = doc[key];
                })
            }
            // vm.isLoadding = false;
            vm.dialog = true;
        } catch (error) {
            vm.$alert.show(error.message);
            vm.$loader.hide();
        }
    }
    async create(vm) {
        try {
            vm.$loader.show();
            let { hospital_id } = vm.details
            let doc = {
                "status": "1",
                hospital_id,
                "created_by": vm.created_by,
                alf_id: vm.isCreate ? "" : vm.id,
                "recipient_registration_id": vm.isCreate ? vm.id : vm.doc.recipient_registration_id,
                "dml_indicator": vm.isCreate ? "I" : "U"
            }
            if (!vm.isCreate) delete doc.status;
            let details = [];
            Object.keys(vm.alf_details).forEach(key => {
                details.push({ key, value: vm.alf_details[key] })
            })
            for (let item of details) {
                let { key, value } = item;
                if (value instanceof File) {
                    doc[key] = await this.uploadFile(value);
                } else {
                    doc[key] = value;
                }
            }
            let { data } = await alfService.create(doc);
            let { status, msg } = data;
            if (!status) {
                vm.$alert.show(msg);
            }
            vm.$router.replace('/manage-alf')
            vm.$loader.hide();
        } catch (error) {
            vm.$alert.show(error.message);
            vm.$loader.hide();
        }
    }
    async updateStatus(approval_status, alf_id, vm) {
        try {
            vm.$loader.show();
            let doc = { approval_status, alf_id, "dml_indicator": "SU" };
            let { data } = await alfService.updateStatus(doc);
            let { status, msg } = data;
            vm.$loader.hide();
            if (!status) {
                vm.$alert.show(msg);
                return;
            }
            vm.dialog = false;
            vm.$emit("fetch");
        } catch (error) {
            vm.$loader.hide();
            vm.$alert.show(error.message);
        }
    }
    upload(doc, id, vm) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                var body = new FormData();
                let { file, file_upload_id } = doc;
                body.append("file_upload_id", file_upload_id);
                body.append("donor_temp_registration_id", id);
                body.append("created_by", vm.created_by);
                body.append("file", file);
                let { data } = await alfService.upload(body);
                let { status, msg } = data;
                let state = vm.isUpdate ? "updated" : "created";
                if (status) {
                    resolve();
                } else {
                    reject({
                        message: `Donor ${state} successfully. <br/>Error while uploading document : ${msg}`,
                    });
                    vm.$router.push("/manage-donor");
                }
            } catch (error) {
                reject(error);
            }
        });
    }
    uploadFile(file) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                var body = new FormData();
                body.append("file", file);
                let { data } = await alfService.uploadFile(body);
                let { status, msg, filePath_url } = data;
                if (status) {
                    resolve(filePath_url);
                } else {
                    reject({
                        message: `Error while uploading document : ${msg}`,
                    });
                }
            } catch (error) {
                reject(error);
            }
        });
    }
}

export default new ReceipienHelper();