var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{ref:"table",attrs:{"title":_vm.title,"fields":_vm.fields,"isBusy":_vm.isLoading,"total":_vm.total,"isMobileView":_vm.isMobile,"items":_vm.formattedList,"isDynamic":true},on:{"export":function($event){return _vm.$emit('export')},"onPageChange":function($event){return _vm.$emit('onPageChange', $event)}},scopedSlots:_vm._u([{key:"recipient_registration_id",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('internal-recipient-icon',{attrs:{"item":item,"value":value}})]}},{key:"transtan_status",fn:function(ref){
var value = ref.value;
return [_c('div',{class:_vm.getBg(value)},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"age",fn:function(ref){
var value = ref.value;
return [_c('div',{class:value > 60 ? 'badge bg-danger  font-size-13' : ''},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('view-button',{on:{"click":function($event){return _vm.openView(item)}}})],1)]}},{key:"mobile_list",fn:function(rows){return [_c('div',{staticClass:"border-bottom"}),_vm._l((rows),function(item,i){return _c('div',{key:i,staticClass:"border-bottom px-2 py-2"},[_c('div',{staticClass:"d-flex mb-1"},[_c('span',[_vm._v(" "+_vm._s(item.recipient_registration_id)+" ")]),_c('div',{staticClass:"flex-grow-1"}),_vm._v(" "+_vm._s(item.age)+" / "+_vm._s(item.gender)+" / "+_vm._s(item.group_desc)+" ")]),_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(item.first_name))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.hospital_name))]),_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(item.aadhar_number || "-")+" "),_c('div',{staticClass:"flex-grow-1"})]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.created_on))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.organ_name))])])})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }