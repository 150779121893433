<template>
  <div>
    <Header :hospitals="hospitals"></Header>
    <ALFStatus :status="status"></ALFStatus>
    <List :isLoading="isLoading" @fetch="fetch" :list="list"></List>
  </div>
</template>

<script>
import { formatDate, isDocObject } from "../../assets/js/common";
import alfService from "../../assets/services/alf.service";
import ALFStatus from "../../components/managealf/ALFStatus.vue";
import Header from "../../components/managealf/Header.vue";
import List from "../../components/managealf/List.vue";
export default {
  components: { List, Header, ALFStatus },
  data() {
    return {
      list: [{}],
      hospitals: [],
      filter: {},
      status: {},
      isLoading: false,
    };
  },
  methods: {
    async fetch() {
      const vm = this;
      try {
        vm.isLoading = true;
        vm.list = [];
        let { data } = await alfService.list();
        let { status, msg, list, count } = data;
        vm.isLoading = false;
        if (isDocObject(count)) vm.status = count[0];
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.list = list.map(item => {
          item.created_on = formatDate(item.created_on)
          return item
        });
      } catch (error) {
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style></style>
