<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap tnos__organ-filter">
      <b-card
        v-for="(item, i) in organs"
        @click="openOrgan(item)"
        :key="i"
        :class="type == item.id ? 'bg-success bg-soft' : ''"
        class="text-center"
        no-body
      >
        <img :src="item.src" style="object-fit: contain" />
        <p class="text-center mb-0 mt-1">
          {{ item.name }}
        </p>
      </b-card>
    </div>
    <select-heart-lungs
      ref="heartlungs"
      @onOrganSelect="$emit('input', $event)"
    ></select-heart-lungs>
    <select-multiple-organs
      ref="multiorgan"
      @onOrganSelect="$emit('input', $event)"
    ></select-multiple-organs>
  </div>
</template>
<script>
import SelectHeartLungs from "./SelectHeartLungs.vue";
import SelectMultipleOrgans from "./SelectMultipleOrgans.vue";
export default {
  components: { SelectHeartLungs, SelectMultipleOrgans },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      type: null,
      organs: [
        {
          src: require("../../../assets/images/organs/kidney.png"),
          id: 1,
          name: "Kidney",
        },
        {
          src: require("../../../assets/images/organs/liver.png"),
          id: 4,
          name: "Liver",
        },
        {
          src: require("../../../assets/images/organs/heart-lung.png"),
          id: "hl",
          name: "Heart & lungs",
        },
        {
          src: require("../../../assets/images/organs/pancreas.png"),
          id: "10",
          name: "Pancreas",
        },
        {
          src: require("../../../assets/images/organs/intestine.png"),
          name: "Small bowel",
          id: "18",
        },
        {
          src: require("../../../assets/images/organs/hand.png"),
          id: "21",
          name: "Hands",
        },
        {
          src: require("../../../assets/images/organs/mulitple.png"),
          id: "multi",
          name: "Multi organ",
        },
        {
          src: require("../../../assets/images/organs/stomach.png"),
          id: "24",
          name: "Stomach",
        },
      ],
    };
  },
  methods: {
    openOrgan({ id, name }) {
      const vm = this;
      vm.type = id;
      vm.$emit("onOrganChange", name);
      if (id == "hl") {
        let list = [11, 12, 19, 20].map((organ_id) => {
          return { organ_id };
        });
        vm.$emit("input", list);
        // vm.$refs.heartlungs.dialog = true;
        return;
      }
      if (id == "multi") {
        vm.$refs.multiorgan.dialog = true;
        return;
      }
      vm.$emit("input", [
        {
          organ_id: id,
        },
      ]);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/design/mixins";
.card {
  cursor: pointer;
}
.tnos__organ-filter {
  @include mobile {
    column-gap: 10px;
  }
  .card {
    width: 120px;
    padding: 16px;
    img {
      width: 88px;
      height: 50px;
    }
    @include mobile {
      padding: 10px;
      width: calc(33% - 10px);
      display: flex;
      align-items: center;
      img {
        width: 60px;
        height: 50px;
      }
      p {
        font-size: 12px;
      }
    }
  }
}
</style>
