<template>
  <b-button size="sm" icon variant="link" @click="$emit('click')">
    <b-icon variant="secondary" icon="eye-fill" class="font-size-16"></b-icon>
  </b-button>
</template>

<script>
export default {};
</script>

<style></style>
