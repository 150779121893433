<template>
  <div>
    <add-button v-if="!item.first_name" @click="dialog = true"> </add-button>
    <edit-button @click="dialog = true" v-else></edit-button>
    <b-modal
      v-model="dialog"
      width="600"
      :title="title"
      hide-footer
      centered
      no-close-on-backdrop
    >
      <form-wrapper @submit="submit">
        <b-row class="pb-0">
          <b-col cols="6">
            <input-field
              v-model.trim="form.first_name"
              label="First Name"
              rules="required|max-50"
            ></input-field>
          </b-col>
          <b-col cols="6">
            <input-field
              v-model.trim="form.last_name"
              label="Last Name"
              rules="required|max-50"
            ></input-field>
          </b-col>
          <b-col cols="6">
            <select-field
              v-model.trim="form.gender"
              label="Gender"
              outlined
              :items="gender"
              rules="required|max-50"
            ></select-field>
          </b-col>
          <b-col cols="6">
            <input-field
              v-model.trim="form.email_id"
              label="Email"
              rules="required|email|max-100"
            ></input-field>
          </b-col>
          <b-col cols="6">
            <select-field
              v-model.trim="form.contact_id"
              label="Contact Type"
              :items="contactTypes"
              rules="required"
            ></select-field>
          </b-col>

          <b-col cols="6">
            <phone-field
              v-model.trim="form.phone_number_1"
              :defaultCountry="form.country_code_1"
              label="Phone Number 1"
              @onCountryCode="form.country_code_1 = $event"
              rules="required|integer"
            ></phone-field>
          </b-col>
          <b-col cols="6">
            <phone-field
              v-model.trim="form.phone_number_2"
              :defaultCountry="form.country_code_2"
              label="Phone Number 2"
              @onCountryCode="form.country_code_2 = $event"
              rules="integer"
            ></phone-field>
          </b-col>
          <b-col cols="6">
            <autocomplete
              v-model.trim="form.qualification_details"
              multiple
              :items="masterQualifications"
              label="Qualification Details"
            ></autocomplete>
          </b-col>
          <b-col cols="6" v-if="loading">
            <file-input
              v-if="isFile"
              v-model="form.photo"
              accept="image/*"
              rules="file-image"
              label="Photo"
            >
              <template v-slot:help>
                <help text="Optional Field"></help>
              </template>
            </file-input>
            <b-button
              outlined
              v-else
              @click="open(form.photo)"
              color="primary"
              small
            >
              View Contact
            </b-button>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger mx-3" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" type="submit">Submit </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import { GENDERS } from "../../../../assets/constants/hospital.contants";
import { openFile } from "../../../../assets/js/common";
import {
  FIELD,
  getMaxRules,
  MULITPLE_SELECT,
} from "../../../../assets/js/rules";
import hospitalService from "../../../../assets/services/hospital.service";
import AddButton from "../../../buttons/AddButton.vue";
import EditButton from "../../../buttons/EditButton.vue";
import PhoneField from "../../../form/PhoneField.vue";
import SelectField from "../../../form/SelectField.vue";
import Help from "../../../Help.vue";
export default {
  props: {
    list: Array,
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    hospital_id: [Number, String],
    index: {
      type: Number,
      default: null,
    },
  },
  components: { Help, AddButton, EditButton, SelectField, PhoneField },
  data() {
    return {
      dialog: false,
      rules: FIELD,
      multipleRules: MULITPLE_SELECT,
      isValid: false,
      phone: FIELD,
      phone2: [],
      form: {},
      loading: true,
    };
  },
  watch: {
    dialog(val) {
      if (val == true) {
        this.init();
      }
    },
  },
  computed: {
    isFile() {
      let { photo } = this.form;
      return photo == null || photo instanceof File;
    },
    gender() {
      return GENDERS;
    },
    title() {
      return this.item.first_name ? "Update Contact" : "Add Contact";
    },
    contactType() {
      let way = ["hospital-registration", "update-hospital"];
      let { name } = this.$route;
      return way.indexOf(name) != -1 ? 1 : 2;
    },
    contactTypes() {
      return this.masterContactTypes.filter((item) => {
        return item.contact_type == this.contactType;
      });
    },
    master() {
      return this.$store.getters.master;
    },
    isUpdate() {
      let { name } = this.$route;
      return name == "update-hospital" || name == "update-transplant-license";
    },
  },
  methods: {
    open(url) {
      openFile(url);
    },
    getMaxRules(max) {
      return getMaxRules(max);
    },
    onPhoneInput1(item, doc) {
      const vm = this;
      vm.phone = [
        (v) => !!v || "This field is required",
        doc.isValid || "Enter valid phone number",
      ];
      if (doc.country.dialCode !== undefined) {
        vm.form.country_code_1 = doc.country.dialCode;
      }
      if (doc.number.significant !== undefined) {
        vm.form.phone_number_1 = doc.number.significant;
      }
    },
    onPhoneInput2(item, doc) {
      const vm = this;
      if (doc.number.significant) {
        vm.phone2 = [doc.isValid || "Enter valid phone number"];
      } else {
        vm.phone2 = [];
      }
      if (doc.country.dialCode !== undefined) {
        vm.form.country_code_2 = doc.country.dialCode;
      }
      if (doc.number.significant !== undefined) {
        vm.form.phone_number_2 = doc.number.significant;
      }
    },
    reset() {
      this.form = {
        first_name: "",
        last_name: "",
        gender: "",
        photo: null,
        email_id: "",
        contact_id: "",
        country_code_1: "91",
        phone_number_1: "",
        country_code_2: "91",
        phone_number_2: "",
        individual_id: "",
        hospital_contact_id: "",
        status: 1,
        qualification_details: [],
      };
    },
    init() {
      const vm = this;
      vm.reset();
      Object.keys(vm.form).forEach((key) => {
        if (vm.item[key] !== undefined) {
          vm.$set(vm.form, key, vm.item[key]);
        }
      });
    },
    async submit() {
      const vm = this;
      let doc = {};
      let { hospital_id } = vm;
      Object.keys(vm.form).forEach((key) => {
        doc[key] = vm.form[key];
      });
      let { email_id, phone_number_1, phone_number_2, hospital_contact_id } =
        doc;
      let isEmail = true;
      let isPhone1 = true;
      let isPhone2 = true;
      vm.list.forEach((item, i) => {
        if (i != vm.index) {
          if (item.email_id == email_id) isEmail = false;
          if (phone_number_1 && item.phone_number_1 == phone_number_1) {
            isPhone1 = false;
          }
          if (phone_number_2 && item.phone_number_2 == phone_number_2) {
            isPhone2 = false;
          }
        }
      });
      if (hospital_id) {
        try {
          vm.$loader.show();
          let { data } = await hospitalService.validateEmailPhone({
            email_id,
            hospital_id,
            hospital_contact_id,
          });
          isEmail = data.status.toString() == "true";
          let res = await hospitalService.validateEmailPhone({
            phone_number_1,
            hospital_id,
            hospital_contact_id,
          });
          isPhone1 = res.data.status.toString() == "true";
          vm.$loader.hide();
        } catch (error) {
          vm.$alert.show(error.message);
          vm.$loader.hide();
        }
      }

      if (!isEmail) {
        vm.$alert.show(
          "Another contact already exists with the same <b>Email</b>. Please enter a unique email."
        );
        return;
      }
      if (!isPhone1) {
        vm.$alert.show(
          "Another contact already exists with the same <b>Phone Number 1</b>. Please enter a unique phone number 1."
        );
        return;
      }
      if (!isPhone2) {
        vm.$alert.show(
          "Another contact already exists with the same <b>Phone Number 2</b>. Please enter a unique phone number 2."
        );
        return;
      }
      if (vm.item.first_name) {
        vm.$emit("onUpdate", { doc, index: vm.index });
      } else {
        vm.$emit("onCreate", doc);
      }
      vm.dialog = false;
    },
  },
};
</script>

<style></style>
