<template>
  <b-container class="payment__success">
    <b-card class="px-2 py-2">
      <img
        src="../assets/images/payment_success.svg"
        width="200"
        height="200"
        alt=""
      />
      <h3 class="text-center">Payment Successful</h3>
      <p class="text-center">Your payment was successful!</p>
      <hr/>
      <table style="width: 300px">
        <tr v-for="(td, i) in details" :key="i">
          <td>{{ td.label }}</td>
          <td>
            <b>{{ td.value }}</b>
          </td>
        </tr>
      </table>
    </b-card>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      doc: {
        transaction_no: "Payment Reference No",
        patient_name: "Recipient Name",
        recipient_registration_id: "UID",
      },
      details: [],
    };
  },
  methods: {
    init() {
      const vm = this;
      const { query } = vm.$route;
      Object.keys(vm.doc).forEach((key) => {
        if (query[key]) {
          vm.details.push({
            label: vm.doc[key],
            value: query[key],
          });
        }
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss">
.payment__success {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  img {
    margin-bottom: 20px;
  }
}
</style>
