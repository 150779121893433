<template>
  <div class="mt-3">
    <div class="d-flex align-items-center mb-3">
      <h3>Common Waitlist</h3>
      <div class="flex-grow-1"></div>
      <b-button @click="$router.push('/')">Back</b-button>
    </div>
    <common-waiting-list-organ-filter
      v-model="organs"
      @input="fetch"
      v-if="false"
      @onOrganChange="onOrganChange"
    ></common-waiting-list-organ-filter>
    <TNOSStatus :status="res"></TNOSStatus>
    <common-waiting-list-filter
      @onOrganChange="onOrganChange"
      v-model="filter"
      @onSeachUid="searchUID"
      @reset="list = []"
      @fetch="fetch"
    ></common-waiting-list-filter>
    <common-waiting-list-table
      :title="title"
      :isSearch="isSearch"
      :isLoading="isLoading"
      :list="list"
    ></common-waiting-list-table>
  </div>
</template>

<script>
import { formatDate, KIDNEY_ID } from "../assets/js/common";
import userService from "../assets/services/user.service";
import CommonWaitingListFilter from "../components/commonWaitingList/CommonWaitingListFilter.vue";
import CommonWaitingListOrganFilter from "../components/commonWaitingList/CommonWaitingListOrganFilter.vue";
import CommonWaitingListTable from "../components/commonWaitingList/CommonWaitingListTable.vue";
import TNOSStatus from "../components/waitingList/tnosWaitingList/TNOSStatus.vue";
export default {
  components: {
    CommonWaitingListOrganFilter,
    CommonWaitingListFilter,
    CommonWaitingListTable,
    TNOSStatus,
  },
  data() {
    return {
      organs: [],
      filter: {},
      isLoading: false,
      isSearch: false,
      title: "Waiting List",
      list: [],
      res: {},
    };
  },
  methods: {
    onOrganChange(name) {
      this.title = "Waiting List - " + name;
    },
    async searchUID(uid) {
      const vm = this;
      if (!uid) return;
      try {
        vm.list = [];
        vm.isLoading = true;
        vm.isSearch = true;
        let { hospital_id } = vm.$store.getters.user;
        let doc = {
          recipient_registration_id: uid,
        };
        if (vm.isHospitalContact) {
          doc.hospital_id = hospital_id;
        }
        let { data } = await userService.commonWaitingListUidSearch(doc);
        let { status, msg, list } = data;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.list = list.map((item, i) => {
          item.created_on = formatDate(item.created_on);
          item.rank = i + 1;
          return item;
        });
        vm.isLoading = false;
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
    async fetch() {
      const vm = this;
      try {
        let doc = {};
        Object.keys(vm.filter).forEach((key) => {
          doc[key] = vm.filter[key];
        });
        if (!vm.title.endsWith("Heart & lungs")) {
          doc.multi_organ = vm.filter.organ
            .map((item) => {
              return item.organ_id;
            })
            .sort(function (a, b) {
              return a - b;
            })
            .toString();
        }
        let isKidney = vm.filter.organ.find((item) => {
          return item.organ_id == KIDNEY_ID;
        });
        if (isKidney != null) doc.filter_type = 1;
        vm.isSearch = false;
        vm.isLoading = true;
        vm.list = [];
        let { data } = await userService.commonWaitingList(doc);
        let { status, msg, list } = data;
        vm.res = data;
        vm.isLoading = false;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.list = list.map((item, i) => {
          item.created_on = formatDate(item.created_on);
          item.rank = i + 1;
          return item;
        });
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
