export const DONOR_STATUS = [
  {
    name: "Ready for Review",
    value: 1,
  },
  {
    name: "Draft Deleted",
    value: 2,
  },
  {
    name: "Requesting Apnoea Details",
    value: 3,
  },
  {
    name: "Requesting More Details",
    value: 4,
  },
  {
    name: "Added More Details",
    value: 5,
  },
  {
    name: "Draft status completed",
    value: 6,
  },
  {
    name: "Termination",
    value: 7,
  },
  {
    name: "Draft",
    value: 8,
  },
  {
    name: "Deleted",
    value: 9,
  },
];
export const FINAL_DONOR_STATUS = [
  {
    name: "Case Officer Allocation Pending",
    value: "0",
  },
  {
    name: "Case Officer Allocated",
    value: "1",
  },
  {
    name: "Local Organ Requested",
    value: "2",
  },
  {
    name: "Local Organ Chosen",
    value: "3",
  },
  {
    name: "Allocation In Progress",
    value: "4",
  },
  {
    name: "Terminated",
    value: "5",
  },
  {
    name: "Organ Assessment Details Updated",
    value: "6",
  },
  {
    name: "Donor Shift to OT",
    value: 7,
  },
];

export const TYPE_OF_CONSENTS = [
  {
    name: "Oral",
    value: "1",
  },
  {
    name: "Written",
    value: "2",
  },
];
export const TYPE_OF_ALLOCATION = [
  {
    name: "Local",
    value: "1",
    color: "bg-success",
  },
  {
    name: "Share Pool",
    value: "2",
    color: "bg-primary",
  },
  {
    name: "Rota",
    value: "3",
    color: "bg-warning",
  },
];
export const RECIPIENT_ALLOCATION_STATUS = [
  {
    name: "Pending",
    value: "0",
  },
  {
    name: "Accepted",
    value: "1",
  },
  {
    name: "Decline",
    value: "2",
  },
];
export const LOGISTICS_STATUS = [
  {
    name: "Pending",
    value: "1",
  },
  {
    name: "Updated",
    value: "2",
  },
];
export const ALLOCATION_STATUS_LIST = [
  {
    name: "Organ Allocation Initiated",
    value: "1",
    color: "grey",
  },
  {
    name: "Waitlist Generated",
    color: "blue",
    value: "2",
  },
  {
    name: "Calling In Progress",
    color: "orange",
    value: "3",
  },
  {
    name: "No Takers List Generated 1",
    color: "orange",
    value: "4",
  },
  {
    name: "No Takers Calling In Progress",
    color: "blue",
    value: "5",
  },
  {
    name: "Priority Wait List Generated",
    color: "blue",
    value: "6",
  },
  {
    name: "Logistics list updated",
    value: "7",
  },
  {
    name: "Organ Retreival in progress",
    color: "orange",
    value: "8",
  },
  {
    name: "Organ Retreived",
    value: "9",
  },
  {
    name: "Trasnporting",
    value: "10",
  },
  {
    name: "Transplant Surgery in progress",
    color: "orange",
    value: "11",
  },
  {
    name: "Transplan Completed",
    value: "12",
  },
  {
    name: "Terminated",
    value: "13",
    color: "red",
  },
  {
    name: "Rota 1 List Generated",
    color: "blue",
    value: "14",
  },
  {
    name: "Rota 2 List Generated",
    color: "blue",
    value: "15",
  },
  {
    name: "Rota 3 List Generated",
    color: "blue",
    value: "16",
  },
  {
    name: "Priority List Confirmed",
    color: "teal",
    value: "17",
  },
  {
    name: "Final List Completed",
    color: "purple",
    value: "18",
  },
  {
    name: "Local Organ Waitlist Generated",
    color: "blue",
    value: "19",
  },
  {
    name: "No Takers Priority List Generated",
    color: "blue",
    value: "20",
  },
  {
    name: "Local Organ Priority List Generated",
    color: "blue",
    value: "21",
  },
  {
    name: "Rota 1 Priority List Generated",
    color: "blue",
    value: "22",
  },
  {
    name: "Rota 2 Priority List Generated",
    color: "blue",
    value: "23",
  },
  {
    name: "Rota 3 Priority List Generated",
    color: "blue",
    value: "24",
  },
  {
    name: "Organ Inspection",
    color: "maroon",
    value: "25",
  },
  {
    name: "Retrieval Initiated",
    color: "navy",
    value: "26",
  },
  {
    name: "Retrieval Complete",
    color: "purple",
    value: "27",
  },
  {
    name: "Transplant Complete",
    value: "28",
    color: "green",
  },
  {
    name: "No Takers List Generated 2",
    color: "blue",
    value: "29",
  },
  {
    name: "No Takers Priority List Generated 2",
    color: "blue",
    value: "30",
  },
  {
    name: "ALF list Genrated",
    color: "blue",
    value: "31",
  },
  {
    name: "ALF Priority List Confirmed",
    color: "teal",
    value: "32",
  },
  {
    name: "Reassign List Generated",
    color: "blue",
    value: "33",
  },
  {
    name: "Reassign Priority List Generated",
    color: "blue",
    value: "34",
  },
  {
    name: "Reassign Priority List Confirmed",
    color: "teal",
    value: "35",
  },
  {
    name: "No Takers Priority List 1 Confirmed",
    color: "teal",
    value: "36",
  },
  {
    name: "No Takers Priority List 2 Confirmed",
    color: "teal",
    value: "37",
  },
  {
    name: "Rota 1 Priority List Confirmed",
    color: "teal",
    value: "38",
  },
  {
    name: "Rota 2 Priority List Confirmed",
    color: "teal",
    value: "39",
  },
  {
    name: "Rota 3 Priority List Confirmed",
    color: "teal",
    value: "40",
  },
  {
    name: "Allocated to Other State",
    color: "teal",
    value: "41",
  },
  {
    name: "Tissue Bank Completed",
    color: "teal",
    value: "42",
  },
];

export const RECIPIENT_ORGAN_ALLOCATION_STATUS = [
  {
    name: "Priority Allocation",
    value: "1",
  },
  {
    name: "Standby 1",
    value: "2",
  },
  {
    name: "Standby 2",
    value: "3",
  },
];
export const DONOR_TIMER = {
  2: 55,
  4: 40,
  33: 55,
  29: 40,
};
