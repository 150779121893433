<template>
  <div>
    <b-dropdown-item-button @click="dialog = true">
      License List</b-dropdown-item-button
    >
    <b-modal centered v-model="dialog" title="License List" hide-footer>
      <b-table
        outlined
        :fields="headers"
        :busy="loading"
        :items="list"
        :items-per-page="5"
      >
        <template #table-busy>
          <div class="text-center text-info my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="mx-2">Loading...</strong>
          </div>
        </template>
        <template #cell(status)="{ item }">
          {{ getStatus(item) }}
        </template>
      </b-table>
      <div class="d-flex">
        <div class="flex-grow-1"></div>
        <b-button variant="outline-danger" outlined @click="dialog = false">
          Close
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { DONOR_TYPES } from "../../../assets/constants/hospital.contants";
import { formatTable, getStatus } from "../../../assets/js/common";
import hospitalService from "../../../assets/services/hospital.service";
export default {
  props: {
    hospital: Object,
  },
  data() {
    return {
      list: [],
      loading: true,
      dialog: false,
      isLoaded: false,
    };
  },
  computed: {
    headers() {
      let list = [];
      let headers = {
        sno: "S No",
        certificate_no: "Certificate No",
        status: "Status",
      };
      Object.keys(headers).forEach((key) => {
        let label = headers[key];
        let doc = {
          label,
          key,
        };
        list.push(doc);
      });
      return list;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.fetch();
      }
    },
  },
  methods: {
    getStatus({ status }) {
      return getStatus(status);
    },
    getLicense({ license_type }) {
      let item = DONOR_TYPES.find((item) => {
        return item.value == license_type;
      });
      return item != null ? item.name : "";
    },
    async fetch() {
      const vm = this;
      try {
        if (vm.isLoaded) return;
        vm.loading = true;
        let id = vm.hospital.hospital_id;
        let { data } = await hospitalService.getHospitalLicenses(id);
        let { status, msg, list } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.loading = false;
        vm.list = list.map((item, i) => {
          item.sno = i + 1;
          item.license_type = vm.getLicense(item);
          item.start_date = formatTable(item.start_date);
          item.end_date = formatTable(item.end_date);
          return item;
        });
        vm.isLoaded = true;
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
