<template>
  <Expandable header="Transplant Registration Details" class="mb-10">
    <b-row class="pb-0">
      <b-col cols="6" v-if="false">
        <yes-no
          @input="input"
          :disabled="disabled"
          label="Whether registered for transplant?"
          v-model="form.registered_for_transplant"
        ></yes-no>
      </b-col>
      <b-col cols="6" v-if="false">
        <yes-no
          label="Whether Corneal bank?"
          :disabled="disabled"
          @input="input"
          v-model="form.corneal"
        ></yes-no>
      </b-col>
      <b-col cols="12">
        <input-group
          :disabled="disabled"
          v-model.trim="form.certificate_no"
          @input="input"
          label="Reference No. from TRANSTAN"
          rules="required|max-100"
        >
          <template v-slot:help>
            <image-help
              tip="This number is found on the approval letter issued by TRANTAN"
              :src="require('../../../assets/transplant_certificate.png')"
            ></image-help>
          </template>
        </input-group>
      </b-col>

      <b-col cols="12" md="4">
        <date-picker
          :disabled="disabled"
          @input="input"
          rules="required"
          :max="new Date()"
          label="Transplant First Registration"
          v-model="form.transplant_first_registration"
        ></date-picker>
      </b-col>
      <b-col cols="12" md="4">
        <date-picker
          @input="input"
          :disabled="disabled"
          :rules="expiryRules"
          label="License Expiry Date"
          v-model="form.license_expiry_date"
        ></date-picker>
      </b-col>
      <!-- <b-col cols="12" md="4">
        <date-picker
          @input="input"
          label="Start Date"
          v-model="form.start_date"
        ></date-picker>
      </b-col>
      <b-col cols="12" md="4">
        <date-picker
          @input="input"
          label="End Date"
          v-model="form.end_date"
        ></date-picker>
      </b-col> -->
      <b-col cols="12" md="4">
        <input-field
          v-model.trim="form.NOTTO_document"
          label="NOTTO Registration No"
          outlined
          :disabled="disabled"
          v-if="false"
          counter="100"
          @input="input"
          dense
          :rules="getMaxNotRules(100)"
        >
          <template v-slot:append>
            <help text="Optional Field"></help>
          </template>
        </input-field>
      </b-col>
    </b-row>
  </Expandable>
</template>

<script>
import { FIELD, getMaxRules, getMaxNotRules } from "../../../assets/js/rules";
import { DONOR_TYPES } from "../../../assets/constants/hospital.contants";
import Expandable from "../../Expandable.vue";
import YesNo from "../../form/YesNo.vue";
import DatePicker from "../../form/DatePicker.vue";
import moment from "moment-timezone";
import hospitalCreateMixins from "../../../assets/mixins/hospital.create.mixins";
import ImageHelp from "../../ImageHelp.vue";
import Help from "../../Help.vue";

export default {
  props: {
    value: Object,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [hospitalCreateMixins],
  components: { Expandable, YesNo, DatePicker, ImageHelp, Help },
  data() {
    return {
      rules: FIELD,
      form: {
        registered_for_transplant: "",
        license_type: "",
        transplant_first_registration: "",
        license_expiry_date: "",
        corneal: "",
        certificate_no: "",
        NOTTO_document: "",
      },
    };
  },
  computed: {
    disabled() {
      return this.isDisabled || (this.isUpdate && !this.isAdmin);
    },
    types() {
      return DONOR_TYPES;
    },
    years() {
      let year = 1899;
      let list = [];
      while (year < new Date().getFullYear()) {
        year++;
        list.push(year);
      }
      return list.reverse();
    },
    max() {
      return moment().format();
    },
    expiryRules() {
      let isBefore = false;
      let { transplant_first_registration, license_expiry_date } = this.form;
      console.log(this.form);
      let first = moment(transplant_first_registration, "YYYY-MM-DD");
      if (transplant_first_registration) {
        isBefore = moment(license_expiry_date, "YYYY-MM-DD").isBefore(first);
      }
      return isBefore ? "required|expiry_date" : "required";
    },
  },
  methods: {
    getMaxRules(max) {
      return getMaxRules(max);
    },
    getMaxNotRules(max) {
      return getMaxNotRules(max);
    },
    input() {
      console.log("welcome");
      this.$emit("input", this.form);
    },
    init() {
      const vm = this;
      Object.keys(vm.value).forEach((key) => {
        vm.$set(vm.form, key, vm.value[key]);
      });
    },
  },
  beforeMount() {
    this.init();
    this.input();
  },
};
</script>

<style></style>
