<template>
  <Expandable header="Donor Details" class="mb-10">
    <b-row>
      <b-col cols="12" md="4">
        <input-field
          v-model="form.donor_name"
          label="Donor Name"
          :disabled="isDisabled"
          @input="onInput"
          invalid="Enter"
          rules="required|alpha"
        >
        </input-field>
      </b-col>
      <b-col cols="12" md="4">
        <input-field
          v-model="form.donor_age"
          type="number"
          label="Donor Age"
          :disabled="isDisabled"
          @input="onInput"
          rules="required"
        >
        </input-field>
      </b-col>
      <b-col cols="12" md="4">
        <select-field
          :items="genders"
          v-model="form.donor_sex"
          label="Donor Gender"
          :disabled="isDisabled"
          @input="onInput"
          rules="required"
        >
        </select-field>
      </b-col>
      <b-col cols="12" md="4">
        <select-field
          @input="onInput"
          label="Donor Blood Group"
          :items="bloodGroups"
          :disabled="isDisabled"
          v-model="form.donor_blood_group"
          rules="required"
        ></select-field>
      </b-col>
      <b-col cols="12" md="4">
        <select-field
          @input="onInput"
          v-model="form.relationship"
          :disabled="isDisabled"
          :items="contactTypes"
          label="Donor Relationship"
          rules="required"
        >
        </select-field>
      </b-col>
      <b-col cols="12" md="4" v-if="form.relationship == 38">
        <input-field
          v-model="form.others"
          label="If others Specify"
          :disabled="isDisabled"
          @input="onInput"
          rules="required"
        >
        </input-field>
      </b-col>
      <b-col cols="12" md="4">
        <text-area
          v-model="form.donor_address"
          label="Donor Address"
          :disabled="isDisabled"
          @input="onInput"
          rules="required"
        >
        </text-area>
      </b-col>
      <b-col cols="12" md="4" v-if="false">
        <phone-field
          v-model.trim="form.donor_phone_number"
          label="Donor Phone Number"
          rules="required"
        ></phone-field>
      </b-col>
      <b-col cols="12" md="4" v-if="false">
        <input-field
          v-model.trim="form.donor_email_id"
          label="Donor Email"
          rules="email|max-100"
        ></input-field>
      </b-col>
    </b-row>
  </Expandable>
</template>

<script>
import Expandable from "../../../Expandable.vue";
import SelectField from "../../../form/SelectField.vue";
import InputField from "../../../form/InputField.vue";
import { DONOR_MASTER_TYPE } from "../../../../assets/js/common";
import TextArea from "../../../form/TextArea.vue";
import { GENDERS } from "../../../../assets/constants/hospital.contants";
import PhoneField from "../../../form/PhoneField.vue";
export default {
  props: {
    value: {},
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { Expandable, SelectField, InputField, TextArea, PhoneField },
  data() {
    return {
      form: {},
      genders: GENDERS,
    };
  },
  computed: {
    contactTypes() {
      return this.masterContactTypes.filter((item) => {
        return item.contact_type == DONOR_MASTER_TYPE;
      });
    },
  },
  methods: {
    onInput() {
      this.$emit("input", this.form);
    },
  },
  beforeMount() {
    this.form = this.value;
  },
};
</script>

<style></style>
