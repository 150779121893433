<template>
  <div class="d-flex align-center">
    <b-button icon="x-octagon-fill" @click="dialog = true" variant="primary">
      Update</b-button
    >
    <b-modal
      tabindex="0"
      v-model="dialog"
      scrollable
      title="Update Transplant Surgery Details"
      hide-footer
      ignore-enforce-focus-selector=".select2-search__field"
      size="lg"
      centered
    >
      <form-wrapper @submit="submit">
        <b-row>
          <b-col cols="12" md="4">
            <input-field
              label="Inpatient Number"
              rules="required|max-num-9"
              v-model="form.inpatient_number"
            ></input-field>
          </b-col>
          <b-col cols="12" md="4">
            <date-picker
              label="Surgery Start Date"
              rules="required"
              @input="onStartDate"
              :min="min"
              v-model="form.date_of_surgery"
            ></date-picker>
          </b-col>
          <b-col cols="12" md="4">
            <time-picker
              label="Surgery Start Time"
              @input="onStartTime"
              rules="required"
              v-model="form.time_of_surgery"
            ></time-picker>
          </b-col>
          <b-col cols="12" md="4">
            <input-field
              label="Status After Surgery"
              rules="required"
              v-model="form.status_after_surgery"
            ></input-field>
          </b-col>
          <b-col cols="12" md="4">
            <input-field
              label="Remarks"
              rules="required"
              v-model="form.remarks"
            ></input-field>
          </b-col>
          <b-col cols="12" md="4">
            <input-field
              label="Warm Ischaemia Time"
              rules="required"
              v-model="form.ischaemia_warm"
            ></input-field>
          </b-col>
          <b-col cols="12" md="4">
            <input-field
              label="Cold Ischaemia Time"
              rules="required"
              v-model="form.ischaemia_cold"
            ></input-field>
          </b-col>
          <b-col cols="12" md="4">
            <date-picker
              label="Surgery Completed Date"
              rules="required"
              :min="minEnd"
              ref="endDate"
              v-model="form.end_date"
            ></date-picker>
          </b-col>
          <b-col cols="12" md="4">
            <time-picker
              label="Surgery Completed Time"
              rules="required"
              :minTime="minTime"
              v-model="form.end_time"
            ></time-picker>
          </b-col>
        </b-row>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" class="ms-3" type="submit"
            >Submit
          </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import { openFile } from "../../../../../assets/js/common";
import donorService from "../../../../../assets/services/donor.service";
import DatePicker from "../../../../form/DatePicker.vue";
import InputField from "../../../../form/InputField.vue";
import TimePicker from "../../../../form/TimePicker.vue";
export default {
  components: { InputField, DatePicker, TimePicker },
  props: {
    recipeint: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      dialog: false,
      form: {
        end_date: "",
        end_time: "",
      },
    };
  },
  computed: {
    min() {
      return moment(this.recipeint.retrieval_on)
        .add(-1, "day")
        .format("YYYY-MM-DD");
    },
    minTime() {
      return this.form.time_of_surgery;
    },
    minEnd() {
      let date = this.recipeint.retrieval_on;
      if (this.form.date_of_surgery) date = this.form.date_of_surgery;
      return moment(date).add(-1, "day").format("YYYY-MM-DD");
    },
  },
  methods: {
    onStartDate() {
      this.form.end_date = "";
      this.form.end_time = "";
    },
    onStartTime() {
      this.form.end_time = "";
    },
    reset() {
      this.form = {};
    },
    open(file) {
      openFile(file);
    },
    async submit() {
      const vm = this;
      let { organ_allocation_id, donor, organ_id, final_donor_id } = vm.item;
      let { case_officer, donor_temp_registration_id } = donor;
      let { recipient_registration_id, recepeint_allocation_id, hospital_id } =
        vm.recipeint;
      let doc = {
        recipient_surgery_id: 0,
        status_after_surgery: 1,
        recepeint_allocation_id,
        organ_allocation_id,
        final_donor_id,
        donor_temp_registration_id,
        case_officer,
        hospital_id,
        organ_id,
        recepient_registration_id: recipient_registration_id,
        created_by: vm.created_by,
        updated_by: vm.created_by,
        dml_indicator: "I",
      };
      Object.keys(vm.form).forEach((key) => {
        doc[key] = vm.form[key];
      });
      try {
        vm.$loader.show();
        let { data } = await donorService.recipientSurgeryDetail(doc);
        let { status, msg } = data;
        vm.dialog = false;
        if (!status) {
          vm.$alert.show(msg);
        }
        vm.$emit("onSubmit");
        vm.$loader.hide();
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
  },
  mounted() {
    console.log("retrivel", this.recipeint);
  },
};
</script>

<style></style>
