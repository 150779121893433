<template>
  <div v-if="!isLoading">
    <form-wrapper @submit="submit">
      <ALFDetails :isDisabled="isDisabled" v-model="alf_details"></ALFDetails>
      <ALFFooter v-if="isEditable" :status="status" class="mb-4"></ALFFooter>
    </form-wrapper>
    <Details v-model="details" :isDisabled="true"></Details>
    <Address
      v-model="address"
      :isDisabled="true"
      :isInternational="isInternational"
    ></Address>
    <organ-requested
      :details="details"
      :isDisabled="true"
      v-model="organ_requested"
    ></organ-requested>
    <consultant-details
      :isDisabled="true"
      :organs="organ_requested"
      :hospital_id="details.hospital_id"
      v-model="consultant"
    ></consultant-details>
    <declaration-attachments
      :isDisabled="true"
      v-model="declarationFiles"
      ref="declarationAttachments"
      :filesList="filesList"
      @onFileRemoved="onFileRemoved($event, 'declarationAttachments')"
    ></declaration-attachments>
    <medical-details>
      <recipient-medical-details
        :isDisabled="true"
        v-model="recipient_medical_details"
      ></recipient-medical-details>
      <virology-status
        v-model="virology_status"
        :isDisabled="true"
      ></virology-status>
    </medical-details>
    <vaccination-status
      v-model="vaccination_status"
      :isDisabled="true"
    ></vaccination-status>
    <recipient-medical-attachments
      v-model="medicalFiles"
      ref="medicalAttachments"
      v-if="false"
      :isDisabled="true"
      :filesList="filesList"
      @onFileRemoved="onFileRemoved($event, 'medicalAttachments')"
    ></recipient-medical-attachments>
    <attachments
      :isDisabled="true"
      v-model="files"
      ref="attachments"
      :filesList="filesList"
      @onFileRemoved="onFileRemoved($event, 'attachments')"
    ></attachments>
    <lung-medical-details
      v-if="isParameter(parameter.LUNG_ID)"
      v-model="lung"
      :isDisabled="true"
    ></lung-medical-details>
    <liver-medical-details
      v-if="
        isParameter(parameter.LIVER_ID) || isParameter(parameter.PANCREAS_ID)
      "
      v-model="liver"
      :isDisabled="true"
    ></liver-medical-details>
    <kidney-medical-details
      v-if="isParameter(parameter.KIDNEY_ID)"
      :isDisabled="true"
      v-model="kidney"
    ></kidney-medical-details>
    <heart-medical-details
      v-if="isParameter(parameter.HEART_ID)"
      v-model="heart"
      :isDisabled="true"
    ></heart-medical-details>
    <contacts v-model="contacts" :isDisabled="true" :count="3"></contacts>
  </div>
</template>

<script>
import menuConstants from "../../assets/constants/menu.constants";
import {
  decode,
  HEART_ID,
  KIDNEY_ID,
  LIVER_ID,
  LUNG_ID,
  PANCREAS_ID,
} from "../../assets/js/common";
import alfHelper from "../../components/managealf/alf.helper";
import ALFDetails from "../../components/managealf/create/ALFDetails.vue";
import ALFFooter from "../../components/managealf/create/ALFFooter.vue";
import Address from "../../components/managerecipient/create/Address.vue";
import Details from "../../components/managerecipient/create/Details.vue";
import Contacts from "../../components/managerecipient/create/RecipientContacts.vue";
import MedicalDetails from "../../components/managerecipient/create/MedicalDetails.vue";
import OrganRequested from "../../components/managerecipient/create/OrganRequested.vue";
import VirologyStatus from "../../components/managerecipient/create/VirologyStatus.vue";
import ConsultantDetails from "../../components/managerecipient/create/ConsultantDetails.vue";
import RecipientMedicalDetails from "../../components/managerecipient/create/HealthDetails.vue";
import VaccinationStatus from "../../components/managerecipient/create/VaccinationStatus.vue";
import HeartMedicalDetails from "../../components/managerecipient/create/HeartMedicalDetails.vue";
import KidneyMedicalDetails from "../../components/managerecipient/create/KidneyMedicalDetails.vue";
import LiverMedicalDetails from "../../components/managerecipient/create/LiverMedicalDetails.vue";
import LungMedicalDetails from "../../components/managerecipient/create/LungMedicalDetails.vue";
import Attachments from "../../components/managerecipient/create/Attachments.vue";
import RecipientMedicalAttachments from "../../components/managerecipient/create/RecipientMedicalAttachments.vue";
import DeclarationAttachments from "../../components/managerecipient/create/DeclarationAttachments.vue";
import recipientHelper from "../../components/managerecipient/recipient.helper";

export default {
  components: {
    ALFDetails,
    ALFFooter,
    Details,
    Address,
    Contacts,
    ConsultantDetails,
    OrganRequested,
    MedicalDetails,
    VirologyStatus,
    RecipientMedicalDetails,
    VaccinationStatus,
    HeartMedicalDetails,
    KidneyMedicalDetails,
    LiverMedicalDetails,
    LungMedicalDetails,
    Attachments,
    RecipientMedicalAttachments,
    DeclarationAttachments,
  },
  data() {
    return {
      contacts: [],
      files: [],
      medicalFiles: [],
      filesList: [],
      declarationFiles: [],
      isLoading: true,
      parameter: {
        HEART_ID,
        KIDNEY_ID,
        LIVER_ID,
        LUNG_ID,
        PANCREAS_ID,
      },
      details: {
        patient_nationlity: 0,
        aadhar_number: "",
        passport: "",
        embassy_documents: null,
        first_name: "",
        last_name: "",
        blood_group: "",
        status: 1,
        created_by: "",
        dml_indicator: "I",
        hospital_id: "",
        hospital_status: 1,
        transtan_status: "1",
        CM_insurance: "",
        CM_insurance_notes: "",
        country_code_1: "",
        phone_number_1: "",
        email_id: "",
        payment_reference_no: "",
        DOB: "",
        age: "",
      },
      address: {
        address_line_1: "",
        address_line_2: "",
        town_village: "",
        is_permanent_address: 0,
        landmark: "",
        country: "99",
        state: "32",
        city: "0",
        pincode: "",
        aadhar_flag: 0,
        created_by: "",
        recipient_address_mapping_id: "",
        recipient_registration_id: "",
        dml_indicator: "I",
      },
      aadhar_address: {
        aadhar_name: "",
        address_line_1: "",
        address_line_2: "",
        town_village: "",
        is_permanent_address: 1,
        recipient_address_mapping_id: "",
        recipient_registration_id: "",
        landmark: "",
        country: "99",
        state: "32",
        city: "0",
        pincode: "",
        created_by: "",
        aadhar_flag: 1,
        dml_indicator: "I",
      },
      lung: {
        recipent_lungs_id: "",
        cause_of_lung_disease: "",
        cause_of_lung_disease_notes: "",
        FEV1: "",
        FVC: "",
        MVV: "",
        DLCO: "",
        self_on_RA: "",
        supplement_O2: "",
        NIV: "",
        mechanical_ventilation: "",
        ECMO: "",
        room_AIR: "",
        room_air_file_name: null,
        on_oxygen: "",
        on_oxygen_file_name: null,
        non_transplant_heart: "",
        lung_surgery: "",
        lung_walk_test_distance: "",
        lung_walk_test_duration: "",
      },
      kidney: {
        recipent_kidney_id: "",
        kidney_urea: "",
        kidney_failure: "",
        first_dialysis_date: "",
        period_undergoing_dialysis: "",
        se_creatinine: "",
        sodium: "",
        potassium: "",
        chloride: "",
        Bi_carbonate: "",
        lymphocyte_cross_match: "",
        CMV: "",
        IGG: "",
        TB_quantiferon: "",
        C_reative_protein: "",
      },
      heart: {
        recipent_heart_id: "",
        NYHA_grade: "",
        cardiac_index: "",
        PVR_index: "",
        PVRI_unit: "1",
        PVRI: "",
        TPG: "",
        walk_test_duration: "",
        walk_test_distance: "",
        heart_non_transplant_heart: "",
        heart_lung_surgery: "",
        NT_pro_BNP: "",
        PRA_status: "",
      },
      liver: {
        recipent_liver_id: "",
        history_of_complications: "",
        complication_description: "",
        cancer_screening: "",
        coronary_angiogram: "",
        stress_test: "",
        room_air_ABG: "",
        liver_PFT: "",
        urea: "",
        creatinine: "",
        uric_acid: "",
        serum_sodium: "",
        serum_potassium: "",
        serum_chloride: "",
        serum_bicarbonate: "",
        serum_magnesium: "",
        serum_phosphorus: "",
        MELD_score: "",
        bilirubin: "",
        total_protein: "",
        albumin: "",
        globulin: "",
        GGT: "",
        AST: "",
        ALT: "",
        INR: "",
        APTT: "",
        platelets: "",
        fibrinogen: "",
      },
      virology_status: {
        virology_status_HBsAg: "",
        virology_status_HBcAb: "",
        virology_status_HCV: "",
        virology_status_epstein_barr: "",
        virology_status_HIV: "",
        virology_status_CMV: "",
        anti_HBsAg: "",
      },
      organ_requested: {
        organ_id: [],
      },
      consultant: {
        consultant_detail: "",
      },
      recipient_medical_details: {
        height: "",
        weight: "",
        BMI: "",
        smoker: "",
        alcohol: "",
        period_of_abstinence: "",
        drugs: "",
        hypertension: "",
        diabetes: "",
        CAD: "",
        bronchial_asthma_COPD: "",
        epilepsy: "",
        hepatitis_B: "",
        hepatitis_C: "",
        history_of_TB: "",
        history_of_malignancy: "",
        type_of_malignancy: "",
        duration_of_remission: "",
        history_of_peripheral_vascular_disease: "",
        history_of_previous_transplants: "",
        history_of_previous_transplants_date: "",
        organ_transplanted: "",
        history_of_covid: "",
        covid_free_period: "",
        hemogram: "",
        ChestXRay: "",
        ECG: "",
        Echo: "",
        relevant_remarks: "",
        tissue_typing: "",
        Live_cadaver: "",
      },
      vaccination_status: [],
      vaccination_status_item: {
        recipient_vaccination_mapping_id: "",
        recipient_registration_id: "",
        master_vaccination_id: "",
        vaccination_date: "",
        status: 1,
        created_by: "",
        dml_indicator: "I",
      },
      updateDoc: {},
      alf_details: {
        file_name: null,
        master_alf_id: "",
        consultant_short_summary: null,
        additional_hepatology_notes: null,
      },
      status: 1,
      doc: {},
      isLoadding: true,
    };
  },
  computed: {
    id() {
      let { id } = this.$route.query;
      id = decode(id);
      return id;
    },
    isCreate() {
      return this.$route.name == "create-alf";
    },
    isInternational() {
      return this.details.patient_nationlity == 1;
    },
    isEditable() {
      let { hospital_id } = this.doc;
      if (this.$route.query.view == "true") return false;
      return (
        this.isAdmins ||
        (this.isHospitalContact && this.user.hospital_id == hospital_id)
      );
    },
    isDisabled() {
      if (this.isCreate) return false;
      if (this.$route.query.view == "true") return true;
      let hasAccess = this.hasEditAccess(menuConstants.ACUTE_LIVER_FAILURE);
      return !hasAccess || this.status == 4 || !this.isEditable;
    },
  },
  methods: {
    isParameter(id) {
      return this.organ_requested.organ_id.indexOf(id) != -1;
    },

    init() {
      const vm = this;
      if (vm.isCreate) {
        recipientHelper.getRecipient(vm, vm.id);
      } else {
        alfHelper.getALF(vm);
      }
    },
    submit() {
      const vm = this;
      alfHelper.create(vm);
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
