<template>
  <div>
    <icon-button
      icon="card-checklist"
      variant="success"
      @click="dialog = true"
    ></icon-button>
    <b-modal
      centered
      scrollable
      v-model="dialog"
      title="ALF Approver List"
      hide-footer
      size="lg"
    >
      <b-table
        outlined
        :fields="headers"
        :items="list"
        :items-per-page="5"
        :busy="loading"
      >
        <template #table-busy>
          <div class="text-center text-info my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="mx-2">Loading...</strong>
          </div>
        </template>
        <template #cell(approval_status)="{ item }">
          {{ getStatus(item) }}
        </template>
        <template #cell(sno)="data">
          <div style="min-width: 30px">
            {{ data.index + 1 }}
          </div>
        </template>
      </b-table>
      <div class="d-flex">
        <div class="flex-grow-1"></div>
        <b-button variant="outline-danger" outlined @click="dialog = false">
          Close
        </b-button>
        <template v-if="isAdmins">
          <b-button
            variant="danger"
            class="ms-3"
            v-if="isRejectAble"
            outlined
            @click="reject()"
          >
            Reject
          </b-button>
          <b-button
            class="ms-3"
            v-if="isApprovable"
            @click="approve()"
            variant="success"
            outlined
          >
            Approve
          </b-button>
        </template>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ALF_REQUEST_STATUS } from "../../assets/constants/alf.constants";
import menuConstants from "../../assets/constants/menu.constants";
import alfService from "../../assets/services/alf.service";
import IconButton from "../buttons/IconButton.vue";
import alfHelper from "./alf.helper";
export default {
  components: { IconButton },
  props: {
    id: Number,
    status: Number,
  },
  data() {
    return {
      list: [],
      dialog: false,
      loading: false,
      isLoaded: false,
    };
  },
  computed: {
    headers() {
      let list = [];
      let headers = {
        sno: "S No",
        first_name: "Doctor Name",
        comments: "Comments",
        approval_status: "Status",
      };
      Object.keys(headers).forEach((key) => {
        let label = headers[key];
        let doc = {
          label,
          key,
        };
        list.push(doc);
      });
      return list;
    },
    isApprovable() {
      let list = this.list.filter((item) => {
        return item.approval_status == 0;
      });
      let hasAccess = this.hasStatusAccess(menuConstants.LIVER_URGENT_LIST, 3);
      return (
        hasAccess &&
        this.status == 2 &&
        this.list.length != 0 &&
        list.length == 0
      );
    },
    isRejectAble() {
      let list = this.list.filter((item) => {
        return item.approval_status == 0;
      });
      let hasAccess = this.hasStatusAccess(menuConstants.LIVER_URGENT_LIST, 5);
      return (
        hasAccess &&
        this.status == 2 &&
        this.list.length != 0 &&
        list.length == 0
      );
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.fetch();
      }
    },
  },
  methods: {
    getStatus({ approval_status }) {
      let item = ALF_REQUEST_STATUS.find((item) => {
        return item.value == approval_status;
      });
      return item != null ? item.name : "";
    },
    async approve() {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, You want to approve this ALF",
        onConfirm: () => {
          alfHelper.updateStatus(3, vm.id, vm);
        },
      });
    },
    reject() {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, You want to reject this ALF",
        onConfirm: () => {
          alfHelper.updateStatus(5, vm.id, vm);
        },
      });
    },
    async fetch() {
      const vm = this;
      try {
        if (vm.isLoaded == true) return;
        vm.loading = true;
        let { data } = await alfService.getALFApproverList(vm.id);
        let { status, msg, list } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.loading = false;
        vm.list = list;
        vm.isLoaded = true;
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
