import http from "../js/axios";
import { HOSPITAL_TYPES } from "../constants/hospital.contants";
import { formatTable } from "../js/common";
class HospitalSerive {
  create(data) {
    return http.post("/hospital/hospital_creation", data);
  }
  update(data) {
    return http.post("/hospital/hospital_creation", data);
  }
  updateStatus(data) {
    return http.post("/hospital/hospitalstatus", data);
  }
  removeFile(hospital_license_fileupload_mapping_id) {
    return http.post("/hospital/hospital_license_file_delete", {
      hospital_license_fileupload_mapping_id,
    });
  }

  get(doc) {
    doc.dml_indicator = "E";
    return http.post("/hospital/hospital_list", doc);
  }
  getHospitalName() {
    return http.post("/userlogin/hospital_name_list", { dml_indicator: "HS" });
  }
  validateEmailPhone(doc) {
    return http.post("/hospital/email_phone_check", doc);
  }
  list(doc) {
    doc.dml_indicator = "L";
    return http.post("/hospital/hospital_list", doc);
  }
  getHospitalContacts(hospital_id) {
    return http.post("/hospital/hospital_list", {
      dml_indicator: "CL",
      hospital_id,
    });
  }
  getHospitalLicenses(hospital_id) {
    return http.post("/hospital/hospital_list", {
      dml_indicator: "LL",
      hospital_id,
    });
  }
  getHospitalOrgans(hospital_id) {
    return http.post("/hospital/hospital_list", {
      dml_indicator: "OL",
      hospital_id,
    });
  }

  upload(data) {
    return http.post("/ByteArrayAPI/hospital_license_files", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  uploadContact(data) {
    return http.post("/ByteArrayAPI/contact_person_fileup_load", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  uploadVideo(data) {
    return http.post("/ByteArrayAPI/video_fileupload", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  parseItem(item) {
    item.type = this.getHospitalType(item.hospital_type);
    item.expiry_date = formatTable(item.license_expiry_date);
    return item;
  }
  getHospitalType(type) {
    let item = HOSPITAL_TYPES.find((item) => {
      return item.value == type;
    });
    return item != null ? item.name : "";
  }
  generatePaymentBill(hospital_id) {
    return http.post("/hospital/payment_billdesk", {
      hospital_type: "1",
      hospital_id,
    });
  }
  getLiverHospital() {
    return http.post("/donor_registration/rota_hospital_order_list", {
      dml_indicator: "HL",
    });
  }
  getRotaOrder() {
    return http.post("/donor_registration/rota_hospital_order_list", {
      dml_indicator: "S",
    });
  }
  addRotaOrder(data) {
    return http.post("/donor_registration/rota_hospital_order", data);
  }
  initPayment(data) {
    return http.post("/hospital/payment_details", data);
  }
}
export default new HospitalSerive();
