<template>
  <div>
    <div class="h5 py-2 px-2 section_subheader mt-3 mb-3">Virology Status</div>
    <b-row>
      <b-col cols="12" md="4">
        <positive-negative
          v-model="form.virology_status_HBsAg"
          @input="onInput"
          :disabled="isDisabled"
          label="HbsAg"
          rules="required"
        ></positive-negative>
      </b-col>
      <b-col cols="12" md="4">
        <positive-negative
          v-model="form.anti_HBsAg"
          :disabled="isDisabled"
          @input="onInput"
          label="Anti HBsAg"
        ></positive-negative>
      </b-col>

      <b-col cols="12" md="4">
        <positive-negative
          @input="onInput"
          v-model="form.virology_status_HCV"
          label="HCV"
          :disabled="isDisabled"
        ></positive-negative>
      </b-col>
      <b-col cols="12" md="4">
        <positive-negative
          v-model="form.virology_status_epstein_barr"
          @input="onInput"
          label="Epstein Barr"
          :disabled="isDisabled"
        ></positive-negative>
      </b-col>
      <b-col cols="12" md="4">
        <positive-negative
          v-model="form.virology_status_HIV"
          rules="required"
          :disabled="isDisabled"
          label="HIV"
          @input="onInput"
        ></positive-negative>
      </b-col>
      <b-col cols="12" md="4">
        <positive-negative
          v-model="form.virology_status_CMV"
          :disabled="isDisabled"
          label="CMV"
          @input="onInput"
        ></positive-negative>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PositiveNegative from "../../form/PositiveNegative.vue";
export default {
  props: {
    value: {},
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { PositiveNegative },
  data() {
    return {
      form: {},
    };
  },
  computed: {},
  methods: {
    onInput() {
      this.$emit("input", this.form);
    },
  },
  beforeMount() {
    this.form = this.value;
  },
};
</script>

<style></style>
