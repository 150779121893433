<template>
  <div>
    <allocation-mobile-view-tabs
      :tabs="tabs"
      v-model="tab"
      v-if="isMobile"
    ></allocation-mobile-view-tabs>
    <div class="allocation__view">
      <div
        class="allocation__view-left"
        style="padding-left: 0px"
        v-if="!isMobile"
      >
        <b-tabs v-model="tab" class="oa-tabs" pills card vertical :value="tab">
          <b-tab v-for="(item, i) in tabs" :key="i" :disabled="item.disabled">
            <template #title>
              <p class="mb-0">
                {{ item.name }}
              </p>
              <div class="font-size-10">
                {{ item.subtitle }}
              </div>
              <div class="font-size-10 mt-1">
                {{ item.created_on }}
              </div>
            </template>
          </b-tab>
        </b-tabs>
      </div>
      <div
        class="allocation__view-right p-0 oa-tabs-content"
        style="margin-left: 0px"
      >
        <generate-rota-waiting-list
          :hospitals="hospitals"
          :item="item"
          v-if="tabItem.status == 1"
        ></generate-rota-waiting-list>
        <allocating-waiting-list
          :isLast="isLast"
          :hospitals="hospitals"
          :item="item"
          :status="tabItem.status"
          v-if="isAllocate(tabItem)"
        ></allocating-waiting-list>
        <update-my-waitlist
          :hospitals="hospitals"
          :history="history"
          :status="tabItem.status"
          :isLast="isLast"
          :item="item"
          v-if="isAllocateUpdate(tabItem)"
        ></update-my-waitlist>
        <generate-provisional-wait-list
          :hospitals="hospitals"
          :status="tabItem.status"
          :isLast="isLast"
          :item="item"
          v-if="isProvision"
        ></generate-provisional-wait-list>
        <update-logistics-list
          :item="item"
          v-if="tabItem.status == 7"
          :isLast="isLast"
        ></update-logistics-list>
        <organ-infection-list
          :item="item"
          v-if="tabItem.status == 25"
          :isLast="isLast"
        >
        </organ-infection-list>
        <retrieval-list
          :item="item"
          v-if="tabItem.status == 26"
          :isLast="isLast"
        ></retrieval-list>
        <transplant-surgery
          :item="item"
          :isLast="isLast"
          v-if="tabItem.status == 28"
        ></transplant-surgery>
        <final-allocation-list
          :item="item"
          :isLast="isLast"
          v-if="tabItem.status == 18"
        ></final-allocation-list>
      </div>
    </div>
  </div>
</template>

<script>
import AllocatingWaitingList from "./steps/AllocatingWaitingList.vue";
import FinalAllocationList from "./steps/FinalAllocationList.vue";
import GenerateProvisionalWaitList from "./steps/GenerateProvisionalWaitList.vue";
import UpdateLogisticsList from "./steps/UpdateLogisticsList.vue";
import UpdateMyWaitlist from "./steps/UpdateMyWaitlist.vue";
import GenerateRotaWaitingList from "./steps/GenerateRotaWaitingList.vue";
import OrganInfectionList from "./steps/OrganInfectionList.vue";
import TransplantSurgery from "./steps/TransplantSurgery.vue";
import RetrievalList from "./steps/RetrievalList.vue";
import AllocationMobileViewTabs from "../mobile/AllocationMobileViewTabs.vue";
import { formatDate } from "../../../assets/js/common";
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    history: {
      type: Array,
      default: () => [],
    },
    hospitals: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    UpdateMyWaitlist,
    AllocatingWaitingList,
    GenerateProvisionalWaitList,
    UpdateLogisticsList,
    GenerateRotaWaitingList,
    FinalAllocationList,
    OrganInfectionList,
    TransplantSurgery,
    RetrievalList,
    AllocationMobileViewTabs,
  },
  data() {
    return {
      tab: null,
    };
  },
  computed: {
    isLast() {
      return this.tab == this.tabs.length - 1;
    },
    tabItem() {
      return this.tabs[this.tab] || {};
    },
    isProvision() {
      let status = [22, 23, 24, 32];
      return status.indexOf(this.tabItem.status) != -1;
    },
    tabs() {
      let { allocation_status, organ_allocation_id } = this.item;
      let list = [
        {
          subtitle: "Wait List",
          disabled: allocation_status != 1,
          status: 1,
        },
        {
          subtitle: "ALF list",
          status: 31,
        },
        {
          subtitle: "Priority List",
          status: 32,
        },
        {
          subtitle: "Rota 1 List",
          status: 14,
        },
        {
          subtitle: "Update List",
          status: 14.1,
        },
        {
          subtitle: "Priority List",
          status: 22,
        },
        {
          subtitle: "Rota 2 List",
          status: 15,
        },
        {
          subtitle: "Update List",
          status: 15.1,
        },
        {
          subtitle: "Priority List",
          status: 23,
        },
        {
          subtitle: "Rota 3 List",
          status: 16,
        },
        {
          subtitle: "Update List",
          status: 16.1,
        },
        {
          subtitle: "Priority List",
          status: 24,
        },
        {
          subtitle: "Logistics",
          status: 7,
        },
        {
          subtitle: "Inspection List",
          status: 25,
        },
        {
          subtitle: "Final Allocation",
          status: 18,
        },
        {
          subtitle: "Organ Retrieval",
          status: 26,
        },
        {
          subtitle: "Transplant Surgery",
          status: 28,
        },
      ];
      if (this.isAdmins) {
        list.splice(4, 1);
        list.splice(6, 1);
        list.splice(8, 1);
      }

      list = list.filter((item, i) => {
        let status = item.status;
        if (status == 14.1) status = 14;
        if (status == 15.1) status = 15;
        if (status == 16.1) status = 16;
        let history = this.history.find((doc) => {
          return (
            doc.status == status &&
            doc.organ_allocation_id == organ_allocation_id
          );
        });
        if (item.status == 1) return true;
        if (history != null) {
          list[i].created_on = formatDate(history.created_on);
        }

        return allocation_status == item.status || history != null;
      });
      return list.map((item, i) => {
        item.name = `Step ${i + 1}`;
        return item;
      });
    },
    fields() {
      let doc = {
        name: "Name",
        email_id: "Email ID / User ID",
        contact_name: "Contact Type",
        phone_number_1: "Phone Number",
        status: "Status",
        action: "Action",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
          tdClass: key == "action" ? "width-100" : "",
        });
      });
      return list;
    },
  },
  methods: {
    isAllocate({ status }) {
      return status == 14 || status == 15 || status == 16 || status == 31;
    },
    isAllocateUpdate({ status }) {
      return status == 14.1 || status == 15.1 || status == 16.1;
    },
  },
  mounted() {
    setTimeout(() => {
      this.tab = this.tabs.length - 1;
    }, 1000);
  },
};
</script>

<style lang="scss">
.oa-tabs {
  padding: 0px 24px !important;
  height: auto;
  &-content {
    margin-right: 12px;
    margin-left: 12px;
    flex: 1;
  }
  .col-auto {
    padding: 0px;
    overflow: hidden;
    background-color: transparent;
  }
  .tab-content {
    padding: 0px;
  }
  ul {
    background-color: transparent;
    padding: 0px;
  }
  ul:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(50% - 1px);
    border-left: 2px dashed #556ee6;
  }
  li {
    text-align: center;
    z-index: 100;
    margin-bottom: 50px;
    background-color: white;
    box-shadow: 0 0.5rem 1rem rgb(18 38 63 / 3%);
    border-radius: 0.25rem;
    white-space: nowrap;
    .nav-link {
      border-radius: 0.25rem;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>
