<template>
  <b-dropdown variant="link">
    <template #button-content>
      <b-icon icon="three-dots" class="font-size-16"></b-icon>
    </template>
    <contacts :hospital="hospital"></contacts>
  </b-dropdown>
</template>

<script>
import Contacts from "./Contacts.vue";
export default {
  props: {
    hospital: Object,
  },
  components: { Contacts },
  data: () => ({
    menu: false,
  }),
};
</script>
<style></style>
