<template>
  <table-view
    :items="formatedList"
    :isBusy="loading"
    :fields="headers"
    :isFilter="!isHospitalContact"
    :isFooter="!isHospitalContact"
    :title="isHospitalContact ? '' : 'Hospital List'"
  >
    <template v-slot:filter v-if="isAdmin">
      <filter-select
        :items="statusList"
        class="ms-2"
        v-model="status"
        placeholder="All Status"
      ></filter-select>
      <filter-select
        :items="masterZones"
        class="ms-2"
        v-model="zone"
        placeholder="All Zone"
      ></filter-select>
    </template>
    <template v-slot:status="{ item }">
      {{ getStatus(item) }}
    </template>
    <template v-slot:action="{ item }">
      <div class="d-flex">
        <Menu :hospital="item"></Menu>
        <view-button v-if="isView(item)" @click="open(item)"></view-button>
        <edit-button v-else @click="open(item)"></edit-button>
        <delete-button
          @click="remove(item)"
          v-if="isDeleteAble(item)"
        ></delete-button>
        <approve-button
          @click="approve(item)"
          v-if="isApprovable(item)"
        ></approve-button>
        <payment-icon-button
          @click="initPayment(item)"
          v-if="isPayment(item)"
        ></payment-icon-button>
      </div>
    </template>
  </table-view>
</template>

<script>
import { encode, initPayment } from "../../assets/js/common";
import hospitalService from "../../assets/services/hospital.service";
import DeleteButton from "../buttons/DeleteButton.vue";
import EditButton from "../buttons/EditButton.vue";
import Menu from "./list/Menu.vue";
import commonMixins from "../../assets/mixins/common.mixins";
import TableView from "../table/TableView.vue";
import ApproveButton from "../buttons/ApproveButton.vue";
import ViewButton from "../buttons/ViewButton.vue";
import { STATUS } from "../../assets/constants/hospital.contants";
import FilterSelect from "../form/FilterSelect.vue";
import menuConstants from "../../assets/constants/menu.constants";
import PaymentIconButton from "../buttons/PaymentIconButton.vue";
import moment from "moment";
export default {
  props: {
    value: Object,
  },
  mixins: [commonMixins],
  components: {
    TableView,
    EditButton,
    Menu,
    DeleteButton,
    ApproveButton,
    ViewButton,
    FilterSelect,
    PaymentIconButton,
  },
  data() {
    return {
      list: [],
      loading: true,
      filter: {
        search: "",
        perPage: 10,
      },
      status: null,
      zone: null,
      totalRows: 1,
      currentPage: 1,
      filterOn: [],
    };
  },
  computed: {
    statusList() {
      let list = [];
      Object.keys(STATUS).forEach((value) => {
        list.push({
          name: STATUS[value],
          value,
        });
      });
      return list;
    },
    formatedList() {
      const vm = this;
      let list = vm.list
        .map((item) => {
          item.zone = vm.getZone(item);
          return item;
        })
        .reverse();
      list = list.filter((item) => {
        if (vm.status == null) return true;
        return item.status == vm.status;
      });
      list = list.filter((item) => {
        if (vm.zone == null) return true;
        return item.zone_id == vm.zone;
      });
      list = list.map((item, i) => {
        item.sno = i + 1;
        return item;
      });
      let pending = list.filter((item) => {
        return item.status == 1;
      }).length;
      let live = list.filter((item) => {
        return item.status == 2;
      }).length;
      let expired = list.filter((item) => {
        return item.status == 3;
      }).length;
      let deleted = list.filter((item) => {
        return item.status == 4;
      }).length;
      let hospital_all = pending + live + expired + deleted;
      vm.$emit("input", { pending, live, expired, deleted, hospital_all });

      return list;
    },
    master() {
      return this.$store.getters.master;
    },
    zones() {
      if (this.master.master_zone instanceof Array == false) return [];
      return this.master.master_zone.map((item) => {
        return { value: item.zone_id, name: item.zone_name };
      });
    },
    headers() {
      let list = [];
      let headers = {
        sno: "S No",
        hospital_name: "Hospital Name",
        hospital_registration_no: "Hospital License Number",
        type: "Hospital Type",
        zone: "Zone",
        expiry_date: "License Expiry Date",
        status: "Status",
        action: "Action",
      };
      Object.keys(headers).forEach((key) => {
        let label = headers[key];
        let doc = {
          label,
          sortable: true,
          key,
          tdClass: key == "action" ? "width-100" : "",
        };
        list.push(doc);
      });
      return list;
    },
    rows() {
      return this.formatedList.length;
    },
  },
  methods: {
    async initPayment(item) {
      let { hospital_id } = item;
      const vm = this;
      try {
        let doc = { dml_indicator: "NH", hospital_id };
        doc.dml_indicator = vm.isPaymentExpiry(item) ? "RH" : "NH";
        vm.$loader.show();
        let data = (await hospitalService.initPayment(doc)).data;
        initPayment(data);
        vm.$loader.hide();
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
    isPayment({ payment_flag, license_expiry_date, hospital_type }) {
      if (hospital_type != 2) return false;
      var duration = moment.duration(
        moment(license_expiry_date).diff(moment())
      );
      return payment_flag == 0 || payment_flag == 2 || duration.asMonths() <= 1;
    },
    isPaymentExpiry({ payment_flag, license_expiry_date }) {
      var duration = moment.duration(
        moment(license_expiry_date).diff(moment())
      );
      return (
        payment_flag == 2 || (payment_flag == 1 && duration.asMonths() <= 1)
      );
    },
    getStatus({ status }) {
      return STATUS[status] || "";
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    isDeleteAble({ status }) {
      if (!this.hasStatusAccess(menuConstants.MANAGE_HOSPITAL, 4)) return false;
      return status != 4;
    },
    isView({ status }) {
      if (!this.hasEditAccess(menuConstants.MANAGE_HOSPITAL)) return false;
      return status == 4;
    },
    isApprovable({ status }) {
      if (!this.hasStatusAccess(menuConstants.MANAGE_HOSPITAL, 2)) return false;
      return status != 2 && status != 4;
    },
    open(item) {
      let { hospital_id } = item;
      let id = encode(hospital_id);
      this.$router.push(`/update-hospital?id=${id}`);
    },
    getZone({ zone_id }) {
      let item = this.zones.find((item) => {
        return item.value == zone_id;
      });
      return item != null ? item.name : "";
    },
    remove({ hospital_id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do want to remove this hospital?",
        onConfirm: () => {
          vm.changeStatus({
            hospital_id,
            status: 4,
          });
        },
      });
    },
    approve({ hospital_id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want to approve this hospital?",
        onConfirm: () => {
          vm.changeStatus({
            hospital_id,
            status: 2,
          });
        },
      });
    },
    async changeStatus(doc) {
      const vm = this;
      try {
        vm.$loader.show();
        let { data } = await hospitalService.updateStatus(doc);
        let { status, msg } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.fetch();
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },

    async fetch() {
      const vm = this;
      try {
        vm.loading = true;
        vm.list = [];
        let { data } = await hospitalService.list({});
        console.log(data);
        let { status, msg, list } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.loading = false;
        vm.list = list.map((item) => {
          return hospitalService.parseItem(item);
        });
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style></style>
