<template>
  <div style="padding-bottom: 100px">
    <table-view
      :title="title"
      class="noselect"
      :fields="fields"
      :isBusy="isLoading"
      :items="live"
    >
      <template v-slot:filter>
        <filter-select
          v-if="isHeartLungs"
          :items="heartLungsList"
          class="ms-2"
          v-model="heartLungs"
          placeholder="All"
        ></filter-select>
      </template>
      <template v-slot:sno="{ item }">
        <div
          class="badge bg-warning font-size-13"
          v-if="item.patient_nationlity == 1"
        >
          {{ item.sno }}
          <i class="mdi mdi-airplane-takeoff"></i>
        </div>
        <span v-else>
          {{ item.sno }}
        </span>
      </template>
      <template v-slot:payment_status="{ value }">
        {{ getPaymentStatus(value) }}
      </template>
      <template v-slot:transtan_status="{ value }">
        <div :class="getBg(getPatientStatus(value))">
          {{ getPatientStatus(value) }}
        </div>
      </template>
      <template v-slot:hospital_status="{ value }">
        {{ getBeReadyStatus(value) }}
      </template>
      <template v-slot:age="{ value }">
        <div :class="value > 60 ? 'badge bg-danger  font-size-13' : ''">
          {{ value }}
        </div>
      </template>
      <template v-slot:CM_insurance="{ value }">
        {{ getCMInsuranceStatus(value) }}
      </template>
      <template v-slot:gender="{ value }">
        {{ getGender(value) }}
      </template>
    </table-view>
  </div>
</template>

<script>
import TableView from "../table/TableView.vue";
import { PATIENT_STATUS } from "../../assets/constants/recipient.constants";
import FilterSelect from "../form/FilterSelect.vue";
import { HEART_LUNGS } from "../../assets/constants/waiting.list.constants";
export default {
  components: {
    TableView,
    FilterSelect,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Waiting List",
    },
    list: Array,
    isSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDraftLoading: false,
      status: null,
      heartLungs: null,
      heartLungsList: HEART_LUNGS,
    };
  },
  watch: {
    title() {
      if (this.isHeartLungs) {
        this.status = "3";
      }
    },
  },
  computed: {
    isHeartLungs() {
      return this.title.endsWith("Heart & lungs");
    },
    statusList() {
      let allowed = ["3", "10"];
      return PATIENT_STATUS.filter((item) => {
        return allowed.indexOf(item.value) != -1;
      });
    },
    live() {
      let list = this.list.map((item) => {
        return item;
      });
      let filter = this.heartLungs;
      if (this.isHeartLungs && filter != null) {
        list = list.filter(({ organ_id }) => {
          let isFilter = true;
          if (organ_id) {
            let split = organ_id.split(",").map((id) => {
              return id.trim().toString();
            });
            if (filter == "12,19,20") {
              let organs = filter.split(",").map((id) => {
                return id.trim().toString();
              });
              split.forEach((id) => {
                if (organs.indexOf(id) == -1) isFilter = false;
              });
            } else {
              isFilter = filter == split.toString();
            }
          } else {
            isFilter = false;
          }
          return isFilter;
        });
      }
      return list;
    },
    fields() {
      let doc = {
        sno: "S.No",
        group_desc: "Blood Group",
        hospital_name: "Hospital Name",
        organ_name: "Organ Name",
        city: "City",
        created_on: "Date of Registration",
        zone_rank_id: "Rank within Zone",
        recipient_registration_id: "UID",
      };
      console.log(this.isSearch)
      if (this.isSearch) delete doc.rank;
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
        });
      });
      return list;
    },
  },
  methods: {
    getBg(val) {
      switch (val) {
        case "Active":
          return "badge bg-success font-size-13";
        case "Pending Approval":
          return "badge bg-warning font-size-13";
        case "Inactive":
          return "badge bg-danger font-size-13";
        case "Deleted":
          return "badge bg-danger font-size-13";
        default:
          return "badge bg-light font-size-13";
      }
    },
  },
};
</script>

<style>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
