<template>
  <div class="d-flex align-center">
    <b-button icon="x-octagon-fill" @click="init()" variant="primary">
      {{ isLogisticUpdate ? "Update" : "View" }}
    </b-button>
    <b-modal
      tabindex="0"
      v-model="dialog"
      title="Logistics Planning"
      hide-footer
      ignore-enforce-focus-selector=".select2-search__field"
      size="lg"
      scrollable
      centered
    >
      <form-wrapper @submit="submit" ref="form">
        <template v-if="isLogisticUpdate">
          <div class="d-flex align-items-center">
            <b-row class="flex-grow-1">
              <b-col cols="12" md="4">
                <input-field
                  label="Contact Name"
                  rules="required"
                  v-model="form.person_name"
                ></input-field>
              </b-col>
              <b-col cols="12" md="4">
                <phone-field
                  label="Mobile Number"
                  rules="required|integer"
                  v-model="form.phone_number"
                ></phone-field>
              </b-col>
              <b-col cols="12" md="4">
                <file-input-group
                  label="Transport Details"
                  accept=".jpg,.jpeg,.png,.pdf"
                  rules="required|file-image-pdf|file-size"
                  v-model="form.file_name"
                ></file-input-group>
              </b-col>
            </b-row>
          </div>
          <div class="d-flex mb-3">
            <div class="flex-grow-1"></div>
            <b-button variant="success" type="submit">Submit </b-button>
          </div>
        </template>

        <table class="table align-middle">
          <thead class="table-light">
            <tr>
              <th class="align-middle">Contact Name</th>
              <th class="align-middle">Mobile Number</th>
              <th class="align-middle">Transport Details</th>
              <th class="align-middle" v-if="isLogisticUpdate">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in list" :key="i">
              <td>{{ item.person_name }}</td>
              <td>{{ item.phone_number }}</td>
              <td>
                <b-button
                  size="sm"
                  @click="open(item.file_name)"
                  variant="outline-primary"
                  >View File</b-button
                >
              </td>
              <td>
                <b-button
                  size="sm"
                  v-if="isLogisticUpdate"
                  @click="edit(item)"
                  variant="outline-secondary"
                  >Edit</b-button
                >
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger" @click="dialog = false"
            >Cancel
          </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import { openFile } from "../../../../../assets/js/common";
import donorService from "../../../../../assets/services/donor.service";
// import donorService from "../../../../../assets/services/donor.service";
import FileInputGroup from "../../../../form/FileInputGroup.vue";
import InputField from "../../../../form/InputField.vue";
import PhoneField from "../../../../form/PhoneField.vue";
import userHelper from "../../../../manageuser/user.helper";
// import userHelper from "../../../../manageuser/user.helper";
export default {
  components: { InputField, FileInputGroup, PhoneField },
  props: {
    recipeint: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      dialog: false,
      form: {},
      list: [],
      options: [
        {
          name: "Accept",
          value: "1",
        },
        {
          name: "Reject",
          value: "2",
        },
      ],
    };
  },
  computed: {
    isLogisticUpdate() {
      if (this.item.allocation_status != 7) return false;
      if (this.isAdmins) return true;
      let { hospital_id } = this.user;
      return this.recipeint.hospital_id == hospital_id;
    },
    fields() {
      let doc = {
        first_name: "Name",
        phone_number_1: "Phone Number",
        photo: "View Transport Details",
        action: "Allocation Status",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
        });
      });
      return list;
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.$emit("onUpdate");
      }
    },
  },
  methods: {
    reset() {
      this.form = {
        person_name: "",
        phone_number: "",
        file_name: null,
        logistics_id: "",
      };
    },
    edit(item) {
      const vm = this;
      Object.keys(vm.form).forEach((key) => {
        if (item[key] != undefined) vm.form[key] = item[key];
      });
    },
    open(file) {
      openFile(file);
    },
    async submit() {
      const vm = this;
      let { person_name, phone_number, file_name, logistics_id } = vm.form;
      let { organ_allocation_id, donor, organ_id, final_donor_id } = vm.item;
      let { case_officer, donor_temp_registration_id } = donor;
      let { recipient_registration_id, recepeint_allocation_id, hospital_id } =
        vm.recipeint;
      let doc = {
        logistics_id,
        recepeint_allocation_id,
        organ_allocation_id,
        final_donor_id,
        donor_temp_registration_id,
        case_officer,
        hospital_id,
        organ_id,
        file_name,
        recepient_registration_id: recipient_registration_id,
        person_name,
        phone_number,
        created_by: vm.created_by,
        updated_by: vm.created_by,
        dml_indicator: "I",
      };
      try {
        vm.$loader.show();
        if (doc.file_name instanceof File) {
          doc.file_name = await userHelper.uploadContact(doc.file_name);
        }
        let { data } = await donorService.createLogistics({
          logistics_table_list: [doc],
        });
        let { status, msg } = data;
        if (!status) {
          vm.$alert.show(msg);
        }
        vm.init();
        vm.$refs.form.reset();
        vm.$loader.hide();
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
    async init() {
      const vm = this;
      vm.reset();
      let { recepeint_allocation_id } = vm.recipeint;
      const doc = {
        dml_indicator: "S",
        recepeint_allocation_id,
      };
      try {
        vm.list = [];
        vm.$loader.show();
        let { data } = await donorService.logisticsList(doc);
        let { list } = data;
        vm.isLoading = false;
        vm.$loader.hide();
        vm.list = list;
        vm.dialog = true;
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
