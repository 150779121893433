<template>
  <ValidationProvider :rules="rules" :vid="vid" v-slot="validationContext">
    <b-form-group :invalid-feedback="message(validationContext)" class="mb-3">
      <template v-slot:label>
        <span v-html="formattedLabel"></span>
      </template>
      <b-form-input
        v-model="model"
        type="number"
        :id="id"
        :disabled="disabled"
        :state="getValidationState(validationContext)"
        @input="onInput"
      ></b-form-input>
    </b-form-group>
  </ValidationProvider>
</template>
<script>
import { BFormInput } from "bootstrap-vue";
import intlTelInput from "intl-tel-input";
import allCountries from "../phone/all-countries";

export default {
  components: {
    BFormInput,
  },
  inheritAttrs: false,
  props: {
    defaultCountry: {
      default: "in",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    vid: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
    },
    type: {
      type: String,
      default: "text",
    },
    invalid: {
      type: String,
      default: "This value must be a valid phone number",
    },
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      model: "",
      isValid: false,
      id: new Date().getTime().toString(),
    };
  },
  computed: {
    getCountryCode() {
      let country = "in";
      let item = allCountries.find((item) => {
        return item.dialCode == this.defaultCountry;
      });
      if (item != null) country = item.iso2;
      return country;
    },
    formattedLabel() {
      if (!this.rules) return this.label;
      if (this.rules.indexOf("required") != -1)
        return this.label + " <span style='color:red'>*</span>";
      return this.label;
    },
  },
  watch: {
    value(val) {
      this.model = val;
    },
  },
  methods: {
    message() {
      return this.invalid;
    },
    getValidationState({ dirty, validated, valid = null }) {
      if (validated && this.rules == "" && this.model == "") return true;
      if (validated && !this.isValid) return false;
      return dirty || validated ? valid : null;
    },
    onInput() {
      const vm = this;
      var input = document.getElementById(vm.id);
      var iti = window.intlTelInputGlobals.getInstance(input);
      setTimeout(() => {
        vm.isValid = iti.isValidNumber();
      }, 100);
      let code = iti.selectedCountryData.dialCode;
      vm.$emit("onCountryCode", code);
      vm.$emit("input", vm.model);
    },
  },
  mounted() {
    const vm = this;
    vm.model = vm.value;
    const input = document.getElementById(vm.id);
    intlTelInput(input, {
      utilsScript: require("intl-tel-input/build/js/utils"),
      initialCountry: vm.getCountryCode,
      onlyCountries: ["in"],
      customPlaceholder: () => {
        return "9999999999";
      },
    });
    window.intlTelInputGlobals.getInstance(input);
    input.addEventListener("countrychange", function () {
      vm.onInput();
    });
    vm.onInput();
  },
};
</script>
<style lang="scss">
.iti.iti--allow-dropdown {
  width: 100% !important;
}
</style>
