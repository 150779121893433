<template>
  <b-dropdown variant="link" dropleft class="dropleft" v-if="isMenu()">
    <template #button-content>
      <b-icon icon="three-dots" class="font-size-16"></b-icon>
    </template>
    <!-- <b-dropdown-item v-if="isMarkDeath(item)" @click="markAsDealth()"
      >Mark as Death</b-dropdown-item
    > -->
    <b-dropdown-item
      v-if="isDocumentVerified(item)"
      @click="markAsDocumentVerified()"
      >Mark as Document Verified</b-dropdown-item
    >
    <!-- <b-dropdown-item v-if="isMarkInActive(item)" @click="markAsInactive()"
      >Mark as Inactive</b-dropdown-item
    > -->
    <recipient-in-active-reason
      v-if="isMarkInActive(item)"
      @fetch="$emit('fetch')"
      :item="item"
    ></recipient-in-active-reason>
    <b-dropdown-item v-if="isMarkActive(item)" @click="markAsActive()"
      >Mark as Active</b-dropdown-item
    >
    <b-dropdown-item
      v-if="isMarkInactiveToActive(item)"
      @click="markAsInactiveToActive()"
      >Mark as Active</b-dropdown-item
    >
    <b-dropdown-item
      v-if="isExtendedByOneYear(item)"
      @click="markAsExtendedByOneYear()"
      >Mark Extended By One Year</b-dropdown-item
    >
    <b-dropdown-item
      v-if="isMarkOrganAllocate(item)"
      @click="markOrganAllocate()"
      >Mark as Organ Allocated</b-dropdown-item
    >
  </b-dropdown>
</template>

<script>
import moment from "moment";
import menuConstants from "../../assets/constants/menu.constants";
import {
  HEART_ID,
  LEFT_LUNG_ID,
  LUNG_ID,
  RIGHT_LUNG_ID,
} from "../../assets/js/common";
import RecipientInActiveReason from "./RecipientInActiveReason.vue";
export default {
  components: { RecipientInActiveReason },
  props: {
    item: {},
  },
  computed: {
    html() {
      let item = this.item;
      return `<b-row class="pb-0">
          <b-col cols="12" class="d-flex align-items-center">
            <div class="text-muted">UID -</div>
            <div class="h6 mb-0">&nbsp;${item.recipient_registration_id}</div>
          </b-col>
          <b-col cols="12" class="mt-2  mb-3 d-flex align-items-center">
            <div class="text-muted">Name -</div>
            <div class="h6 mb-0">&nbsp;${item.first_name}</div>
          </b-col>
        </b-row>`;
    },
  },
  methods: {
    isMenu() {
      let item = this.item;
      return (
        // this.isMarkDeath(item) ||
        this.isMarkInActive(item) ||
        this.isMarkActive(item) ||
        this.isDocumentVerified(item) ||
        this.isMarkInactiveToActive(item) ||
        this.isMarkOrganAllocate(item) ||
        this.isExtendedByOneYear(item)
      );
    },
    isMarkDeath({ transtan_status }) {
      let statusList = [2, 3, 6];
      let hasAccess = this.hasStatusAccess(menuConstants.MANAGE_RECIPIENT, 4);
      return hasAccess && statusList.indexOf(transtan_status) != -1;
    },
    isMarkInActive({ transtan_status, organ_id }) {
      let organs = [LEFT_LUNG_ID, RIGHT_LUNG_ID, LUNG_ID, HEART_ID];
      let list = [];
      if (organ_id) {
        list = organ_id.split(",").map((id) => {
          return id.trim().toString();
        });
      }
      let isAllowed = true;
      organs.forEach((id) => {
        if (list.indexOf(id) != -1) isAllowed = false;
      });
      if (!isAllowed) return;
      let hasAccess = this.hasStatusAccess(menuConstants.MANAGE_RECIPIENT, 10);
      return hasAccess && transtan_status == 3;
    },
    isMarkInactiveToActive({ transtan_status }) {
      let hasAccess = this.hasStatusAccess(menuConstants.MANAGE_RECIPIENT, 11);
      return hasAccess && transtan_status == 10;
    },
    isExtendedByOneYear({ transtan_status, created_on, organ_id }) {
      let hasAccess = this.hasStatusAccess(menuConstants.MANAGE_RECIPIENT, 6);
      let year = moment.duration(moment().diff(moment(created_on))).asYears();
      let isExtended = false;
      if (organ_id) {
        let organs = organ_id.split(",").map((item) => {
          return item.trim().toString();
        });
        isExtended =
          year < 2 &&
          (organs.indexOf(LUNG_ID.toString()) != -1 ||
            organs.indexOf(HEART_ID.toString()) != -1);
      }
      return (
        hasAccess &&
        (transtan_status == 5 || transtan_status == 3) &&
        isExtended
      );
    },
    isMarkActive({ transtan_status }) {
      let hasAccess = this.hasStatusAccess(menuConstants.MANAGE_RECIPIENT, 3);
      return hasAccess && transtan_status == 10;
    },
    isDocumentVerified({ transtan_status }) {
      let hasAccess = this.hasStatusAccess(menuConstants.MANAGE_RECIPIENT, 12);
      return hasAccess && transtan_status == 2;
    },
    isMarkOrganAllocate({ transtan_status }) {
      let hasAccess = this.hasStatusAccess(menuConstants.MANAGE_RECIPIENT, 13);
      return hasAccess && transtan_status == 3;
    },
    markAsExtendedByOneYear() {
      const vm = this;
      let { recipient_registrationid } = vm.item;
      vm.$confirm.show({
        message: `${vm.html}Are you sure, Do want to mark this recipient as extended by one year?`,
        onConfirm: () => {
          vm.$emit("onChangeStatus", {
            recipient_registration_id: recipient_registrationid,
            transtan_status: 6,
            dml_indicator: "A",
          });
        },
      });
    },
    markAsDealth() {
      const vm = this;
      let { recipient_registrationid } = vm.item;
      vm.$confirm.show({
        message: `${vm.html}Are you sure, Do want to mark this recipient as death?`,
        onConfirm: () => {
          vm.$emit("onChangeStatus", {
            recipient_registration_id: recipient_registrationid,
            transtan_status: 4,
            dml_indicator: "A",
          });
        },
      });
    },
    markAsInactive() {
      const vm = this;
      let { recipient_registrationid } = vm.item;
      vm.$confirm.show({
        message: `${vm.html}Are you sure, Do want to mark this recipient as Inactive?`,
        onConfirm: () => {
          vm.$emit("onChangeStatus", {
            recipient_registration_id: recipient_registrationid,
            transtan_status: 10,
            dml_indicator: "A",
          });
        },
      });
    },
    markOrganAllocate() {
      const vm = this;
      let { recipient_registrationid } = vm.item;
      vm.$confirm.show({
        message: `${vm.html}Are you sure, Do want to mark this recipient as organ allocated?`,
        onConfirm: () => {
          vm.$emit("onChangeStatus", {
            recipient_registration_id: recipient_registrationid,
            transtan_status: 13,
            dml_indicator: "A",
          });
        },
      });
    },
    markAsInactiveToActive() {
      const vm = this;
      let { recipient_registrationid } = vm.item;
      vm.$confirm.show({
        message: `${vm.html}Are you sure, Do want to mark this recipient as active?`,
        onConfirm: () => {
          vm.$emit("onChangeStatus", {
            recipient_registration_id: recipient_registrationid,
            transtan_status: 11,
            dml_indicator: "A",
          });
        },
      });
    },
    markAsActive() {
      const vm = this;
      let { recipient_registrationid } = vm.item;
      vm.$confirm.show({
        message: `${vm.html}Are you sure, Do want to mark this recipient as active?`,
        onConfirm: () => {
          vm.$emit("onChangeStatus", {
            recipient_registration_id: recipient_registrationid,
            transtan_status: 3,
            dml_indicator: "A",
          });
        },
      });
    },
    markAsDocumentVerified() {
      const vm = this;
      let { recipient_registrationid } = vm.item;
      vm.$confirm.show({
        message: `${vm.html}Are you sure, Do want to mark this recipient as Document Verified?`,
        onConfirm: () => {
          vm.$emit("onChangeStatus", {
            recipient_registration_id: recipient_registrationid,
            transtan_status: 12,
            dml_indicator: "A",
          });
        },
      });
    },
  },
};
</script>

<style></style>
