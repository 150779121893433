<template>
  <header class="login__header">
    <div class=" d-flex align-center" :class="isWaitingList ? 'container-fluid px-4' : 'container'">
      <img class="logo__tn" src="@/assets/logo_mini.svg" alt @click="$router.push('/')" />
      <div class="flex-grow-1"></div>
      <img class="logo__transtan" src="@/assets/logo.png" alt />
    </div>
  </header>
</template>
<script>
export default {
  computed: {
        isWaitingList() {
      return this.$route.name == "commong-waiting-list";
    },

  }
}
</script>
<style lang="scss">
.login {
  &__header {
    background: white;
    height: 80px;
    .container, .container-fluid {
      height: 80px;
      align-items: center;
    }
    .logo__tn {
      height: 50px;
      width: 50px;
    }
    .logo__transtan {
      width: 256px;
      height: 50px;
    }
  }
}
</style>
