<template>
  <b-button
    class="help"
    v-b-tooltip.hover.bottom
    variant="outline-info"
    :title="text"
  >
    <b-icon class="font-size-16" icon="question" scale="1.5"> </b-icon>
  </b-button>
</template>

<script>
export default {
  props: {
    text: String,
  },
};
</script>

<style>
.help {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.help__prepend {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
</style>
