<template>
  <div>
    <Expandable header="Organ Consented" class="mb-10">
      <b-row>
        <b-col cols="12" md="4" v-for="(item, i) in organs" :key="i">
          <button-yes-no
            :label="item.name"
            @input="onInput"
            :disabled="isDisabled"
            v-model="form[item.value]"
          ></button-yes-no>
        </b-col>
      </b-row>
    </Expandable>
    <Expandable header="Tissue Consented" class="mb-10">
      <b-row>
        <b-col cols="12" md="4" v-for="(item, i) in tissues" :key="i">
          <button-yes-no
            :label="item.name"
            @input="onInput"
            :disabled="isDisabled"
            v-model="form[item.value]"
          ></button-yes-no>
        </b-col>
      </b-row>
    </Expandable>
  </div>
</template>

<script>
import Expandable from "../../Expandable.vue";
import ButtonYesNo from "../../form/ButtonYesNo.vue";
export default {
  props: {
    value: {},
    isDisabled: {
      default: false,
      type: Boolean,
    },
  },
  components: { Expandable, ButtonYesNo },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    organs() {
      if (this.master.master_organ instanceof Array == false) return [];
      let master = this.master.master_organ.filter((item) => {
        let { status, tissue_bank, consent_organ_id } = item;
        return status != 4 && consent_organ_id == 1 && tissue_bank == 0;
      });
      return master.map((item) => {
        return { value: item.organ_id, name: item.organ_name };
      });
    },
    tissues() {
      if (this.master.master_organ instanceof Array == false) return [];
      let master = this.master.master_organ.filter((item) => {
        let { status, tissue_bank, consent_organ_id } = item;
        return status != 4 && consent_organ_id == 1 && tissue_bank == 1;
      });
      return master.map((item) => {
        return { value: item.organ_id, name: item.organ_name };
      });
    },
  },
  methods: {
    onInput() {
      this.$emit("input", this.form);
    },
  },
  beforeMount() {
    if (this.value != undefined) this.form = this.value;
  },
  mounted() {},
};
</script>

<style></style>
