import {
  getDateOnly,
  getDocObject,
  isArray,
  isObject,
} from "../../assets/js/common";
import donorService from "../../assets/services/donor.service";
class ReceipienHelper {
  getAge(vm) {
    if (!vm.details.DOB) return "";
    var ageDifMs = Date.now() - new Date(vm.details.DOB);
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
  getDoc = (vm) => {
    let doc = {};
    if (!vm.details.individual_id) delete vm.details.individual_id;
    if (!vm.details.donor_temp_registration_id)
      delete vm.details.donor_temp_registration_id;
    doc = getDocObject(vm.details, doc);
    doc = getDocObject(vm.cause_of_death, doc);
    doc = getDocObject(vm.contact, doc);
    doc = getDocObject(vm.ventilator_settings, doc);
    doc = getDocObject(vm.injuries, doc);
    doc = getDocObject(vm.resistance_measure, doc);
    doc = getDocObject(vm.medical_details, doc);
    doc = getDocObject(vm.medication_inotrope, doc);
    doc = getDocObject(vm.apg_test, doc);
    doc = getDocObject(vm.apnea_test_details, doc);
    doc.age_registered_date = getDateOnly();
    doc.covid_free_period = vm.covid_free_period;
    doc.age = this.getAge(vm);
    doc.donor_medication = vm.medications;
    doc.donor_medical_detail = this.getMedicalDetails(vm);
    doc.donor_consented_organ = this.getOrganArray(vm);
    Object.keys(doc).forEach((key) => {
      if (doc[key] === "") {
        doc[key] = null;
      }
    });
    return doc;
  };
  getMedicalDetails = (vm) => {
    let { co_morbidities } = vm;
    let { donor_medical_detail } = vm.updateDoc;
    if (donor_medical_detail == undefined) donor_medical_detail = [];
    donor_medical_detail = donor_medical_detail.map((item) => {
      item.status = "4";
      return item;
    });
    Object.keys(co_morbidities).forEach((id) => {
      let status = co_morbidities[id] == 1 ? 1 : 4;
      let doc = {
        hospital_id: vm.details.hospital_id,
        master_donor_medical_detail_id: id,
        donor_temp_registration_id: 0,
        created_by: vm.created_by,
        status,
      };
      let index = -1;
      donor_medical_detail.forEach((item, i) => {
        if (item.organ_id == id) index = i;
      });
      if (index == -1) {
        donor_medical_detail.push(doc);
      } else {
        donor_medical_detail[index].status = status;
      }
    });
    return donor_medical_detail;
  };
  getOrganArray = (vm) => {
    let { donor_consented_organ } = vm.updateDoc;
    if (donor_consented_organ == undefined) donor_consented_organ = [];
    donor_consented_organ = donor_consented_organ.map((item) => {
      item.status = "4";
      return item;
    });
    Object.keys(vm.organ_requested).forEach((id) => {
      let status = vm.organ_requested[id] == 1 ? 1 : 4;
      let doc = {
        hospital_id: vm.details.hospital_id,
        organ_id: id,
        created_by: vm.created_by,
        status,
      };
      let index = -1;
      donor_consented_organ.forEach((item, i) => {
        if (item.organ_id == id) index = i;
      });
      if (index == -1) {
        donor_consented_organ.push(doc);
      } else {
        donor_consented_organ[index].status = status;
      }
    });
    return donor_consented_organ;
  };
  initUpdate(data, vm) {
    let {
      donor_list,
      donor_consented_organ,
      donor_medical_detail,
      donor_medication,
      donor_investigations_fileupload_mapping,
    } = data;
    if (isArray(donor_list)) {
      let doc = donor_list[0];
      vm.covid_free_period = doc.covid_free_period;
      if (isObject(doc)) {
        this.initDoc(vm, doc, "details");
        this.initDoc(vm, doc, "cause_of_death");
        this.initDoc(vm, doc, "contact");
        this.initDoc(vm, doc, "ventilator_settings");
        this.initDoc(vm, doc, "injuries");
        this.initDoc(vm, doc, "resistance_measure");
        this.initDoc(vm, doc, "medical_details");
        this.initDoc(vm, doc, "medication_inotrope");
        this.initDoc(vm, doc, "apg_test");
        this.initDoc(vm, doc, "apnea_test_details");
        vm.details.dml_indicator = "U";
      }
    }
    if (isArray(donor_consented_organ)) {
      donor_consented_organ.forEach((item) => {
        let { organ_id, status } = item;
        vm.organ_requested[organ_id] = status == 1 ? "1" : "0";
      });
    }
    if (isArray(donor_medical_detail)) {
      donor_medical_detail.forEach((item) => {
        let { master_donor_medical_detail_id, status } = item;
        vm.co_morbidities[master_donor_medical_detail_id] =
          status == 1 ? "1" : "0";
      });
    }
    if (isArray(donor_medication)) {
      vm.medications = donor_medication.map((item) => {
        return item;
      });
    }
    if (isArray(donor_investigations_fileupload_mapping)) {
      vm.filesList = donor_investigations_fileupload_mapping;
    }
    vm.isLoading = false;
  }
  initDoc(vm, doc, key) {
    Object.keys(vm[key]).forEach((i) => {
      if (doc[i] !== undefined) vm[key][i] = doc[i];
    });
  }
  async getRecipient(vm) {
    try {
      vm.$loader.show();
      vm.details.donor_temp_registration_id = vm.id;
      let { status, msg, data } = await donorService.get(vm.id);
      if (status) {
        console.log(data);
        vm.updateDoc = data;
        vm.$emit("input", data);
        this.initUpdate(data, vm);
        vm.$loader.hide();
      } else {
        vm.$alert.show(msg);
        vm.$router.replace("/manage-donor");
      }
    } catch (error) {
      vm.$loader.hide();
      vm.$alert.show(error.message);
    }
  }
  upload(doc, id, vm) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        var body = new FormData();
        let { file, file_upload_id } = doc;
        body.append("file_upload_id", file_upload_id);
        body.append("donor_temp_registration_id", id);
        body.append("created_by", vm.created_by);
        body.append("file", file);
        let { data } = await donorService.upload(body);
        let { status, msg } = data;
        let state = vm.isUpdate ? "updated" : "created";
        if (status) {
          resolve();
        } else {
          reject({
            message: `Donor ${state} successfully. <br/>Error while uploading document : ${msg}`,
          });
          vm.$router.push("/manage-donor");
        }
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new ReceipienHelper();
