import { getDocObject, isArray, isObject } from "../../../assets/js/common";
import liveTransplantService from "../../../assets/services/live.transplant.service";
class ReceipienHelper {
    getDoc = (vm) => {
        let doc = {};
        doc = getDocObject(vm.donor_details, doc);
        doc = getDocObject(vm.recipient_details, doc);
        doc = getDocObject(vm.surgery_details, doc);
        return doc;
    }
    initUpdate(data, vm) {
        let { list } = data;
        if (isArray(list)) {
            let doc = list[0];
            if (isObject(doc)) {
                this.initDoc(vm, doc, 'donor_details')
                console.log(vm.donor_details);
                this.initDoc(vm, doc, 'recipient_details')
                this.initDoc(vm, doc, 'surgery_details')
                vm.donor_details.dml_indicator = "U";
            }
        }
        vm.isLoading = false;
    }
    initDoc(vm, doc, key) {
        Object.keys(vm[key]).forEach(i => {
            if (doc[i] !== undefined) vm[key][i] = doc[i];
        })
    }
    async get(vm) {
        try {
            vm.$loader.show();
            vm.donor_details.live_transplant_detail_id = vm.id;
            let { status, msg, data } = await liveTransplantService.get(vm.id);
            if (status) {
                console.log(data);
                vm.updateDoc = data;
                this.initUpdate(data, vm);
            } else {
                vm.$alert.show(msg);
                vm.$router.replace("/live-transplant");
            }
            vm.$loader.hide();
        } catch (error) {
            vm.$loader.hide();
            vm.$alert.show(error.message);
        }
    }
}

export default new ReceipienHelper();