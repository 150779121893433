export default {
  headers: {
    details: "Details",
    address: "Additional Address",
    organ_requested: "Organ Requested",
    consultant: "Consultant Details",
    declaration_attachments: "Declaration Attachments",
    medical_details: "Medical Details",
    general_details: "General Details",
    history: "History",
    malignancy_status: "Malignancy Status",
    virology_status: "Virology Status",
    vaccination_status: "Vaccination Status",
    attachments: "Attachments",
    lung: "Evaluation Specific to Lung",
    lung_PFT: "PFT",
    lung_ventilary_status: "Ventilatory Status",
    lung_arterial_blood_gas: "Arterial Blood Gas",
    kidney: "Evaluation Specific to Kidney",
    heart: "Evaluation Specific to Heart",
    liver: "Evaluation Specific to Liver / Pancreas",
    liver_function: "Liver Function",
    liver_cardiac_evaluation: "Cardiac Evaluation",
    liver_pulmonary_evaluation: "Pulmonary Evaluation",
    liver_renal_evaluation: "Renal Evaluation",
    liver_coagulation_profile: "Coagulation Profile",
    contact: "Alternate Family Contact"
  },
  details: {
    uid: "Unique ID",
    hospital_name: "Hospital Name",
    created_on: "Date of Registration",
    patient_nationlity: "Recipient Nationality",
    aadhar_number: "Aadhar ID",
    passport: "Passport",
    passport_document: "Passport Document",
    embassy_documents: "Embassy Document",
    first_name: "Recipient Name",
    DOB: "Recipient Date of Birth",
    age: "Recipient Age",
    gender: "Gender",
    phone_number_1: "Phone Number",
    email_id: "Email",
    blood_group: "Blood Group",
    CM_insurance: "CM Insurance",
    CM_insurance_notes: "Insurance Document",
    notto_id: "NOTTO Registration No",
    payment_reference_no: "Payment Reference No",
  },
  address: {
    address_line_1: "Address Line 1",
    address_line_2: "Address Line 2",
    town_village: "Town Village",
    landmark: "Landmark",
    country: "Country",
    state: "State",
    city: "City",
    pincode: "Pincode",
  },
  organ_requested: {
    organ_id: "Organs",
  },
  consultant: {
    consultant_detail: "Consultant Name",
  },
  general_details: {
    height: "Height",
    weight: "Weight",
    BMI: "BMI",
  },
  history: {
    smoker: "Smoker",
    alcohol: "Alcohol",
    period_of_abstinence: "Period of Abstinence",
    drugs: "Drugs",
    hypertension: "Hypertension",
    diabetes: "Diabetes",
    CAD: "CAD",
    bronchial_asthma_COPD: "Bronchial Asthma/COPD/Other Lung Disease",
    epilepsy: "Epilepsy",
    hepatitis_B: "Hepatitis B",
    hepatitis_C: "Hepatitis C",
    history_of_TB: "History of TB",
    history_of_peripheral_vascular_disease:
      "History of Peripheral Vascular Disease",
    history_of_previous_transplants: "History of Previous Transplant",
    organ_transplanted: "Organ Transplanted",
    history_of_covid: "History of Covid",
    covid_free_period: "Covid Free Period",
  },
  malignancy_status: {
    history_of_malignancy: "History of Malignancy",
    type_of_malignancy: "Type of Malignancy",
    duration_of_remission: "Duration of remission",
  },
  virology_status: {
    virology_status_HBsAg: "HbsAg",
    virology_status_HCV: "HCV",
    virology_status_epstein_barr: "Epstein Barr",
    virology_status_HIV: "HIV",
    virology_status_CMV: "CMV",
    anti_HBsAg: "Anti HBsAg",
  },
  lung: {
    cause_of_lung_disease: "Cause of Lung Disease",
    cause_of_lung_disease_notes: "Cause of Lung Disease - Description",
    lung_walk_test_duration: "6 Minute Walk Test - Able to Complete",
    lung_walk_test_distance: "6 minute Walk Test Distance",
    non_transplant_heart:
      "History of Previous Non-Transplant Heart & Lung Surgery",
    lung_surgery: "Surgery details",
  },
  lung_PFT: {
    FEV1: "Forced Expiratory Volume in 1 second (FEV1)",
    FVC: "Forced Vital Capacity (FVC)",
    MVV: "Maximal Voluntary Ventilation(MVV)",
    DLCO: "DLCO",
  },
  lung_ventilary_status: {
    self_on_RA: "Self on Room air",
    supplement_O2: "Supplement O2",
    NIV: "Non-invasive ventilation (NIV)",
    mechanical_ventilation: "Mechanical Ventilation",
    ECMO: "ECMO",
  },
  lung_arterial_blood_gas: {
    room_AIR: "Room air",
    room_air_file_name: "Room air file",
    on_oxygen: "On Oxygen",
    on_oxygen_file_name: "On Oxygen File",
  },
  kidney: {
    kidney_urea: "Urea",
    se_creatinine: "Creatinine",
    sodium: "Serum Sodium",
    potassium: "Serum Potassium",
    chloride: "Serum Chloride",
    Bi_carbonate: "Serum Bicarbonate",
    first_dialysis_date: "First Dialysis Date",
    period_undergoing_dialysis: "Period Undergoing Dialysis",
  },
  heart: {
    cardiac_index: "Cardiac Index",
    TPG: "TPG Trans pulmonary gradient",
    PVRI: "PVRI",
    walk_test_duration: "6 Minute Walk Test - Able to Complete",
    walk_test_distance: "6 minute Walk Test Distance",
    NT_pro_BNP: "NT pro BNP",
    heart_non_transplant_heart:
      "History of Previous Non-Transplant Heart & Lung Surgery",
    heart_lung_surgery: "Surgery details",
  },
  liver: {
    history_of_complications: "History of Complications",
    complication_description: "Complication Description",
    cancer_screening: "Cancer Screening",
  },
  liver_function: {
    MELD_score: "MELD Score",
    bilirubin: "Bilirubin",
    albumin: "Albumin",
    globulin: "Globulin",
    GGT: "GGT",
    AST: "AST",
    ALT: "ALT",
  },
  liver_cardiac_evaluation: {
    coronary_angiogram: "Coronary Angiogram",
    stress_test: "Stress Test",
  },
  liver_pulmonary_evaluation: {
    room_air_ABG: "Room air - ABG",
    liver_PFT: "PFT",
  },
  liver_renal_evaluation: {
    urea: "Urea",
    creatinine: "Creatinine",
    uric_acid: "Uric Acid",
    serum_sodium: "Serum Sodium",
    serum_potassium: "Serum Potassium",
    serum_chloride: "Serum Chloride",
    serum_bicarbonate: "Serum Bicarbonate",
    serum_magnesium: "Serum Magnesium",
    serum_phosphorus: "Serum Phosphate",
  },
  liver_coagulation_profile: {
    INR: "INR",
    APTT: "APTT",
    platelets: "Platelets",
    fibrinogen: "Fibrinogen",
  },
  units: {
    MELD_score: "mg/dl",
    bilirubin: "mg/dl",
    albumin: "g/dl",
    globulin: "g/dl",
    GGT: "IU/L",
    AST: "IU/L",
    ALT: "IU/L",
    kidney_urea: "mg/dl",
    se_creatinine: "mg/dl",
    sodium: "mg/dl",
    potassium: "mg/dl",
    chloride: "mg/dl",
    Bi_carbonate: "mg/dl",
    period_undergoing_dialysis: "Months",
    TPG: "NA",
    walk_test_distance: "Meters",
    NT_pro_BNP: "%",
  },
};
