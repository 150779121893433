<template>
  <div>
    <allocation-recipient-table
      title="Organ Retrieval"
      :fields="fields"
      :isLast="isLast"
      :item="item"
      :isBusy="isLoading"
      :items="list"
    >
      <template v-slot:action="doc">
        <retrieval-details-view
          :recipeint="doc.item"
          :item="item"
          v-if="doc.item.organ_retrieval_status != 0"
        ></retrieval-details-view>
        <retrieval-details
          :recipeint="doc.item"
          v-if="isRetrievalUpdate(doc.item)"
          :item="item"
          @onSubmit="init()"
        ></retrieval-details>
      </template>
      <div class="d-flex mt-3" v-if="item.allocation_status == 26 && isAdmins">
        <!-- <re-assign-list
          :item="item"
          :status="status"
          @fetch="init()"
          :list="list"
          v-if="isReassign"
        ></re-assign-list>
        <b-button
          @click="dialog = true"
          class="me-3"
          variant="success"
          v-if="isNotaker1 || isNotaker2"
        >
          {{ noTakerTitle }}
        </b-button> -->
        <div class="flex-grow-1"></div>
        <b-button
          variant="primary"
          v-if="isUpdateTransplantSugery"
          @click="updateTransplantSugery()"
          >Request Transplant Surgery Details</b-button
        >
      </div>
    </allocation-recipient-table>
    <b-modal
      tabindex="0"
      v-model="dialog"
      hide-footer
      hide-header
      ignore-enforce-focus-selector=".select2-search__field"
      size="xl"
      body-class="px-0 py-0"
      centered
    >
      <generate-rota-waiting-list
        @close="dialog = false"
        v-if="item.type_of_allocation == 3"
        type="popup"
        :item="item"
      ></generate-rota-waiting-list>
      <generate-waiting-list
        @close="dialog = false"
        type="popup"
        v-else
        :item="item"
      ></generate-waiting-list>
    </b-modal>
  </div>
</template>

<script>
import {
  BE_READY_STATUS,
  PATIENT_STATUS,
  PAYMENT_STATUS,
} from "../../../../assets/constants/recipient.constants";
import { formatDate } from "../../../../assets/js/common";
import event from "../../../../assets/js/event";
import donorMixins from "../../../../assets/mixins/donor.mixins";
import donorService from "../../../../assets/services/donor.service";
import AllocationRecipientTable from "./AllocationRecipientTable.vue";
import GenerateRotaWaitingList from "./GenerateRotaWaitingList.vue";
import GenerateWaitingList from "./GenerateWaitingList.vue";
// import ReAssignList from "./provisionalwaitlist/ReAssignList.vue";
import RetrievalDetails from "./retrievalList/RetrievalDetails.vue";
import RetrievalDetailsView from "./retrievalList/RetrievalDetailsView.vue";
export default {
  components: {
    RetrievalDetails,
    RetrievalDetailsView,
    // ReAssignList,
    GenerateWaitingList,
    GenerateRotaWaitingList,
    AllocationRecipientTable,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    history: {
      type: Array,
      default: () => [],
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Number,
      default: 2,
    },
    hospitals: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [donorMixins],
  data() {
    return {
      isLoading: false,
      dialog: false,
      list: [],
      paymentStatus: PAYMENT_STATUS,
      patientStatus: PATIENT_STATUS,
      beReadyStatus: BE_READY_STATUS,
    };
  },
  computed: {
    noTakerTitle() {
      if (this.isNotaker1) return "Initiate Notaker";
      return "Initiate Notaker 2";
    },
    isUpdateTransplantSugery() {
      let { list } = this;
      let updated = list.filter((item) => {
        return item.organ_retrieval_status == 1;
      });
      if (list.length == 0 || updated.length == 0) return false;
      return true;
    },
    fields() {
      let doc = {
        organ_retrieval_status: "Retrieval",
        action: "Action",
      };
      return doc;
    },
    isAllDeclined() {
      let { list } = this;
      let updated = list.filter((item) => {
        return item.organ_retrieval_status == 1;
      });
      if (list.length == 0 || updated.length != 0) return true;
      return false;
    },
    isReassign() {
      let { allocation_status, organ_allocation_id, type_of_allocation } =
        this.item;
      if (type_of_allocation == 1) return false;
      if (!this.isAdmins) return false;
      if (allocation_status != 26) return false;
      if (this.isAllDeclined) return false;
      let items = this.history.filter((item) => {
        let status = item.status;
        if (item.organ_allocation_id != organ_allocation_id) return false;
        return status == 4 || status == 29 || status == 33;
      });
      return items.length == 0;
    },
    isNotaker1() {
      let { allocation_status, organ_allocation_id, type_of_allocation } =
        this.item;
      if (type_of_allocation == 1) return false;
      if (!this.isAdmins) return false;
      if (allocation_status != 26) return false;
      if (this.isAllDeclined) return false;
      let items = this.history.filter((item) => {
        let status = item.status;
        if (item.organ_allocation_id != organ_allocation_id) return false;
        return status == 29;
      });
      return items.length == 0;
    },
    isNotaker2() {
      let { allocation_status, organ_allocation_id, type_of_allocation } =
        this.item;
      if (type_of_allocation == 1) return false;

      if (!this.isAdmins) return false;
      if (allocation_status != 26) return false;
      if (this.isAllDeclined) return false;
      let items = this.history.filter((item) => {
        let status = item.status;
        if (item.organ_allocation_id != organ_allocation_id) return false;
        return status == 4;
      });
      return items.length == 0;
    },
  },
  methods: {
    isRetrievalUpdate({ hospital_id, organ_retrieval_status, isUpdate }) {
      if (!isUpdate) return false;
      if (!this.isHospitalContact) return false;
      if (organ_retrieval_status != 0) return false;
      if (this.item.allocation_status != 26) return false;
      return hospital_id == this.user.hospital_id;
    },
    updateTransplantSugery() {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want to update transplant sugery?",
        onConfirm: async () => {
          try {
            vm.$loader.show();
            let list = [];
            let { organ_allocation_id } = vm.item;
            vm.list.forEach((item) => {
              let { recepeint_allocation_id } = item;
              let doc = {
                recepeint_allocation_id,
                organ_allocation_id,
                organ_surgery_by: vm.created_by,
                dml_indicator: "OSS",
              };
              list.push(doc);
            });
            let { data } =
              await donorService.organAllocationWaitingListStatusUpdate({
                recepient_organ_allocation_proc: list,
              });
            let { status, msg } = data;
            vm.isLoading = false;
            vm.$loader.hide();
            event.$emit("refresh");
            if (!status) {
              vm.$alert.show(msg);
            }
          } catch (error) {
            vm.$loader.hide();
            vm.$alert.show(error.message);
          }
        },
      });
    },
    async init() {
      const vm = this;
      vm.dialog = false;
      let {
        organ_id_org,
        is_multi,
        organ_id,
        organ_allocation_id,
        type_of_allocation,
      } = vm.item;
      if (is_multi) organ_id = organ_id_org;
      const doc = {
        dml_indicator: "LOSF",
        organ_id,
        organ_allocation_id,
      };
      if (type_of_allocation == 1) doc.dml_indicator = "FOSL";
      try {
        vm.isLoading = true;
        vm.list = [];
        let { data } = await donorService.getAllocatedWL(doc);
        let { status, msg, list } = data;
        vm.isLoading = false;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        list = list.map((item) => {
          item.gender = vm.getGender(item.gender);
          item.created_on = formatDate(item.created_on);
          return item;
        });
        vm.list = list.map((item, i) => {
          let isUpdate = false;
          if (list[i - 1] != undefined) {
            isUpdate =
              list[i - 1].organ_retrieval_status == 2 &&
              item.organ_retrieval_status == 0;
          } else {
            isUpdate = item.organ_retrieval_status == 0;
          }
          item.isUpdate = isUpdate;
          return item;
        });
        console.log(vm.list);
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
