<template>
  <div>
    <table-view
      :isBusy="isLoading"
      :fields="fields"
      :items="list"
      title="Contact"
    >
      <template v-slot:add>
        <create-contact @init="init" v-if="isCreate"></create-contact>
      </template>
      <template v-slot:status="{ value }">
        {{ value == 1 ? "Active" : "Deleted" }}
      </template>
      <template v-slot:contact_type="{ value }">
        {{ getContactType(value) }}
      </template>
      <template v-slot:action="{ item }">
        <div class="d-flex" v-if="isAction(item)">
          <create-contact :item="item" @init="init"></create-contact>
          <delete-button @click="remove(item)"></delete-button>
        </div>
      </template>
    </table-view>
  </div>
</template>

<script>
import masterService from "../../assets/services/master.service";
import DeleteButton from "../buttons/DeleteButton.vue";
import TableView from "../table/TableView.vue";
import CreateContact from "./create/CreateContact.vue";
import { CONTACT_TYPES } from "../../assets/constants/master.constants";
import menuConstants from "../../assets/constants/menu.constants";
export default {
  components: { TableView, DeleteButton, CreateContact },
  data() {
    return {
      list: [],
      isLoading: false,
    };
  },
  computed: {
    fields() {
      let doc = {
        contact_name: "Contact Name",
        contact_type: "Contact Type",
        status: "Status",
        action: "Action",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
          sortable: true,
          tdClass: key == "action" ? "width-100" : "",
        });
      });
      return list;
    },
    isCreate() {
      return this.hasCreateAccess(menuConstants.CONTACT);
    },
  },
  methods: {
    isAction({ status }) {
      if (!this.hasStatusAccess(menuConstants.CONTACT, 4)) return false;
      return status == 1;
    },
    getContactType(val) {
      let item = CONTACT_TYPES.find((item) => {
        return item.value == val;
      });
      return item ? item.name : "";
    },

    async remove({ contact_id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want to remove this?",
        onConfirm: async () => {
          try {
            let { data } = await masterService.CUDContact({
              contact_id,
              dml_indicator: "D",
              status: 4,
            });
            let { status, msg } = data;
            vm.init();
            if (!status) {
              vm.$alert.show(msg);
            }
          } catch (error) {
            vm.isLoading = false;
            vm.$alert.show(error.message);
          }
        },
      });
    },
    async init() {
      const vm = this;
      try {
        vm.list = [];
        vm.isLoading = true;
        let { data } = await masterService.getContact();
        let { status, msg, list } = data;
        vm.isLoading = false;
        if (status) {
          vm.list = list.filter(({ contact_type }) => {
            return contact_type == 3 || contact_type == 4;
          });
        } else {
          vm.$alert.show(msg);
        }
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
