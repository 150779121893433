<template>
  <div>
    <b-card class="pa-0 tabs">
      <Tabs :theme="theme" v-model="tab">
        <TabItem v-for="item in tabs" :key="item.id" :name="item.id">
          <template #name>
            {{ item.name }}
          </template>
        </TabItem>
      </Tabs>
    </b-card>
    <cause-of-death v-show="tab == menu.CAUSE_OF_DEATH"></cause-of-death>
    <termination-reasons
      v-show="tab == menu.TERMINATION_REASON"
    ></termination-reasons>
    <zone v-show="tab == menu.ZONE"></zone>
    <organ v-show="tab == menu.ORGAN"></organ>
    <multiple-organ v-show="tab == menu.MULTIPLE_ORGAN"></multiple-organ>
    <contact v-show="tab == menu.CONTACT"></contact>
    <blood-group v-show="tab == menu.BLOOD_GROUP"></blood-group>
    <file-upload v-show="tab == menu.FILE_UPLOAD"></file-upload>
    <qualification v-show="tab == menu.QUALIFICATION"></qualification>
    <department v-show="tab == menu.DEPARTMENT"></department>
  </div>
</template>

<script>
import menuConstants from "../assets/constants/menu.constants";
import BloodGroup from "../components/masters/BloodGroup.vue";
import CauseOfDeath from "../components/masters/CauseOfDeath.vue";
import Contact from "../components/masters/Contact.vue";
import Department from "../components/masters/Department.vue";
import FileUpload from "../components/masters/FileUpload.vue";
import MultipleOrgan from "../components/masters/MultipleOrgan.vue";
import Organ from "../components/masters/Organ.vue";
import Qualification from "../components/masters/Qualification.vue";
import TerminationReasons from "../components/masters/TerminationReasons.vue";
import Zone from "../components/masters/Zone.vue";
export default {
  components: {
    CauseOfDeath,
    TerminationReasons,
    Zone,
    Organ,
    Contact,
    BloodGroup,
    FileUpload,
    Qualification,
    Department,
    MultipleOrgan,
  },
  data() {
    return {
      theme: {
        nav: "white",
        navItem: "#BDBDBD",
        navActiveItem: "#556ee6",
        slider: "#556ee6",
        arrow: "#556ee6",
      },
      menu: menuConstants,
      tab: "",
    };
  },
  computed: {
    tabs() {
      let list = [
        {
          name: "Cause of Death",
          id: menuConstants.CAUSE_OF_DEATH,
        },
        {
          name: "Termination Reasons",
          id: menuConstants.TERMINATION_REASON,
        },
        {
          name: "Zone",
          id: menuConstants.ZONE,
        },
        {
          name: "Organ",
          id: menuConstants.ORGAN,
        },
        {
          name: "Multi Organ",
          id: "multi_29",
        },
        {
          name: "Contact",
          id: menuConstants.CONTACT,
        },
        {
          name: "Blood Group",
          id: menuConstants.BLOOD_GROUP,
        },
        {
          name: "File Upload",
          id: menuConstants.FILE_UPLOAD,
        },
        {
          name: "Qualification",
          id: menuConstants.QUALIFICATION,
        },
        {
          name: "Department",
          id: menuConstants.DEPARTMENT,
        },
      ];
      list = list.filter(({ id }) => {
        let { userRights } = this.$store.getters;
        let item = userRights.find((item) => {
          if(id == "multi_29") id = 29;
          return item.module_id == id;
        });
        if (item == null) return false;
        return item.permit == 1;
      });
      return list;
    },
  },
};
</script>

<style lang="scss">
.tabs {
  overflow: hidden;
  .card-body {
    padding: 0px !important;
    .tab__slider {
      opacity: 1 !important;
    }
    .tab__nav__item {
      white-space: nowrap !important;
    }
  }
}
</style>
