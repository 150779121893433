<template>
  <div>
    <table-view
      title="Potential Donor List"
      class="mt-4"
      :fields="fields"
      :isBusy="isLoading"
      :items="list"
    >
      <template v-slot:status="{ item }">
        {{ getStatus(item) }}
      </template>
      <template v-slot:action="{ item }">
        <div class="d-flex">
          <Menu
            :item="item"
            @onDraftStateCompleted="$emit('onDraftStateCompleted')"
            v-if="isMenu(item)"
            @fetch="$emit('fetch')"
            @changeStatus="changeStatus"
          ></Menu>
          <view-button v-if="isView(item)"> </view-button>
          <edit-button v-else @click="open(item)"></edit-button>
          <delete-button
            v-if="isDeleteAble(item)"
            @click="remove(item)"
          ></delete-button>
          <termination
            :id="item.donor_temp_registration_id"
            @fetch="$emit('fetch')"
            v-if="isTerminated(item)"
          ></termination>
        </div>
      </template>
    </table-view>
  </div>
</template>

<script>
import { encode } from "../../assets/js/common";
import { DONOR_STATUS } from "../../assets/constants/donor.constants";
import DeleteButton from "../buttons/DeleteButton.vue";
import EditButton from "../buttons/EditButton.vue";
import TableView from "../table/TableView.vue";
import donorService from "../../assets/services/donor.service";
import Termination from "./Termination.vue";
import Menu from "./Menu.vue";
import menuConstants from "../../assets/constants/menu.constants";
import ViewButton from "../buttons/ViewButton.vue";
export default {
  components: {
    TableView,
    EditButton,
    DeleteButton,
    Termination,
    Menu,
    ViewButton,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    list: Array,
  },
  data() {
    return {
      isDraftLoading: false,
    };
  },
  computed: {
    fields() {
      let doc = {
        sno: "S.No",
        donor_temp_registration_id: "Donor ID",
        donor_name: "Donor Name",
        group_desc: "Blood Group",
        hospital_name: "Hospital Name",
        organ_consented: "Organ Consented",
        phone_number_1: "Phone Number",
        requesting_apnea_moredetails: "Request Note",
        status: "Status",
        action: "Action",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
          tdClass: key == "action" ? "width-100" : "",
        });
      });
      return list;
    },
  },
  methods: {
    isView({ status }) {
      let hasAccess = this.hasEditAccess(menuConstants.MANAGE_DONOR);
      return !hasAccess || status == 2 || status == 9;
    },
    isMenu({ status }) {
      return this.isAdmins && (status == 1 || status == 5);
    },
    isDeleteAble({ status }) {
      let statusList = [2, 9, 7, 6];
      if (status == 1) {
        return this.hasStatusAccess(menuConstants.MANAGE_DONOR, 9);
      }
      let hasAccess = this.hasStatusAccess(menuConstants.MANAGE_DONOR, 2);
      return hasAccess && statusList.indexOf(status) == -1;
    },
    isTerminated(item) {
      let statusList = [6, 8];
      let hasAccess = this.hasStatusAccess(menuConstants.MANAGE_DONOR, 7);
      return (
        hasAccess && this.isDeleteAble(item) && statusList.indexOf(status) == -1
      );
    },
    open({ donor_temp_registration_id }) {
      let id = encode(donor_temp_registration_id);
      this.$router.push(`/update-donor?id=${id}`);
    },
    getStatus({ status }) {
      let item = DONOR_STATUS.find((item) => {
        return item.value == status;
      });
      return item != null ? item.name : "";
    },
    remove({ donor_temp_registration_id, status }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do want to remove this donor?",
        onConfirm: () => {
          vm.changeStatus({
            donor_temp_registration_id,
            status: status == 1 ? 9 : 2,
            dml_indicator: "A",
          });
        },
      });
    },
    async changeStatus(doc) {
      const vm = this;
      try {
        vm.$loader.show();
        let { data } = await donorService.updateStatus(doc);
        let { status, msg } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.$emit("fetch");
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
