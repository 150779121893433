<template>
  <div class="recipient-transfer">
    <b-card>
      <b-card-title class="pb-3"> Enter Recipient Details </b-card-title>
      <form-wrapper @submit="submit">
        <input-group
          type="text"
          label="Aadhar Number"
          rules="required|aadhar|dec-0"
          v-model="form.aadhar_number"
        >
          <template v-slot:help>
            <verify-aadhar-otp @onVerified="isVerified = true" :number="form.aadhar_number"></verify-aadhar-otp>
          </template>
        </input-group>
        <autocomplete
          v-model="form.hospital_id"
          label="Hospital"
          :items="hospitals"
          :multiple="false"
          v-if="isAdmin"
          :max="1"
          rules="required"
        >
        </autocomplete>
        <input-field
          label="UID"
          rules="required|dec-0"
          type="text"
          v-model="form.recipient_registration_id"
        ></input-field>
        <b-btn variant="primary" :disabled="!isVerified" class="mt-1" type="submit" block>
          Submit</b-btn
        >
      </form-wrapper>
    </b-card>
  </div>
</template>

<script>
import { encode } from "../../assets/js/common";
import hospitalService from "../../assets/services/hospital.service";
import recipientService from "../../assets/services/recipient.service";
import FormWrapper from "../../components/form/FormWrapper.vue";
import InputField from "../../components/form/InputField.vue";
import InputGroup from "../../components/form/InputGroup.vue";
import VerifyAadharOtp from "../../components/managerecipient/create/VerifyAadharOtp.vue";
export default {
  components: { InputField, FormWrapper, InputGroup, VerifyAadharOtp },
  data() {
    return {
      form: {
        recipient_registration_id: "",
        hospital_id: "",
        aadhar_number: "",
      },
      isVerified: false,
      hospitals: [],
    };
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.$loader.show();
        let { data } = await recipientService.transfer(vm.form);
        let { status, msg, recipient_list } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        let id = encode(recipient_list[0].recipient_registrationid);
        this.$router.push(`/update-recipient?id=${id}`);
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
    async init() {
      const vm = this;
      try {
        vm.$loader.show();
        vm.hospitals = [];
        let { data } = await hospitalService.getHospitalName();
        let { status, msg, hospital_name } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.$loader.hide();
        vm.hospitals = hospital_name.map((item) => {
          item.name = item.hospital_name;
          item.value = item.hospital_id;
          return item;
        });
        vm.$emit("hospitals", vm.hospitals);
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss">
.recipient-transfer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 155px);
  .card {
    width: 350px;
  }
}
</style>
