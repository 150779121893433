<template>
  <ValidationProvider :rules="rules" :vid="vid" v-slot="validationContext">
    <b-form-group>
      <template v-slot:label>
        <span v-html="formattedLabel"></span>
      </template>
      <b-input-group
        class="mb-3"
        :invalid-feedback="message(validationContext)"
      >
        <b-form-input
          v-model="model"
          :type="type"
          v-bind="$attrs"
          @focus="$emit('focus', $event)"
          :disabled="disabled"
          :state="getValidationState(validationContext)"
          @input="$emit('input', model)"
        >
        </b-form-input>
        <b-input-group-append>
          <b-button
            class="help"
            v-if="type == 'password'"
            @click="type = 'text'"
            variant="outline-secondary"
          >
            <b-icon icon="eye-slash-fill"> </b-icon>
          </b-button>
          <b-button
            @click="type = 'password'"
            v-else
            class="help"
            variant="outline-secondary"
          >
            <b-icon icon="eye-fill"> </b-icon>
          </b-button>
        </b-input-group-append>
        <b-form-invalid-feedback>
          {{ message(validationContext) }}
        </b-form-invalid-feedback>
      </b-input-group>
    </b-form-group>
  </ValidationProvider>
</template>
<script>
import { BFormInput } from "bootstrap-vue";

export default {
  components: {
    BFormInput,
  },
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    vid: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
    },
    invalid: {
      type: String,
    },
    value: {},
  },
  data() {
    return {
      model: "",
      type: "password",
    };
  },
  computed: {
    formattedLabel() {
      if (!this.rules) return this.label;
      if (this.rules.indexOf("required") != -1)
        return this.label + " <span style='color:red'>*</span>";
      return this.label;
    },
  },
  watch: {
    value(val) {
      this.model = val;
    },
  },
  methods: {
    message(validationContext) {
      if (this.invalid) return this.invalid;
      return validationContext.errors[0];
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
  mounted() {
    this.model = this.value;
  },
};
</script>
