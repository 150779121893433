<template>
  <b-button variant="primary" depressed @click="$emit('click')" tile>
    <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
    ADD NEW
  </b-button>
</template>

<script>
export default {};
</script>

<style></style>
