<template>
  <Expandable header="Registration Address" class="mb-10" v-if="false">
    <template v-slot:action>
      <v-btn @click="copy" tile depressed small class="primary">
        Copy Business Address
      </v-btn>
    </template>
    <v-row class="pb-0">
      <v-col cols="12" md="4">
        <v-text-field
          v-model.trim="form.address_line_1"
          label="Address Line 1"
          outlined
          @input="input"
          dense
          :rules="rules"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model.trim="form.address_line_2"
          label="Address Line 2"
          outlined
          @input="input"
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model.trim="form.town_village"
          label="Town Village"
          outlined
          @input="input"
          dense
          :rules="rules"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model.trim="form.landmark"
          label="Landmark"
          @input="input"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-autocomplete
          v-model.trim="form.country"
          @change="onCountryChange"
          label="Country"
          :items="countries"
          outlined
          item-text="name"
          item-value="value"
          dense
          :rules="rules"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="4">
        <v-autocomplete
          v-model.trim="form.state"
          label="State"
          outlined
          :items="states"
          @change="onStateChange"
          item-text="name"
          item-value="value"
          dense
          :rules="rules"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="4">
        <v-autocomplete
          v-model.trim="form.city"
          :items="citys"
          @change="input"
          label="City"
          outlined
          item-text="name"
          item-value="value"
          dense
          :rules="rules"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          @input="input"
          v-model.trim="form.pincode"
          label="Pincode"
          outlined
          type="number"
          dense
          :rules="rules"
        ></v-text-field>
      </v-col>
    </v-row>
  </Expandable>
</template>

<script>
import { FIELD } from "../../../assets/js/rules";
import { HOSPITAL_TYPES } from "../../../assets/constants/hospital.contants";
import Expandable from "../../Expandable.vue";
import masterService from "../../../assets/services/master.service";
export default {
  components: { Expandable },
  props: {
    value: Object,
    business: Object,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: FIELD,
      form: {
        address_line_1: "",
        address_line_2: "",
        town_village: "",
        is_permanent_address: 0,
        landmark: "",
        country: "",
        state: "",
        city: "",
        pincode: "",
      },
      citys: [],
      states: [],
    };
  },
  computed: {
    types() {
      return HOSPITAL_TYPES;
    },
    master() {
      return this.$store.getters.master;
    },
    countries() {
      if (this.master.country instanceof Array == false) return [];
      return this.master.country.map((item) => {
        return { value: item.id, name: item.name };
      });
    },
    years() {
      let year = 1899;
      let list = [];
      while (year < new Date().getFullYear()) {
        year++;
        list.push(year);
      }
      return list.reverse();
    },
  },
  methods: {
    async onCountryChange(copy) {
      const vm = this;
      if (copy == undefined) {
        vm.form.state = "";
        vm.form.city = "";
      }
      vm.states = [];
      vm.citys = [];
      vm.$emit("input", vm.form);
      try {
        vm.$loader.show();
        let { data } = await masterService.getStateCity({
          country_id: vm.form.country,
        });
        let { list, status, msg } = data;
        if (status) {
          vm.states = list.map((item) => {
            return { name: item.name, value: item.id };
          });
        } else {
          vm.$alert.show(msg);
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
      }
    },
    async onStateChange(copy) {
      const vm = this;
      if (copy == undefined) {
        vm.form.city = "";
      }
      vm.citys = [];
      vm.$emit("input", vm.form);
      try {
        vm.$loader.show();
        let { data } = await masterService.getStateCity({
          state_id: vm.form.state,
        });
        let { list, status, msg } = data;
        if (status) {
          vm.citys = list.map((item) => {
            return { name: item.name, value: item.id };
          });
        } else {
          vm.$alert.show(msg);
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
      }
    },
    copy() {
      const vm = this;
      let val = vm.business;
      Object.keys(vm.form).forEach((key) => {
        if (key !== "is_permanent_address") {
          if (val[key] !== undefined) vm.$set(vm.form, key, val[key]);
        }
      });
      this.onCountryChange(true);
      this.onStateChange(true);
    },
    input() {
      this.$emit("input", this.form);
    },
  },
  mounted() {
    this.input();
  },
};
</script>

<style></style>
