<template>
  <ValidationProvider :rules="rules" :vid="vid" v-slot="validationContext">
    <date-picker
      v-model="model"
      value-type="format"
      format="DD-MM-YYYY"
      :editable="false"
      @change="onInput"
      :disabled-date="disabledDate"
      :disabled="disabled"
      :clearable="false"
    >
      <template v-slot:input>
        <b-form-group>
          <template v-slot:label>
            <span v-html="formattedLabel"></span>
          </template>
          <b-input-group class="mb-3">
            <b-form-input
              v-model="model"
              :type="type"
              readonly
              :disabled="disabled"
              :state="getValidationState(validationContext)"
            >
            </b-form-input>
            <b-input-group-append>
              <b-button variant="outline-info" class="help">
                <b-icon icon="calendar-event" class="font-size-16"></b-icon>
              </b-button>
            </b-input-group-append>
            <b-form-invalid-feedback>
              {{ message(validationContext) }}
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </template>
    </date-picker>
  </ValidationProvider>
</template>
<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";

export default {
  components: {
    DatePicker,
  },
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    vid: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
    },
    type: {
      type: String,
      default: "text",
    },
    invalid: {
      type: String,
      default: "This field is required",
    },
    value: {
      type: String,
      default: "",
    },
    max: {
      default: "",
    },
    min: {
      default: "",
    },
  },
  data() {
    return {
      model: "",
    };
  },
  computed: {
    formattedLabel() {
      if (!this.rules) return this.label;
      if (this.rules.indexOf("required") != -1)
        return this.label + " <span style='color:red'>*</span>";
      return this.label;
    },
  },
  watch: {
    value() {
      this.init();
    },
  },
  methods: {
    onInput(val) {
      this.model = val;
      let date = moment(val, "DD-MM-YYYY").format("YYYY-MM-DD");
      this.$emit("input", date);
    },
    disabledDate(date) {
      const isMax = date > new Date(this.max);
      const isMin = date < new Date(this.min);
      if (this.max != "") return isMax;
      if (this.min != "") return isMin;
      return false;
    },
    message(validationContext) {
      return validationContext.errors[0];
    },
    getValidationState(context) {
      let { dirty, validated, valid = null } = context;
      return dirty || validated ? valid : null;
    },
    init() {
      if (this.value) {
        console.log("date", this.value);
        let date = moment(this.value).format("DD-MM-YYYY");
        console.log("date", date);
        this.model = date;
      } else {
        this.model = "";
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss">
.mx-icon-calendar {
  display: none;
}
.mx-datepicker {
  width: 100% !important;
}
</style>
