<template>
  <div>
    <table-view
      title="Live Transplant List"
      :fields="fields"
      :isBusy="isLoading"
      :items="recipients"
    >
      <template v-slot:status="{ value }">
        {{ statusList[value] || "" }}
      </template>
      <template v-slot:action="{ item }">
        <div class="d-flex">
          <view-button v-if="isView(item)" @click="open(item)"></view-button>
          <edit-button v-else @click="open(item)"></edit-button>
          <delete-button
            v-if="isDeleteAble(item)"
            @click="remove(item)"
          ></delete-button>
        </div>
      </template>
    </table-view>
  </div>
</template>

<script>
import { encode, KIDNEY_ID, LIVER_ID } from "../../../assets/js/common";
import DeleteButton from "../../buttons/DeleteButton.vue";
import EditButton from "../../buttons/EditButton.vue";
import TableView from "../../table/TableView.vue";
import LiveTransplantService from "../../../assets/services/live.transplant.service";
import menuConstants from "../../../assets/constants/menu.constants";
import ViewButton from "../../buttons/ViewButton.vue";
export default {
  components: { TableView, EditButton, DeleteButton, ViewButton },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    list: Array,
  },
  data() {
    return {
      statusList: {
        1: "Active",
        2: "Deleted",
      },
      organ: null,
      isDraftLoading: false,
    };
  },
  computed: {
    recipients() {
      return this.list.filter((item) => {
        if (this.organ == null) return true;
        return item.organ_id == this.organ;
      });
    },
    organs() {
      let allowed = [LIVER_ID, KIDNEY_ID];
      if (this.master.master_organ instanceof Array == false) return [];
      let list = this.master.master_organ.filter(({ organ_id }) => {
        return allowed.indexOf(organ_id.toString()) != -1;
      });
      return list.map((item) => {
        return { value: item.organ_id, name: item.organ_name };
      });
    },

    fields() {
      let doc = {
        sno: "S.No",
        hospital_name: "Donor Hospital Name",
        donor_name: "Donor Name",
        donor_age: "Age",
        donor_sex: "Gender",
        donor_address: "Donor Address",
        recipient_name: "Recipient Name",
        recipient_age: "Age",
        recipient_sex: "Gender",
        recipient_address: "Recipient Address",
        relationship: "Donor Relationship",
        organ_name: "Transplanted Organ",
        date_of_surgery: "Date of Transplantation",
        status: "Status",
        action: "Action",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          key,
          label: doc[key],
          tdClass: key == "action" ? "width-100" : "",
        });
      });
      return list;
    },
  },
  methods: {
    isView({ status }) {
      let hasAccess = this.hasEditAccess(menuConstants.LIVE_TRANSPLANT);
      return !hasAccess || status == 2;
    },
    isDeleteAble({ status }) {
      let hasAccess = this.hasStatusAccess(menuConstants.LIVE_TRANSPLANT, 2);
      return hasAccess && status != 2;
    },
    open({ live_transplant_detail_id }) {
      let id = encode(live_transplant_detail_id);
      this.$router.push(`/update-live-transplant?id=${id}`);
    },
    remove({ live_transplant_detail_id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do want to remove this?",
        onConfirm: () => {
          vm.changeStatus({
            live_transplant_detail_id,
            status: 2,
            dml_indicator: "A",
          });
        },
      });
    },
    async changeStatus(doc) {
      const vm = this;
      try {
        vm.$loader.show();
        let { data } = await LiveTransplantService.updateStatus(doc);
        let { status, msg } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.$emit("fetch");
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
