<template>
  <div>
    <b-button variant="primary" class="mb-4" depressed @click="dialog = true">
      Create Backup Allocation
    </b-button>
    <b-modal
      v-model="dialog"
      width="600"
      title="Create Backup Allocation"
      hide-footer
      centered
      size="sm"
      no-close-on-backdrop
    >
      <form-wrapper @submit="submit()">
        <select-field
          label="Select Organ"
          rules="required"
          v-model="organ_id"
          :items="items"
          @input="type = ''"
        ></select-field>
        <select-field
          label="Allocation Type"
          rules="required"
          v-model="type"
          :items="types"
        ></select-field>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <b-button variant="outline-danger mx-3" @click="dialog = false"
            >Cancel
          </b-button>
          <b-button variant="success" type="submit">Submit </b-button>
        </div>
      </form-wrapper>
    </b-modal>
  </div>
</template>

<script>
import event from '../../../assets/js/event';
import donorService from "../../../assets/services/donor.service";
import FormWrapper from "../../form/FormWrapper.vue";
import SelectField from "../../form/SelectField.vue";
export default {
  components: { SelectField, FormWrapper },
  props: {
    organs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      type: "",
      organ_id: "",
    };
  },
  computed: {
    items() {
      console.log(this.organs);
      return this.organs.map(({ organ_name, organ_id }) => {
        return { name: organ_name, value: organ_id };
      });
    },
    types() {
      if (!this.organ_id) return [];
      let organ = this.organs.find((item) => {
        return this.organ_id == item.organ_id;
      });
      if (organ == null) return [];
      return this.$parent.allocationTypes(organ);
    },
  },
  methods: {
    async submit() {
      const vm = this;
      let { final_donor_id, id } = vm.$parent;
      let organ_allocation_list = [];
      let { hospital_id, case_officer } = this.$parent.donor;
      let doc = {
        final_donor_id,
        organ_allocation_id: "",
        donor_temp_registration_id: id,
        case_officer,
        hospital_id,
        organ_id: vm.organ_id,
        backup_flow: 1,
        status: 1,
        type_of_allocation: vm.type,
        created_by: vm.created_by,
        dml_indicator: "I",
      };
      organ_allocation_list.push(doc);
      try {
        vm.$loader.show();
        let { data } = await donorService.organAllocation({
          organ_allocation_list,
        });
        let { status, msg } = data;
        vm.dialog = false;
        if (!status) {
          vm.$alert.show(msg);
        }
        event.$emit("refresh");
        vm.$loader.hide();
        vm.dialog = false;
        vm.type = "";
        vm.organ_id = "";
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
        event.$emit("refresh");
      }
    },
  },
};
</script>

<style></style>
