<template>
  <div>
    <div
      class="badge bg-warning font-size-13"
      v-if="item.patient_nationlity == 1"
    >
      {{ value }}
      <i class="mdi mdi-airplane-takeoff"></i>
    </div>
    <span v-else>
      {{ value }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    item: {},
    value: {},
  },
};
</script>

<style></style>
