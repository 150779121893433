import http from "../js/axios";
import { formatTable } from "../js/common";
class HospitalSerive {
  create(data) {
    return http.post("/hospital/organ_license", data);
  }
  get(organ_license_id) {
    return http.post("/hospital/organ_license_list", {
      dml_indicator: "E",
      organ_license_id,
    });
  }
  updateStatus(doc) {
    return http.post("/hospital/organ_license_status", doc);
  }
  upload(data) {
    return http.post("/ByteArrayAPI/organ_license_fileup_load", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  removeFile(organ_license_fileupload_mapping_id) {
    return http.post("/hospital/organ_license_file_delete", {
      organ_license_fileupload_mapping_id,
    });
  }
  getLicenseContacts(organ_license_id) {
    return http.post("/hospital/organ_license_list", {
      dml_indicator: "CL",
      organ_license_id,
    });
  }
  list(doc) {
    return http.post("/hospital/organ_license_list", doc);
  }
  parseItem(item) {
    item.expiry_date_format = formatTable(item.expiry_date);
    return item;
  }
  validateEmailPhone(doc) {
    return http.post("/hospital/email_phone_check", doc);
  }
  uploadContact(data) {
    return http.post("/ByteArrayAPI/contact_person_fileup_load", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
}
export default new HospitalSerive();
