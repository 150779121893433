/* eslint-disable no-useless-escape */
export const EMAIL = [
  (v) => !!v || "E-mail is required",
  (v) => /.+@.+/.test(v) || "E-mail must be valid",
];
export function getMaxRules(max) {
  return [
    (v) => !!v || "This field is required",
    (v) =>
      (v && v.length <= max) || `Field must be less than ${max} characters`,
  ];
}
export function getMaxNotRules(max) {
  return [
    (v) => v.length <= max || `Field must be less than ${max} characters`,
  ];
}
export function getMaxEmailRules(max) {
  return [
    (v) => !!v || "This field is required",
    (v) =>
      (v && v.length <= max) || `Field must be less than ${max} characters`,
    (v) => /.+@.+/.test(v) || "E-mail must be valid",
  ];
}
export const URL_RULES = [
  (value) => !!value || "This field is required.",
  (value) =>
    (value && value.length <= 100) || `Field must be less than 100 characters`,
  (value) =>
    value.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    ) != null || "URL is not valid",
];

export const FIELD = [(v) => !!v || "This field is required"];
export const MULITPLE_SELECT = [(v) => v.length != 0 || "This field is required"];
export const PHONE = [
  (v) => !!v || "This field is required",
  // v => v.length == 10 || "Please enter a valid Phone number"
];
export const PINCODE = [
  (v) => !!v || "This field is required",
  (v) => v.length == 6 || "Please enter a valid pincode",
];
export const PASSWORD = [
  (v) => !!v || "This field is required",
  (v) => v.length > 7 || "Min 8 character required",
];
export const REDUCTION_RATE = [
  (v) => !!v || "This field is required",
  (v) => (v <= 99 && v >= 1) || "Percentage should be between 1 to 99",
];
export const PAYOUT_STATUS = [
  (v) => !!v || "This field is required",
  (v) => v === "Paid" || "Field must be Paid",
];
export const COMMISSION_RATIO = [
  (v) => !!v || "This field is required",
  (v) => (v <= 1 && v >= 0) || "Ratio should be between 0 to 1",
];
