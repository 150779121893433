<template>
  <Expandable header="Medications Used by Donor" class="mb-10">
    <template v-slot:action>
      <create
        v
        :list="list"
        v-if="isAdd && !isDisabled"
        @onCreate="onCreate"
      ></create>
    </template>
    <b-table
      class="mt-3"
      :fields="headers"
      :items="list"
      :head-variant="'dark'"
      thead-class="table-light"
    >
      <template #cell(master_vaccination_id)="{ item }">
        {{ getVaccinationName(item) }}
      </template>
      <template #cell(action)="{ item, index }">
        <div v-if="!isDisabled">
          <div class="d-flex" v-if="item.status != 4">
            <create
              :list="list"
              :item="item"
              :index="index"
              @onUpdate="onUpdate"
            ></create>
            <delete-button @click="remove(index)"> </delete-button>
          </div>
          <div class="d-flex" v-else>
            <b-button pill variant="danger" @click="undo(index)" size="sm"
              >undo remove</b-button
            >
          </div>
        </div>
      </template>
    </b-table>
  </Expandable>
</template>

<script>
import { FIELD } from "../../../assets/js/rules";
import DeleteButton from "../../buttons/DeleteButton.vue";
import Expandable from "../../Expandable.vue";
import Create from "./medication/Create.vue";
export default {
  props: {
    value: Array,
    count: {
      type: Number,
      default: 3,
    },
    isDisabled: {
      default: false,
      type: Boolean,
    },
  },
  components: { Expandable, Create, DeleteButton },
  data() {
    return {
      rules: FIELD,
      files: [],
      list: [],
    };
  },
  computed: {
    isAdd() {
      let count = this.count;
      const vm = this;
      let list = vm.list.filter((item) => {
        return item.status == 1;
      });
      return list.length != count;
    },
    headers() {
      let doc = {
        medication_name: "Medications Used",
        action: "Action",
      };
      let list = [];
      Object.keys(doc).forEach((key) => {
        list.push({
          label: doc[key],
          key,
        });
      });
      return list;
    },
    isUpdate() {
      let { name } = this.$route;
      return name == "update-hospital" || "update-transplant-license";
    },
  },
  methods: {
    getVaccinationName({ master_vaccination_id }) {
      let item = this.masterVaccinations.find((item) => {
        return item.value == master_vaccination_id;
      });
      return item != null ? item.name : "";
    },
    undo(i) {
      this.list[i].status = 1;
    },
    remove(i) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure?",
        onConfirm: () => {
          if (vm.list[i].hospital_contact_id) {
            vm.list[i].status = 4;
          } else {
            vm.list.splice(i, 1);
          }
        },
      });
      vm.$emit("input", vm.list);
    },
    onCreate(item) {
      const vm = this;
      vm.list.unshift(item);
      vm.$emit("input", vm.list);
    },
    onUpdate({ doc, index }) {
      const vm = this;
      vm.$set(vm.list, index, doc);
      vm.$emit("input", vm.list);
    },
  },
  mounted() {
    this.list = this.value;
  },
};
</script>

<style></style>
