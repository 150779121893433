<template>
  <div>
    <template v-if="isMobile">
      <allocation-user-details @click="layer.details = true" :doc="updateDoc" />
      <allocation-mobile-tabs
        v-model="tab"
        :donor="donor"
        :status="final_donor_status"
        ref="tabs"
        @input="onMobileTabClick"
        :history="allocation_list_history"
        :list="allocation_list"
      />
      <popup-layer class="popup-layer" :visible.sync="layer.details">
        <donor-details :doc="updateDoc" />
        <donor-registration @input="initOrgans" v-model="updateDoc" />
      </popup-layer>
      <popup-layer class="popup-layer" :visible.sync="layer.allocation">
        <allocation-organs
          class="my-4"
          v-if="isOrganAllocation"
          :list="list"
          :status="final_donor_status"
          :allocation_list="allocation_list"
          :donor="donor"
        />
      </popup-layer>
      <popup-layer class="popup-layer" :visible.sync="layer.tab">
        <div v-if="tab != 0 && isRefresh">
          <allocation-tissue :item="currentTab" v-if="isTissue(currentTab)" />
          <template v-else>
            <allocation-in-house-steps
              :hospitals="hospitals"
              :history="allocation_list_history"
              v-if="isLocal"
              :item="currentTab"
            />
            <allocation-shared-poll-steps
              :hospitals="hospitals"
              :history="allocation_list_history"
              v-if="isShared"
              :item="currentTab"
            />
            <allocation-rota-steps
              :hospitals="hospitals"
              :history="allocation_list_history"
              v-if="isRota"
              :item="currentTab"
            />
          </template>
        </div>
      </popup-layer>
    </template>
    <template v-else>
      <donor-details :doc="updateDoc" />
      <allocation-tabs
        v-model="tab"
        :status="final_donor_status"
        :donor="donor"
        ref="tabs"
        :history="allocation_list_history"
        :list="allocation_list"
      />
      <div v-show="tab == 0">
        <allocation-organs
          v-if="isOrganAllocation"
          :list="list"
          :status="final_donor_status"
          :allocation_list="allocation_list"
          :donor="donor"
        />
        <donor-registration @input="initOrgans" v-model="updateDoc" />
      </div>
      <div v-if="tab != 0 && isRefresh">
        <allocation-tissue :item="currentTab" v-if="isTissue(currentTab)" />
        <template v-else>
          <allocation-in-house-steps
            :hospitals="hospitals"
            :history="allocation_list_history"
            v-if="isLocal"
            :item="currentTab"
          />
          <allocation-shared-poll-steps
            :hospitals="hospitals"
            :history="allocation_list_history"
            v-if="isShared"
            :item="currentTab"
          />
          <allocation-rota-steps
            :hospitals="hospitals"
            :history="allocation_list_history"
            v-if="isRota"
            :item="currentTab"
          />
        </template>
      </div>
    </template>
    <chat-widget></chat-widget>
  </div>
</template>

<script>
import { isDocObject } from "../../assets/js/common";
import hospitalService from "../../assets/services/hospital.service";
import AllocationOrgans from "../../components/organAllocation/AllocationOrgans.vue";
import AllocationInHouseSteps from "../../components/organAllocation/create/AllocationInHouseSteps.vue";
import AllocationSharedPollSteps from "../../components/organAllocation/create/AllocationSharedPollSteps.vue";
import AllocationRotaSteps from "../../components/organAllocation/create/AllocationRotaSteps.vue";
import AllocationTabs from "../../components/organAllocation/create/AllocationTabs.vue";
import DonorDetails from "../../components/organAllocation/create/DonorDetails.vue";
import DonorRegistration from "../donor/DonorRegistration.vue";
import Event from "../../assets/js/event.js";
import donorService from "../../assets/services/donor.service";
import donorMixins from "../../assets/mixins/donor.mixins";
import Timer from "../../assets/js/idle.timer.js";
import AllocationTissue from "../../components/organAllocation/create/AllocationTissue.vue";
import AllocationUserDetails from "../../components/organAllocation/mobile/AllocationUserDetails.vue";
import AllocationMobileTabs from "../../components/organAllocation/mobile/AllocationMobileTabs.vue";
import ChatWidget from "../../components/organAllocation/ChatWidget.vue";
export default {
  components: {
    DonorDetails,
    AllocationTabs,
    DonorRegistration,
    AllocationOrgans,
    AllocationInHouseSteps,
    AllocationSharedPollSteps,
    AllocationRotaSteps,
    AllocationTissue,
    AllocationUserDetails,
    AllocationMobileTabs,
    ChatWidget,
  },
  mixins: [donorMixins],
  data() {
    return {
      layer: {
        details: false,
        allocation: false,
        tab: false,
      },
      final_donor_status: null,
      tab: "0",
      timer: null,
      allocation_list: [],
      list: [],
      updateDoc: {},
      allocation_list_history: [],
      hospitals: [],
      isRefresh: false,
    };
  },
  watch: {
    tab() {
      this.refresh();
    },
  },
  computed: {
    isRota() {
      return this.currentTab.type_of_allocation == 3;
    },
    isShared() {
      return this.currentTab.type_of_allocation == 2;
    },
    isLocal() {
      return this.currentTab.type_of_allocation == 1;
    },
    currentTab() {
      let item = null;
      if (this.$refs.tabs) {
        item = this.$refs.tabs.tabs.find((item) => {
          return item.id == this.tab;
        });
      }
      if (item != undefined) {
        let doc = this.masterOrgans.find((o) => {
          return o.organ_id == item.organ_id;
        });
        doc = doc || {};
        item.allocated_organ = doc.allocated_organ || item.organ_id.toString();
        if (item.allocated_organ == undefined) item.allocated_organ = "";
        item.organ_id = item.allocated_organ;
        item.is_multi = item.organ_id.toString().split(",").length > 1;
      }
      return item || {};
    },
    donor() {
      let { donor_list } = this.updateDoc;
      let donor = {};
      if (isDocObject(donor_list)) {
        donor = donor_list[0];
      }
      return donor;
    },
    isOrganAllocation() {
      let status = [2, 3, 4, 5];
      return status.indexOf(this.donor.final_donor_status) != -1;
    },
  },
  methods: {
    onMobileTabClick(id) {
      if (id == 0) {
        this.layer.allocation = true;
      } else {
        this.layer.tab = true;
      }
    },
    isTissue({ organ_id }) {
      let organ = this.masterOrgans.find((item) => {
        return item.organ_id == organ_id && item.tissue_bank == 1;
      });
      return organ != null;
    },
    refresh() {
      this.isRefresh = false;
      setTimeout(() => {
        this.isRefresh = true;
      }, 100);
    },
    async initOrgans() {
      const vm = this;
      let { id } = vm;
      let { hospital_id } = vm.donor;
      vm.list = [];
      vm.allocation_list = [];
      try {
        vm.$loader.show();
        let { data } = await donorService.getAllocatedOrgans({
          dml_indicator: "S",
          hospital_id,
          donor_temp_registration_id: id,
        });
        let { status, msg, list, allocation_list, allocation_list_history } =
          data;
        vm.final_donor_status = data.Final_donor_status;
        vm.dialog = false;
        vm.form = {};
        if (!status) {
          vm.$alert.show(msg);
        }
        vm.allocation_list = allocation_list.map((item) => {
          item.organ_id_org = item.organ_id;
          return item;
        });
        vm.allocation_list_history = allocation_list_history;
        vm.list = list;
        setTimeout(() => {
          vm.$refs.tabs.init();
          this.refresh();
        }, 500);
        vm.$loader.hide();
        vm.init();
      } catch (error) {
        vm.$alert.show(error.message);
        vm.$loader.hide();
      }
    },
    async init() {
      const vm = this;
      try {
        vm.$loader.show();
        vm.hospitals = [];
        let { user_type, hospital_id } = vm.$store.getters.user;
        let doc = { user_type };
        if (user_type == 1) {
          doc.hospital_id = hospital_id;
          vm.form.hospital_id = hospital_id;
        }
        let { data } = await hospitalService.list(doc);
        let { status, msg, list } = data;
        if (!status) {
          vm.$loader.hide();
          vm.$alert.show(msg);
          return;
        }
        vm.$loader.hide();
        vm.hospitals = list.map((item) => {
          item.name = item.hospital_name;
          item.value = item.hospital_id;
          return item;
        });
      } catch (error) {
        vm.$loader.hide();
        vm.$alert.show(error.message);
      }
    },
  },
  beforeDestroy() {
    Event.$off("refresh");
    this.timer.deactivate();
  },
  mounted() {
    Event.$on("refresh", () => {
      this.initOrgans();
    });
    // let isFirst = true;
    this.timer = new Timer(() => {
      this.initOrgans();
      console.log("60 seconds idle initiated");
    }, 60 * 1000);
    this.timer.activate();
    document.body.classList.remove("vertical-collpsed");
    document.body.classList.toggle("vertical-collpsed");
  },
};
</script>

<style lang="scss">
.popup-layer {
  padding-top: 70px !important;
  overflow-y: auto;
}

.allocation__view {
  display: flex;
  &-left {
    min-width: 130px;
    margin-left: -10px;
  }
  &-right {
    flex: 1;
    margin-left: 0px;
    margin-right: -0px;
    width: calc(100% - 150px);
  }
}
</style>
