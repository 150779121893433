<template>
  <div>
    <CMInsuranceFilter
      v-model="filter"
      @hospitals="hospitals = $event"
      @fetch="fetch"
    ></CMInsuranceFilter>
    <CMInsuranceTable
      :isLoading="isLoading"
      :list="list"
      @fetch="fetch"
    ></CMInsuranceTable>
  </div>
</template>

<script>
import recipientService from "../../assets/services/recipient.service";
import CMInsuranceFilter from "../../components/waitingList/CMInsurance/CMInsuranceFilter.vue";
import CMInsuranceTable from "../../components/waitingList/CMInsurance/CMInsuranceTable.vue";
export default {
  components: { CMInsuranceFilter, CMInsuranceTable },
  data() {
    return {
      isLoading: false,
      filter: {},
      list: [],
      hospitals: [],
    };
  },
  methods: {
    async fetch() {
      const vm = this;
      try {
        vm.isLoading = true;
        vm.list = [];
        let { data } = await recipientService.getCMInsuranceWaitingList(
          vm.filter
        );
        let { status, msg, list } = data;
        vm.isLoading = false;
        if (!status) {
          vm.$alert.show(msg);
          return;
        }
        vm.list = list;
      } catch (error) {
        vm.isLoading = false;
        vm.$alert.show(error.message);
      }
    },
  },
};
</script>

<style></style>
